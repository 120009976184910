import React, { FC, MouseEvent, useCallback, useRef } from 'react';
import { NavLink, match, useHistory } from 'react-router-dom';
import { Location, LocationState } from 'history';
import { classnames } from 'tailwindcss-classnames';

interface HeaderTabProps {
  title: string;
  path?: string;
  strict?: boolean;
  exact?: boolean;
  isDisabled?: boolean;
  isActive?: <Params extends { [K in keyof Params]?: string }>(
    matchParam: match<Params> | null,
    location: Location<LocationState>
  ) => boolean;
  isSelected?: boolean; // mobile tab option
  selectMobileTab?: () => void; // mobile tab option
}

export const HeaderTab: FC<HeaderTabProps> = ({
  title,
  path,
  strict,
  exact,
  isDisabled,
  isActive,
  isSelected,
  selectMobileTab,
}) => {
  const history = useHistory();

  const navLinkClass = classnames({
    'hover:text-primary-900': !isDisabled,
    'cursor-default': isDisabled,
    'text-primary-250': isDisabled,
    'text-primary-400': !isDisabled,
  });

  const ref = useRef<HTMLSpanElement>(null);
  const navigateToPath = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
      event.preventDefault();
      if (!isDisabled && path) {
        history.push(path);
      }
    },
    [history, isDisabled, path]
  );

  const mobileButtonClass = classnames(
    { 'border-green': isSelected },
    { 'text-primary-900': isSelected },
    { 'text-primary-400': !isSelected },
    { 'hover:text-primary-900': !isSelected },
    { 'border-white': !isSelected }
  );

  return path ? (
    <NavLink
      className={`pb-2 mr-5 text-base md:mr-7.5 ${navLinkClass}`}
      activeClassName="border-b-4 border-green text-primary-900"
      strict={strict}
      exact={exact}
      to={path}
      isActive={isActive ? isActive : undefined}
      onClick={navigateToPath}
    >
      <span className="font-semibold scroll-m-10" ref={ref}>
        {title}
      </span>
    </NavLink>
  ) : (
    <button
      className={`pb-2 mr-5 text-base md:mr-7.5 border-b-4 ${mobileButtonClass}`}
      onClick={selectMobileTab}
    >
      <span className="font-semibold">{title}</span>
    </button>
  );
};
