import React, { FC } from 'react';

import iconMetricUp from 'assets/images/pages/dashboard/facilities/icon__metric-up.png';
import iconMetricDown from 'assets/images/pages/dashboard/facilities/icon__metric-down.png';
import iconMetricUp2x from 'assets/images/pages/dashboard/facilities/icon__metric-up@2x.png';
import iconMetricDown2x from 'assets/images/pages/dashboard/facilities/icon__metric-down@2x.png';
import { classnames } from 'tailwindcss-classnames';

interface PercentageChangeIndicatorProps {
  percentageChange: number | null;
  alwaysSmall?: boolean;
}

export const PercentageChangeIndicator: FC<PercentageChangeIndicatorProps> = ({
  percentageChange,
  alwaysSmall,
}) => {
  let metricIcon, metricIcon2x;

  if (!percentageChange || Math.abs(percentageChange) > 1000) {
    return null;
  }

  const percentageNumberClass = classnames(
    { hidden: alwaysSmall },
    { 'text-green': percentageChange > 0 },
    { 'text-red': percentageChange < 0 },
    'text-sm',
    'font-semibold'
  );

  if (percentageChange > 0) {
    metricIcon = iconMetricUp;
    metricIcon2x = iconMetricUp2x;
  } else {
    metricIcon = iconMetricDown;
    metricIcon2x = iconMetricDown2x;
  }

  return (
    <span className="flex items-baseline space-x-1">
      <picture>
        <img
          className="w-3 transform translate-y-0.5"
          src={metricIcon2x}
          srcSet={`${metricIcon} 1x, ${metricIcon2x} 2x`}
        />
      </picture>
      <span className={percentageNumberClass}>
        {Math.abs(percentageChange).toFixed(1)}%
      </span>
    </span>
  );
};
