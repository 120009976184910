import React, { FC } from 'react';
import { AdminStats } from '~/components/admin';
import { ContentContainer } from '~/components/layout';
import { AdminFacilityTable } from '~/components/table/adminFacilityTable/AdminFacilityTable';
import { UsersTable } from '~/components/table/usersTable';

export const AdminOverviewPage: FC = () => {
  return (
    <ContentContainer className="py-12.5">
      <AdminStats />
      <div className="grid grid-cols-1 mt-8 2xl:grid-cols-2 gap-x-15">
        <AdminFacilityTable showTitle xlWidth="half" rowsPerPage={7} />
        <UsersTable showTitle xlWidth="half" rowsPerPage={7} />
      </div>
    </ContentContainer>
  );
};
