import { PostCalendarEventBody } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const postCalendarEvent = async (
  body: PostCalendarEventBody
): Promise<PostCalendarEventBody> => {
  const { attachments, ...rest } = body;
  const { data } = await queryBuilder(
    'POST',
    'calendar/event',
    undefined,
    rest,
    attachments
  );
  return data;
};
