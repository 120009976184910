import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import { FacilityOverview, FacilityTeam } from '~/components/facilityOverview';
import { FacilityAboutPage, FacilityWeatherPage } from '~/pages/facility';
import { RoutesPath } from '~/types';
import { PrivateRoute } from './PrivateRoute';

export const FacilityOverviewContentRouter: FC = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        strict
        path={RoutesPath.FacilityOverview}
        component={FacilityOverview}
      />
      <PrivateRoute
        exact
        strict
        path={RoutesPath.FacilityTeam}
        component={FacilityTeam}
      />
      <PrivateRoute
        exact
        strict
        path={RoutesPath.FacilityAbout}
        component={FacilityAboutPage}
      />
      <PrivateRoute
        exact
        strict
        path={RoutesPath.FacilityWeather}
        component={FacilityWeatherPage}
      />
    </Switch>
  );
};
