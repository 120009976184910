import { Facility } from '@intelligent-play-v2/domain';
import { LatLong } from '~/types';

export const getLatLng = (facility: Facility): google.maps.LatLng => {
  // pgtyped latlong is incorrect type number[] -> cast to LatLong type
  let latLong: LatLong | null = null;

  if (facility.latLong) {
    latLong = (facility.latLong as unknown) as LatLong;
  } else {
    if (facility.pitches && facility.pitches[0].devices) {
      latLong = (facility.pitches[0].devices[0].latLong as unknown) as LatLong;
    }
  }
  if (latLong) {
    return new google.maps.LatLng(latLong.x, latLong.y);
  }
  return new google.maps.LatLng(0, 0);
};
