import React, { FC } from 'react';
import settingsIcon from 'assets/images/navs/icon__settings-dark.png';

import mobileMenuIcon from 'assets/images/navs/icon__mobile.png';
import settingsIcon2x from 'assets/images/navs/icon__settings-dark@2x.png';
import mobileMenuIcon2x from 'assets/images/navs/icon__mobile@2x.png';
import { AlertsDropdown } from './AlertsDropdown';
import { UserMenuDropdown } from './UserMenuDropdown';
import { formatDateGbUs } from '~/utils';
import { RoutesPath } from '~/types';
import { Link } from 'react-router-dom';
import { useAuthStore } from '~/stores';

interface StatusBarProps {
  toggleMenu: () => void;
}

export const StatusBar: FC<StatusBarProps> = ({ toggleMenu }) => {
  const { user } = useAuthStore();
  const utcNow = new Date();

  let lastLogin = formatDateGbUs(utcNow, 'E d MMM yyyy');
  if (user && user.userActivity && user.userActivity[1]) {
    lastLogin = formatDateGbUs(
      new Date(user.userActivity[1].startTime),
      'E d MMM yyyy'
    );
  }

  return (
    <div className="sticky top-0 z-30 flex items-center justify-between h-15 bg-white border-b border-primary-100">
      <picture
        onClick={toggleMenu}
        className="ml-3 cursor-pointer md:invisible"
      >
        <img
          src={mobileMenuIcon}
          srcSet={`${mobileMenuIcon} 1x, ${mobileMenuIcon2x} 2x`}
        />
      </picture>
      <div className="flex items-center py-2 space-x-5" id="menu-bar">
        <div className="hidden text-sm text-primary-400 md:block">
          Last login: {lastLogin}
        </div>
        <div id="alert-buttons" className="inline-flex px-2">
          <AlertsDropdown />
          <Link to={RoutesPath.Settings}>
            <picture>
              <img
                className="transform cursor-pointer hover:rotate-12"
                src={settingsIcon2x}
                srcSet={`${settingsIcon} 1x, ${settingsIcon2x} 2x`}
              />
            </picture>
          </Link>
        </div>
        <UserMenuDropdown />
      </div>
    </div>
  );
};
