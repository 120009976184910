import React, { FC, useCallback, useState } from 'react';

import {
  DailyRanges,
  OutOfHours,
  Pitch,
  TimeRange,
  UserAlertSubscription,
  UserType,
} from '@intelligent-play-v2/domain';
import { PitchUserAlertSettings } from './PitchUserAlertSettings';
import { PitchSnapshotScheduleSettings } from './PitchSnapshotScheduleSettings';
import { useAuthStore } from '~/stores';
import { PitchOutOfHoursSettings } from './PitchOutOfHoursSettings';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { putOutOfHours } from '~/api/queries/outOfHours/putPitchOutOfHours';
import { Button, ButtonType } from '../button';
import { putUserAlertSubscriptions } from '~/api';

interface PitchSettingsProps {
  pitch: Pitch;
  userAlertSubscriptions: UserAlertSubscription[];
  outOfHours: OutOfHours;
}

export const PitchSettings: FC<PitchSettingsProps> = ({
  pitch,
  userAlertSubscriptions,
  outOfHours,
}) => {
  const { user } = useAuthStore();
  const queryClient = useQueryClient();

  const pitchDailyRanges = convertDailyRangesFromDb(outOfHours.dailyRanges);

  const [dailyRanges, setDailyRanges] = useState<DailyRanges>(pitchDailyRanges);

  const [selectedSubscriptions, setSelectedSubscriptions] = useState<
    UserAlertSubscription[]
  >(userAlertSubscriptions);

  const setSelectedSubscription = useCallback(
    (alertTypeId: number, isChecked: boolean) => {
      let newSubscriptions = [...selectedSubscriptions];
      if (isChecked) {
        newSubscriptions?.push({
          pitchId: pitch.id,
          alertTypeId,
          userId: user!.id,
        });
      } else {
        newSubscriptions = newSubscriptions.filter(
          subscription => alertTypeId !== subscription.alertTypeId
        );
      }
      setSelectedSubscriptions(newSubscriptions);
    },
    [pitch.id, selectedSubscriptions, user]
  );

  const handleUpdateDailyRange = (
    day: keyof DailyRanges,
    timeSetting: keyof TimeRange,
    value: string
  ): void => {
    const newDailyRanges = { ...dailyRanges };
    newDailyRanges[day][timeSetting] = value;
    setDailyRanges(newDailyRanges);
  };

  const {
    mutate: mutateSavePitchOutOfHours,
    isLoading: isSavingPitchOutOfHours,
  } = useMutation(
    async () =>
      putOutOfHours(outOfHours.id, {
        pitchId: outOfHours.pitchId,
        dailyRanges: convertDailyRangesToDb(dailyRanges),
      }),
    {
      onSuccess: () => {
        toast.success('Saved pitch out of hours times');
        queryClient.invalidateQueries(['getPitchOutOfHours']);
      },
    }
  );

  const {
    mutate: mutateSavePitchUserAlertSubscriptions,
    isLoading: isSavingUserAlertSubscriptions,
  } = useMutation(
    async () => putUserAlertSubscriptions(pitch.id, selectedSubscriptions),
    {
      onSuccess: () => {
        toast.success('Successfully saved alert subscriptions');
      },
      onError: () => {
        toast.error('Error saving alert subscriptions');
      },
    }
  );

  const handleSavePitchSettings = (): void => {
    mutateSavePitchUserAlertSubscriptions();
    mutateSavePitchOutOfHours();
  };

  const isSaving = isSavingPitchOutOfHours || isSavingUserAlertSubscriptions;

  const isAdmin =
    user &&
    (user.userTypeId === UserType.FacilityAdmin ||
      user.userTypeId === UserType.SystemAdmin);

  return (
    <div
      key={pitch.id}
      className="flex-col justify-around space-y-4 border rounded-xs border-primary-250"
    >
      <div className="inline-flex items-center justify-between w-full p-3 border-b border-primary-100">
        <h5 className="text-lg font-semibold transform">{pitch.name}</h5>
        <Button
          size="small"
          className="w-auto"
          type={ButtonType.Primary}
          text="Save changes"
          onClick={handleSavePitchSettings}
          isLoading={isSaving}
        />
      </div>

      <PitchUserAlertSettings
        pitch={pitch}
        selectedSubscriptions={selectedSubscriptions}
        setSelectedSubscription={setSelectedSubscription}
      />
      <hr className="border-primary-100" />

      {!!outOfHours && isAdmin && (
        <PitchOutOfHoursSettings
          pitchDailyRanges={dailyRanges}
          handleUpdateDailyRange={handleUpdateDailyRange}
        />
      )}

      {isAdmin && (
        <>
          <hr className="border-primary-100" />
          <PitchSnapshotScheduleSettings pitch={pitch} />
        </>
      )}
    </div>
  );
};

const convertDailyRangesFromDb = (dailyRanges: DailyRanges): DailyRanges =>
  Object.entries(dailyRanges).reduce((acc, [day, { start, end }]) => {
    acc[day as keyof DailyRanges] = {
      start: formatTimeStringFromDb(start),
      end: formatTimeStringFromDb(end),
    };
    return acc;
  }, {} as DailyRanges);

const convertDailyRangesToDb = (dailyRanges: DailyRanges): DailyRanges =>
  Object.entries(dailyRanges).reduce((acc, [day, { start, end }]) => {
    acc[day as keyof DailyRanges] = {
      start: formatTimeStringToDb(start),
      end: formatTimeStringToDb(end),
    };
    return acc;
  }, {} as DailyRanges);

const formatTimeStringFromDb = (time: string): string =>
  time ? `${time.slice(0, 2)}:${time.slice(2)}` : '';

const formatTimeStringToDb = (time: string): string => time.split(':').join('');
