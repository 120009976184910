import React, { FC } from 'react';
import { Loading } from '../loading';

interface HeatmapContainerProps {
  heatmapImage: string | null;
  isLoadingHeatmap: boolean;
  pitchWidth: number | null;
  pitchLength: number | null;
}

export const HeatmapContainer: FC<HeatmapContainerProps> = ({
  heatmapImage,
  isLoadingHeatmap,
  pitchWidth,
  pitchLength,
}) => {
  let aspectRatio = 801 / 481;
  if (pitchWidth && pitchLength) {
    aspectRatio = pitchLength / pitchWidth;
  }

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        aspectRatio: aspectRatio.toString(),
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(96, 157, 82)',
          zIndex: 0,
        }}
      >
        {heatmapImage ? (
          <img
            src={heatmapImage}
            className="w-full"
            style={{ aspectRatio: aspectRatio.toString() }}
          />
        ) : (
          <div
            style={{
              width: '100%',
              aspectRatio: aspectRatio.toString(),
              display: 'grid',
              placeItems: 'center',
              background: isLoadingHeatmap ? 'none' : 'white',
              opacity: isLoadingHeatmap ? 1 : 0.5,
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 10,
            }}
          >
            {isLoadingHeatmap ? <Loading /> : <span>No heatmap data</span>}
          </div>
        )}
      </div>
    </div>
  );
};
