import { SportType } from '@intelligent-play-v2/domain';
import iconCricket from 'assets/images/sports/icon__sport-cricket__14px-dark.png';
import iconCricket2x from 'assets/images/sports/icon__sport-cricket__14px-dark@2x.png';
import iconFootball from 'assets/images/sports/icon__sport-football__14px-dark.png';
import iconFootball2x from 'assets/images/sports/icon__sport-football__14px-dark@2x.png';
import iconHockey from 'assets/images/sports/icon__sport-hockey__14px-dark.png';
import iconHockey2x from 'assets/images/sports/icon__sport-hockey__14px-dark@2x.png';
import iconLacrosse from 'assets/images/sports/icon__sport-lacrosse__14px-dark.png';
import iconLacrosse2x from 'assets/images/sports/icon__sport-lacrosse__14px-dark@2x.png';
import iconRugby from 'assets/images/sports/icon__sport-rugby__14px-dark.png';
import iconRugby2x from 'assets/images/sports/icon__sport-rugby__14px-dark@2x.png';
import iconSoccer from 'assets/images/sports/icon__sport-soccer__14px-dark.png';
import iconSoccer2x from 'assets/images/sports/icon__sport-soccer__14px-dark@2x.png';
import { Country } from 'countries-and-timezones';

export const getIconForSportType = (sportType: SportType): [string, string] => {
  let icon = '',
    icon2x = '';

  switch (sportType) {
    case SportType.Soccer:
      icon = iconSoccer;
      icon2x = iconSoccer2x;
      break;
    case SportType['American Football']:
    case SportType.AFL:
      icon = iconFootball;
      icon2x = iconFootball2x;
      break;
    case SportType.Hockey:
      icon = iconHockey;
      icon2x = iconHockey2x;
      break;
    case SportType.Rugby:
      icon = iconRugby;
      icon2x = iconRugby2x;
      break;
    case SportType.Cricket:
      icon = iconCricket;
      icon = iconCricket2x;
      break;
    case SportType.Lacrosse:
      icon = iconLacrosse;
      icon2x = iconLacrosse2x;
      break;
    default:
      break;
  }

  return [icon, icon2x];
};

export const getLocalisedSportName = (
  sportType: SportType,
  country: Country | null
): string => {
  let sportName = SportType[sportType];
  if (!country) {
    return sportName;
  }
  if (country) {
    switch (sportType) {
      case SportType.Soccer:
        if (country.id !== 'US' && country.id !== 'AU') {
          sportName = 'Football';
        }
        break;
      case SportType.AFL:
        if (country.id === 'AU') {
          sportName = 'Football';
        }
        break;
      case SportType['American Football']:
        if (country.id === 'US') {
          sportName = 'Football';
        }
        break;
      default:
        break;
    }
  }

  return sportName;
};
