import React, { ChangeEvent, FC, MouseEvent, RefObject, useState } from 'react';
import classnames, { TArg } from 'tailwindcss-classnames';
import hiddenIcon from 'assets/images/ctas_inputs_modals/icon__input-hidden.png';
import hiddenIcon2x from 'assets/images/ctas_inputs_modals/icon__input-hidden@2x.png';
import { UseFormRegisterReturn } from 'react-hook-form';

interface InputProps {
  register?: UseFormRegisterReturn;
  placeholder?: string;
  required?: boolean;
  value?: string;
  onChange?: (text: string) => void;
  label?: string;
  isLightMode?: boolean;
  type?: string;
  errorMessage?: string;
  buttonText?: string;
  buttonAction?: () => void;
  isFocused?: boolean;
  onChangeFocus?: (isFocused: boolean) => void;
  disabled?: boolean;
  icon?: string;
  icon2x?: string;
  disableManualInput?: boolean;
  hasBottomPopup?: boolean;
  fieldRef?: RefObject<HTMLDivElement>;
  readOnly?: boolean;
  maxLength?: number;
  isMultiline?: boolean;
  size?: 'small' | 'normal';
  className?: string | TArg;
}

export const TextInput: FC<InputProps> = ({
  register,
  label,
  value,
  onChange,
  placeholder = '',
  required = false,
  isLightMode = false,
  type,
  errorMessage,
  buttonText,
  buttonAction,
  isFocused,
  onChangeFocus,
  disabled,
  icon,
  icon2x,
  disableManualInput = false,
  hasBottomPopup = false,
  fieldRef,
  readOnly,
  maxLength,
  isMultiline = false,
  size = 'normal',
  className = '',
}) => {
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const requiredChar = required ? '*' : '';
  const labelText = errorMessage
    ? errorMessage
    : label
    ? `${label}${requiredChar}`
    : null;

  const inputClassnames = (className as string).split(' ') as TArg[];

  const isPasswordField = type === 'password';

  const buttonActionHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    if (!buttonAction) {
      return;
    }
    buttonAction();
  };

  const passwordToggleHandler = (
    event: MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    setIsShowingPassword(!isShowingPassword);
  };

  const onChangeFocusHandler = (focused: boolean): void => {
    if (onChangeFocus && focused) {
      onChangeFocus(focused);
    }
  };

  let onChangeHandler:
    | undefined
    | ((
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => void) = undefined;

  if (onChange) {
    onChangeHandler = event => {
      event.preventDefault();
      if (onChange) {
        onChange(event.target.value);
      }
    };
  } else if (register) {
    onChangeHandler = register.onChange;
  }

  const inputClass = classnames(
    { 'pr-12': isPasswordField || !!errorMessage },
    { 'border-red': !!errorMessage },
    { border: (!!errorMessage || isLightMode) && !hasBottomPopup },
    { 'shadow-red': !!errorMessage },
    { 'placeholder-red': !!errorMessage },
    { 'text-red': !!errorMessage },
    { 'bg-input-error': !!errorMessage },
    { 'bg-primary-700': !isLightMode },
    { 'bg-white': isLightMode },
    { 'shadow-575': !isLightMode },
    { 'shadow-250': isLightMode },
    { 'border-primary-250': isLightMode },
    { 'placeholder-primary-700': isLightMode },
    { 'placeholder-white': !isLightMode },
    { 'shadow-green': isFocused },
    { 'border-green': isFocused },
    { 'pl-10': !!icon },
    { 'pr-4': !!icon },
    { 'text-primary-400': disabled },
    { 'cursor-not-allowed': disabled },
    { rounded: !hasBottomPopup },
    { 'rounded-t': hasBottomPopup },
    { 'border-l': hasBottomPopup },
    { 'border-r': hasBottomPopup },
    { 'border-t': hasBottomPopup },
    { 'h-12.5': size === 'normal' },
    { 'px-4': size === 'normal' },
    { 'py-4': size === 'normal' },
    { 'h-8': size === 'small' },
    { 'px-2': size === 'small' },
    { 'py-2': size === 'small' },
    { 'h-20': isMultiline },
    'w-full',
    'h-12.5',
    'text-base',
    'placeholder-opacity-50',
    'outline-none',
    'bg-right-4',
    'bg-no-repeat',
    ...inputClassnames
  );

  const style = disableManualInput ? { caretColor: 'transparent' } : {};

  const labelClass = classnames(
    { 'text-red': !!errorMessage },
    { 'text-primary-400': disabled }
  );

  const hiddenIconClass = classnames({
    'opacity-30': !isShowingPassword,
  });

  return (
    <div className="flex-grow">
      <div className="flex justify-between text-sm font-semibold">
        <p className={labelClass}>{labelText}</p>
        {!!buttonText && (
          <button
            type="button"
            onClick={buttonActionHandler}
            className="text-green cursor-pointer hover:text-green-dark"
          >
            {buttonText}
          </button>
        )}
      </div>
      <div ref={fieldRef} className="relative mt-2.5">
        {icon && (
          <img
            className="absolute top-5 left-4 w-4.5"
            src={icon2x}
            srcSet={`${icon} 1x, ${icon2x} 2x`}
          />
        )}
        {isMultiline ? (
          <textarea
            className={inputClass}
            placeholder={placeholder}
            {...register}
            value={value ?? undefined}
            onChange={onChangeHandler}
            onFocus={() => onChangeFocusHandler(true)}
            onBlur={() => onChangeFocusHandler(false)}
            disabled={disabled}
            style={style}
            readOnly={readOnly}
            maxLength={maxLength}
          />
        ) : (
          <input
            className={inputClass}
            placeholder={placeholder}
            type={type === 'password' && isShowingPassword ? 'text' : type}
            {...register}
            value={value ?? undefined}
            onChange={onChangeHandler}
            onFocus={() => onChangeFocusHandler(true)}
            onBlur={() => onChangeFocusHandler(false)}
            disabled={disabled}
            style={style}
            readOnly={readOnly}
            maxLength={maxLength}
            autoComplete={type}
          />
        )}
        {isPasswordField && (
          <button
            type="button"
            onClick={passwordToggleHandler}
            className="absolute top-0 bottom-0 right-0 flex flex-col items-center justify-center w-12"
          >
            {!errorMessage && (
              <img
                className={hiddenIconClass}
                src={hiddenIcon2x}
                srcSet={`${hiddenIcon} 1x, ${hiddenIcon2x} 2x`}
              />
            )}
          </button>
        )}
      </div>
    </div>
  );
};
