import React, { FC, useEffect, useRef, useState } from 'react';
import { useContainerDimensions } from '~/hooks';
import { sortByPitchNumber } from '~/utils';
import { SearchBar } from '../searchBar';
import { PitchRow, PitchSummaryCard } from './PitchSummaryCard';
interface PitchSummaryCardsProps {
  pitches: PitchRow[];
  focusedPitch?: number;
  isLoading?: boolean;
}

export const PitchSummaryCards: FC<PitchSummaryCardsProps> = ({
  pitches,
  focusedPitch,
  isLoading,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { width, handleResize } = useContainerDimensions(componentRef);
  const [filteredPitches, setFilteredPitches] = useState<PitchRow[]>([]);
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    setFilteredPitches(
      pitches
        .filter(
          pitch =>
            pitch.name?.toUpperCase().includes(searchText.toUpperCase()) ||
            pitch.facilityPitchNumber?.toString().includes(searchText)
        )
        .sort(sortByPitchNumber)
    );
  }, [pitches, searchText]);

  return (
    <div className="w-full md:w-1/2 lg:w-2/3">
      <div className="text-sm font-medium text-primary-400">
        {`Total fields: ${pitches.length}`}
      </div>
      <div className="mt-2.5">
        <SearchBar searchText={searchText} onChange={setSearchText} />
      </div>
      <div className="relative">
        <div
          ref={componentRef}
          className="pb-4 space-x-3 overflow-x-auto whitespace-nowrap over"
        >
          {filteredPitches.map(pitch => (
            <PitchSummaryCard
              pitch={pitch}
              key={pitch.id}
              focusedPitch={focusedPitch}
              isLoading={isLoading}
            />
          ))}
        </div>
        {width < (componentRef.current?.scrollWidth || 0) && (
          <div className="absolute top-0 bottom-0 right-0 w-10 pointer-events-none bg-gradient-to-r from-gradientOpacity to-primary-100" />
        )}
      </div>
    </div>
  );
};
