import React, { FC, useCallback, useMemo } from 'react';
import { AreaClosed, Bar, Line, LinePath } from '@visx/shape';
import { Tooltip, defaultStyles, useTooltip } from '@visx/tooltip';
import { scaleLinear, scaleTime } from '@visx/scale';
import { curveMonotoneX } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { localPoint } from '@visx/event';
import { bisector, extent, max } from 'd3-array';
import { KeyStatisticColour } from '~/types';
import { classnames } from 'tailwindcss-classnames';
import { formatDateGbUs, formatNumber } from '~/utils';

interface DatePoint {
  date: string;
  hours: number;
}
interface KeyStatisticsGraphProps {
  data: DatePoint[];
  comparisonData: DatePoint[] | null;
  width: number;
  height: number;
  colour?: KeyStatisticColour;
}

export const KeyStatisticsGraph: FC<KeyStatisticsGraphProps> = ({
  data,
  comparisonData,
  width,
  height,
  colour = KeyStatisticColour.Green,
}) => {
  const { tooltipData, tooltipTop, showTooltip, hideTooltip } = useTooltip<
    DatePoint[]
  >();

  const tooltipStyles = {
    ...defaultStyles,
    background: 'white',
    color: 'black',
    border:
      colour === KeyStatisticColour.Green
        ? '#519c42 solid 1px'
        : '#c99f3a solid 1px',
    transform: 'translateX(-75%)',
    padding: '2px 4px',
    boxShadow: 'none',
    zIndex: 999,
  };

  const tooltipTextClass = classnames(
    { 'text-green': colour === KeyStatisticColour.Green },
    { 'text-yellow': colour === KeyStatisticColour.Yellow },
    'text-xs',
    'font-semibold',
    'whitespace-nowrap'
  );

  const margin = {
    top: 0,
    bottom: 12,
    left: 0,
    right: 0,
  };

  const getDate = (d: DatePoint): Date => new Date(d.date);
  const getHoursValue = (d: DatePoint): number => d.hours;
  const bisectDate = bisector<DatePoint, Date>(d => new Date(d.date)).left;

  const getMaxSeriesValue = useCallback(() => {
    let dataMax = max(data, getHoursValue) || 1;
    if (comparisonData) {
      const comparisonDataMax = max(comparisonData, getHoursValue) || 1;
      dataMax = Math.max(comparisonDataMax, dataMax);
    }
    return dataMax === 1 ? 1 : dataMax + dataMax / 10;
  }, [data, comparisonData]);

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: extent(data, getDate) as [Date, Date],
      }),
    [data, xMax]
  );

  const hoursValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [0, getMaxSeriesValue()],
        nice: true,
      }),
    [getMaxSeriesValue, yMax]
  );

  const handleTooltip = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
  ): void => {
    if (data.length) {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = dateScale.invert(x);
      const series = [data];
      if (comparisonData) {
        series.push(comparisonData);
      }
      const tooltipDataArray = series.map(item => {
        const index = bisectDate(item, x0, 1);
        const d0 = item[index - 1];
        const d1 = item[index];
        let d = d0;
        if (d1 && getDate(d1)) {
          d =
            x0.valueOf() - getDate(d0).valueOf() >
            getDate(d1).valueOf() - x0.valueOf()
              ? d1
              : d0;
        }
        return d;
      });

      showTooltip({
        tooltipTop:
          hoursValueScale(getHoursValue(tooltipDataArray[0])) + margin.top,
        tooltipData: tooltipDataArray,
      });
    }
  };

  return (
    <div className="absolute">
      <svg width={width} height={height}>
        <LinearGradient
          id="area-gradient"
          from="#E5F0E3"
          to="#E5F0E3"
          toOpacity={0}
        />
        <LinearGradient
          id="area-gradient-yellow"
          from="#f7f1e2"
          to="#f7f1e2"
          toOpacity={0}
        />
        {data.map(d => {
          return (
            <Bar
              key={`bar-${d.date}`}
              x={dateScale(getDate(d)) ?? 0}
              y={hoursValueScale(getHoursValue(d)) ?? 0}
              width={1}
              height={yMax - (hoursValueScale(getHoursValue(d)) ?? 0)}
              fill={colour === KeyStatisticColour.Green ? '#E5F0E3' : '#f7f1e2'}
            />
          );
        })}
        <AreaClosed
          data={data}
          x={d => dateScale(getDate(d)) ?? 0}
          y={d => hoursValueScale(getHoursValue(d)) ?? 0}
          yScale={hoursValueScale}
          strokeWidth={1}
          fill={
            colour === KeyStatisticColour.Green
              ? 'url(#area-gradient)'
              : 'url(#area-gradient-yellow)'
          }
          curve={curveMonotoneX}
        />
        <LinePath
          data={data}
          curve={curveMonotoneX}
          x={d => dateScale(getDate(d)) ?? 0}
          y={d => hoursValueScale(getHoursValue(d)) ?? 0}
          stroke={colour === KeyStatisticColour.Green ? '#519c42' : '#c99f3a'}
          strokeWidth={1}
        />
        {comparisonData && (
          <LinePath
            data={comparisonData}
            curve={curveMonotoneX}
            x={d => dateScale(getDate(d)) ?? 0}
            y={d => hoursValueScale(getHoursValue(d)) ?? 0}
            stroke={'#4054B2'}
            strokeWidth={1}
          />
        )}
        <Bar
          x={margin.left}
          y={margin.top}
          width={xMax}
          height={yMax}
          fill="transparent"
          rx={14}
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
        {tooltipData && (
          <>
            <circle
              cx={dateScale(getDate(tooltipData[0])) ?? 0}
              cy={tooltipTop}
              r={2}
              fill="transparent"
              stroke={
                colour === KeyStatisticColour.Green ? '#519c42' : '#c99f3a'
              }
              strokeWidth={1}
              pointerEvents="none"
            />
            <Line
              from={{
                x: dateScale(getDate(tooltipData[0])) ?? 0,
                y: tooltipTop,
              }}
              to={{
                x: dateScale(getDate(tooltipData[0])) ?? 0,
                y: (tooltipTop ?? 0) + 20,
              }}
              stroke={
                colour === KeyStatisticColour.Green ? '#519c42' : '#c99f3a'
              }
              strokeWidth={1}
              pointerEvents="none"
            />
          </>
        )}
      </svg>
      {tooltipData && (
        <Tooltip
          key={Math.random()}
          top={tooltipTop}
          left={dateScale(getDate(tooltipData[0])) ?? 0}
          style={tooltipStyles}
        >
          <div className={tooltipTextClass}>
            {formatDateGbUs(getDate(tooltipData[0]), 'dd/MM')}
          </div>
          <div className="flex items-center">
            {comparisonData && <div className="w-2.5 h-0.5 mr-1 bg-green" />}
            <div className="text-base font-semibold whitespace-nowrap">
              {formatNumber(getHoursValue(tooltipData[0]))}
            </div>
          </div>
          {comparisonData && (
            <div className="flex items-center">
              <div className="w-2.5 h-0.5 bg-blue" />
              <div className="ml-1 text-base font-semibold whitespace-nowrap">
                {formatNumber(getHoursValue(tooltipData[1]))}
              </div>
            </div>
          )}
        </Tooltip>
      )}
    </div>
  );
};
