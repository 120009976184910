import { addDays, format, isSameDay, subDays } from 'date-fns';
import React, { FC } from 'react';

import { celsiusToFahrenheit } from './utils';
import { getWeatherIcon } from '~/utils';
import { DailyWeather } from '@intelligent-play-v2/domain';

interface SelectedWeatherCardProps {
  date: string;
  weather: DailyWeather;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  isCelsius: boolean;
}

export const SelectedWeatherCard: FC<SelectedWeatherCardProps> = ({
  date,
  weather,
  setSelectedDate,
  isCelsius,
}) => {
  const dateObject = new Date(date);
  const isYesterday = isSameDay(addDays(dateObject, 1), new Date());
  const isToday = isSameDay(dateObject, new Date());
  const isTomorrow = isSameDay(subDays(dateObject, 1), new Date());

  const dateString = isYesterday
    ? 'Yesterday'
    : isToday
    ? 'Today'
    : isTomorrow
    ? 'Tomorrow'
    : format(dateObject, 'EEE d MMM');

  return (
    <div className="pb-2">
      <div
        className="flex flex-col flex-shrink-0 w-full bg-white rounded shadow-250 cursor-pointer sm:w-56"
        onClick={() => setSelectedDate(date)}
      >
        {/* Dates */}
        <div className="flex flex-row justify-between px-4 py-3 border-b border-primary-200">
          <span className="font-semibold">{dateString}</span>
          {(isYesterday || isToday || isTomorrow) && (
            <span>{format(dateObject, 'EEE d MMM')}</span>
          )}
        </div>

        {/* Weather overview */}
        <div className="flex flex-col justify-around h-full p-4 border-b border-primary-200">
          <div className="flex flex-row justify-around">
            <span className="w-full font-bold text-7xl">
              {isCelsius
                ? weather.highTempCelsius
                : celsiusToFahrenheit(weather.highTempCelsius)}
              °
            </span>
            <div className="flex-shrink-0 w-15 mr-2 text-center sm:text-right">
              <img
                src={getWeatherIcon(weather.weatherCode)}
                className="inline m-0"
              />
            </div>
          </div>
          <div className="flex flex-col pt-4">
            <span className="text-2.5xl three-lines-ellipsis">
              {weather.location}
            </span>
            <span className="pt-1 text-lg three-lines-ellipsis">
              {weather.summary}
            </span>
          </div>
        </div>

        {/* Weather details */}
        <div className="flex flex-row justify-start w-full p-3 space-x-7 sm:space-x-0">
          <div className="flex flex-col sm:w-1/2">
            <span className="font-bold">{weather.precipitationPercent}%</span>
            <span className="text-sm">Precipitation</span>
          </div>
          <div className="flex flex-col sm:w-1/2">
            <span className="font-bold">{weather.humidityPercent}%</span>
            <span className="text-sm">Humidity</span>
          </div>
        </div>
      </div>
    </div>
  );
};
