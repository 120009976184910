import React, { FC, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import { ResourceFaq } from '@intelligent-play-v2/domain';

import { TextInput } from '~/components/forms';
import { Button, ButtonType } from '~/components/button';
import { Modal } from '~/components/modals';

import { patchFaq } from '~/api/queries/resources/patchFaq';

interface ResourceFaqModalProps {
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  resourceFaq: ResourceFaq;
}
interface ResourceFaqFormData {
  question: string;
  answer: string;
}

export const ResourceFaqModal: FC<ResourceFaqModalProps> = ({
  showModal,
  setShowModal,
  resourceFaq,
}) => {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    setValue('question', resourceFaq.question);
    setValue('answer', resourceFaq.answer);
  }, [resourceFaq, setValue]);

  const {
    mutate: mutateUpdateResourceFaq,
    isLoading: isLoadingUpdateFaq,
  } = useMutation(patchFaq, {
    onSuccess: () => {
      setShowModal(false);
      reset();
      queryClient.invalidateQueries('faq');
      toast.success('Succesfully updated FAQ');
    },
    onError: () => {
      toast.error('Error updating FAQ');
    },
  });

  const onSubmit = (formData: ResourceFaqFormData): void => {
    mutateUpdateResourceFaq({
      ...formData,
      id: resourceFaq.id,
    });
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Edit FAQ"
      estimatedHeight={514}
    >
      <div className="w-full space-y-3 sm:w-140">
        <TextInput
          register={register('question', {
            required: 'Please enter a question',
            maxLength: 200,
          })}
          label="Question"
          placeholder="Question"
          required
          type="text"
          isLightMode
          errorMessage={errors.question?.message}
          isMultiline
          className="h-20"
          maxLength={200}
        />
        <TextInput
          register={register('answer', {
            required: 'Please enter an answer',
          })}
          label="Answer"
          placeholder="Answer"
          required
          type="text"
          isLightMode
          errorMessage={errors.answer?.message}
          isMultiline
          className="h-48"
        />

        <div className="flex mt-5 space-x-5">
          <div className="w-1/2">
            <Button
              onClick={() => setShowModal(false)}
              text="Cancel"
              type={ButtonType.Outline}
            />
          </div>
          <div className="w-1/2">
            <Button
              onClick={handleSubmit(onSubmit)}
              text="Save FAQ"
              isLoading={isLoadingUpdateFaq}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
