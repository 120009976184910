import classnames from 'classnames';
import React, { FC } from 'react';

interface LegendProps {
  text: string;
  color: 'green' | 'grey';
  onClick: () => void;
  isShowing: boolean;
}

export const Legend: FC<LegendProps> = ({
  text,
  color,
  onClick,
  isShowing,
}) => {
  const legendStyles = classnames({ 'line-through': !isShowing });
  const circleColor = classnames(
    { 'border-primary-400': color === 'grey' },
    { 'border-green': color === 'green' }
  );

  return (
    <div
      onClick={onClick}
      className={`mr-4  font-bold flex text-sm cursor-pointer ${legendStyles}`}
    >
      <div
        className={`mr-1 mt-0.5 w-3 h-3 min-w-3 border-2 rounded-lg ${circleColor}`}
      />
      {text}
    </div>
  );
};
