import { ContactUsBody } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const postContactUsRequest = async (
  body: ContactUsBody
): Promise<void> => {
  const { data } = await queryBuilder('POST', 'support/contact-us', undefined, {
    ...body,
  });
  return data;
};
