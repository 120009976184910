import {
  DatePoints,
  Facility,
  PitchMachineMaintenanceSessionsResponse,
  PitchMaintenanceHoursResponse,
  PitchPlayerCountResponse,
  PitchPlayerHoursResponse,
  PitchUsageHoursResponse,
  StatisticData,
} from '@intelligent-play-v2/domain';
import { getSeriesColor } from '~/components/graphs/SeriesColors';
import {
  ReportGraphType,
  ToggleableDataSeries,
  ToggleableDataSeriesCollection,
} from '~/types';
import { toDatePoint } from '.';
import { formatDateGbUs } from './localePreferences';

const getPitchName = (facility: Facility, pitchId: number): string => {
  const name = facility.pitches?.find(pitch => pitch.id === pitchId)?.name;
  return name || pitchId.toString();
};

type ReportDataResponse =
  | PitchMaintenanceHoursResponse[]
  | PitchPlayerCountResponse[]
  | PitchPlayerHoursResponse[]
  | PitchUsageHoursResponse[]
  | PitchMachineMaintenanceSessionsResponse[];

interface ArgsStore {
  isContinuous: boolean;
  dataResponse: ReportDataResponse;
  facility: Facility;
  timezone: string;
  reportGraphType: ReportGraphType;
  setContinuousData: (facilityId: number, data: ToggleableDataSeries[]) => void;
  setNonContinuousData: (
    facilityId: number,
    data: ToggleableDataSeriesCollection[]
  ) => void;
}

export const setDataResponseAsToggleableDataStore = ({
  isContinuous,
  dataResponse,
  facility,
  timezone,
  reportGraphType,
  setContinuousData,
  setNonContinuousData,
}: ArgsStore): void => {
  if (isContinuous) {
    const toggleableDataSeries = dataResponse.map((dataSerie, index) => {
      const identifier = reportGraphType + '-' + dataSerie.pitchId;

      // Use the the enum value as the lookup key
      const statisticData = Object.entries(dataSerie)
        .filter(([key]) => key === reportGraphType)
        .map(([, value]) => value as StatisticData)[0];

      let data: DatePoints = {};
      if (statisticData) {
        data = statisticData.data[0];
      }

      return {
        data: toDatePoint(data || [], timezone),
        showLine: true,
        color: getSeriesColor(index),
        name: getPitchName(facility, dataSerie.pitchId),
        key: identifier,
      };
    });
    setContinuousData(facility.id, toggleableDataSeries);
  } else {
    // Turn data into ToggleableDataSeriesCollection for each pitch
    const pitchDataSeriesCollection: ToggleableDataSeriesCollection[] = dataResponse.map(
      collection => {
        // Use the the enum value as the lookup key
        const statisticData = Object.entries(collection)
          .filter(([key]) => key === reportGraphType)
          .map(([, value]) => value as StatisticData)[0];

        let data: DatePoints[] = [];
        if (statisticData) {
          data = statisticData.data;
        }

        const toggleableDays: ToggleableDataSeries[] = data.map(
          (dataItem, index) => {
            const dataPoints = toDatePoint(dataItem || [], timezone);
            const identifier =
              reportGraphType +
              '-' +
              collection.pitchId +
              '-' +
              dataPoints[0].date.substring(0, 10);

            return {
              data: dataPoints,
              showLine: true,
              color: getSeriesColor(index),
              name: formatDateGbUs(new Date(dataPoints[0].date), 'EEE dd/MM'),
              key: identifier,
            };
          }
        );
        const returnValue = {
          data: toggleableDays,
          name: getPitchName(facility, collection.pitchId),
          key: collection.pitchId.toString(),
        };
        return returnValue;
      }
    );
    setNonContinuousData(facility.id, pitchDataSeriesCollection);
  }
};
