import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { TextInput } from '../forms';
import { Button } from '../button';
import { postRequestUserInvite } from '~/api';
import { RequestUserInviteBody } from '@intelligent-play-v2/domain';

interface InviteUserFormProps {
  onSuccess: () => void;
  facilityName: string;
}

export const InviteUserForm: FC<InviteUserFormProps> = ({
  onSuccess,
  facilityName,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm();
  const [error, setError] = useState<string>();

  const { mutate: mutateInviteUser, isLoading } = useMutation(
    postRequestUserInvite,
    {
      onSuccess,
      onError: (e: unknown) => {
        setError(String(e));
      },
    }
  );

  const onSubmitHandler: SubmitHandler<RequestUserInviteBody> = (
    data
  ): void => {
    data.facilityName = facilityName;
    mutateInviteUser(data);
  };

  return (
    <div className="w-80 md:w-96">
      <form className="flex flex-col space-y-5">
        <div className="flex flex-row justify-between w-full space-x-3">
          <TextInput
            register={register('firstName', {
              required: 'Please enter a first name',
            })}
            label="First name"
            placeholder="Enter first name"
            required={true}
            type="text"
            isLightMode
            errorMessage={errors.firstName?.message}
          />
          <TextInput
            register={register('lastName', {
              required: 'Please enter a last name',
            })}
            label="Last name"
            placeholder="Enter last name"
            required={true}
            type="text"
            isLightMode
            errorMessage={errors.lastName?.message}
          />
        </div>
        <TextInput
          register={register('email', {
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: 'Please enter a valid email address',
            },
          })}
          label="Email address"
          placeholder="Enter email address"
          type="email"
          required={true}
          isLightMode
          errorMessage={errors.email?.message}
        />
        <TextInput
          register={register('jobTitle')}
          label="Job title"
          placeholder="Enter job title"
          isLightMode
          errorMessage={errors.jobTitle?.message}
          required={false}
        />
        <Button
          onClick={handleSubmit(onSubmitHandler)}
          text="Send request"
          disabled={
            !dirtyFields.firstName ||
            !dirtyFields.lastName ||
            !dirtyFields.email
          }
          className="mt-auto lg:mt-12.5"
          isLoading={isLoading}
          disabledStyle="light"
        />
        {error && <p className="mt-4 text-red">{error}</p>}
      </form>
    </div>
  );
};
