import React, { FC, useEffect, useRef } from 'react';
import { classnames } from 'tailwindcss-classnames';

import {
  MaintenanceStatus,
  Pitch,
  PitchAggregate,
  PitchMaintenanceStatus,
} from '@intelligent-play-v2/domain';

import { StatSummarySmall } from '../table/facilityTable/StatSummarySmall';

import iconEuh from 'assets/images/pages/dashboard/facilities/icon__EUH.png';
import iconMaintenance from 'assets/images/pages/dashboard/facilities/icon__maintenance.png';
import iconEuh2x from 'assets/images/pages/dashboard/facilities/icon__EUH@2x.png';
import iconMaintenance2x from 'assets/images/pages/dashboard/facilities/icon__maintenance@2x.png';
import urgent from 'assets/images/pages/facility/fields/icon__field-urgent.png';
import urgent2x from 'assets/images/pages/facility/fields/icon__field-urgent@2x.png';
import { NavLink, useParams } from 'react-router-dom';
import { RoutesPath } from '~/types';
import { PitchSportType } from './PitchSportType';
import { BeatLoader } from 'react-spinners';
import { getCountryForTimezone } from 'countries-and-timezones';

export interface PitchRow extends Pitch {
  aggregate: PitchAggregate | null;
  maintenanceStatus: PitchMaintenanceStatus | null;
}
interface PitchSummaryCardProps {
  pitch: PitchRow;
  focusedPitch?: number;
  isLoading?: boolean;
}

export const PitchSummaryCard: FC<PitchSummaryCardProps> = ({
  pitch,
  focusedPitch,
  isLoading,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { facilityId } = useParams<{ facilityId: string }>();
  const { facilityPitchNumber } = pitch;

  const country = pitch.timezone ? getCountryForTimezone(pitch.timezone) : null;

  const isOverdue =
    pitch.maintenanceStatus?.status === MaintenanceStatus.Overdue;
  const isDue = pitch.maintenanceStatus?.status === MaintenanceStatus.Due;
  const isNotDue =
    pitch.maintenanceStatus?.status === MaintenanceStatus.Complete ||
    pitch.maintenanceStatus?.status === MaintenanceStatus.NotDue;

  const containerClass = classnames(
    { 'border-red': isOverdue },
    { 'shadow-red': isOverdue },
    { 'border-yellow': isDue },
    { 'shadow-yellow': isDue },
    { 'border-green': isNotDue },
    { 'shadow-green': isNotDue },
    { 'border-primary-200': !pitch.maintenanceStatus?.status },
    'inline-block',
    'text-primary-900',
    'bg-white',
    'rounded',
    'w-60',
    'border',
    'shadow-250',
    'cursor-pointer'
  );

  const imageContainerClass = classnames(
    { 'bg-red': isOverdue },
    { 'shadow-red-dark': isOverdue },
    { 'bg-yellow': isDue },
    { 'shadow-yellow-dark': isDue },
    { 'bg-green': isNotDue },
    { 'shadow-green-dark': isNotDue },
    { 'text-primary-500': !pitch.maintenanceStatus?.status },
    { 'bg-primary-200': !pitch.maintenanceStatus?.status },
    { 'shadow-250': !pitch.maintenanceStatus?.status },
    'flex',
    'items-center',
    'justify-center',
    'text-xl',
    'font-semibold',
    'text-white',
    'rounded-full',
    'w-7',
    'h-7',
    'relative',
    'flex-shrink-0'
  );

  const path = RoutesPath.PitchUsage.replace(':facilityId', facilityId).replace(
    ':pitchId',
    pitch.id.toString()
  );

  useEffect(() => {
    if (cardRef && cardRef.current && focusedPitch === facilityPitchNumber) {
      cardRef.current.scrollIntoView();
    }
  }, [focusedPitch, facilityPitchNumber]);

  return (
    <NavLink to={path}>
      <div ref={cardRef} className={containerClass}>
        <div className="flex items-center p-2.5 pb-3 border-b border-primary-100">
          <div className={imageContainerClass}>
            {pitch.facilityPitchNumber}
            {pitch.maintenanceStatus?.status === MaintenanceStatus.Overdue && (
              <picture>
                <img
                  className="absolute -top-1 -left-1"
                  src={urgent}
                  srcSet={`${urgent} 1x, ${urgent2x} 2x`}
                />
              </picture>
            )}
          </div>
          <div className="text-lg font-semibold ml-2.5 overflow-hidden overflow-ellipsis">
            {pitch.name}
          </div>
          {isLoading && (
            <div className="ml-auto">
              <BeatLoader size={6} />
            </div>
          )}
        </div>
        <div className="border-b border-primary-100 py-2.5 px-3">
          <div className="text-xs">Stats this week</div>
          <div className="flex mt-1 space-x-3 text-sm font-semibold">
            <StatSummarySmall
              icon={iconEuh}
              icon2x={iconEuh2x}
              name="Total EUH"
              value={pitch.aggregate?.euh.datesTotal}
              percentageChange={pitch.aggregate?.euh.percentageChange ?? null}
              alwaysSmall={true}
            />
            <StatSummarySmall
              icon={iconMaintenance}
              icon2x={iconMaintenance2x}
              name="Maintenance hours"
              value={pitch.aggregate?.maintenanceHours.datesTotal}
              percentageChange={
                pitch.aggregate?.maintenanceHours.percentageChange ?? null
              }
              alwaysSmall={true}
            />
          </div>
        </div>
        <div className="border-b border-primary-100 py-2.5 px-3">
          <div className="text-xs">Primary use(s)</div>
          <div className="flex flex-row flex-wrap gap-2 mt-1">
            {pitch.sportTypes?.map(sport => (
              <PitchSportType
                key={sport}
                sportTypeId={sport}
                isSmallText
                country={country}
              />
            ))}
          </div>
        </div>
        <div className="py-2.5 px-3 pb-3">
          <div className="text-xs">Turf type</div>
          <div className="mt-1 text-sm font-semibold">{pitch.turfType}</div>
        </div>
      </div>
    </NavLink>
  );
};
