import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthPage, ForgotPasswordPage, ResetPasswordPage } from '~/pages/auth';
import { AppProvider } from '~/providers/App.Provider';
import { PrivateRoute } from './PrivateRoute';
import { RoutesPath } from '~/types';
import { useAuthStore } from '~/stores';
import { useQuery } from 'react-query';
import { getWhoami } from '~/api';

export const GlobalRouter: FC = () => {
  const { setUser, user, logout } = useAuthStore();

  useQuery('whoami', getWhoami, {
    refetchOnWindowFocus: true,
    enabled: !user,
    retry: 0,
    suspense: true,
    onSuccess: data => setUser(data),
    onError: () => logout(),
  });

  return (
    <Switch>
      <Route path={RoutesPath.ForgotPassword} component={ForgotPasswordPage} />
      <Route path={RoutesPath.ResetPassword} component={ResetPasswordPage} />
      <Route
        path={[RoutesPath.Login]}
        render={() => {
          if (user) {
            return (
              <Redirect
                to={{
                  pathname: RoutesPath.Dashboard,
                }}
              />
            );
          } else {
            return <AuthPage />;
          }
        }}
      />
      <PrivateRoute path={RoutesPath.Dashboard} component={AppProvider} />
    </Switch>
  );
};
