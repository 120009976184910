import {
  format,
  isSameDay,
  parseISO,
  startOfDay,
  subMilliseconds,
} from 'date-fns';
import React, { FC } from 'react';
import { EventProps } from 'react-big-calendar';
import { CalendarEvent, CalendarEventType } from '@intelligent-play-v2/domain';

import editIcon from 'assets/images/pages/calendar/icon__task-edit.png';
import editIcon2x from 'assets/images/pages/calendar/icon__task-edit@2x.png';
import deleteIcon from 'assets/images/pages/calendar/icon__task-delete.png';
import deleteIcon2x from 'assets/images/pages/calendar/icon__task-delete@2x.png';
import fieldTestIcon from 'assets/images/pages/field/calendar/icon__task-field-test.png';
import fieldTestIcon2x from 'assets/images/pages/field/calendar/icon__task-field-test@2x.png';
import maintenanceIcon from 'assets/images/pages/calendar/icon__task-maintenance.png';
import maintenanceIcon2x from 'assets/images/pages/calendar/icon__task-maintenance@2x.png';
import completeMaintenanceIcon from 'assets/images/pages/calendar/icon__task-maintenance-complete.png';
import completeMaintenanceIcon2x from 'assets/images/pages/calendar/icon__task-maintenance-complete@2x.png';
import { userHasCalendarWriteAccess } from '@intelligent-play-v2/lib';
import { useAuthStore } from '~/stores';
import isEqual from 'lodash.isequal';
import { isAllDayEvent } from '~/utils';

export interface FacilityCalendarEvent extends CalendarEvent {
  facilityName?: string;
  pitchName?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CalendarEventProps<TEvent extends Record<string, any> = Event>
  extends EventProps {
  event: TEvent;
  onClickEdit: (event: CalendarEvent) => void;
  onClickDelete: (event: CalendarEvent) => void;
  onClickView: (event: CalendarEvent) => void;
}

export const CalendarMonthEvent: FC<
  CalendarEventProps<FacilityCalendarEvent>
> = ({ event, onClickEdit, onClickDelete, onClickView }) => {
  const { user } = useAuthStore();

  const isEditable = event.eventTypeId !== CalendarEventType.FieldTesting;
  let eventIcon = null;
  let eventIcon2x = null;
  if (event.eventTypeId === CalendarEventType.FieldTesting) {
    eventIcon = fieldTestIcon;
    eventIcon2x = fieldTestIcon2x;
  } else if (
    event.eventTypeId === CalendarEventType.MaintenanceTask &&
    !event.completedAt
  ) {
    eventIcon = maintenanceIcon;
    eventIcon2x = maintenanceIcon2x;
  } else if (
    event.eventTypeId === CalendarEventType.MaintenanceTask &&
    event.completedAt
  ) {
    eventIcon = completeMaintenanceIcon;
    eventIcon2x = completeMaintenanceIcon2x;
  }

  return (
    <div
      className="cursor-pointer flex justify-between w-full p-1.5 bg-white 2xl:space-y-0 2xl:items-center 2xl:flex-row"
      onClick={() => onClickView(event)}
    >
      <div className="flex flex-col overflow-hidden text-sm bg-white">
        <div className="flex items-center">
          {eventIcon && (
            <picture className="flex-shrink-0 mr-1 mt-0.5">
              <img
                src={eventIcon}
                srcSet={`${eventIcon} 1x, ${eventIcon2x} 2x`}
              />
            </picture>
          )}
          <div className="font-semibold truncate">{event.title}</div>
        </div>
        <div className="text-xs text-primary-400 truncate mt-0.5">
          {event.facilityName
            ? `${event.pitchName} - ${event.facilityName}`
            : getEventLabel(event)}
        </div>
      </div>
      {isEditable && user && userHasCalendarWriteAccess(user) ? (
        <div className="inline-flex flex-shrink-0 mt-1 ml-1 space-x-2">
          <button
            className="hidden cursor-pointer lg:block"
            onClick={e => {
              e.stopPropagation();
              onClickEdit(event);
            }}
          >
            <picture>
              <img src={editIcon} srcSet={`${editIcon} 1x, ${editIcon2x} 2x`} />
            </picture>
          </button>
          <button
            className="hidden cursor-pointer 2xl:block"
            onClick={e => {
              e.stopPropagation();
              onClickDelete(event);
            }}
          >
            <picture>
              <img
                src={deleteIcon}
                srcSet={`${deleteIcon} 1x, ${deleteIcon2x} 2x`}
              />
            </picture>
          </button>
        </div>
      ) : (
        <div>
          <span className="hidden ml-1 text-sm text-green lg:block">View</span>
        </div>
      )}
    </div>
  );
};

const getEventLabel = (event: FacilityCalendarEvent): string => {
  if (!event || !event.startDate || !event.endDate) {
    return '';
  }
  const startDate = parseISO(event.startDate);
  const endDate = parseISO(event.endDate);

  let from, to;
  if (event.facilityName) {
    return event.facilityName;
  } else if (isAllDayEvent(startDate, endDate)) {
    return 'All day';
  } else if (isSameDay(startDate, subMilliseconds(endDate, 1))) {
    from = format(startDate, 'HH:mm');
    to = format(endDate, 'HH:mm');
  } else {
    from = format(startDate, 'd MMM yyyy');
    const isEndDateStartOfDay = isEqual(startOfDay(endDate), endDate);
    to = format(
      isEndDateStartOfDay ? subMilliseconds(endDate, 1) : endDate,
      'd MMM yyyy'
    );
  }
  return `${from} - ${to}`;
};
