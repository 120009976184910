import { Facility } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { FacilityName } from '../table/facilityTable/FacilityName';
import { Checkbox } from '../checkbox';

interface FacilitySelectorProps {
  facility: Facility;
  selectedPitches: number[];
  onChangeCheckboxHandler: (items: number[], newCheckedValue: boolean) => void;
}

export const FacilitySelector: FC<FacilitySelectorProps> = ({
  facility,
  selectedPitches,
  onChangeCheckboxHandler,
}) => {
  const isFacilityChecked = selectedPitches.length > 0;
  return (
    <div
      className="flex px-4 py-3 bg-white border border-primary-100 rounded shadow-250 cursor-pointer"
      onClick={() =>
        onChangeCheckboxHandler(
          facility.pitches?.map(pitch => pitch.id) ?? [],
          !isFacilityChecked
        )
      }
    >
      <Checkbox
        size="large"
        isChecked={isFacilityChecked}
        onChange={() =>
          onChangeCheckboxHandler(
            facility.pitches?.map(pitch => pitch.id) ?? [],
            !isFacilityChecked
          )
        }
      />
      <div className="flex w-full ml-3 -mr-2.5 space-x-2 lg:ml-7">
        <div className="w-1/2 md:w-5/12 xl:w-3/12">
          <FacilityName facility={facility} />
        </div>
        <div className="w-1/2 md:w-7/12 xl:w-9/12">
          <div className="text-xs font-medium text-primary-400">Fields</div>
          <div className="flex flex-wrap w-full gap-x-6 gap-y-2.5 pt-1.5">
            {facility.pitches?.map(pitch => {
              const isPitchChecked = selectedPitches.includes(pitch.id);
              return (
                <div
                  className="flex cursor-pointer"
                  key={pitch.id}
                  onClick={event => {
                    onChangeCheckboxHandler([pitch.id], !isPitchChecked);
                    event.stopPropagation();
                  }}
                >
                  <Checkbox isChecked={isPitchChecked} />
                  <div className="pl-2 text-base text-primary-900">
                    {pitch.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
