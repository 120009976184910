import {
  Interval,
  addDays,
  differenceInDays,
  eachDayOfInterval,
  eachHourOfInterval,
  eachMinuteOfInterval,
  eachMonthOfInterval,
  getHours,
  getMinutes,
  isBefore,
  set,
  setHours,
} from 'date-fns';
import { DataSeries, DatePoint, ToggleableDataSeries } from '~/types';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface DateTicks {
  gridTickDates: Date[];
  tickDates: Date[] | undefined;
  tickLabelDates: Date[];
}

export interface LinearTicks {
  gridTicks: number[];
  ticks: number[];
  tickLabels: number[];
}

// e.g. 9.75 => 09:45
export const decimalTimeToHumanTime = (decimalTime: number): string => {
  const hour = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime % 1) * 60)
    .toString()
    .padStart(2, '0');

  return hour + ':' + minutes;
};

// e.g. 09:45 => 9.75
export const humanTimeToDecimalTime = (humanTime: string): number => {
  const [hours, minutes] = humanTime.split(':').map(x => +x);

  return hours + (minutes || 0) / 60;
};

// e.g. '2022-03-04T09:45:43Z => 9.75
export const dateToDecimalTime = (date: Date): number =>
  getHours(date) + getMinutes(date) / 60;

// e.g. DatePoint(date=Date('2022-03-04T09:45:43Z)) => 9.75
export const datePointToDecimalTime = (d: DatePoint): number =>
  dateToDecimalTime(new Date(d.date));

export const getHoursValue = (d: DatePoint): number => d.hours || 0;

export const getDateBoundries = (
  dataSeries: DataSeries[] | ToggleableDataSeries[]
): DateRange => {
  let minDate: Date | null = null;
  let maxDate: Date | null = null;
  dataSeries.forEach(({ data }) => {
    if (data.length) {
      if (!minDate || new Date(data[0].date) < minDate) {
        minDate = new Date(data[0].date);
      }
      if (!maxDate || new Date(data[data.length - 1].date) > maxDate) {
        maxDate = new Date(data[data.length - 1].date);
      }
    }
  });

  let startDate = minDate ?? new Date();
  let endDate = maxDate ?? new Date();

  const daysInterval = Math.abs(differenceInDays(startDate, endDate));
  if (daysInterval < 1) {
    startDate = set(startDate, { hours: 0, minutes: 0 });
    endDate = set(endDate, { hours: 23, minutes: 45 });
  }

  return { startDate, endDate };
};

export const getTicks = (startDate: Date, endDate: Date): DateTicks => {
  let gridTickDates: Date[] = [];
  let tickDates: Date[] | undefined = undefined;
  let tickLabelDates: Date[] = [];
  const difference = Math.abs(differenceInDays(startDate, endDate));
  if (difference < 1) {
    gridTickDates = eachHourOfInterval(
      { start: startDate, end: endDate },
      { step: 3 }
    );
    tickDates = eachHourOfInterval(
      { start: startDate, end: endDate },
      { step: 1 }
    );
    tickLabelDates = eachHourOfInterval(
      { start: startDate, end: endDate },
      { step: 3 }
    );
  } else if (difference < 7) {
    gridTickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 1 }
    );
    tickDates = datesInStepHoursOfInterval(
      { start: startDate, end: endDate },
      { step: 6 }
    );
    tickLabelDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 1 }
    ).map(date => setHours(date, 12));
  } else if (difference < 14) {
    gridTickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 2 }
    );
    tickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 1 }
    );
    tickLabelDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 2 }
    );
  } else if (difference < 28) {
    gridTickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 4 }
    );
    tickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 1 }
    );
    tickLabelDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 4 }
    );
  } else if (difference < 92) {
    gridTickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 7 }
    );
    gridTickDates.push(endDate);
    tickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 1 }
    );
    tickLabelDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 7 }
    );
  } else if (difference < 184) {
    gridTickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 14 }
    );
    gridTickDates.push(endDate);
    tickDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 1 }
    );
    tickLabelDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 14 }
    );
  } else {
    gridTickDates = eachMonthOfInterval({ start: startDate, end: endDate });
    if (isBefore(gridTickDates[0], startDate)) {
      gridTickDates[0] = startDate;
    }
    tickDates = undefined;
    tickLabelDates = eachDayOfInterval(
      { start: startDate, end: endDate },
      { step: 4 }
    );
    tickLabelDates = eachMonthOfInterval({ start: startDate, end: endDate });
  }

  gridTickDates.push(endDate); // add final grid tick
  return {
    tickLabelDates,
    tickDates,
    gridTickDates,
  };
};

export const getTicksForDay = (
  startTime: string,
  endTime: string,
  date: Date
): LinearTicks => {
  const startHour = +startTime.split(':')[0];
  const endHour = +endTime.split(':')[0];

  const startDate = set(date, { hours: startHour, minutes: 0 });
  let endDate = set(date, { hours: endHour, minutes: 0 });

  let differenceHours = endHour - startHour;

  if (endHour === 0) {
    endDate = addDays(endDate, 1);
    differenceHours = 24 - startHour;
  }

  let gridTicks = [];
  let tickLabels = [];

  const ticks = eachMinuteOfInterval(
    { start: startDate, end: endDate },
    { step: 15 }
  ).map(dateToDecimalTime);

  if (differenceHours < 3) {
    gridTicks = eachMinuteOfInterval(
      { start: startDate, end: endDate },
      { step: 15 }
    ).map(dateToDecimalTime);
    tickLabels = gridTicks;
  } else if (differenceHours < 9) {
    gridTicks = eachMinuteOfInterval(
      { start: startDate, end: endDate },
      { step: 15 }
    ).map(dateToDecimalTime);
    tickLabels = eachHourOfInterval(
      { start: startDate, end: endDate },
      { step: 1 }
    ).map(dateToDecimalTime);
  } else if (differenceHours < 18) {
    gridTicks = eachHourOfInterval(
      { start: startDate, end: endDate },
      { step: 2 }
    ).map(dateToDecimalTime);
    tickLabels = eachHourOfInterval(
      { start: startDate, end: endDate },
      { step: 2 }
    ).map(dateToDecimalTime);
  } else {
    gridTicks = eachHourOfInterval(
      { start: startDate, end: endDate },
      { step: 3 }
    ).map(dateToDecimalTime);
    tickLabels = eachHourOfInterval(
      { start: startDate, end: endDate },
      { step: 3 }
    ).map(dateToDecimalTime);
  }

  gridTicks.push(dateToDecimalTime(endDate)); // add final grid tick
  tickLabels.push(dateToDecimalTime(endDate)); // add final grid label

  return {
    tickLabels,
    ticks,
    gridTicks,
  };
};

const datesInStepHoursOfInterval = (
  interval: Interval,
  options: { step: number }
): Date[] => {
  const numberOfSteps = 24 / options.step; // 4
  const steps = Array.from(Array(numberOfSteps).keys()); // 0, 1, 2, 3
  return eachDayOfInterval(
    { start: interval.start, end: interval.end },
    { step: 1 }
  ).reduce((accum: Date[], date) => {
    const dates = steps.map(
      stepIndex => new Date(setHours(date, stepIndex * options.step))
    );
    return [...accum, ...dates];
  }, []);
};
