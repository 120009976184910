import { useState } from 'react';

interface CheckedRecordArrayHook {
  checkedItems: Record<string, number[]>;
  setCheckedItems: React.Dispatch<
    React.SetStateAction<Record<string, number[]>>
  >;
  toggleChecked: (key: string, number: number) => void;
  toggleCheckedMany: (
    items: Record<string, number[]>,
    newCheckedValue: boolean
  ) => void;
}

export const useCheckedRecordArray = (
  initialCheckedItems: Record<string, number[]>
): CheckedRecordArrayHook => {
  const [checkedItems, setCheckedItems] = useState<Record<string, number[]>>(
    initialCheckedItems
  );

  const toggleCheckedMany = (
    items: Record<string, number[]>,
    newCheckedValue: boolean
  ): void => {
    const newCheckedItems = { ...checkedItems };
    Object.entries(items).forEach(([pitchId, userIds]) => {
      userIds.forEach(userId => {
        if (newCheckedValue && !newCheckedItems[pitchId].includes(userId)) {
          newCheckedItems[pitchId].push(userId);
        }
        if (!newCheckedValue) {
          newCheckedItems[pitchId] = newCheckedItems[pitchId].filter(
            pitchUserId => pitchUserId !== userId
          );
        }
      });
    });
    setCheckedItems(newCheckedItems);
  };

  const toggleChecked = (key: string, value: number): void => {
    const newCheckedItems = { ...checkedItems };
    const newCheckedValue = !newCheckedItems[key].find(item => item === value);
    if (newCheckedValue && !newCheckedItems[key].includes(value)) {
      newCheckedItems[key].push(value);
    }
    if (!newCheckedValue) {
      newCheckedItems[key] = newCheckedItems[key].filter(
        item => item !== value
      );
    }
    setCheckedItems(newCheckedItems);
  };

  return {
    checkedItems,
    setCheckedItems,
    toggleCheckedMany,
    toggleChecked,
  };
};
