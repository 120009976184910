import { Detection } from '@intelligent-play-v2/domain';
import React, { FC, RefObject, useRef } from 'react';

interface DrawCircleProps {
  ctx: CanvasRenderingContext2D;
  radius: number;
  color: string;
  scale: number;
  age: number;
  x: number;
  y: number;
}
interface LivePitchProps {
  detections: Detection[];
  pitchImage: HTMLImageElement;
  width: number;
  height: number;
  componentRef?: RefObject<HTMLDivElement>;
}

export const LivePitch: FC<LivePitchProps> = ({
  detections,
  pitchImage,
  componentRef,
  width,
  height,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const now = Date.now() / 1000;

  const marginX = width * 0.053;
  const marginY = height * 0.077;
  const factorX = (width - marginX) / width;
  const factorY = (height - marginY) / height;
  const scale = width / 1354;

  const drawCircle = (props: DrawCircleProps): void => {
    props.ctx.save();
    props.ctx.beginPath();
    props.ctx.arc(
      props.x * props.scale,
      props.y * props.scale,
      props.radius * props.scale,
      0,
      2 * Math.PI
    );
    props.ctx.globalAlpha = 1 - props.age * 0.8;
    props.ctx.fillStyle = props.color;
    props.ctx.fill();
    props.ctx.closePath();
    props.ctx.restore();
  };

  if (canvasRef.current) {
    const ctx = canvasRef.current.getContext('2d');
    if (ctx) {
      ctx.drawImage(pitchImage, 0, 0, 1600, 1130, 0, 0, width, height);
      detections
        .filter(({ time }) => now - time > 110 && now - time < 120)
        .forEach(detection => {
          const circleProps: DrawCircleProps = {
            ctx: ctx,
            radius: detection.type === 1 ? 12 : 20,
            color: detection.type === 1 ? '#ffff00' : '#ff0000',
            scale: scale,
            age: (now - detection.time - 110) / 10,
            x: (detection.x + marginX) * factorX,
            y: (detection.y + marginY) * factorY,
          };
          drawCircle(circleProps);
        });
    }
  }

  return (
    <div className="flex" ref={componentRef}>
      <canvas ref={canvasRef} width={width} height={height} />
    </div>
  );
};
