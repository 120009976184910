import { Facility, Pitch } from '@intelligent-play-v2/domain';

export const getPitchInstallDate = (pitch: Pitch): Date => {
  let oldestDate = new Date();
  pitch.devices?.forEach(device => {
    if (device.dateOfInstall) {
      const deviceDateOfInstall = new Date(device.dateOfInstall);
      if (deviceDateOfInstall < oldestDate) {
        oldestDate = deviceDateOfInstall;
      }
    }
  });

  return oldestDate;
};

export const getFacilityInstallDate = (facility: Facility): Date => {
  let oldestDate = new Date();
  facility.pitches?.forEach(pitch => {
    pitch.devices?.forEach(device => {
      if (device.dateOfInstall) {
        const deviceDateOfInstall = new Date(device.dateOfInstall);
        if (deviceDateOfInstall < oldestDate) {
          oldestDate = deviceDateOfInstall;
        }
      }
    });
  });

  return oldestDate;
};
