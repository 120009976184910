import React, { FC, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import { Link, Redirect } from 'react-router-dom';

import { ReportArea, TimePeriod } from '@intelligent-play-v2/domain';

import { Button, ButtonType } from '~/components/button';
import { ContentContainer, PageHeaderWithButtons } from '~/components/layout';
import { FacilityReport, PitchUsageHeatmap } from '~/components/reportPreview';
import { PrintableReport } from '~/components/reportPreview/printableReport/PrintableReport';
import { useReportStore } from '~/stores/useReportStore';
import { RoutesPath } from '~/types';
import { useReportQueryParams } from '~/hooks/useReportQueryParams.hook';

import downloadIcon from 'assets/images/pages/report/icon__download-pdf.png';
import downloadIcon2x from 'assets/images/pages/report/icon__download-pdf@2x.png';
import editIcon from 'assets/images/pages/report/icon__edit.png';
import editIcon2x from 'assets/images/pages/report/icon__edit@2x.png';

import { EditReportOption } from './editReportOption';
import { EditReportCard } from './editReportCard';
import { sortByPitchNumber } from '~/utils';

export const ReportPreview: FC = () => {
  const {
    pdfIncludedFacilityIds,
    setInitialPdfSettings,
    clearReportPdfSettings,
  } = useReportStore();

  const {
    pitchIds,
    reportedPitchFacilities,
    timePeriod,
    customDates,
    selectedReportAreaIds,
    reportTitle,
  } = useReportQueryParams();

  const componentRef = useRef<HTMLDivElement>(null);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);

  const facilityIds = reportedPitchFacilities.map(({ id }) => id);

  let dateRangeText: string = timePeriod;

  if (timePeriod === TimePeriod.Custom && customDates) {
    dateRangeText = `${format(
      customDates.startDate,
      'E d MMMM yyyy'
    )} - ${format(customDates.endDate, 'E d MMMM yyyy')}`;
  }

  const documentTitle =
    reportTitle.length > 0 ? reportTitle : 'ip-report-custom';

  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
    onAfterPrint: () => setIsLoadingPrint(false),
    pageStyle: `@page { margin-top: 0px !important; margin-bottom: 0px !important;}`,
    documentTitle,
  });

  const handleDownloadPdf = (): void => {
    if (handlePrint) {
      setIsLoadingPrint(true);
      handlePrint();
    }
  };

  useEffect(() => {
    if (!pdfIncludedFacilityIds.size) {
      setInitialPdfSettings(facilityIds, pitchIds || [], selectedReportAreaIds);
    }
  }, [
    facilityIds,
    pdfIncludedFacilityIds,
    pitchIds,
    selectedReportAreaIds,
    setInitialPdfSettings,
  ]);

  useEffect(() => {
    return () => {
      clearReportPdfSettings();
    };
  }, [clearReportPdfSettings]);

  const headerTitle = reportTitle.length > 0 ? reportTitle : `Your Report`;

  const isDownloadDisabled =
    !pdfIncludedFacilityIds ||
    pdfIncludedFacilityIds.size === 0 ||
    isLoadingPrint;

  // if any essential query params aren't set, redirect back to report page
  if (
    !pitchIds.length ||
    !selectedReportAreaIds.length ||
    !timePeriod ||
    (timePeriod === TimePeriod.Custom &&
      (!customDates || !customDates.startDate || !customDates.endDate))
  ) {
    return <Redirect to={RoutesPath.Report} />;
  }

  const includeHeatmap = selectedReportAreaIds.includes(ReportArea.Heatmap);

  return (
    <>
      <PageHeaderWithButtons title={headerTitle}>
        <div className="flex space-x-5">
          <Link
            to={{
              pathname: RoutesPath.Report,
              search: location.search,
            }}
            onClick={clearReportPdfSettings}
          >
            <Button
              text="Edit report"
              type={ButtonType.Outline}
              icon={editIcon}
              icon2x={editIcon2x}
            />
          </Link>
          <Button
            text="Download PDF"
            type={ButtonType.Secondary}
            icon={downloadIcon}
            icon2x={downloadIcon2x}
            onClick={handleDownloadPdf}
            disabled={isDownloadDisabled}
            isLoading={isLoadingPrint}
          />
        </div>
      </PageHeaderWithButtons>
      <ContentContainer>
        <div className="mt-12.5">
          <EditReportOption title="Date range">
            <EditReportCard title={dateRangeText} />
          </EditReportOption>
          <EditReportOption title="Facilities included">
            {reportedPitchFacilities.map(facility => {
              return (
                <EditReportCard
                  key={facility.id}
                  title={facility.name ?? ''}
                  options={facility.pitches
                    ?.sort(sortByPitchNumber)
                    .map(pitch => pitch.name ?? '')}
                />
              );
            })}
          </EditReportOption>
        </div>
        {reportedPitchFacilities.map(facility => {
          return (
            <FacilityReport
              facility={facility}
              customDates={customDates}
              timePeriod={timePeriod}
              key={facility.id}
              pitchesStats={
                includeHeatmap
                  ? facility.pitches?.map(pitch => (
                      <PitchUsageHeatmap
                        pitch={pitch}
                        timezone={facility.tzDatabaseTimezone ?? 'UTC'}
                        customDates={customDates}
                        timePeriod={timePeriod}
                        key={pitch.id}
                      />
                    ))
                  : []
              }
            />
          );
        })}
        <div className="flex mt-7.5 mb-22">
          <div className="ml-auto">
            <Button
              text="Download PDF"
              type={ButtonType.Secondary}
              icon={downloadIcon}
              icon2x={downloadIcon2x}
              onClick={handleDownloadPdf}
              disabled={isDownloadDisabled}
              isLoading={isLoadingPrint}
            />
          </div>
        </div>
      </ContentContainer>
      <div className="h-0 overflow-hidden">
        <PrintableReport
          ref={componentRef}
          reportedPitchFacilities={reportedPitchFacilities}
          timePeriod={timePeriod}
          customDates={customDates}
        />
      </div>
    </>
  );
};
