import React, { FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Route, useHistory, useLocation } from 'react-router-dom';
import mobile from 'is-mobile';

import { ContactUsForm } from '~/components/contactUs';
import { ContactDetails } from '~/components/contactUs/contactDetails';
import { ContactFaq } from '~/components/contactUs/contactFaq';
import {
  ContentContainer,
  HeaderTab,
  PageHeader,
  PageHeaderWithTabs,
} from '~/components/layout';
import { usePageTitle } from '~/hooks';
import { RoutesPath } from '~/types';

const mobilePaths = [
  RoutesPath.ContactHelpCentre,
  RoutesPath.ContactDetails,
  RoutesPath.ContactContactUs,
];
const desktopPaths = [RoutesPath.Contact];

export const ContactPage: FC = () => {
  usePageTitle('Contact - Intelligent Play');

  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();
  const location = useLocation();
  const pathName = location.pathname as RoutesPath;
  const history = useHistory();

  useEffect(() => {
    if (!isMobile) {
      if (mobilePaths.includes(pathName)) {
        history.replace(RoutesPath.Contact);
      }
    } else {
      if (desktopPaths.includes(pathName)) {
        history.replace(RoutesPath.ContactHelpCentre);
      }
    }
  }, [history, isMobile, pathName]);

  return (
    <>
      <div className="sm:hidden">
        <PageHeaderWithTabs title="Contact our support team">
          <div className="flex">
            <HeaderTab
              title={'Help centre'}
              path={RoutesPath.ContactHelpCentre}
            />
            <HeaderTab
              title={'Contact us'}
              path={RoutesPath.ContactContactUs}
              exact
              strict
            />
            <HeaderTab
              title={'Details'}
              path={RoutesPath.ContactDetails}
              exact
              strict
            />
          </div>
        </PageHeaderWithTabs>
      </div>
      <div className="hidden sm:block">
        <PageHeader title="Contact our support team" />
      </div>
      <ContentContainer className="py-7.5 md:py-12.5">
        <div className="sm:pb-10">
          <Route
            path={[RoutesPath.Contact, RoutesPath.ContactHelpCentre]}
            exact
          >
            <ContactFaq />
          </Route>
        </div>
        <div className="sm:space-y-5 xl:space-y-0 xl:flex xl:space-x-15">
          <div className="w-full xl:w-2/3">
            <Route
              path={[RoutesPath.Contact, RoutesPath.ContactContactUs]}
              exact
            >
              <ContactUsForm />
            </Route>
          </div>
          <div className="w-full xl:w-1/3">
            <Route path={[RoutesPath.Contact, RoutesPath.ContactDetails]} exact>
              <ContactDetails />
            </Route>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
