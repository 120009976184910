import React, { FC } from 'react';
import { TArg, classnames } from 'tailwindcss-classnames';

interface ContentContainerProps {
  mobileFullWidth?: boolean;
  className?: string | TArg;
}

export const ContentContainer: FC<ContentContainerProps> = ({
  children,
  mobileFullWidth = false,
  className = '',
}) => {
  const contentClassnames = (className as string).split(' ') as TArg[];

  const contentContainerClass = classnames(
    { 'px-5': !mobileFullWidth },
    'md:px-5',
    'lg:px-10',
    '2xl:px-15',
    ...contentClassnames
  );

  return <div className={contentContainerClass}>{children}</div>;
};
