import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { TableColumn } from './Table';

interface TableRowProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  columns: TableColumn[];
  index?: number;
  isPrintable?: boolean;
}

export const TableRow: FC<TableRowProps> = ({
  data,
  columns,
  index,
  isPrintable,
}) => {
  const componentClassNames = classnames({
    border: isPrintable,
    'border-primary-200': isPrintable,
    'h-15': isPrintable,
    'h-16.5': !isPrintable,
  });

  return (
    <div
      className={`${componentClassNames} flex items-center bg-white rounded shadow-250 p-4`}
    >
      {columns.map(({ heading, widthClass, value }) => {
        return (
          <div
            key={`${heading}-${data.id}`}
            className={`${widthClass} mr-2 last:mr-0`}
          >
            {value(data, index)}
          </div>
        );
      })}
    </div>
  );
};
