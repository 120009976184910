import React, { FC, useEffect } from 'react';
import { Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import mobile from 'is-mobile';

import { FacilityOverviewPage } from '~/pages/facility';
import { PitchPage } from '~/pages/pitch';

import { RoutesPath } from '~/types';
import { PrivateRoute } from './PrivateRoute';

const mobilePaths = [
  RoutesPath.FacilityOverviewFeed,
  RoutesPath.FacilityOverviewKeyStats,
  RoutesPath.FacilityOverviewFields,
];
const desktopPaths = [
  RoutesPath.FacilityTeam,
  RoutesPath.FacilityAbout,
  RoutesPath.FacilityOverview,
];

export const FacilityContentRouter: FC = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();
  const location = useLocation();
  const pathName = location.pathname.replace(
    facilityId,
    ':facilityId'
  ) as RoutesPath;
  const history = useHistory();

  useEffect(() => {
    if (!isMobile) {
      if (mobilePaths.includes(pathName)) {
        history.replace(
          RoutesPath.FacilityOverview.replace(':facilityId', facilityId)
        );
      }
    } else {
      if (desktopPaths.includes(pathName)) {
        history.replace(
          RoutesPath.FacilityOverviewFeed.replace(':facilityId', facilityId)
        );
      }
    }
  }, [facilityId, history, isMobile, pathName]);

  return (
    <Switch>
      <PrivateRoute strict path={RoutesPath.PitchUsage} component={PitchPage} />
      <PrivateRoute
        strict
        path={RoutesPath.FacilityOverview}
        component={FacilityOverviewPage}
      />
    </Switch>
  );
};
