import { Facility } from '@intelligent-play-v2/domain';

interface LatLong {
  x: number;
  y: number;
}

export const getLatLong = (facility: Facility): LatLong => {
  let latLngString;
  // if facility is defined in the facility table (not supported yet)
  if (facility.latLong) {
    latLngString = (facility.latLong as unknown) as LatLong;
  }
  // else we use the first device's latlng
  else {
    if (
      facility.pitches &&
      facility.pitches[0] &&
      facility.pitches[0].devices
    ) {
      latLngString = (facility.pitches[0].devices[0]
        .latLong as unknown) as LatLong;
    }
  }
  return latLngString || { x: 0, y: 0 };
};
