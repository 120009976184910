import React, { FC } from 'react';
import { getDay } from 'date-fns';
import {
  CalendarEvent,
  MaintenanceStatus,
  PitchMaintenanceStatus,
} from '@intelligent-play-v2/domain';
import { Tooltip, TooltipPosition } from '~/components/tooltip';
import { DayStatusTooltipContent } from './DayStatusTooltipContent';

interface DayStatusTooltipProps {
  left: number;
  isDayInFuture: boolean;
  pitchMaintenanceStatus?: PitchMaintenanceStatus;
  manualMaintenanceEvents: CalendarEvent[];
  editMaintenanceEvent: (event: CalendarEvent) => void;
  deleteEvent: (event: CalendarEvent) => void;
}

export const DayStatusTooltip: FC<DayStatusTooltipProps> = ({
  left,
  isDayInFuture,
  pitchMaintenanceStatus,
  manualMaintenanceEvents,
  editMaintenanceEvent,
  deleteEvent,
}) => {
  const isDanger =
    pitchMaintenanceStatus?.status === MaintenanceStatus.Overdue ||
    !!manualMaintenanceEvents.some(
      event => !event.completedAt && !isDayInFuture
    );

  let width = undefined;
  let tooltipPosition = TooltipPosition.Right;
  let pointerLeftPosition = left;

  if (pitchMaintenanceStatus) {
    const dayOfWeek = getDay(new Date(pitchMaintenanceStatus.date));
    if (dayOfWeek > 4 || dayOfWeek === 0) {
      width = manualMaintenanceEvents.length > 0 ? 189 : 128;
      width -= 44;
      tooltipPosition = TooltipPosition.Left;
      pointerLeftPosition = width + 22;
    }
  }

  return (
    <Tooltip
      pointerLeftPosition={pointerLeftPosition}
      isDanger={isDanger}
      isBordered={true}
      isHoverable
      tooltipPosition={tooltipPosition}
      width={width}
    >
      <div className="overflow-y-auto max-h-60">
        {/* content */}
        {pitchMaintenanceStatus &&
          pitchMaintenanceStatus.status !== MaintenanceStatus.NotDue && (
            <DayStatusTooltipContent
              title="Machine Brush"
              status={pitchMaintenanceStatus.status}
              date={pitchMaintenanceStatus.date}
              dueDate={pitchMaintenanceStatus.dueDate}
              maintenanceHoursCompleted={
                pitchMaintenanceStatus.maintenanceHoursCompleted
              }
              isTracked={true}
            />
          )}

        {manualMaintenanceEvents.map(event => {
          const status = event.completedAt
            ? MaintenanceStatus.Complete
            : isDayInFuture
            ? MaintenanceStatus.Due
            : MaintenanceStatus.Overdue;

          return (
            <div key={event.id}>
              <DayStatusTooltipContent
                title={event.title}
                date={event.startDate}
                dueDate={new Date(event.startDate)}
                status={status}
                isTracked={false}
                onClickEdit={() => editMaintenanceEvent(event)}
                onClickDelete={() => deleteEvent(event)}
              />
            </div>
          );
        })}
      </div>
    </Tooltip>
  );
};
