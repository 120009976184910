import React, { FC } from 'react';
import { User } from '@intelligent-play-v2/domain';
import { UserCheckbox } from './userCheckbox';

interface UserChecklistProps {
  users: User[];
  checkedUsers: Record<string, number[]>;
  setIsChecked: (newValue: boolean, userId: number) => void;
  pitchId?: string;
}

export const UserChecklist: FC<UserChecklistProps> = ({
  users,
  checkedUsers,
  setIsChecked,
  pitchId,
}) => {
  return (
    <div className="grid gap-5 grid-cols-auto-fit">
      {users.map(user => {
        const isChecked = pitchId
          ? checkedUsers[pitchId].includes(user.id)
          : !!Object.values(checkedUsers).find(userIds => {
              return userIds.includes(user.id);
            });
        return (
          <UserCheckbox
            key={user.id}
            user={user}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
        );
      })}
    </div>
  );
};
