import { createContext } from 'react';

interface InitialReportLoadingContext {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState = {
  isLoading: false,
  setIsLoading: () => null,
};

export const ReportLoadingContext = createContext<InitialReportLoadingContext>(
  initialState
);
