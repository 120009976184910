import React, { FC } from 'react';
import fieldIcon from 'assets/images/pages/admin/icon__facility-fields.png';
import fieldIcon2x from 'assets/images/pages/admin/icon__facility-fields@2x.png';

interface PermissionInfoProps {
  text: string;
}

export const PermissionInfo: FC<PermissionInfoProps> = ({ text }) => {
  return (
    <div className="flex items-center">
      <div className="flex items-center justify-center w-6 h-6 bg-primary-100 rounded-xs">
        <picture>
          <img
            className="w-4"
            src={fieldIcon}
            srcSet={`${fieldIcon} 1x, ${fieldIcon2x} 2x`}
          />
        </picture>
      </div>
      <div className="ml-2.5 whitespace-nowrap">{text}</div>
    </div>
  );
};
