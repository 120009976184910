import React, { FC, useEffect, useState } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Select, SelectOptionType } from '~/components/select';
import { UserThumbnail, UserThumbnailType } from '~/components/userThumbnail';
import { TextInput } from '~/components/forms';
import {
  Client,
  User,
  UserType,
  getUserTypeString,
} from '@intelligent-play-v2/domain';
import { enumToObjectArray } from '~/utils/enumToObjectArray';
import { getUserInitials } from '~/utils/getUserInitials';

interface AdminUserDetailsProps {
  user: User;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: { [x: string]: any };
  userInputImageRef: React.MutableRefObject<HTMLInputElement | null>;
  setImageFile: (value: React.SetStateAction<File | undefined>) => void;
  imagePreview: string;
}

export const AdminUserDetails: FC<AdminUserDetailsProps> = ({
  user,
  register,
  setValue,
  errors,
  userInputImageRef,
  setImageFile,
  imagePreview,
}) => {
  // User type
  const userTypeOptions: SelectOptionType[] = enumToObjectArray(
    UserType as never
  ).map(({ id }) => ({
    label: getUserTypeString(id as UserType),
    value: id.toString(),
  }));

  const [selectedUserType, setSelectedUserType] = useState<SelectOptionType>(
    userTypeOptions.find(userType => user.userTypeId === +userType.value) ||
      userTypeOptions[0]
  );
  const handleUserTypeChange = (option: SelectOptionType | null): void => {
    const newValue = option ? option : userTypeOptions[0];
    setValue('userTypeId', newValue.value);
    setSelectedUserType(newValue);
  };

  // Dashboard type
  const dashboardTypeOptions: SelectOptionType[] = [
    { label: 'Intelligent Play', value: Client.IntelligentPlay.toString() },
    { label: 'Genius', value: Client.FieldTurf.toString() },
  ];
  if (user.clientId === Client.VivacityLabs) {
    dashboardTypeOptions.push({
      label: 'Vivacity Labs',
      value: Client.VivacityLabs.toString(),
    });
  }
  const [
    selectedDashboardType,
    setSelectedDashboardType,
  ] = useState<SelectOptionType>(
    dashboardTypeOptions.find(
      dashboardType => user.clientId === +dashboardType.value
    ) || dashboardTypeOptions[1]
  );
  const handleDashboardTypeChange = (option: SelectOptionType | null): void => {
    const newValue = option ? option : dashboardTypeOptions[0];
    setValue('clientId', newValue.value);
    setSelectedDashboardType(newValue);
  };

  let initials = '';
  let firstName: string, lastName: string;
  if (user.name) {
    [firstName, lastName] = user.name.split(' ');
    initials = getUserInitials(user.name);
  }

  useEffect(() => {
    if (user.name) {
      setValue('firstName', firstName);
      setValue('lastName', lastName);
    }
    setValue('email', user.email || '');
    setValue('phoneNumber', user.phoneNumber || '');
    setValue('jobTitle', user.jobTitle || '');
    setValue('userTypeId', selectedUserType.value);
    setValue('clientId', selectedDashboardType.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setValue]);

  const onProfileImageClick = (): void => {
    if (userInputImageRef.current) {
      userInputImageRef.current.click();
    }
  };

  const inputOnChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        setImageFile(file);
      }
    }
  };

  return (
    <div className="bg-white rounded shadow-250">
      <div className="flex flex-row p-3 border-b border-primary-100">
        <span className="font-semibold">Personal details</span>
      </div>

      <div className="flex flex-col px-4 pt-6 pb-8 lg:px-8 lg:space-x-8 lg:flex-row">
        <div className="w-auto pb-6">
          <input
            ref={userInputImageRef}
            type="file"
            accept="image/png, image/gif, image/jpeg"
            className="hidden"
            onChange={inputOnChangeHandler}
          />
          <UserThumbnail
            type={UserThumbnailType.UserDetails}
            text={initials}
            onClick={onProfileImageClick}
            imageSrc={
              imagePreview.length > 0 ? imagePreview : user.imageThumbnailUrl
            }
          />
        </div>
        <div className="w-full">
          <form className="flex flex-col space-y-5">
            <div className="flex w-full space-x-3">
              <div className="w-1/2">
                <TextInput
                  register={register('firstName', {
                    required: 'Please enter a first name',
                  })}
                  label="First name"
                  placeholder="Enter first name"
                  required
                  type="text"
                  isLightMode
                  errorMessage={errors.firstName?.message}
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  register={register('lastName', {
                    required: 'Please enter a last name',
                  })}
                  label="Last name"
                  placeholder="Enter last name"
                  required
                  type="text"
                  isLightMode
                  errorMessage={errors.lastName?.message}
                />
              </div>
            </div>
            <div className="flex w-full space-x-3">
              <div className="w-1/2">
                <TextInput
                  register={register('email', {
                    pattern: {
                      value: /^\S+@\S+\.\S+$/,
                      message: 'Please enter a valid email address',
                    },
                    required: 'Please enter a valid email address',
                  })}
                  label="Email address"
                  placeholder="Enter email address"
                  type="email"
                  required
                  isLightMode
                  errorMessage={errors.email?.message}
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  register={register('phoneNumber')}
                  label="Phone number"
                  placeholder="Enter phone number"
                  isLightMode
                  errorMessage={errors.phoneNumber?.message}
                />
              </div>
            </div>
            <div className="flex w-full space-x-3">
              <div className="w-1/2">
                <div className="text-sm font-semibold">
                  <p>User type</p>
                </div>
                <div className="mt-2.5">
                  <Select
                    options={userTypeOptions}
                    selectedValue={selectedUserType}
                    onChange={handleUserTypeChange}
                    isForm
                    shadow
                  />
                </div>
              </div>
              <div className="w-1/2">
                <TextInput
                  register={register('jobTitle')}
                  label="Job title"
                  placeholder="Enter job title"
                  isLightMode
                  errorMessage={errors.jobTitle?.message}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="text-sm font-semibold">
                <p>Dashboard type</p>
              </div>
              <div className="mt-2.5">
                <Select
                  options={dashboardTypeOptions}
                  selectedValue={selectedDashboardType}
                  onChange={handleDashboardTypeChange}
                  isForm
                  shadow
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
