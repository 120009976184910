import React, { FC } from 'react';

interface SelectOptionProps {
  value: string;
  label: string;
}

export const SelectOption: FC<SelectOptionProps> = ({ label }) => {
  return (
    <div>
      <span>{label}</span>
    </div>
  );
};
