import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import { ResourceTutorial } from '@intelligent-play-v2/domain';

import { TextInput } from '~/components/forms';
import { Button, ButtonType } from '~/components/button';
import { Modal, PromptModal } from '~/components/modals';
import {
  PostTutorialBody,
  deleteTutorial,
  patchTutorial,
  postTutorial,
} from '~/api';

import deleteIcon from 'assets/images/pages/field/modals/icon__button-delete.png';
import deleteIcon2x from 'assets/images/pages/field/modals/icon__button-delete@2x.png';
import whiteDeleteIcon from 'assets/images/ctas_inputs_modals/icon__cta-delete-white.png';
import whiteDeleteIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-delete-white@2x.png';

interface TutorialModalProps {
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  tutorial?: ResourceTutorial | null; // pass in resource to edit
}
interface TutorialFormData {
  description: string;
  title: string;
  youtubeUrl: string;
}

export const TutorialModal: FC<TutorialModalProps> = ({
  showModal,
  setShowModal,
  tutorial,
}) => {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  useEffect(() => {
    if (tutorial) {
      const {
        title: tutorialTitle,
        description: tutorialDescription,
        youtubeId: tutorialYoutubeId,
      } = tutorial;
      setValue('title', tutorialTitle);
      setValue('description', tutorialDescription);
      setValue('youtubeUrl', tutorialYoutubeId);
    } else {
      reset();
    }
  }, [tutorial, setValue, reset]);

  const {
    mutate: mutatePostTutorial,
    isLoading: isLoadingPostTutorial,
  } = useMutation(postTutorial, {
    onSuccess: () => {
      setShowModal(false);
      reset();
      queryClient.invalidateQueries('resources/tutorials');
      toast.success('Succesfully created a tutorial');
    },
    onError: () => {
      toast.error('Error creating tutorial');
    },
  });
  const {
    mutate: mutateUpdateTutorial,
    isLoading: isLoadingUpdateTutorial,
  } = useMutation(patchTutorial, {
    onSuccess: () => {
      setShowModal(false);
      reset();
      queryClient.invalidateQueries('resources/tutorials');
      toast.success('Succesfully updated the tutorial');
    },
    onError: () => {
      toast.error('Error updating tutorial');
    },
  });

  const onSubmit = (formData: TutorialFormData): void => {
    let youtubeId = formData.youtubeUrl;
    const youtubeUrl = formData.youtubeUrl
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (youtubeUrl[2] !== undefined) {
      youtubeId = youtubeUrl[2].split(/[^0-9a-z_-]/i)[0];
    }

    if (youtubeId.length !== 11) {
      setError('youtubeUrl', {
        type: 'pattern',
        message: 'Please enter a valid YouTube URL',
      });
      return;
    }
    const data: PostTutorialBody = { ...formData, youtubeId: youtubeId };
    if (tutorial) {
      mutateUpdateTutorial({
        ...data,
        id: tutorial.id,
      });
    } else {
      mutatePostTutorial({ ...data });
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutate: mutateDeleteTutorial, isLoading: isDeleting } = useMutation(
    deleteTutorial,
    {
      onSuccess: () => {
        setShowModal(false);
        setShowDeleteModal(false);
        queryClient.invalidateQueries('resources/tutorials');
        toast.success('Succesfully deleted the tutorial');
      },
      onError: () => {
        toast.error('Error deleting the tutorial');
      },
    }
  );

  const deleteTutorialHandler = (): void => {
    if (tutorial) {
      mutateDeleteTutorial(tutorial.id);
    }
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={tutorial ? 'Edit tutorial' : 'Create tutorial'}
      estimatedHeight={514}
    >
      <div className="w-full space-y-3 sm:w-140">
        <TextInput
          register={register('title', {
            required: 'Please enter a title',
            maxLength: 100,
          })}
          label="Title"
          placeholder="Title"
          required
          type="text"
          isLightMode
          errorMessage={errors.title?.message}
        />
        <TextInput
          register={register('description', {
            required: 'Please enter a description',
            maxLength: 500,
          })}
          label="Description"
          placeholder="Description"
          required
          type="text"
          isLightMode
          errorMessage={errors.description?.message}
          isMultiline
        />
        <TextInput
          register={register('youtubeUrl', {
            required: 'Please enter a YouTube URL',
            pattern: {
              value: /(youtu.be\/)|(embed\?)|(watch\?)|^.{11}$/,
              message: 'Please enter a valid YouTube URL',
            },
          })}
          label="YouTube URL"
          placeholder="https://www.youtube.com/watch?v=jfooQxaNN3A"
          required
          type="text"
          isLightMode
          errorMessage={errors.youtubeUrl?.message}
        />
        <div>
          <div className="text-base font-semibold">Examples</div>
          <div className="mt-2 text-sm">
            YouTube links should look like:
            <div className="font-semibold">jfooQxaNN3A</div>
            <div className="font-semibold">https://youtu.be/jfooQxaNN3A</div>
            <div className="font-semibold">
              https://www.youtube.com/watch?v=jfooQxaNN3A
            </div>
          </div>
        </div>
        {tutorial ? (
          <div className="flex mt-5 space-x-5">
            <div className="w-1/2">
              <Button
                onClick={() => setShowDeleteModal(true)}
                text="Delete tutorial"
                type={ButtonType.OutlineDanger}
                icon={deleteIcon}
                icon2x={deleteIcon2x}
                className="border border-primary-250"
              />
            </div>
            <div className="w-1/2">
              <Button
                onClick={handleSubmit(onSubmit)}
                text="Save tutorial"
                isLoading={isLoadingUpdateTutorial}
              />
            </div>
          </div>
        ) : (
          <div className="w-full mt-5">
            <Button
              onClick={handleSubmit(onSubmit)}
              text="Create tutorial"
              isLoading={isLoadingPostTutorial}
            />
          </div>
        )}
      </div>
      <PromptModal
        showModal={showDeleteModal}
        title="Are you sure you want to delete this?"
        primaryButton={
          <Button
            text="Yes, delete"
            size="small"
            type={ButtonType.Danger}
            icon={whiteDeleteIcon}
            icon2x={whiteDeleteIcon2x}
            onClick={deleteTutorialHandler}
            isLoading={isDeleting}
          />
        }
        secondaryButton={
          <Button
            text="Cancel"
            size="small"
            type={ButtonType.Outline}
            onClick={() => setShowDeleteModal(false)}
            disabled={isDeleting}
          />
        }
      />
    </Modal>
  );
};
