import create from 'zustand';
import { ToggleableDataSeries, ToggleableDataSeriesCollection } from '~/types';

interface ToggleDataStore {
  facilityDataSeries: Record<string, ToggleableDataSeries[]>;
  setFacilityDataSeries: (
    facilityId: number,
    dataSeries: ToggleableDataSeries[]
  ) => void;
  toggleLine: (facilityId: number, key: string) => void;
  dailySeriesCollections: Record<string, ToggleableDataSeriesCollection[]>;
  setDailySeriesCollections: (
    facilityId: number,
    dataSeries: ToggleableDataSeriesCollection[]
  ) => void;
  toggleCollectionLine: (facilityId: number, key: string) => void;
}

export const useEuhDataStore = create<ToggleDataStore>(set => ({
  facilityDataSeries: {},
  setFacilityDataSeries: (facilityId, toggleableData) => {
    set(({ facilityDataSeries }) => {
      facilityDataSeries[facilityId] = toggleableData;
      return {
        facilityDataSeries,
      };
    });
  },
  toggleLine: (facilityId: number, key: string) => {
    set(({ facilityDataSeries }) => {
      const dataSeriesCopy: Record<string, ToggleableDataSeries[]> = {
        ...facilityDataSeries,
      };
      const index = dataSeriesCopy[facilityId].findIndex(
        series => series.key === key
      );
      if (index >= 0) {
        dataSeriesCopy[facilityId] = dataSeriesCopy[facilityId].map(
          (item, i) => {
            if (index === i) {
              item.showLine = !item.showLine;
            }
            return item;
          }
        );
      }
      return { facilityDataSeries: dataSeriesCopy };
    });
  },
  dailySeriesCollections: {},
  setDailySeriesCollections: (facilityId, toggleableData) => {
    set(({ dailySeriesCollections }) => {
      dailySeriesCollections[facilityId] = toggleableData;
      return {
        dailySeriesCollections,
      };
    });
  },
  toggleCollectionLine: (facilityId: number, key: string) => {
    set(({ dailySeriesCollections }) => {
      const dataCollectionCopy: Record<
        string,
        ToggleableDataSeriesCollection[]
      > = {
        ...dailySeriesCollections,
      };
      dataCollectionCopy[facilityId] = dataCollectionCopy[facilityId].map(
        collection => {
          const index = collection.data.findIndex(
            dataSeries => dataSeries.key === key
          );
          if (index >= 0) {
            collection.data = collection.data.map((item, i) => {
              if (index === i) {
                item.showLine = !item.showLine;
              }
              return item;
            });
          }
          return collection;
        }
      );

      return { dailySeriesCollections };
    });
  },
}));
