import React, { FC } from 'react';
import classNames from 'tailwindcss-classnames';
import { NavigationList } from './NavigationList';
import { useAuthStore } from '~/stores';
import { Client } from '@intelligent-play-v2/domain';

import ipLogo from 'assets/images/logos/logo__nav-desktop.png';
import ipLogo2x from 'assets/images/logos/logo__nav-desktop@2x.png';
import ipLogoMob from 'assets/images/logos/logo__nav-mob.png';
import ipLogoMob2x from 'assets/images/logos/logo__nav-mob@2x.png';

import fieldTurfLogo from 'assets/images/logos/logo__field-turf-nav-desktop.png';
import fieldTurfLogo2x from 'assets/images/logos/logo__field-turf-nav-desktop@2x.png';
import fieldTurfLogoMob from 'assets/images/logos/logo__field-turf-nav-mob.png';
import fieldTurfLogoMob2x from 'assets/images/logos/logo__field-turf-nav-mob@2x.png';

import closeIcon from 'assets/images/navs/icon__mobile-close.png';
import closeIcon2x from 'assets/images/navs/icon__mobile-close@2x.png';

interface SidebarProps {
  showMobileMenu: boolean;
  closeMenu: () => void;
}

export const Sidebar: FC<SidebarProps> = ({ showMobileMenu, closeMenu }) => {
  const { user } = useAuthStore();

  let logo = ipLogo;
  let logo2x = ipLogo2x;
  let logoMob = ipLogoMob;
  let logoMob2x = ipLogoMob2x;
  if (user?.clientId === Client.FieldTurf) {
    logo = fieldTurfLogo;
    logo2x = fieldTurfLogo2x;
    logoMob = fieldTurfLogoMob;
    logoMob2x = fieldTurfLogoMob2x;
  }

  const menuClass = classNames(
    { fixed: showMobileMenu },
    { hidden: !showMobileMenu },
    { 'w-12/16': showMobileMenu },
    'text-white',
    'sm:w-120',
    'md:w-auto',
    'md:relative',
    'md:block',
    'bg-primary-900',
    'font-tw-cent',
    'h-screen',
    'overflow-auto'
  );

  return (
    <div className={menuClass}>
      <picture>
        <img
          onClick={closeMenu}
          className="absolute cursor-pointer md:hidden left-5 top-5"
          src={closeIcon2x}
          srcSet={`${closeIcon} 1x, ${closeIcon2x} 2x`}
        />
      </picture>
      <div className="flex justify-center py-6 md:pt-12 md:pb-12.5">
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={`${logoMob} 1x, ${logoMob2x} 2x`}
          />
          <img src={logo} srcSet={`${logo} 1x, ${logo2x} 2x`} />
        </picture>
      </div>
      <NavigationList />
    </div>
  );
};
