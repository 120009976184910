import React, { FC, useContext, useEffect } from 'react';
import { ReportLoadingContext } from '~/contexts';

export const ReportLoadingMessage: FC = () => {
  const { setIsLoading } = useContext(ReportLoadingContext);
  useEffect(() => {
    setIsLoading(true);
    return () => {
      setIsLoading(false);
    };
  }, [setIsLoading]);
  return (
    <>
      <div>Getting things ready...</div>
      <div>Please give us a few moments to generate your report.</div>
    </>
  );
};
