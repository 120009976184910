import React, { FC, useCallback } from 'react';
import ReactSelect, { ValueType } from 'react-select';
import { classnames } from 'tailwindcss-classnames';
import { SelectDropdownIcon } from './SelectDropdownIcon';
import { SelectOption } from './SelectOption';

export interface SelectOptionType {
  label: string;
  value: string;
}

interface SelectProps {
  options: SelectOptionType[];
  selectedValue?: SelectOptionType | null;
  placeholder?: string;
  disabled?: boolean;
  bordered?: boolean;
  shadow?: boolean;
  bigger?: boolean;
  isForm?: boolean;
  isMobile?: boolean;
  onChange: (value: ValueType<SelectOptionType, false> | null) => void;
}

export const Select: FC<SelectProps> = ({
  options,
  placeholder,
  selectedValue,
  disabled,
  bigger,
  isForm,
  isMobile,
  onChange,
  bordered,
  shadow,
}) => {
  const height = isForm ? 50 : bigger ? 40 : 30;

  const selectClass = classnames({
    'shadow-250': shadow,
    'rounded-sm': shadow,
    'rounded-xs': !shadow,
  });

  const dropdownIcon = useCallback(
    () => <SelectDropdownIcon isDisabled={disabled} />,
    [disabled]
  );

  return (
    <ReactSelect
      className={`text-sm font-medium ${selectClass}`}
      placeholder={placeholder}
      isSearchable={false}
      options={options}
      value={selectedValue}
      isDisabled={disabled}
      onChange={onChange}
      components={{
        IndicatorSeparator: null,
        // eslint-disable-next-line react/display-name
        DropdownIndicator: dropdownIcon,
      }}
      formatOptionLabel={SelectOption}
      styles={{
        control: styles => ({
          ...styles,
          backgroundColor: 'white',
          minHeight: 30,
          height: height,
          width: '100%',
          border: isForm
            ? '1px solid #c9d1d7'
            : bordered
            ? '1px solid #E7EAED'
            : 'none',
          cursor: 'pointer',
          '&:hover': {
            border: '1px solid #c9d1d7',
          },
        }),
        singleValue: (styles, state) => ({
          ...styles,
          color: isMobile ? '#519c42' : '#202431',
          opacity: state.isDisabled ? '30%' : '100%',
          transform: 'translateY(-8px)',
          paddingLeft: isForm ? '8px' : styles.paddingLeft,
        }),
        menu: styles => ({
          ...styles,
          margin: 0,
          paddingBottom: 0,
          border: bordered ? '1px solid #E7EAED' : 'none',
          borderRadius: '0 0 2px 2px',
          '--tw-shadow': !bordered ? '0 2px 0 0 #c9d1d7' : '',
          boxShadow: !bordered
            ? 'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)'
            : '',
          '> div': {
            padding: 0,
          },
          zIndex: 20,
          width: isMobile ? window.innerWidth + 'px' : styles.width,
          transform: isMobile
            ? 'translateX(-14px) translateY(4px)'
            : styles.transform,
        }),
        option: styles => ({
          ...styles,
          borderTop: '1px solid lightgrey',
          backgroundColor: 'white',
          color: isMobile ? '#519c42' : '#202431',
          '&:hover': {
            backgroundColor: '#e5f0e3',
          },
          cursor: 'pointer',
          '> div': {
            transform: 'translateY(-1px)',
          },
          minHeight: isMobile ? '40px' : height + 'px',
          lineHeight: isMobile ? '30px' : isForm ? '30px' : styles.lineHeight,
          alignItems: 'center',
          display: 'flex',
        }),
        menuList: styles => ({
          ...styles,
          maxHeight: isMobile ? '250px' : '150px',
          width: '100%',
        }),
      }}
    />
  );
};
