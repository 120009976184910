import {
  Facility,
  OutOfHours,
  UserAlertSubscription,
} from '@intelligent-play-v2/domain';

import React, { FC } from 'react';
import { PitchSettings } from './PitchSettings';

interface FacilitySettingsProps {
  facility: Facility;
  selectedAlertSubscriptions: UserAlertSubscription[];
  outOfHours: OutOfHours[];
}

export const FacilitySettings: FC<FacilitySettingsProps> = ({
  facility,
  selectedAlertSubscriptions,
  outOfHours,
}) => {
  return (
    <div
      key={facility.id}
      className="w-full p-3 space-y-6 bg-white rounded shadow-250"
    >
      <h5 className="text-xl font-semibold translate-y-1">{facility.name}</h5>

      {facility.pitches?.map(pitch => {
        const pitchOutOfHours = outOfHours.filter(
          ({ pitchId }) => pitchId === pitch.id
        )[0];

        const pitchUserAlertSubscriptions = selectedAlertSubscriptions.filter(
          ({ pitchId }) => pitchId === pitch.id
        );

        return (
          <PitchSettings
            key={pitch.id}
            pitch={pitch}
            userAlertSubscriptions={pitchUserAlertSubscriptions}
            outOfHours={pitchOutOfHours}
          />
        );
      })}
    </div>
  );
};
