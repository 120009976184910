import { isBefore } from 'date-fns';
import React, { FC, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import { useOutsideClicker } from '~/hooks';
import { formatDateGbUs } from '~/utils';
import { TextInput } from '../../forms';
import { DatePickerDay, DatePickerNavbar } from '~/components/datePicker';
import 'react-day-picker/lib/style.css';
import '~/styles/dayPicker.css';

interface DatePickerFieldProps {
  label: string;
  selectedDay: Date;
  setSelectedDay: (date: Date) => void;
  disabledBefore?: Date;
}

export const DatePickerField: FC<DatePickerFieldProps> = ({
  label,
  selectedDay,
  setSelectedDay,
  disabledBefore,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const dialogRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  useOutsideClicker(dialogRef, buttonRef, () => setIsFocused(false));

  const renderDay = (day: Date): React.ReactNode => {
    return <DatePickerDay day={day} selectedDay={selectedDay} size="small" />;
  };

  const onDayClick = (date: Date): void => {
    if (disabledBefore && isBefore(date, disabledBefore)) {
      return;
    }
    setSelectedDay(date);
  };

  return (
    <div className="relative w-full">
      <TextInput
        label={label}
        type="text"
        value={formatDateGbUs(selectedDay, 'dd/MM/yyyy')}
        isLightMode
        disableManualInput
        isFocused={isFocused}
        onChangeFocus={setIsFocused}
        hasBottomPopup={isFocused}
        fieldRef={buttonRef}
        readOnly
      />
      {isFocused && (
        <div
          ref={dialogRef}
          className="absolute z-10 w-full bg-white border border-green rounded-b shadow-green"
        >
          <DayPicker
            disabledDays={disabledBefore && { before: disabledBefore }}
            className="flex w-full mt-4 DatePickerSmall"
            selectedDays={selectedDay}
            onDayClick={onDayClick}
            renderDay={renderDay}
            enableOutsideDaysClick={false}
            showOutsideDays={true}
            navbarElement={props => (
              <DatePickerNavbar {...props} size="small" />
            )}
            captionElement={<></>}
            weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
            firstDayOfWeek={1}
            modifiers={{ today: undefined }}
          />
        </div>
      )}
    </div>
  );
};
