import { queryBuilder } from '~/api';

export const getFacilityImageUrl = async (
  facilityId: number | string
): Promise<string> => {
  const { data } = await queryBuilder(
    'GET',
    `facilities/${facilityId}/imageUrl`
  );
  return data;
};
