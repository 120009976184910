import React, { FC } from 'react';
import { Button, ButtonType } from '~/components/button';
import { PromptModal } from '../promptModal';
import whiteDeleteIcon from 'assets/images/ctas_inputs_modals/icon__cta-delete-white.png';
import whiteDeleteIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-delete-white@2x.png';
import redDeleteIcon from 'assets/images/ctas_inputs_modals/icon__cta-delete-red.png';
import redDeleteIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-delete-red@2x.png';

interface EventDeleteModalProps {
  showModal: boolean;
  showRecurrenceDelete: boolean;
  isDeleting: boolean;
  isDeletingRecurrence: boolean;
  closeDeleteModal: () => void;
  onDelete: () => void;
  onDeleteRecurrence?: () => void;
}

export const EventDeleteModal: FC<EventDeleteModalProps> = ({
  showModal,
  showRecurrenceDelete,
  isDeleting,
  isDeletingRecurrence,
  closeDeleteModal,
  onDelete,
  onDeleteRecurrence,
}) => {
  return (
    <PromptModal
      title="Are you sure you want to delete this?"
      primaryButton={
        <Button
          text="Yes, delete"
          size="small"
          type={ButtonType.Danger}
          icon={whiteDeleteIcon}
          icon2x={whiteDeleteIcon2x}
          onClick={onDelete}
          disabled={isDeleting || isDeletingRecurrence}
          isLoading={isDeleting}
        />
      }
      secondaryButton={
        <Button
          text="Cancel"
          size="small"
          type={ButtonType.Outline}
          onClick={closeDeleteModal}
          disabled={isDeleting}
        />
      }
      thirdButton={
        showRecurrenceDelete ? (
          <Button
            text="Delete all future recurrences"
            size="small"
            type={ButtonType.OutlineDanger}
            className="border border-primary-250"
            icon={redDeleteIcon}
            icon2x={redDeleteIcon2x}
            onClick={onDeleteRecurrence}
            disabled={isDeleting || isDeletingRecurrence}
            isLoading={isDeletingRecurrence}
          />
        ) : undefined
      }
      showModal={showModal}
    />
  );
};
