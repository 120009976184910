import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { PercentageChangeIndicator } from '~/components/data';
import { Loading } from '~/components/loading';
import { formatNumber } from '~/utils';

interface StatSummarySmallProps {
  icon: string;
  icon2x: string;
  name: string;
  value?: number;
  percentageChange: number | null;
  alwaysSmall?: boolean;
}

export const StatSummarySmall: FC<StatSummarySmallProps> = ({
  icon,
  icon2x,
  name,
  value,
  percentageChange,
  alwaysSmall,
}) => {
  const containerClass = classnames('flex', 'items-center', 'w-32');
  const imageContainerClass = classnames(
    { 'lg:w-10': !alwaysSmall },
    { 'lg:h-10': !alwaysSmall },
    'flex',
    'items-center',
    'justify-center',
    'w-5',
    'h-5',
    'bg-primary-100',
    'rounded-sm',
    'flex-shrink-0'
  );
  const imageClass = classnames({ 'lg:w-auto': !alwaysSmall }, 'w-3');
  const statLabelClass = classnames(
    { 'lg:block': !alwaysSmall },
    'hidden',
    'text-xs',
    'text-primary-400'
  );

  return (
    <div className={containerClass}>
      <div className={imageContainerClass}>
        <picture>
          <img
            className={imageClass}
            src={icon2x}
            srcSet={`${icon} 1x, ${icon2x} 2x`}
          />
        </picture>
      </div>
      <div className="flex flex-col justify-between ml-2">
        <span className="flex items-baseline">
          {value === undefined ? (
            <Loading size={5} />
          ) : (
            <>
              <span className="font-semibold">{formatNumber(value)}</span>
              <span className="ml-1.5">
                <PercentageChangeIndicator
                  percentageChange={percentageChange}
                  alwaysSmall={alwaysSmall}
                />
              </span>
            </>
          )}
        </span>
        <span className={statLabelClass}>{name}</span>
      </div>
    </div>
  );
};
