import React, { ElementType, FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuthStore } from '~/stores';
import { RoutesPath } from '~/types';
import { UserType } from '@intelligent-play-v2/domain';

interface AdminRouteProps {
  component: ElementType;
  strict?: boolean;
  path: string;
  exact?: boolean;
}

export const AdminRoute: FC<AdminRouteProps> = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  component: Component,
  strict = false,
  ...rest
}) => {
  const { user } = useAuthStore();

  return (
    <Switch>
      <Route
        {...rest}
        strict={strict}
        render={() => {
          if (user && user.userTypeId === UserType.SystemAdmin) {
            return <Component {...rest} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: RoutesPath.Dashboard,
                  state: { from: location.pathname },
                }}
              />
            );
          }
        }}
      />
    </Switch>
  );
};
