import React, { FC, RefObject, useEffect, useState } from 'react';
import { DateRangeStats, TimePeriod } from '@intelligent-play-v2/domain';
import { classnames } from 'tailwindcss-classnames';
import { useMediaQuery } from 'react-responsive';
import mobile from 'is-mobile';
import { KeyStatisticsGraph } from '~/components/graphs/KeyStatisticsGraph';
import { Tooltip, TooltipParent } from '~/components/tooltip';
import { getKeyStatisticUI } from './getKeyStatisticUI';
import { PercentageChangeIndicator } from '.';
import { KeyStatisticColour, KeyStatisticType } from '~/types';
import { formatNumber, toDatePoint } from '~/utils';

interface KeyStatisticProps {
  data: DateRangeStats;
  comparisonData?: DateRangeStats;
  width: number;
  timePeriod: TimePeriod;
  keyStatisticType: KeyStatisticType;
  componentRef?: RefObject<HTMLDivElement>;
}

export const KeyStatistic: FC<KeyStatisticProps> = ({
  data,
  comparisonData,
  width,
  timePeriod,
  keyStatisticType,
  componentRef,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { title, icon, icon2x, infoText, colour } = getKeyStatisticUI(
    keyStatisticType
  );
  const {
    total,
    datesTotal,
    totalPreviousPeriod,
    percentageChange,
    dates,
  } = data;

  const imageContainerStyle = classnames(
    { 'bg-green-lightest': colour === KeyStatisticColour.Green },
    { 'bg-yellow-lightest': colour === KeyStatisticColour.Yellow },
    'w-12.5',
    'h-12.5',
    'rounded',
    'flex',
    'justify-center',
    'items-center',
    'flex-shrink-0'
  );

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const datePoints = toDatePoint(dates, timezone);
  const comparisonDatePoints = comparisonData
    ? toDatePoint(comparisonData.dates, timezone)
    : null;

  const prevString = total
    ? 'Week to date'
    : `Previous ${timePeriod.split(' ').splice(1).join(' ')}`;
  const displayedPrevTotal = total ? datesTotal : totalPreviousPeriod;

  const keyStatisticContentClass = classnames({
    'my-5': Object.entries(dates).length <= 1,
    'mt-5': Object.entries(dates).length > 1,
  });

  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();

  useEffect(() => {
    if (!isMobile) {
      setShowTooltip(false);
    }
  }, [isMobile]);

  const displayedTotal = total ? total : datesTotal;
  return (
    <div ref={componentRef} className="flex flex-col justify-between bg-white">
      <div className={`mx-5 text-primary-900 ${keyStatisticContentClass}`}>
        <TooltipParent
          onClick={isMobile ? () => setShowTooltip(!showTooltip) : undefined}
          className="flex mb-5"
        >
          <div className={imageContainerStyle}>
            <picture>
              <img
                className="m-auto my-1.5"
                src={icon}
                srcSet={`${icon} 1x, ${icon2x} 2x`}
              />
            </picture>
          </div>
          <div className="ml-2.5">
            <div className="text-sm font-semibold">{title}</div>
            <div className="text-5xl font-semibold">
              {formatNumber(displayedTotal)}
            </div>
            {percentageChange && Math.abs(percentageChange) < 1000 && (
              <div className="w-12 h-3 my-1 ml-1">
                <PercentageChangeIndicator
                  percentageChange={percentageChange}
                />
              </div>
            )}
            {comparisonData && (
              <div className="text-5xl font-semibold text-blue">
                {formatNumber(comparisonData?.datesTotal)}
              </div>
            )}
          </div>
          <Tooltip
            pointerLeftPosition={25}
            showTooltip={isMobile && showTooltip}
            isHoverDisabled={isMobile}
          >
            <div className="p-2.5">
              <div className="flex items-center justify-center w-3 h-3 text-xs text-white bg-green rounded-full">
                i
              </div>
              <div className="text-primary-900 text-sm mt-0.75">{infoText}</div>
            </div>
          </Tooltip>
        </TooltipParent>
        <div className="hidden 2xl:block">
          <hr className="border-primary-100" />
          {(totalPreviousPeriod > 0 || (total && total > 0)) && (
            <div className="flex justify-between text-xs font-medium mt-1.5">
              <div>{prevString}</div>
              <div className="flex space-x-4 font-semibold">
                <div>{formatNumber(displayedPrevTotal)}</div>
                {comparisonData && (
                  <div className="text-blue">
                    {formatNumber(comparisonData.totalPreviousPeriod)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {Object.entries(dates).length > 1 && (
        <div className="hidden mt-2 -mb-3 h-22 2xl:block">
          <KeyStatisticsGraph
            data={datePoints}
            comparisonData={comparisonDatePoints}
            width={width}
            height={88}
            colour={colour}
          />
        </div>
      )}
      {isMobile && showTooltip && (
        <div
          className="absolute inset-0 z-10"
          onClick={() => setShowTooltip(false)}
        />
      )}
    </div>
  );
};
