import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { isAfter, isSameDay } from 'date-fns';

import { MaintenanceStatus } from '@intelligent-play-v2/domain';
import { userHasCalendarWriteAccess } from '@intelligent-play-v2/lib';

import { formatDateGbUs, formatNumber } from '~/utils';

import editIcon from 'assets/images/pages/calendar/icon__task-edit.png';
import editIcon2x from 'assets/images/pages/calendar/icon__task-edit@2x.png';
import deleteIcon from 'assets/images/pages/calendar/icon__task-delete.png';
import deleteIcon2x from 'assets/images/pages/calendar/icon__task-delete@2x.png';
import urgentIcon from 'assets/images/pages/field/maintenance/icon__calendar-urgent.png';
import urgentIcon2x from 'assets/images/pages/field/maintenance/icon__calendar-urgent@2x.png';
import { useAuthStore } from '~/stores';

interface DayStatusTooltipContentProps {
  title: string;
  date: Date | string;
  status: MaintenanceStatus;
  isTracked: boolean;
  dueDate?: Date | null;
  maintenanceHoursCompleted?: number;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
}

export const DayStatusTooltipContent: FC<DayStatusTooltipContentProps> = ({
  title,
  date,
  status,
  dueDate,
  maintenanceHoursCompleted,
  isTracked,
  onClickEdit,
  onClickDelete,
}) => {
  const { user } = useAuthStore();
  const isDanger = status === MaintenanceStatus.Overdue;
  const tooltipTextClass = classnames(
    { 'text-red': isDanger },
    { 'text-primary-900': !isDanger },
    'text-sm',
    'font-semibold'
  );

  const tooltipText =
    status === MaintenanceStatus.Complete ? 'Completed' : 'Due on ';

  const dueDateClass = classnames(
    { 'text-yellow': status === MaintenanceStatus.Due },
    {
      'text-red-dark': status === MaintenanceStatus.Overdue,
    }
  );

  const isMaintenanceExpected =
    status === MaintenanceStatus.Due ||
    status === MaintenanceStatus.Overdue ||
    (status === MaintenanceStatus.Complete &&
      dueDate !== null &&
      (isAfter(new Date(date), new Date(dueDate || '')) ||
        isSameDay(new Date(date), new Date(dueDate || ''))));

  return (
    <div className="p-1.5 pb-2.5 whitespace-nowrap text-primary-900 flex">
      <div>
        <div className="flex">
          {isDanger && (
            <img
              className="w-3 h-3 mr-1 transform translate-y-0.5"
              src={urgentIcon2x}
              srcSet={`${urgentIcon} 1x, ${urgentIcon2x} 2x`}
            />
          )}
          <div className={tooltipTextClass}>{title}</div>
        </div>
        <div className="mt-0.5 text-xs font-normal">
          {formatDateGbUs(new Date(date), 'E d MMM yyyy')}
        </div>
        {isTracked && (
          <div className="mt-0.5 text-xs font-normal flex flex-col">
            <span>Hours expected: {isMaintenanceExpected ? '1' : '0'}</span>
            <span>
              Hours maintained: {formatNumber(maintenanceHoursCompleted || 0)}
            </span>
          </div>
        )}
        <div className="mt-0.5 text-xs text-primary-500 font-normal">
          {tooltipText}
          {status !== MaintenanceStatus.Complete && (
            <span className={dueDateClass}>
              {dueDate && formatDateGbUs(new Date(dueDate), 'E d MMM yyyy')}
            </span>
          )}
        </div>
      </div>
      {user &&
        userHasCalendarWriteAccess(user) &&
        onClickEdit &&
        onClickDelete && (
          <div className="flex ml-4 space-x-2">
            <button
              className="w-4.5 cursor-pointer flex mt-2"
              onClick={() => onClickEdit()}
            >
              <picture>
                <img
                  src={editIcon}
                  srcSet={`${editIcon} 1x, ${editIcon2x} 2x`}
                />
              </picture>
            </button>
            <button
              className="flex w-4 mt-2 cursor-pointer"
              onClick={() => onClickDelete()}
            >
              <picture>
                <img
                  src={deleteIcon}
                  srcSet={`${deleteIcon} 1x, ${deleteIcon2x} 2x`}
                />
              </picture>
            </button>
          </div>
        )}
    </div>
  );
};
