import { Facility } from '@intelligent-play-v2/domain';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getCountryForTimezone } from 'countries-and-timezones';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { getFacilityImageUrl } from '~/api';
import { TextInput } from '~/components/forms';
import { Loading } from '~/components/loading';
import {
  GOOGLE_MAP_API_KEY,
  googleMapOptions,
} from '~/components/map/constants';
import { getLatLng } from '~/components/map/utils';
import { getCountryFlag } from '~/utils';
import pin from 'assets/images/pages/facilities/map/icon__pin.png';
import { FieldValues, UseFormRegister } from 'react-hook-form';

interface FacilityDetailsProps {
  facility: Facility;
  register: UseFormRegister<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: { [x: string]: any };
}

export const FacilityDetails: FC<FacilityDetailsProps> = ({
  facility,
  register,
  errors,
}) => {
  const country = getCountryForTimezone(facility?.tzDatabaseTimezone ?? '');
  const flagIcons = country ? getCountryFlag(country) : null;

  const {
    data: facilityImageUrl,
    isLoading: isLoadingFacilityImage,
  } = useQuery(['facilityImage', facility.id], async () =>
    getFacilityImageUrl(facility.id)
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  return (
    <div className="text-primary-900 bg-white rounded shadow-250">
      <div className="px-5 py-3 text-lg font-semibold border-b border-primary-100">
        Facility details
      </div>
      <div className="grid grid-cols-1 gap-10 p-5 lg:grid-cols-2">
        <div className="space-y-5">
          <TextInput
            register={register('name', {
              required: 'Please enter a facility name',
            })}
            label="Facility name"
            placeholder="Facility name"
            required={true}
            type="text"
            isLightMode
            errorMessage={errors.name?.message}
          />
          <TextInput
            register={register('address')}
            label="Address"
            placeholder="Address"
            type="text"
            isLightMode
            errorMessage={errors.address?.message}
          />
          <div className="grid gap-5 sm:grid-cols-2">
            <TextInput
              register={register('postcode')}
              label="Postcode"
              placeholder="Postcode"
              type="text"
              isLightMode
              errorMessage={errors.postcode?.message}
            />
            <TextInput
              label="Country"
              type="text"
              isLightMode
              errorMessage={errors.country?.message}
              value={country?.name}
              icon={flagIcons ? flagIcons.flagIcon : undefined}
              icon2x={flagIcons ? flagIcons.flagIcon2x : undefined}
              disabled={true}
            />
          </div>
          <div>
            <div className="text-sm font-semibold">Site overview image</div>
            <div className="p-4 border rounded border-primary-250 mt-2.5 shadow-250 flex items-center">
              {isLoadingFacilityImage ? (
                <Loading />
              ) : !facilityImageUrl ? (
                'No image'
              ) : (
                <picture>
                  <img src={facilityImageUrl} className="h-17.5" />
                </picture>
              )}

              <div className="ml-5">
                <div className="text-base font-semibold">
                  {`facility${facility.id}.png`}
                </div>
                {/* <div className="mt-1 text-xs font-medium text-primary-400">
              14kb
            </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm font-semibold">
          {isLoaded && (
            <div className="mt-2.5">
              <GoogleMap
                mapContainerStyle={{ height: '504px' }}
                center={getLatLng(facility)}
                options={googleMapOptions}
                zoom={16}
              >
                <Marker position={getLatLng(facility)} icon={pin} />
              </GoogleMap>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
