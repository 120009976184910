import React, { FC } from 'react';
import { ContentContainer } from '..';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

export const PageHeader: FC<PageHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="pt-7.5 bg-white border-b border-primary-100 md:pt-14 sm:static sticky top-15 sm:top-0 z-20 sm:z-0">
      <ContentContainer className="flex pb-7 md:pb-7.5">
        <div className="font-tw-cent text-3xl font-bold">{title}</div>
        <div className="mt-auto mb-0.75 ml-2.5 text-base text-primary-300 md:ml-3">
          {subtitle}
        </div>
      </ContentContainer>
    </div>
  );
};
