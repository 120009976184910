import React, { FC } from 'react';
import { RoutesPath } from '~/types';
import { InfoWindow } from '@react-google-maps/api';
import { Facility } from '@intelligent-play-v2/domain';
import { Link } from 'react-router-dom';

interface FacilityInfoWindowProps {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  facility: Facility;
  allowRedirect: boolean;
}

export const FacilityInfoWindow: FC<FacilityInfoWindowProps> = ({
  position,
  facility,
  allowRedirect,
}) => {
  return (
    <InfoWindow position={position}>
      <Link
        to={
          allowRedirect
            ? RoutesPath.FacilityOverview.replace(
                ':facilityId',
                facility.id.toString()
              )
            : '#'
        }
      >
        <h1 className="pl-1 font-bold cursor-pointer">{facility.name}</h1>
      </Link>
    </InfoWindow>
  );
};
