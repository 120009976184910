import React, { FC } from 'react';
import downloadIcon from 'assets/images/pages/field/usage/icon__download.png';
import downloadIcon2x from 'assets/images/pages/field/usage/icon__download@2x.png';

interface DownloadButtonProps {
  onClick?: () => void;
}

export const DownloadButton: FC<DownloadButtonProps> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex text-base font-bold text-blue cursor-pointer"
    >
      <picture className="mt-0.5 mr-2 w-4.5 ">
        <img
          src={downloadIcon2x}
          srcSet={`${downloadIcon} 1x, ${downloadIcon2x} 2x`}
        />
      </picture>
      Download
    </div>
  );
};
