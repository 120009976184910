import { Snapshot, TimePeriod } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';
export interface PeakTimeSnapshotParams {
  pitchId: number;
  timePeriod: TimePeriod;
  timezone: string;
  from?: string;
  to?: string;
}

export const getPeakTimeSnapshots = async ({
  pitchId,
  timePeriod,
  timezone,
  from,
  to,
}: PeakTimeSnapshotParams): Promise<Snapshot[]> => {
  const { data } = await queryBuilder('GET', 'snapshots/peakTime', {
    pitchId,
    timePeriod,
    timezone,
    from,
    to,
  });
  return data;
};
