import { PitchMaintenanceHoursResponse } from '@intelligent-play-v2/domain';
import { FilteredStatisticsParams } from '~/types';
import { queryBuilder } from '~/api';

export const getMaintenanceHoursStats = async ({
  pitchIds,
  timePeriod,
  from,
  to,
  timezone,
  dailyStartTime,
  dailyEndTime,
  includedDays,
}: FilteredStatisticsParams): Promise<PitchMaintenanceHoursResponse[]> => {
  const { data } = await queryBuilder('GET', 'stats/maintenanceHours', {
    timePeriod,
    from,
    to,
    pitchIds: pitchIds.join(','),
    timezone,
    dailyStartTime,
    dailyEndTime,
    includedDays: includedDays?.join(','),
  });
  return data;
};
