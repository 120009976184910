export enum ReportGraphType {
  PlayerHours = 'playerHours',
  PlayerCount = 'playerCount',
  UsageHours = 'usageHours',
  EUH = 'euh',
  MachineMaintenanceHours = 'machineMaintenanceHours',
  MachineMaintenanceSessions = 'machineMaintenanceSessions',
  MaintenanceHours = 'maintenanceHours',
  MaintenanceSessions = 'maintenanceSessions',
}

export const getReportGraphAxisLabel = (graphType: ReportGraphType): string => {
  switch (graphType) {
    case ReportGraphType.PlayerHours:
      return 'Player hours';
    case ReportGraphType.PlayerCount:
      return 'Player count';
    case ReportGraphType.UsageHours:
      return 'Usage hours';
    case ReportGraphType.EUH:
      return 'EUH';
    case ReportGraphType.MachineMaintenanceHours:
      return 'Machine maintenance hours';
    case ReportGraphType.MachineMaintenanceSessions:
      return 'Machine maintenance sessions';
    case ReportGraphType.MaintenanceHours:
      return 'Maintenance hours';
    case ReportGraphType.MaintenanceSessions:
      return 'Maintenance sessions';
    default:
      return '';
  }
};

export const getReportTooltipLabel = (graphType: ReportGraphType): string => {
  switch (graphType) {
    case ReportGraphType.PlayerHours:
      return 'hours';
    case ReportGraphType.PlayerCount:
      return 'players';
    case ReportGraphType.UsageHours:
      return 'hours';
    case ReportGraphType.EUH:
      return 'euh';
    case ReportGraphType.MachineMaintenanceHours:
      return 'hour';
    case ReportGraphType.MachineMaintenanceSessions:
      return 'sessions';
    case ReportGraphType.MaintenanceHours:
      return 'hours';
    case ReportGraphType.MaintenanceSessions:
      return 'session';
    default:
      return '';
  }
};
