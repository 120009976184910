import React, { FC } from 'react';
import { MaintenanceCarriedOut } from '~/types';
import maintenanceIcon from '/assets/images/pages/dashboard/key_stats/icon__maintenance.png';
import maintenanceIcon2x from '/assets/images/pages/dashboard/key_stats/icon__maintenance@2x.png';

interface MaintenanceCarriedOutMarkerProps {
  maintenanceCarriedOut: MaintenanceCarriedOut;
  onClick: (time: string) => void;
}

export const MaintenanceCarriedOutMarker: FC<MaintenanceCarriedOutMarkerProps> = ({
  maintenanceCarriedOut,
  onClick,
}) => {
  const { time, pct } = maintenanceCarriedOut;
  return (
    <div
      className="absolute transform -translate-x-1.5"
      style={{ left: `${pct}%` }}
    >
      <img
        className="h-2 cursor-pointer"
        src={maintenanceIcon}
        srcSet={`${maintenanceIcon} 1x, ${maintenanceIcon2x} 2x`}
        onClick={() => onClick(time)}
      />
      <div
        className="absolute w-0 h-0 left-0.5 mt-0.5"
        style={{
          borderLeft: '4px solid transparent',
          borderRight: '4px solid transparent',
          borderTop: '4px solid #519c42',
        }}
      />
    </div>
  );
};
