/* eslint-disable max-len */
import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { FaqCategory } from '@intelligent-play-v2/domain';

import arrowRightIcon from 'assets/images/navs/icon__arrow-right.png';
import arrowRightIcon2x from 'assets/images/navs/icon__arrow-right@2x.png';
import { getCategoryIcons } from '~/utils/contactFaq.utils';

interface ContactFaqCardProps {
  category: FaqCategory;
  entries: number;
  isSelected: boolean;
  setSelectedCategory: (category: FaqCategory) => void;
}

export const ContactFaqCard: FC<ContactFaqCardProps> = ({
  category,
  entries,
  isSelected,
  setSelectedCategory,
}) => {
  const selectedClass = classnames(
    { 'border-green': isSelected },
    { 'shadow-green': isSelected },
    { 'shadow-250': !isSelected }
  );

  const [icon, icon2x] = getCategoryIcons(category);

  return (
    <div
      className={`flex flex-row sm:flex-col w-full sm:border border-primary-250 rounded-sm h-12 sm:h-[184px] sm:justify-center items-center cursor-pointer bg-white px-4 py-2 sm:p-0 ${selectedClass}`}
      onClick={() => setSelectedCategory(category)}
    >
      <picture>
        <img
          className="w-6 sm:w-auto sm:pt-4"
          src={icon}
          srcSet={`${icon} 1x, ${icon2x} 2x`}
        />
      </picture>
      <div className="pl-4 text-base font-semibold text-center sm:pl-0 sm:pt-6">
        {category} FAQs
      </div>
      <div className="ml-auto mr-6 text-sm sm:ml-0 sm:mr-0 sm:pt-1">
        {entries} Entries
      </div>
      <picture className="sm:hidden">
        <img
          className="object-cover"
          src={arrowRightIcon}
          srcSet={`${arrowRightIcon} 1x, ${arrowRightIcon2x} 2x`}
        />
      </picture>
    </div>
  );
};
