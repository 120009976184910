import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getTutorials } from '~/api/queries/resources';
import { Button, ButtonType } from '~/components/button';
import { ContentContainer } from '~/components/layout';
import { TutorialModal } from '~/components/modals';
import { TutorialCard } from '~/components/resources';
import { ResourceTutorial, UserType } from '@intelligent-play-v2/domain';
import { useHistory } from 'react-router-dom';
import { RoutesPath } from '~/types';
import { useAuthStore } from '~/stores';
import { useMediaQuery } from 'react-responsive';
import mobile from 'is-mobile';
import { IP_ONBOARDING_TOUR_UUID } from '~/config';

export const ResourcesTutorialsPage: FC = () => {
  const { user } = useAuthStore();
  const history = useHistory();
  const [isLoadingTour, setIsLoadingTour] = useState(false);
  const [
    selectedTutorial,
    setSelectedTutorial,
  ] = useState<null | ResourceTutorial>(null);
  const [showTutorialModal, setShowTutorialModal] = useState(false);

  const isSystemAdmin = user?.userTypeId === UserType.SystemAdmin;
  const isMobile = useMediaQuery({ query: '(max-width: 639px)' }) || mobile();

  window.addEventListener('message', function (event) {
    if (event.data.TYPE === 'KOMPASSIFY_TOUR_STARTED') {
      setIsLoadingTour(false);
    }
  });

  const handleLaunchTutorial = (): void => {
    setIsLoadingTour(true);
    history.push(RoutesPath.Dashboard);
    if (window.kompassifyLaunchTour) {
      window.kompassifyLaunchTour(IP_ONBOARDING_TOUR_UUID, 0, false);
    }
  };

  const handleOpenEditTutorialModal = (tutorial: ResourceTutorial): void => {
    setSelectedTutorial(tutorial);
    setShowTutorialModal(true);
  };

  const { data } = useQuery(['resources/tutorials'], async () =>
    getTutorials()
  );

  useEffect(() => {
    if (showTutorialModal === false) {
      setSelectedTutorial(null);
    }
  }, [showTutorialModal]);

  const tutorialResource: ResourceTutorial = {
    id: 9998,
    title: 'How to use the login area',
    description:
      'Refamiliarise yourself with the new Intelligent Play user  dashboard by taking our interactive walkthrough tour.',
    youtubeId: '',
    createdAt: new Date(),
    updatedAt: null,
  };
  const addNewResource: ResourceTutorial = {
    id: 9999,
    title: 'Add a new tutorial',
    description: 'Click on the button below to add a new tutorial.',
    youtubeId: '',
    createdAt: new Date(),
    updatedAt: null,
  };

  return (
    <ContentContainer className="py-6">
      <div className="grid gap-6 grid-col-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        <div className="hidden md:block">
          {!isMobile && (
            <TutorialCard
              tutorial={tutorialResource}
              action={
                <Button
                  className="w-10/16"
                  type={ButtonType.Secondary}
                  text="Launch tutorial"
                  onClick={handleLaunchTutorial}
                  isLoading={isLoadingTour}
                />
              }
              onClickEdit={() => undefined}
              hideEditButton
            />
          )}
        </div>
        {data &&
          data.map(tutorial => {
            return (
              <TutorialCard
                key={tutorial.id}
                tutorial={tutorial}
                onClickEdit={handleOpenEditTutorialModal}
              />
            );
          })}
        {isSystemAdmin && (
          <TutorialCard
            tutorial={addNewResource}
            action={
              <Button
                className="w-10/16"
                text="Create Tutorial"
                onClick={() => setShowTutorialModal(true)}
                type={ButtonType.Secondary}
              />
            }
            onClickEdit={() => undefined}
            hideEditButton
          />
        )}
      </div>
      <TutorialModal
        setShowModal={setShowTutorialModal}
        showModal={showTutorialModal}
        tutorial={selectedTutorial}
      />
    </ContentContainer>
  );
};
