import React, { FC, MouseEvent, useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { classnames } from 'tailwindcss-classnames';

interface ContentTabProps {
  title: string;
  path: string;
  strict?: boolean;
  exact?: boolean;
  isDisabled?: boolean;
}

export const ContentTab: FC<ContentTabProps> = ({
  title,
  path,
  strict,
  exact,
  isDisabled,
}) => {
  const history = useHistory();

  const navLinkClass = classnames({
    'hover:text-primary-900': !isDisabled,
    'cursor-default': isDisabled,
    'text-primary-250': isDisabled,
    'text-primary-400': !isDisabled,
  });

  const navigateToPath = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (!isDisabled) {
        history.push(path);
      }
    },
    [history, isDisabled, path]
  );

  return (
    <NavLink
      className={`pb-2 mr-5 text-xl md:mr-7.5 ${navLinkClass}`}
      activeClassName="border-b-4 border-green text-primary-900"
      strict={strict}
      exact={exact}
      to={path}
      onClick={navigateToPath}
    >
      <span className="font-semibold">{title}</span>
    </NavLink>
  );
};
