import React, { FC, useRef, useState } from 'react';
import notificationIcon from 'assets/images/navs/icon__notifications-dark.png';
import notificationIconGreen from 'assets/images/navs/icon__notifications-green.png';
import notificationIcon2x from 'assets/images/navs/icon__notifications-dark@2x.png';
import notificationIconGreen2x from 'assets/images/navs/icon__notifications-green@2x.png';
import { useAlertStore } from '~/stores';
import { useOutsideClicker } from '~/hooks';
import { UserAlertRow } from './UserAlertRow';

export const AlertsDropdown: FC = () => {
  const { userAlerts } = useAlertStore();

  const [showAlertsDropdown, setShowAlertsDropdown] = useState(false);

  const toggleAlertsDropdown = (): void => {
    setShowAlertsDropdown(!showAlertsDropdown);
  };

  const dialogRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useOutsideClicker(dialogRef, buttonRef, () => setShowAlertsDropdown(false));

  let alertsIcon = notificationIcon,
    alertsIcon2x = notificationIcon2x;
  if (showAlertsDropdown) {
    alertsIcon = notificationIconGreen;
    alertsIcon2x = notificationIconGreen2x;
  }

  return (
    <div className="flex">
      <button ref={buttonRef} onClick={toggleAlertsDropdown}>
        <picture>
          <img
            className="mr-5 transform cursor-pointer hover:rotate-12"
            src={alertsIcon2x}
            srcSet={`${alertsIcon} 1x, ${alertsIcon2x} 2x`}
          />
        </picture>
      </button>
      {userAlerts.length ? (
        <div className="absolute flex items-center justify-center w-4 h-4 transform translate-x-3 -translate-y-2 bg-red rounded-full">
          <span className="inline-block font-semibold text-white text-2xs">
            {userAlerts.length > 99 ? '99+' : userAlerts.length}
          </span>
        </div>
      ) : null}
      {showAlertsDropdown && (
        <div
          ref={dialogRef}
          // eslint-disable-next-line max-len
          className="absolute left-0 z-20 w-full m-auto mt-10 text-sm transform bg-white sm:rounded-sm sm:border-l-2 sm:border-r-2 sm:left-auto sm:-translate-x-80 sm:w-96 shadow-top-bottom border-primary-250 max-h-56"
        >
          {/* pointer */}
          <div
            key="pointer"
            className="hidden sm:inline-block absolute -top-px right-13 w-1.5 h-1.5 bg-white"
            style={{
              boxShadow: `3px 3px 0px 0 #c9d1d7`,
              borderColor: 'transparent #c9d1d7 #c9d1d7 transparent',
              transform: 'rotate(-135deg)',
            }}
          />
          {/* content */}
          <div className="overflow-y-auto max-h-56">
            {userAlerts.length ? (
              userAlerts.map(alert => (
                <UserAlertRow key={alert.alertId} alert={alert} />
              ))
            ) : (
              <div className="p-4 text-base text-center">
                You have no unread alerts
              </div>
            )}
            {}
          </div>
        </div>
      )}
    </div>
  );
};
