import { DailyWeather } from '@intelligent-play-v2/domain';
import { format, isToday } from 'date-fns';
import React, { FC } from 'react';
import { DateHeaderProps } from 'react-big-calendar';
import { classnames } from 'tailwindcss-classnames';
import { getWeatherIcon } from '~/utils';

export interface CalendarMonthDateHeaderProps extends DateHeaderProps {
  weatherForDay?: DailyWeather;
}

export const CalendarMonthDateHeader: FC<CalendarMonthDateHeaderProps> = ({
  date,
  weatherForDay,
}) => {
  const isDayToday = isToday(date);

  const dayNumberClasses = classnames(
    'w-7',
    'h-7',
    'text-center',
    'flex',
    'justify-center',
    'items-center',
    {
      'bg-primary-200': isDayToday,
      'rounded-full': isDayToday,
    }
  );
  return (
    <div className="inline-flex items-center justify-between w-full py-px pl-1 pr-2 mb-0.5 text-xl font-medium leading-none">
      <span className={dayNumberClasses}>{format(date, 'd')}</span>
      {weatherForDay && (
        <div className="relative">
          <picture className="relative right-1">
            <img
              className="w-4"
              src={getWeatherIcon(weatherForDay.weatherCode)}
            />
          </picture>
          <div className="absolute text-center -top-1 left-2">
            <span className="text-sm">{weatherForDay.highTempCelsius}°</span>
          </div>
        </div>
      )}
    </div>
  );
};
