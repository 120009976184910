import React, { FC } from 'react';
import { ContactItem } from './contactItem';
import emailIcon from '/assets/images/pages/contact_us/icon__contact-email.png';
import emailIcon2x from '/assets/images/pages/contact_us/icon__contact-email@2x.png';
import phoneIcon from '/assets/images/pages/contact_us/icon__contact-phone.png';
import phoneIcon2x from '/assets/images/pages/contact_us/icon__contact-phone@2x.png';
import addressIcon from '/assets/images/pages/contact_us/icon__contact-address.png';
import addressIcon2x from '/assets/images/pages/contact_us/icon__contact-address@2x.png';

export const ContactDetails: FC = () => {
  return (
    <div className="-mx-5 bg-white shadow-250 sm:rounded sm:mx-0">
      <ContactItem
        icon={emailIcon}
        icon2x={emailIcon2x}
        title="General enquiries"
        information="info@intelligent-play.com"
      />
      <ContactItem
        icon={phoneIcon}
        icon2x={phoneIcon2x}
        title="UK office"
        information="+44 (0)1773 531 444"
      />
      <ContactItem
        icon={phoneIcon}
        icon2x={phoneIcon2x}
        title="North America office"
        information="+1 (941) 960-6610"
      />
      <ContactItem
        icon={addressIcon}
        icon2x={addressIcon2x}
        title="Head office"
        information="Unit 6 New Line Road, Kirkby in Ashfield, Nottinghamshire, NG17 8JQ"
      />
    </div>
  );
};
