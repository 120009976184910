import { ReportPreset } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const updatePreset = async (
  params: ReportPreset
): Promise<ReportPreset> => {
  const { data } = await queryBuilder(
    'PUT',
    `reports/presets/${params.id}`,
    undefined,
    {
      ...params,
    }
  );
  return data;
};
