import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import {
  Facility,
  ReportArea,
  StatsDates,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import { useMetadataStore } from '~/stores';

interface ReportQueryParams {
  presetId?: number;
  pitchIds: number[];
  reportedPitchFacilities: Facility[];
  timePeriod: TimePeriod;
  customDates: StatsDates | null;
  includedDays: Day[];
  dailyStartTime: string;
  dailyEndTime: string;
  isContinuous: boolean;
  selectedReportAreaIds: ReportArea[];
  reportTitle: string;
}

export const useReportQueryParams = (): ReportQueryParams => {
  const { facilities } = useMetadataStore();
  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location.search), [
    location,
  ]);

  const reportTitle = params.get('reportTitle') ?? '';

  const pitchIds = useMemo(
    () =>
      params
        .get('pitchIds')
        ?.split(',')
        .map(id => +id)
        .sort((a, b) => b - a) ?? [],
    [params]
  );

  const reportedPitchFacilities = useMemo(
    () =>
      facilities.reduce((rows: Facility[], facility) => {
        const reportedPitches =
          facility.pitches?.filter(
            pitch => !pitchIds || pitchIds.includes(pitch.id)
          ) ?? [];
        if (reportedPitches.length > 0) {
          rows.push({ ...facility, pitches: reportedPitches });
        }
        return rows;
      }, []),
    [facilities, pitchIds]
  );

  const timePeriod = params.get('timePeriod') as TimePeriod;

  let customDates: StatsDates | null = null;
  if (timePeriod === TimePeriod.Custom) {
    const startDateParam = params.get('startDate');
    const endDateParam = params.get('endDate');
    if (startDateParam && endDateParam) {
      customDates = {
        startDate: new Date(startDateParam),
        endDate: new Date(endDateParam),
      };
    }
  }

  const dailyStartTime = params.get('startTime') || '00:00';
  const dailyEndTime = params.get('endTime') || '00:00';

  let includedDays = params
    .get('days')
    ?.split(',')
    .map(id => +id as Day);

  if (!includedDays) {
    includedDays = [];
  }

  const isContinuous =
    dailyStartTime === '00:00' &&
    dailyEndTime === '00:00' &&
    isEqual(includedDays.sort(), [0, 1, 2, 3, 4, 5, 6]);

  let selectedReportAreaIds: ReportArea[] | undefined = params
    .get('reportAreas')
    ?.split(',')
    .map(id => +id)
    .sort((a, b) => b - a);

  if (!selectedReportAreaIds) {
    selectedReportAreaIds = [];
  }

  let presetId;
  const presetIdString = params.get('presetId');
  if (presetIdString) {
    presetId = parseInt(presetIdString, 10);
  }

  return {
    presetId,
    pitchIds,
    reportedPitchFacilities,
    timePeriod,
    customDates,
    includedDays,
    dailyStartTime,
    dailyEndTime,
    isContinuous,
    selectedReportAreaIds,
    reportTitle,
  };
};
