import React, { CSSProperties, ChangeEvent, FC } from 'react';
import { DailyWeather } from '@intelligent-play-v2/domain';
import { getPreferredTemperature, getWeatherIcon } from '~/utils';

interface ReportGraphToggleProps {
  color: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  weather?: DailyWeather;
}

export const ReportGraphToggle: FC<ReportGraphToggleProps> = ({
  color,
  checked,
  onChange,
  value,
  name,
  weather,
}) => {
  return (
    <div
      style={{
        color: color,
      }}
    >
      <label className="flex items-center justify-center my-0.5 cursor-pointer pr-5">
        <input
          className={'w-5 h-5 cursor-pointer align-middle'}
          style={{ accentColor: color } as CSSProperties}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          value={value}
        />
        <span className="pl-2 text-base font-semibold align-middle">
          {name}
        </span>
      </label>
      {weather && (
        <div className="relative inline-flex justify-around w-full">
          <div className="flex flex-row -space-x-3">
            <img
              className="relative top-0 object-contain w-4 h-4"
              src={getWeatherIcon(weather.weatherCode)}
            />
            <div className="relative w-6 font-semibold text-center text-primary-900">
              {getPreferredTemperature(weather.highTempCelsius)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
