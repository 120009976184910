import { PitchMaintenanceStatus } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface PitchesMaintenanceStatusParams {
  pitchIds: number[];
  timezone: string;
  date?: string;
}

export const getPitchesMaintenanceStatus = async ({
  pitchIds,
  timezone,
  date,
}: PitchesMaintenanceStatusParams): Promise<PitchMaintenanceStatus[]> => {
  const { data } = await queryBuilder('GET', `pitches/maintenanceStatus`, {
    pitchIds: pitchIds.join(','),
    timezone,
    date,
  });
  return data;
};
