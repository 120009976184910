import { Detection, Pitch, SportType } from '@intelligent-play-v2/domain';
import React, {
  FC,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useContainerDimensions } from '~/hooks';
import { LivePitch } from './LivePitch';
import blankPitch from 'assets/images/pages/field/live/blank-pitch.png';
import soccerPitch from 'assets/images/pages/field/live/soccer-pitch.jpg';
import { getLive } from '~/api/queries/pitches/getLive';

interface LiveDetectionsProps {
  pitch: Pitch;
}

export const LiveDetections: FC<LiveDetectionsProps> = ({ pitch }) => {
  const [detections, setDetections] = useState<Detection[]>([]);
  const forceUpdate = useReducer(x => x + 1, 0)[1];
  const componentRef = useRef(null);
  const { width } = useContainerDimensions(componentRef);
  const height = width / 1.6;

  const getPitchImage = useMemo((): HTMLImageElement => {
    const pitchImage = new Image();
    if (pitch.sportTypes && pitch.sportTypes.includes(SportType.Soccer)) {
      pitchImage.src = soccerPitch;
    } else {
      pitchImage.src = blankPitch;
    }
    return pitchImage;
  }, [pitch.sportTypes]);

  useEffect(() => {
    const updateInterval = setInterval(() => forceUpdate(), 20);

    const detectionsInterval = setInterval(() => {
      getLive(pitch.id.toString()).then(result => setDetections(result));
    }, 5000);

    return () => {
      clearInterval(updateInterval);
      clearInterval(detectionsInterval);
    };
  }, [forceUpdate, pitch.id]);

  return (
    <div className="px-5 py-5 bg-white rounded shadow-250">
      <LivePitch
        detections={detections}
        pitchImage={getPitchImage}
        componentRef={componentRef}
        width={width}
        height={height}
      />
    </div>
  );
};
