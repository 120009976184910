/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, RefObject, useCallback, useMemo } from 'react';
import { AreaClosed, Bar, Line, LinePath } from '@visx/shape';
import { Tooltip, defaultStyles, useTooltip } from '@visx/tooltip';
import { scaleLinear, scaleTime } from '@visx/scale';
import { curveMonotoneX } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { localPoint } from '@visx/event';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { bisector, max } from 'd3-array';
import { format, isEqual } from 'date-fns';
import { classnames } from 'tailwindcss-classnames';
import {
  formatDateGbUs,
  formatNumber,
  getPreferredTemperature,
  getWeatherIcon,
} from '~/utils';
import { differenceInDays } from 'date-fns/esm';
import {
  DataSeries,
  DatePoint,
  ToggleableDataSeries,
  ToolTipDatePoint,
} from '~/types';
import { DateWeatherTick } from './DateWeatherTick';
import { getDateBoundries, getHoursValue, getTicks } from './utils';
import { useQuery } from 'react-query';
import { useMetadataStore } from '~/stores';
import {
  HistoricalWeatherParams,
  getHistoricalWeather,
} from '~/api/queries/weather/getHistoricalWeather';
import { getLatLong } from '~/utils/getLatLong';

interface GraphProps {
  dataSeries: DataSeries[] | ToggleableDataSeries[];
  primaryAxisLabel: string;
  secondaryAxisLabel?: string;
  enableTooltip?: boolean;
  tooltipValueLabel?: string;
  width: number;
  height: number;
  componentRef?: RefObject<HTMLDivElement>;
  isSmall?: boolean;
  isUsingTwoAxis?: boolean;
  isReportPage?: boolean;
  facilityId: number;
}

export const Graph: FC<GraphProps> = ({
  dataSeries,
  primaryAxisLabel,
  secondaryAxisLabel,
  enableTooltip,
  tooltipValueLabel,
  width,
  height,
  componentRef,
  isSmall,
  isUsingTwoAxis,
  isReportPage,
  facilityId,
}) => {
  const { facilities } = useMetadataStore();

  const { tooltipData, tooltipTop, showTooltip, hideTooltip } = useTooltip<
    ToolTipDatePoint[]
  >();

  // STYLES
  const lightGrey = '#e0e0e0';
  const green = '#519c42';
  const tooltipTextClass = classnames(
    'text-green',
    'text-xs',
    'font-semibold',
    'whitespace-nowrap'
  );
  const tooltipStyles = {
    ...defaultStyles,
    background: 'white',
    color: 'black',
    border: `${green} solid 1px`,
    transform: 'translateX(-75%)',
    padding: '2px 4px',
    boxShadow: 'none',
    zIndex: 999,
  };

  const margin = {
    top: 20,
    bottom: 100,
    left: isSmall ? 80 : width > 500 ? 120 : 40,
    right: secondaryAxisLabel ? 80 : 50,
  };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // ACCESSORS
  const getDate = (d: DatePoint): Date => new Date(d.date);
  const bisectDate = bisector<DatePoint, Date>(d => new Date(d.date)).left;

  const getMaxSeriesValue = useCallback(() => {
    let absoluteMax = 1;
    dataSeries.forEach(({ data }) => {
      const currentSerieMax = max(data, getHoursValue) || 0;
      const newLocal = currentSerieMax > absoluteMax;
      absoluteMax = newLocal ? currentSerieMax : absoluteMax;
    });
    if (absoluteMax < 10) {
      absoluteMax = Math.ceil(absoluteMax);
    } else if (absoluteMax < 250) {
      absoluteMax = Math.ceil(absoluteMax / 10) * 10;
    } else {
      absoluteMax = Math.ceil(absoluteMax / 100) * 100;
    }
    return absoluteMax === 1 ? 1 : absoluteMax;
  }, [dataSeries]);

  const { startDate, endDate } = useMemo(() => {
    return getDateBoundries(dataSeries);
  }, [dataSeries]);

  const daysInterval = Math.abs(differenceInDays(startDate, endDate));

  // TICK FORMATTING
  const { tickDates, tickLabelDates, gridTickDates } = useMemo(() => {
    return getTicks(startDate, endDate);
  }, [endDate, startDate]);

  const formatXTicksValues = (value: any): string => {
    const tickDate = tickLabelDates.find(date => isEqual(date, value as Date));
    return tickDate ? new Date(value).toISOString() : '';
  };

  const facility = facilities.find(({ id }) => id === facilityId);

  const latLong = facility ? getLatLong(facility) : { x: 0, y: 0 };

  const weatherParams: HistoricalWeatherParams = {
    latLong: `${latLong.x},${latLong.y}`,
    dates: tickLabelDates.map(date => format(date, 'yyyy-MM-dd')).join(','),
  };

  const { data: weather } = useQuery(
    ['weather', weatherParams],
    async () => getHistoricalWeather(weatherParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: tickLabelDates.length > 0,
    }
  );

  // SCALES
  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: [startDate, endDate],
        nice: false,
      }),
    [startDate, endDate, xMax]
  );

  const hoursValueScale = useMemo(() => {
    return scaleLinear({
      range: [yMax, 0],
      domain: [0, getMaxSeriesValue()],
      nice: false,
    });
  }, [getMaxSeriesValue, yMax]);

  const rightAxisValueScale = useMemo(() => {
    if (!dataSeries[1]) {
      return null;
    }
    const secondSeries = dataSeries[1].data;
    return scaleLinear({
      range: [yMax, 0],
      domain: [
        0,
        max(secondSeries, getHoursValue) === 0
          ? 1
          : (max(secondSeries, getHoursValue) || 0) +
            (max(secondSeries, getHoursValue) || 0) / 2,
      ],
      nice: 6,
      round: false,
    });
  }, [dataSeries, yMax]);

  // TOOLTIP
  const handleTooltip = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
  ): void => {
    if (!enableTooltip) {
      return;
    }
    const { x } = localPoint(event) || { x: 0 };
    const x0 = dateScale.invert(x - margin.left);
    const tooltipDataArray = (dataSeries as DataSeries[]).reduce(
      (array: ToolTipDatePoint[], dataSeriesItem) => {
        const index = bisectDate(dataSeriesItem.data, x0, 1);
        const d0 = dataSeriesItem.data[index - 1];
        const d1 = dataSeriesItem.data[index];
        let d = d0;
        if (d1 && getDate(d1)) {
          d =
            x0.valueOf() - getDate(d0).valueOf() >
            getDate(d1).valueOf() - x0.valueOf()
              ? d1
              : d0;
        }
        if (d) {
          array.push({
            ...d,
            lineColour: dataSeriesItem.color,
            showLine: dataSeriesItem.showLine,
          });
        }
        return array;
      },
      []
    );

    let scale = hoursValueScale;
    if (
      isUsingTwoAxis &&
      rightAxisValueScale &&
      dataSeries[0] &&
      !dataSeries[0].showLine
    ) {
      scale = rightAxisValueScale;
    }
    const tooltipSeriesIndex = tooltipDataArray.findIndex(
      dataSeriesItem => dataSeriesItem.showLine
    );
    if (tooltipSeriesIndex === -1) {
      return;
    }

    if (enableTooltip) {
      showTooltip({
        tooltipTop:
          scale(getHoursValue(tooltipDataArray[tooltipSeriesIndex])) +
          margin.top,
        tooltipData: tooltipDataArray,
        tooltipLeft: margin.left,
      });
    }
  };

  const isData = (dataSeries as DataSeries[]).reduce(
    (hasData: boolean, dataSeriesItem: DataSeries) => {
      return hasData || dataSeriesItem.data.length > 0;
    },
    false
  );

  const startDateWeatherKey = format(new Date(startDate), 'yyyy-MM-dd');

  if (!isData) {
    return null;
  }

  return (
    <div ref={componentRef} className="relative">
      <svg width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={hoursValueScale}
            width={xMax}
            numTicks={7}
            stroke={lightGrey}
          />
          <GridColumns
            scale={dateScale}
            width={xMax}
            height={yMax + 20}
            stroke={lightGrey}
            tickValues={gridTickDates}
          />
          <AxisLeft
            scale={hoursValueScale}
            tickClassName={' text-sm font-semibold'}
            numTicks={6}
            hideAxisLine
            hideTicks
            tickLabelProps={() => ({
              textAnchor: 'end',
              verticalAnchor: 'middle',
            })}
          />
          {isUsingTwoAxis && rightAxisValueScale && (
            <AxisRight
              scale={rightAxisValueScale}
              tickClassName={' text-sm font-semibold'}
              numTicks={6}
              left={xMax}
              hideAxisLine
              hideTicks
              tickLabelProps={() => ({
                verticalAnchor: 'middle',
              })}
            />
          )}
          <AxisBottom
            top={yMax}
            scale={dateScale}
            stroke={lightGrey}
            tickClassName={' font-normal text-base'}
            tickStroke={lightGrey}
            tickFormat={formatXTicksValues}
            tickComponent={props => {
              let tickWeather = null;
              if (
                weather &&
                props &&
                props.formattedValue &&
                daysInterval > 0
              ) {
                const tickDate = format(
                  new Date(props.formattedValue),
                  'yyyy-MM-dd'
                );
                if (weather[tickDate]) {
                  tickWeather = weather[tickDate];
                }
              }
              return (
                <DateWeatherTick
                  tickRendererProps={props}
                  daysInterval={daysInterval}
                  weather={tickWeather}
                />
              );
            }}
            tickValues={tickDates}
            tickLength={8}
          />
          <text
            x={xMax / 2}
            y={yMax + 85}
            fontSize={14}
            className="font-semibold"
            textAnchor="middle"
          >
            {daysInterval < 1 ? formatDateGbUs(startDate, 'E d MMM') : 'Date'}
          </text>
          {daysInterval < 1 && weather && weather[startDateWeatherKey] && (
            <>
              <image
                x={xMax / 2}
                y={yMax + 40}
                href={getWeatherIcon(weather[startDateWeatherKey].weatherCode)}
                width={20}
                height={20}
              />
              <text
                transform=""
                x={xMax / 2}
                y={yMax + 64}
                textAnchor="middle"
                className="text-2xl font-semibold"
              >
                {getPreferredTemperature(
                  weather[startDateWeatherKey].highTempCelsius
                ) + '°'}
              </text>
            </>
          )}
          <text
            x={-yMax / 2}
            y={isSmall ? '-45' : '-60'}
            transform="rotate(-90)"
            fontSize={14}
            className="font-semibold"
            textAnchor="middle"
          >
            {primaryAxisLabel}
          </text>
          {secondaryAxisLabel && (
            <text
              x={-yMax / 2}
              y={xMax + 50}
              transform="rotate(-90)"
              fontSize={14}
              className="font-semibold"
              textAnchor="middle"
            >
              {secondaryAxisLabel}
            </text>
          )}
          <LinearGradient
            id="area-gradient"
            from="#E5F0E3"
            to="#E5F0E3"
            toOpacity={0}
          />
          {!isReportPage && (
            <>
              {dataSeries[0] && dataSeries[0].showLine ? (
                <AreaClosed
                  data={dataSeries[0].data}
                  x={d => dateScale(getDate(d)) ?? 0}
                  y={d => hoursValueScale(getHoursValue(d)) ?? 0}
                  yScale={hoursValueScale}
                  strokeWidth={1}
                  fill={'url(#area-gradient)'}
                  curve={curveMonotoneX}
                />
              ) : null}
              {[...dataSeries].reverse().map(({ data, showLine }, index) => {
                return showLine ? (
                  <LinePath
                    key={index}
                    data={data}
                    curve={curveMonotoneX}
                    x={d => dateScale(getDate(d)) ?? 0}
                    y={d =>
                      isUsingTwoAxis && rightAxisValueScale && index === 0
                        ? rightAxisValueScale(getHoursValue(d)) ?? 0
                        : hoursValueScale(getHoursValue(d)) ?? 0
                    }
                    stroke={index === 0 ? '#7C7F8B' : green}
                    strokeWidth={2}
                  />
                ) : null;
              })}
            </>
          )}
          {isReportPage &&
            dataSeries
              .filter(({ showLine }) => showLine)
              .map(({ data, color }, index) => {
                return (
                  <LinePath
                    key={index}
                    data={data}
                    curve={curveMonotoneX}
                    x={d => dateScale(getDate(d)) ?? 0}
                    y={d => hoursValueScale(getHoursValue(d)) ?? 0}
                    stroke={color}
                    strokeWidth={2}
                  />
                );
              })}
          <Bar
            width={xMax}
            height={yMax}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
        </Group>
        {tooltipData && enableTooltip && (
          <>
            <circle
              cx={
                dateScale(getDate(tooltipData[0])) + margin.left ?? margin.left
              }
              cy={tooltipTop ?? margin.top}
              r={3}
              fill="transparent"
              stroke={green}
              strokeWidth={1}
              pointerEvents="none"
            />
            <Line
              from={{
                x:
                  dateScale(getDate(tooltipData[0])) + margin.left ??
                  margin.left,
                y: tooltipTop ?? margin.top,
              }}
              to={{
                x:
                  dateScale(getDate(tooltipData[0])) + margin.left + 30 ??
                  margin.left,
                y: tooltipTop ?? margin.top,
              }}
              stroke={green}
              strokeWidth={1}
              pointerEvents="none"
            />
          </>
        )}
      </svg>
      <div className="absolute inset-0 pointer-events-none">
        {tooltipData && enableTooltip && (
          <Tooltip
            key={Math.random()}
            top={tooltipTop ? tooltipTop - 30 : margin.top}
            left={
              dateScale(getDate(tooltipData[0])) + margin.left + 70 ??
              margin.left
            }
            style={tooltipStyles}
          >
            <div className={tooltipTextClass}>
              {daysInterval < 56
                ? formatDateGbUs(getDate(tooltipData[0]), 'dd/MM/yy h:mm aa')
                : formatDateGbUs(getDate(tooltipData[0]), 'dd/MM/yy')}
            </div>
            {tooltipData.map((tooltipDataItem, index) => {
              const { showLine, lineColour } = tooltipDataItem;
              return (
                showLine &&
                tooltipData.length > 0 && (
                  <div key={index} className="flex items-center">
                    <div
                      className={`w-2.5 h-0.5`}
                      style={{ backgroundColor: lineColour }}
                    />
                    <div className="ml-1 text-base font-semibold whitespace-nowrap">
                      {`${formatNumber(getHoursValue(tooltipDataItem))}
                        ${tooltipValueLabel}`}
                    </div>
                  </div>
                )
              );
            })}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
