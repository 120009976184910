import React, { FC, ReactNode } from 'react';

import { classnames } from 'tailwindcss-classnames';

import checkIcon from 'assets/images/ctas_inputs_modals/icon__modal-check-circle.png';
import checkIcon2x from 'assets/images/ctas_inputs_modals/icon__modal-check-circle@2x.png';
import errorIcon from 'assets/images/ctas_inputs_modals/icon__modal-times-circle.png';
import errorIcon2x from 'assets/images/ctas_inputs_modals/icon__modal-times-circle@2x.png';

interface PromptModalProps {
  title: string;
  type?: 'danger' | 'success' | 'none'; // sets icon
  text?: string;
  primaryButton: ReactNode;
  secondaryButton?: ReactNode;
  thirdButton?: ReactNode;
  showModal: boolean;
}

export const PromptModal: FC<PromptModalProps> = ({
  title,
  type = 'none',
  text,
  primaryButton,
  secondaryButton,
  thirdButton,
  showModal,
}) => {
  const primaryContainerClass = classnames(
    { 'w-24': !secondaryButton },
    { 'ml-auto': !secondaryButton },
    { 'w-9/16': !!secondaryButton },
    'grid'
  );
  let icon = '';
  let icon2x = '';
  if (type === 'danger') {
    icon = errorIcon;
    icon2x = errorIcon2x;
  } else if (type === 'success') {
    icon = checkIcon;
    icon2x = checkIcon2x;
  }
  return showModal ? (
    <>
      <div className="fixed inset-0 z-50 flex overflow-x-hidden text-primary-900">
        <div className="fixed inset-0 bg-primary-900 opacity-25" />
        <div className="relative flex flex-col m-auto w-80">
          <div className="p-5 bg-white rounded shadow-250">
            {/* header*/}
            <div className="flex items-center space-x-2.5">
              {type !== 'none' && (
                <picture>
                  <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
                </picture>
              )}
              <h3 className="text-lg font-semibold">{title}</h3>
            </div>
            {/* body*/}
            {text && (
              <div className="relative flex-auto mt-2.5 text-sm">{text}</div>
            )}
            {/* buttons*/}
            <div className="flex mt-4 ml-auto space-x-2.5">
              {secondaryButton && (
                <div className="grid w-7/16">{secondaryButton}</div>
              )}
              {primaryButton && (
                <div className={primaryContainerClass}>{primaryButton} </div>
              )}
            </div>
            {thirdButton && <div className="mt-3">{thirdButton}</div>}
          </div>
        </div>
      </div>
    </>
  ) : null;
};
