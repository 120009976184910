import React, { FC, useEffect, useState } from 'react';
import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useParams } from 'react-router-dom';
import { classnames } from 'tailwindcss-classnames';
import { format } from 'date-fns';
import mobile from 'is-mobile';

import { DateRangeModal } from '~/components/modals';
import {
  SnapshotParams,
  getSnapshots,
  postTriggerDeviceSnapshot,
  requestTriggerSnapshotAccess,
} from '~/api';
import { Button, ButtonType, ToggleButton } from '~/components/button';
import { useMetadataStore } from '~/stores';
import {
  Snapshot,
  SnapshotReason,
  StatsDates,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import { SnapshotThumbnail } from '~/components/snapshot/SnapshotThumbnail';
import snapshotButtonIcon from 'assets/images/pages/field/snapshots/icon__cta-camera.png';
import snapshotButtonIcon2x from 'assets/images/pages/field/snapshots/icon__cta-camera@2x.png';
import { Loading } from '~/components/loading';
import { usePageTitle, useTimePeriodSelect } from '~/hooks';
import { formatDateGbUs, getFacilityNow } from '~/utils';
import { SnapshotFullSize } from '~/components/snapshot/SnapshotFullSize';
import { useMediaQuery } from 'react-responsive';
import { SingleDateModal } from '~/components/modals/singleDateModal';
import usernameArrowIcon from 'assets/images/navs/icon__username-arrow.png';
import usernameArrowIcon2x from 'assets/images/navs/icon__username-arrow@2x.png';

export const PitchSnapshotsPage: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();
  const queryClient = useQueryClient();
  const { facilities } = useMetadataStore();
  const { facilityId, pitchId } = useParams<{
    facilityId: string;
    pitchId: string;
  }>();

  const selectedFacility = facilities.find(f => f.id === +facilityId);
  const selectedPitch = selectedFacility?.pitches?.find(p => p.id === +pitchId);

  usePageTitle(
    `Snapshots - ${selectedPitch?.name} - ${selectedFacility?.name} - Intelligent Play`
  );

  const [showModal, setShowModal] = useState(false);
  const [focusedSnapshot, setFocusedSnapshot] = useState<Snapshot>();

  const defaultTimePeriod = isMobile ? TimePeriod.Custom : TimePeriod.Today;
  const defaultCustomDates: StatsDates | null = isMobile
    ? { startDate: new Date(), endDate: new Date() }
    : null;

  const {
    selectedTimePeriod,
    setSelectedTimePeriod,
    customDates,
    setCustomDates,
    customDatesText,
  } = useTimePeriodSelect(defaultTimePeriod, defaultCustomDates);

  const snapshotsParams: SnapshotParams = {
    pitchId: +pitchId,
    timePeriod: selectedTimePeriod ?? defaultTimePeriod,
    timezone: selectedFacility?.tzDatabaseTimezone || 'Etc/UTC',
    reasons: [SnapshotReason.scheduled, SnapshotReason.triggered],
  };
  if (selectedTimePeriod === TimePeriod.Custom && customDates) {
    snapshotsParams.from = format(customDates.startDate, 'yyyy-MM-dd');
    snapshotsParams.to = format(customDates.endDate, 'yyyy-MM-dd');
  }

  const { data: snapshots, isLoading: isSnapshotsLoading } = useQuery(
    ['snapshots', snapshotsParams],
    async () => getSnapshots(snapshotsParams)
  );

  const applyCustomDatesHandler = (
    customFrom: Date | null,
    customTo: Date | null
  ): void => {
    if (customFrom && customTo) {
      setSelectedTimePeriod(TimePeriod.Custom);
      setCustomDates({
        startDate: customFrom,
        endDate: customTo,
      });
    }
  };

  const {
    mutate: triggerSnapshot,
    isLoading: isLoadingSnapshotJwt,
  } = useMutation(requestTriggerSnapshotAccess, {
    onSuccess: async triggerAccess => {
      const { jwt, domain } = triggerAccess;
      if (selectedPitch && selectedPitch.devices) {
        await Promise.all(
          selectedPitch.devices.map(({ id }) =>
            triggerDeviceSnapshot({ jwt, domain, deviceId: id })
          )
        );
      }
    },
    onError: () => {
      alert('Failed triggering snapshot');
    },
  });

  const isMutatingSnapshots = useIsMutating(['trigger_snapshot']);

  const {
    mutate: triggerDeviceSnapshot,
    isError: isTriggerDeviceSnapshotError,
  } = useMutation(postTriggerDeviceSnapshot, {
    mutationKey: ['trigger_snapshot'],
    onSuccess: () => {
      queryClient.invalidateQueries('snapshots');
    },
  });

  useEffect(() => {
    if (isTriggerDeviceSnapshotError) {
      queryClient.invalidateQueries('snapshots');
      setTimeout(async () => {
        queryClient.invalidateQueries('snapshots');
      }, 1000 * 45);
      alert(
        'Device snapshot is taking longer than usual, wait a minute and try again'
      );
    }
  }, [isTriggerDeviceSnapshotError, queryClient]);

  if (!selectedFacility || !selectedPitch) {
    // error handled on parent
    return null;
  }

  const isTakingSnapshot = isLoadingSnapshotJwt || isMutatingSnapshots > 0;

  const triggerSnapshotHandler = (): void => {
    if (!isTakingSnapshot) {
      triggerSnapshot();
    }
  };

  let snapshotButtonText = 'Take live snapshot';
  if (isTakingSnapshot) {
    const numberOfDevices =
      isMutatingSnapshots !== 0
        ? isMutatingSnapshots
        : selectedPitch.devices?.length;
    snapshotButtonText = `Taking snapshot ${numberOfDevices}/${selectedPitch.devices?.length} remaining`;
  }

  const triggerSnapshotButtonClass = classnames(
    { 'cursor-not-allowed': isTakingSnapshot },
    'font-normal'
  );

  const facilityTimezone = selectedFacility.tzDatabaseTimezone || 'Etc/UTC';
  const nowFacility = getFacilityNow(facilityTimezone);

  return (
    <div className="bg-white rounded shadow-250 sm:my-4">
      <div className="flex flex-row justify-between items-center px-4 py-1 sm:py-2.5 border-b border-primary-100 bg-white sticky top-[167px] z-20 sm:static">
        {isMobile ? (
          <div
            className="inline-flex items-center gap-2 text-sm font-semibold text-green cursor-pointer"
            onClick={() => setShowModal(true)}
          >
            {customDates
              ? format(customDates.startDate, 'EEE d MMM yyyy')
              : format(new Date(), 'EEE d MMM yyyy')}
            <picture>
              <img
                src={usernameArrowIcon2x}
                srcSet={`${usernameArrowIcon} 1x, ${usernameArrowIcon2x} 2x`}
              />
            </picture>
          </div>
        ) : (
          <div className="flex flex-row space-x-1">
            <ToggleButton
              text={TimePeriod.Today}
              onClick={() => setSelectedTimePeriod(TimePeriod.Today)}
              active={selectedTimePeriod === TimePeriod.Today}
            />
            <ToggleButton
              text={TimePeriod.Yesterday}
              onClick={() => setSelectedTimePeriod(TimePeriod.Yesterday)}
              active={selectedTimePeriod === TimePeriod.Yesterday}
            />
            <ToggleButton
              text={TimePeriod.Last7Days}
              onClick={() => setSelectedTimePeriod(TimePeriod.Last7Days)}
              active={selectedTimePeriod === TimePeriod.Last7Days}
            />
            <ToggleButton
              text={customDatesText}
              onClick={() => {
                setShowModal(!showModal);
              }}
              active={selectedTimePeriod === 'Custom'}
            />
          </div>
        )}
        <div>
          <Button
            size="xsmall"
            text={snapshotButtonText}
            type={ButtonType.Secondary}
            className={triggerSnapshotButtonClass}
            icon={snapshotButtonIcon}
            icon2x={snapshotButtonIcon2x}
            onClick={triggerSnapshotHandler}
          />
        </div>
      </div>
      <div className="z-0">
        {!isSnapshotsLoading && (!snapshots || !snapshots.length) ? (
          <div className="h-16 p-5">{`No snapshots for ${
            isMobile && customDates
              ? formatDateGbUs(customDates?.startDate, 'dd/MM/yyyy')
              : selectedTimePeriod === TimePeriod.Custom
              ? customDatesText
              : selectedTimePeriod
          }`}</div>
        ) : isSnapshotsLoading ? (
          <div className="h-111">
            <Loading />
          </div>
        ) : focusedSnapshot ? (
          <div className="p-3">
            <SnapshotFullSize
              snapshot={focusedSnapshot}
              timezone={selectedFacility.tzDatabaseTimezone || 'Etc/UTC'}
              onClick={() => setFocusedSnapshot(undefined)}
            />
          </div>
        ) : (
          <div className="grid gap-2 p-4 grid-cols-auto-fit">
            {snapshots?.map(snapshot => (
              <SnapshotThumbnail
                key={`${snapshot.timestamp}-${snapshot.deviceId}`}
                snapshot={snapshot}
                timezone={selectedFacility.tzDatabaseTimezone || 'Etc/UTC'}
                onClick={
                  isMobile ? undefined : () => setFocusedSnapshot(snapshot)
                }
              />
            ))}
          </div>
        )}
      </div>
      {isMobile ? (
        <SingleDateModal
          showModal={showModal}
          setShowModal={setShowModal}
          initialDate={customDates ? customDates.startDate : new Date()}
          applyDates={applyCustomDatesHandler}
        />
      ) : (
        <DateRangeModal
          disableAfter={nowFacility}
          showModal={showModal}
          setShowModal={setShowModal}
          permittedDateRange={{ months: 1 }}
          applyDates={applyCustomDatesHandler}
        />
      )}
    </div>
  );
};
