import React, { FC } from 'react';
import { UsageZone } from './usageZone';
import { HeatmapSectorData } from './utils';

export const HeatmapSectorsHalves: FC<HeatmapSectorData> = ({
  averageCount,
  totalEuh,
}) => {
  const max = Math.max(...averageCount[0]);

  return (
    <div className="grid h-full grid-cols-2 gap-1">
      <UsageZone value={averageCount[0][0]} totalEuh={totalEuh} max={max} />
      <UsageZone value={averageCount[0][1]} totalEuh={totalEuh} max={max} />
    </div>
  );
};
