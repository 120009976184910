import { ToggleableDataSeriesCollection } from '~/types';

export const toggleStoreCollectionLine = (
  dailySeriesCollections: Record<string, ToggleableDataSeriesCollection[]>,
  facilityId: number,
  lineKey: string
): Record<string, ToggleableDataSeriesCollection[]> => {
  const dataCollectionCopy = {
    ...dailySeriesCollections,
  };
  dataCollectionCopy[facilityId] = dataCollectionCopy[facilityId].map(
    collection => {
      const index = collection.data.findIndex(
        dataSeries => dataSeries.key === lineKey
      );
      if (index >= 0) {
        collection.data = collection.data.map((item, i) => {
          if (index === i) {
            item.showLine = !item.showLine;
          }
          return item;
        });
      }
      return collection;
    }
  );
  return dataCollectionCopy;
};
