import { queryBuilder } from '~/api';

export const postMarkEventAsComplete = async (
  eventId: number
): Promise<void> => {
  const { data } = await queryBuilder(
    'PUT',
    `calendar/event/${eventId}/complete`
  );
  return data;
};
