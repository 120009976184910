import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { APP_ROOT } from '~/config';

import './index.css';
import { GlobalRouter } from './router/GlobalRouter';
import { Loading } from './components/loading';

const rootElement = document.getElementById(APP_ROOT);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      retry: 0,
    },
  },
});

function ReactApp(): JSX.Element {
  return (
    <Suspense fallback={<Loading isFullScreen />}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <GlobalRouter />
        </BrowserRouter>
      </QueryClientProvider>
    </Suspense>
  );
}

render(<ReactApp />, rootElement);
