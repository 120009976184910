import React, { FC } from 'react';
import { Facility } from '@intelligent-play-v2/domain';

interface FacilitiesSubtitleProps {
  facilities: Facility[];
}

export const FacilitiesSubtitle: FC<FacilitiesSubtitleProps> = ({
  facilities,
}) => {
  const pitchCount = facilities.reduce((count, { pitches }) => {
    return count + (pitches ? pitches.length : 0);
  }, 0);

  return (
    <span className="flex space-x-4 text-sm">
      <span>
        Total facilities:
        <span className="font-semibold text-primary-900">
          {` ${facilities.length}`}
        </span>
      </span>

      <span>
        Total fields:
        <span className="font-semibold text-primary-900">{` ${pitchCount}`}</span>
      </span>
    </span>
  );
};
