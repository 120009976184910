import React, { FC } from 'react';
import returnIcon from 'assets/images/navs/icon__return.png';
import returnIcon2x from 'assets/images/navs/icon__return@2x.png';
import { useHistory, useParams } from 'react-router-dom';
import { RoutesPath } from '~/types';

interface PageHeaderWithTabsAndReturnProps {
  title: string;
  subtitle: string;
}

export const PageHeaderWithTabsAndReturn: FC<PageHeaderWithTabsAndReturnProps> = ({
  title,
  subtitle,
  children,
}) => {
  const history = useHistory();
  const { facilityId } = useParams<{
    facilityId: string;
  }>();

  const navigateToFacility = (): void => {
    history.push(
      RoutesPath.FacilityOverviewFields.replace(':facilityId', facilityId)
    );
  };

  return (
    <div className="sticky z-20 justify-between max-w-full bg-white border-b border-primary-100 sm:static top-15 sm:top-0 sm:z-0">
      <div className="flex px-5 py-4 md:px-15 md:pt-14 md:pb-6">
        <div className="w-10 h-10 bg-green-lightest rounded-full">
          <div
            className="flex justify-center mt-4"
            onClick={() => navigateToFacility()}
          >
            <picture>
              <img
                src={returnIcon}
                srcSet={`${returnIcon} 1x, ${returnIcon2x} 2x`}
              />
            </picture>
          </div>
        </div>
        <div className="ml-4">
          <div className="text-primary-300 mb-0.5 text-sm">{subtitle}</div>
          <div className="font-tw-cent text-[22px] leading-7 font-semibold">
            {title}
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <div className="px-5 md:pl-15">
          <ul className="h-7.5 flex whitespace-nowrap">{children}</ul>
        </div>
      </div>
    </div>
  );
};
