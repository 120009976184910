import React, { FC } from 'react';

interface MinimiseButtonProps {
  text: string;
  onClick: () => void;
}

export const MinimiseButton: FC<MinimiseButtonProps> = ({ text, onClick }) => (
  <button
    onClick={onClick}
    className="w-full overflow-hidden text-sm font-semibold text-white bg-green rounded-bl rounded-br shadow-green-dark h-7"
  >
    {text}
  </button>
);
