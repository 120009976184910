import React, { FC, useState } from 'react';
import DayPicker, { DateUtils, RangeModifier } from 'react-day-picker';
import { Duration, add, formatDuration, isAfter, isBefore } from 'date-fns';

import { Modal } from '~/components/modals';
import { Button } from '~/components/button';
import { DatePickerDay, DatePickerNavbar } from '~/components/datePicker';
import { TextInput } from '~/components/forms';
import { formatDateGbUs } from '~/utils';
import 'react-day-picker/lib/style.css';
import '~/styles/dayPicker.css';

interface DateRangeModalProps {
  disableAfter: Date;
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  dateFrom?: Date;
  dateTo?: Date;
  setDateFrom?: (date?: Date) => void;
  setDateTo?: (date?: Date) => void;
  applyDates?: (dateFrom: Date | null, dateTo: Date | null) => void;
  permittedDateRange?: Duration;
}

enum DateRangeField {
  From = 'From',
  To = 'To',
}

export const DateRangeModal: FC<DateRangeModalProps> = ({
  disableAfter,
  showModal,
  setShowModal,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  permittedDateRange,
  applyDates,
}) => {
  const [from, setFrom] = useState(dateFrom);
  const [to, setTo] = useState(dateTo);

  const [focusedField, setFocusedField] = useState<DateRangeField>();
  const selectedDays: [Date | undefined, RangeModifier] = [from, { from, to }];
  const modifiers = { start: from, end: to, today: undefined };

  const onDayClick = (day: Date): void => {
    if (isAfter(day, disableAfter)) {
      return;
    }
    if (focusedField === DateRangeField.From) {
      if (to && isAfter(day, to)) {
        setTo(day);
      }
      setFrom(day);
      setFocusedField(DateRangeField.To);
    } else if (focusedField === DateRangeField.To) {
      if (from && isBefore(day, from)) {
        setFrom(day);
      }
      setTo(day);
      setFocusedField(undefined);
    } else {
      const range = DateUtils.addDayToRange(day, {
        from: from,
        to: to,
      });
      setFrom(range.from ? range.from : undefined);
      setTo(range.to ? range.to : undefined);
    }
  };

  const renderDay = (day: Date): React.ReactNode => {
    return <DatePickerDay day={day} dateFrom={from} dateTo={to} />;
  };

  const onFocusFieldHandler = (
    field: DateRangeField,
    isFocused: boolean
  ): void => {
    if (isFocused) {
      setFocusedField(field);
    }
  };

  const applyDatesHandler = (): void => {
    setShowModal(false);
    if (from && to) {
      if (setDateFrom && setDateTo) {
        setDateFrom(from);
        setDateTo(to);
      }
    }
    if (applyDates) {
      applyDates(from ?? null, to ?? null);
    }
  };

  const clearSelectionHandler = (): void => {
    setTo(undefined);
    setFrom(undefined);
  };

  const getErrorMessage = (): string | undefined => {
    if (!from || !to) {
      return 'Please select from and to dates';
    } else if (permittedDateRange) {
      const latestPermittedEndDate = add(from, permittedDateRange);
      if (isAfter(to, latestPermittedEndDate)) {
        return `Maximum permitted date range is ${formatDuration(
          permittedDateRange
        )}`;
      }
    }
    return undefined;
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Custom date range"
      estimatedHeight={620}
    >
      <div className="flex space-x-5">
        <TextInput
          isLightMode
          label="From"
          value={from ? formatDateGbUs(from, 'dd/MM/yy') : ''}
          isFocused={'From' === focusedField}
          onChangeFocus={isFocused =>
            onFocusFieldHandler(DateRangeField.From, isFocused)
          }
          placeholder="Select start date"
          disableManualInput
        />
        <TextInput
          isLightMode
          label="To"
          value={to ? formatDateGbUs(to, 'dd/MM/yy') : ''}
          isFocused={'To' === focusedField}
          onChangeFocus={isFocused =>
            onFocusFieldHandler(DateRangeField.To, isFocused)
          }
          placeholder="Select end date"
          disableManualInput
        />
      </div>
      <DayPicker
        className="w-full mt-7"
        selectedDays={selectedDays}
        disabledDays={{ after: disableAfter }}
        onDayClick={onDayClick}
        modifiers={modifiers}
        renderDay={renderDay}
        enableOutsideDaysClick={false}
        showOutsideDays={true}
        navbarElement={props => <DatePickerNavbar {...props} />}
        captionElement={<></>}
        weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
        firstDayOfWeek={1}
      />
      <div className="flex space-x-4">
        <div className="w-1/2 h-12.5">
          {(to || from) && (
            <button
              onClick={clearSelectionHandler}
              className="flex items-center justify-center w-full h-full text-lg font-semibold text-green hover:text-green-dark"
            >
              Clear selection
            </button>
          )}
        </div>
        <div className="w-1/2">
          <Button
            disabled={!!getErrorMessage()}
            text="Apply dates"
            onClick={applyDatesHandler}
          />
          <span className="text-sm text-red">{getErrorMessage()}</span>
        </div>
      </div>
    </Modal>
  );
};
