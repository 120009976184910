import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { MaintenanceGraph } from '~/components/graphs/MaintenanceGraph';
import {
  MaintenanceHeatmap,
  PitchMaintenanceCalendar,
} from '~/components/pitchMaintenance';
import { PitchMaintenanceStatusParams, getPitchMaintenanceStatus } from '~/api';
import { useMetadataStore } from '~/stores';
import { TaskList } from '~/components/taskList';
import { TimePeriod } from '@intelligent-play-v2/domain';
import {
  formatDateGbUs,
  getDueMaintenanceTasks,
  getFacilityNow,
} from '~/utils';
import { subDays } from 'date-fns';
import { usePageTitle } from '~/hooks';
import { FacilityTask } from '~/types';

export const PitchMaintenancePage: FC = () => {
  const { facilities } = useMetadataStore();
  const { facilityId, pitchId } = useParams<{
    facilityId: string;
    pitchId: string;
  }>();

  const selectedFacility = facilities.find(f => f.id === +facilityId);
  const selectedPitch = selectedFacility?.pitches?.find(p => p.id === +pitchId);

  usePageTitle(
    `Maintenance - ${selectedPitch?.name} - ${selectedFacility?.name} - Intelligent Play`
  );

  const facilityTimezone = selectedFacility?.tzDatabaseTimezone ?? 'ETC/UTC';

  const nowFacility = getFacilityNow(facilityTimezone);

  const [selectedDate, setSelectedDate] = useState<Date>(nowFacility);

  const pitchMaintenanceStatusParams: PitchMaintenanceStatusParams = {
    pitchId,
    timePeriod: TimePeriod.Today,
    timezone: facilityTimezone,
  };

  const { data: pitchMaintenanceStatus, isLoading } = useQuery(
    ['maintenanceStatus', pitchMaintenanceStatusParams],
    async () => getPitchMaintenanceStatus(pitchMaintenanceStatusParams)
  );

  const facilityTasks: FacilityTask[] = getDueMaintenanceTasks(
    pitchMaintenanceStatus
  );

  if (!selectedFacility || !selectedPitch) {
    // error handled on parent
    return null;
  }

  const previousDay = subDays(selectedDate, 1);

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-wrap mt-5 space-x-0 space-y-3 sm:space-y-0 sm:space-x-5 sm:flex-nowrap">
        <div className="w-full sm:w-1/2 lg:w-9/16">
          <div className="mx-4 mb-4 sm:mx-0">
            <TaskList
              taskList={facilityTasks}
              isLoading={isLoading}
              hideView={true}
            />
          </div>
          <PitchMaintenanceCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            today={nowFacility}
          />
        </div>
        <div className="flex flex-col w-full space-y-3 bg-white rounded shadow-250 sm:w-1/2 lg:w-7/16">
          <div className="flex flex-row p-3 border-b border-primary-100">
            <span className="font-semibold">Heatmaps</span>
          </div>
          <MaintenanceHeatmap
            pitch={selectedPitch}
            selectedDate={selectedDate}
            timezone={selectedFacility.tzDatabaseTimezone || 'Etc/UTC'}
            title={`Current day: ${formatDateGbUs(selectedDate, 'dd/MM/yyyy')}`}
          />
          <MaintenanceHeatmap
            pitch={selectedPitch}
            selectedDate={previousDay}
            timezone={selectedFacility.tzDatabaseTimezone || 'Etc/UTC'}
            title={`Previous day: ${formatDateGbUs(previousDay, 'dd/MM/yyyy')}`}
          />
        </div>
      </div>
      <div className="hidden sm:block">
        <MaintenanceGraph timezone={facilityTimezone} />
      </div>
    </div>
  );
};
