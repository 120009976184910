import { PitchMachineMaintenanceSessionsResponse } from '@intelligent-play-v2/domain';
import { FilteredStatisticsParams } from '~/types';
import { queryBuilder } from '~/api';

export const getMachineMaintenanceSessions = async ({
  pitchIds,
  timePeriod,
  from,
  to,
  timezone,
  dailyStartTime,
  dailyEndTime,
  includedDays,
}: FilteredStatisticsParams): Promise<
  PitchMachineMaintenanceSessionsResponse[]
> => {
  const { data } = await queryBuilder(
    'GET',
    'stats/machineMaintenanceSessions',
    {
      timePeriod,
      from,
      to,
      pitchIds: pitchIds.join(','),
      timezone,
      dailyStartTime,
      dailyEndTime,
      includedDays: includedDays?.join(','),
    }
  );
  return data;
};
