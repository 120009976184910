import { ContactUsBody } from '@intelligent-play-v2/domain';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { postContactUsRequest } from '~/api';
import { Button, ButtonType } from '~/components/button';
import { TextInput } from '~/components/forms';
import { Select, SelectOptionType } from '~/components/select';
import { ContactFormConfirmation } from './contactFormConfirmation';

export const ContactUsForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: 'onChange' });

  const [isFormCompleted, setIsFormCompleted] = useState(false);

  const { mutate: mutateContactUsReq, isLoading } = useMutation(
    postContactUsRequest,
    {
      onSuccess: () => {
        setIsFormCompleted(true);
        reset();
      },
      onError: () => {
        toast.error('Error sending contact us request');
      },
    }
  );

  const enquiryOptions = [
    { label: 'General enquiry', value: 'General enquiry' },
  ];

  const onSubmitHandler: SubmitHandler<ContactUsBody> = (data): void => {
    data.enquiryType = selectedEnquiryOptions.value;
    mutateContactUsReq(data);
  };

  const [
    selectedEnquiryOptions,
    setSelectedEnquiryOptions,
  ] = useState<SelectOptionType>(enquiryOptions[0]);

  if (isFormCompleted) {
    return (
      <ContactFormConfirmation
        onClickReload={() => setIsFormCompleted(false)}
      />
    );
  }
  return (
    <div className="rounded sm:shadow-250 sm:bg-white">
      <div className="flex-row hidden p-5 border-b border-primary-100 sm:flex">
        <span className="font-semibold">Contact us</span>
      </div>
      <div className="space-y-5 sm:p-5">
        <div className="space-y-5 sm:space-y-0 sm:space-x-5 sm:flex">
          <div className="w-full sm:w-1/2">
            <TextInput
              register={register('firstName', {
                required: 'Please enter a first name',
              })}
              label="First name"
              placeholder="Enter first name"
              required
              type="text"
              isLightMode
              errorMessage={errors.firstName?.message}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <TextInput
              register={register('lastName', {
                required: 'Please enter a last name',
              })}
              label="Last name"
              placeholder="Enter last name"
              required
              type="text"
              isLightMode
              errorMessage={errors.lastName?.message}
            />
          </div>
        </div>
        <div className="space-y-5 sm:space-y-0 sm:space-x-5 sm:flex">
          <div className="w-full sm:w-1/2">
            <TextInput
              register={register('email', {
                required: 'Please enter a valid email address',
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: 'Please enter a valid email address',
                },
              })}
              required
              label="Email address"
              placeholder="Enter email address"
              type="text"
              isLightMode
              errorMessage={errors.email?.message}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <TextInput
              register={register('phoneNumber', {
                required: 'Please enter phone number',
              })}
              label="Phone number"
              placeholder="Enter phone number"
              required
              type="text"
              isLightMode
              errorMessage={errors.phoneNumber?.message}
            />
          </div>
        </div>
        <div className="space-y-5 sm:space-y-0 sm:space-x-5 sm:flex">
          <div className="w-full sm:w-1/2">
            <div className="flex justify-between text-sm font-semibold">
              <p>Enquiry type</p>
            </div>
            <div className="mt-2.5">
              <Select
                options={enquiryOptions}
                selectedValue={selectedEnquiryOptions}
                onChange={option =>
                  setSelectedEnquiryOptions(option ?? enquiryOptions[0])
                }
                shadow
                bordered
                isForm
              />
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <TextInput
              register={register('contactPreferences', {
                required: 'Please enter your preference(s)',
              })}
              label="What days or times should we contact you?"
              placeholder="Please enter your preference(s)"
              required
              type="text"
              isLightMode
              errorMessage={errors.contactPreferences?.message}
            />
          </div>
        </div>
        <div className="w-full">
          <TextInput
            register={register('notes')}
            label="Notes"
            placeholder="(optional)"
            isLightMode
            required={false}
            maxLength={5000}
            isMultiline
            className="h-40"
          />
        </div>
        <div className="flex flex-row-reverse">
          <div className="w-full mb-6 sm:w-64">
            <Button
              text="Send request"
              type={ButtonType.Secondary}
              onClick={handleSubmit(onSubmitHandler)}
              isLoading={isLoading}
              disabled={!isValid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
