import React, { FC } from 'react';
import { EventWrapperProps } from 'react-big-calendar';
import { classnames } from 'tailwindcss-classnames';
import { CalendarEvent, CalendarEventType } from '@intelligent-play-v2/domain';

export const CalendarEventWrapper: FC<EventWrapperProps<CalendarEvent>> = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any
) => {
  const event = props.event as CalendarEvent;

  const classes = classnames(
    'rounded-xs',
    'bg-white',
    'border',
    'mx-2',
    'mb-1',
    {
      'border-blue': event.eventTypeId !== CalendarEventType.MaintenanceTask,
      'shadow-blue-dark':
        event.eventTypeId !== CalendarEventType.MaintenanceTask,
      'shadow-red':
        event.eventTypeId === CalendarEventType.MaintenanceTask &&
        !event.completedAt,
      'border-red':
        event.eventTypeId === CalendarEventType.MaintenanceTask &&
        !event.completedAt,
      'shadow-green':
        event.eventTypeId === CalendarEventType.MaintenanceTask &&
        !!event.completedAt,
      'border-green':
        event.eventTypeId === CalendarEventType.MaintenanceTask &&
        !!event.completedAt,
    }
  );

  return (
    <div className={classes}>
      {React.createElement(props.components.event, {
        event,
      })}
    </div>
  );
};
