import { ReportPreset } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const getReportPresets = async (): Promise<ReportPreset[]> => {
  const { data }: { data: ReportPreset[] } = await queryBuilder(
    'GET',
    'reports/presets'
  );

  return data.map(preset => ({
    ...preset,
    startDate: preset.startDate ? new Date(preset.startDate) : null,
    endDate: preset.endDate ? new Date(preset.endDate) : null,
  }));
};
