import React, { FC } from 'react';
import checkCircle from '/assets/images/ctas_inputs_modals/icon__modal-check-circle.png';
import checkCircle2x from '/assets/images/ctas_inputs_modals/icon__modal-check-circle@2x.png';

interface ContactFormConfirmationProps {
  onClickReload: () => void;
}

export const ContactFormConfirmation: FC<ContactFormConfirmationProps> = ({
  onClickReload,
}) => {
  return (
    <div className="-mx-5 sm:mx-0 bg-white sm:rounded shadow-250 p-7.5">
      <div className="flex items-center">
        <picture>
          <img
            src={checkCircle}
            srcSet={`${checkCircle} 1x, ${checkCircle2x} 2x`}
          />
        </picture>
        <div className="ml-3 text-xl font-semibold">
          Request successfully sent
        </div>
      </div>
      <div className="mt-4 text-base leading-5">
        We endeavour to answer your query within two working days, subject to
        the timings you have requested. A receipt of this has also been sent to
        the provided email address.
      </div>
      <button
        className="mt-5 text-base font-medium leading-5 text-green"
        onClick={onClickReload}
      >
        Reload form
      </button>
    </div>
  );
};
