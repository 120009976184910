import React, { FC } from 'react';

interface AuthTitleProps {
  text: string;
  badge?: string;
  badge2x?: string;
}

export const AuthTitle: FC<AuthTitleProps> = ({ text, badge, badge2x }) => {
  return (
    <div className="flex -mt-2 md:mt-0">
      {badge && (
        <div className="flex-shrink-0">
          <img
            className="pb-6 mr-5 w-9 lg:w-9"
            src={badge2x}
            srcSet={`${badge} 1x, ${badge2x} 2x`}
          />
        </div>
      )}
      <p className="mt-1 font-tw-cent text-5.5xl font-bold md:text-6xl">
        {text}
      </p>
    </div>
  );
};
