import { RefObject, useCallback, useEffect, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

export const useContainerDimensions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  myRef: RefObject<any>
): {
  width: number;
  height: number;
  handleResize: () => void;
} => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getDimensions = useCallback(
    (): Dimensions => ({
      width: (myRef && myRef.current && myRef.current.offsetWidth) || 0,
      height: (myRef && myRef.current && myRef.current.offsetHeight) || 0,
    }),
    [myRef]
  );

  const handleResize = useCallback((): void => {
    setDimensions(getDimensions());
  }, [getDimensions]);

  useEffect(() => {
    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, handleResize, getDimensions]);

  return { ...dimensions, handleResize };
};
