import React, { FC, SyntheticEvent, useState } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { useAuthStore } from '~/stores';
import { ResourceFaq, UserType } from '@intelligent-play-v2/domain';

import arrowDownIcon from 'assets/images/navs/icon__username-arrow.png';
import arrowDownIcon2x from 'assets/images/navs/icon__username-arrow@2x.png';
import arrowUpIcon from 'assets/images/navs/icon__username-arrow-up.png';
import arrowUpIcon2x from 'assets/images/navs/icon__username-arrow-up@2x.png';
import editIcon from 'assets/images/pages/calendar/icon__task-edit.png';
import editIcon2x from 'assets/images/pages/calendar/icon__task-edit@2x.png';
import { ResourceFaqModal } from '~/components/modals/resourceFaqModal';

interface ContactFaqQuestionProps {
  question: ResourceFaq;
}

export const ContactFaqQuestion: FC<ContactFaqQuestionProps> = ({
  question,
}) => {
  const { user } = useAuthStore();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showResourceFaqModal, setShowResourceFaqModal] = useState(false);

  const showEditButton = user?.userTypeId === UserType.SystemAdmin;

  const icon = isOpen ? arrowUpIcon : arrowDownIcon;
  const icon2x = isOpen ? arrowUpIcon2x : arrowDownIcon2x;

  const onClickEditHandler = (event: SyntheticEvent): void => {
    event.stopPropagation();
    setShowResourceFaqModal(true);
  };

  const openedClass = classnames(
    { border: isOpen },
    { 'border-green': isOpen },
    { 'shadow-green': isOpen },
    { 'shadow-250': !isOpen }
  );
  return (
    <>
      <div
        className={`flex flex-col p-4 sm:border rounded shadow-250 cursor-pointer border-primary-250 first:mt-8 bg-white ${openedClass}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-row justify-between">
          <div className="font-semibold">{question.question}</div>
          <div className="flex flex-row flex-shrink-0">
            {showEditButton && (
              <div>
                <div
                  className="z-50 cursor-pointer"
                  onClick={event => onClickEditHandler(event)}
                >
                  <picture>
                    <img
                      src={editIcon}
                      srcSet={`${editIcon} 1x, ${editIcon2x} 2x`}
                    />
                  </picture>
                </div>
              </div>
            )}
            <div className="flex-shrink-0 ml-4">
              <picture>
                <img
                  className="w-3.5 pt-1.5"
                  src={icon}
                  srcSet={`${icon} 1x, ${icon2x} 2x`}
                />
              </picture>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="pt-2 text-base leading-5">{question.answer}</div>
        )}
      </div>
      <ResourceFaqModal
        showModal={showResourceFaqModal}
        setShowModal={setShowResourceFaqModal}
        resourceFaq={question}
      />
    </>
  );
};
