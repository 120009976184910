import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Sidebar, StatusBar } from '~/components/layout';

export const Layout: FC = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  return (
    <div className="flex min-h-screen bg-primary-100 flex-nowrap">
      <div className="relative bg-primary-900 md:w-9/32 lg:w-7/32 2xl:w-5/32">
        <div className="fixed top-0 bottom-0 z-40 overflow-auto md:w-9/32 lg:w-7/32 2xl:w-5/32 md:h-auto">
          <Sidebar
            closeMenu={() => setShowMobileMenu(false)}
            showMobileMenu={showMobileMenu}
          />
        </div>
      </div>
      <div className="w-full md:w-23/32 lg:w-25/32 2xl:w-27/32">
        <StatusBar toggleMenu={() => setShowMobileMenu(!showMobileMenu)} />
        {children}
      </div>
    </div>
  );
};
