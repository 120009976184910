import React, { FC, useState } from 'react';
import { Modal } from '../modals';
import { Select, SelectOptionType } from '../select';

import comparisonIcon from 'assets/images/pages/field/key_stats/icon__comparison-add.png';
import comparisonIcon2x from 'assets/images/pages/field/key_stats/icon__comparison-add@2x.png';
import close from 'assets/images/pages/field/key_stats/icon__comparison-remove.png';
import close2x from 'assets/images/pages/field/key_stats/icon__comparison-remove@2x.png';

import { Button, ButtonType } from '../button';

interface ComparisonFieldProps {
  pitchesOptions: SelectOptionType[];
  onComparison: (pitchId: number | null) => void;
}

export const ComparisonField: FC<ComparisonFieldProps> = ({
  pitchesOptions,
  onComparison,
}) => {
  const [showComparisonFieldModal, setShowComparisonFieldModal] = useState(
    false
  );
  const [
    dropdownSelectedPitch,
    setDropdownSelectedPitch,
  ] = useState<SelectOptionType>(pitchesOptions[0]);

  const [selectedPitch, setSelectedPitch] = useState<SelectOptionType | null>(
    null
  );

  const handleDropdownChange = (option: SelectOptionType | null): void => {
    const newValue = option ? option : pitchesOptions[0];
    setDropdownSelectedPitch(newValue);
  };

  const handleAddComparison = (): void => {
    setSelectedPitch(dropdownSelectedPitch);
    onComparison(+dropdownSelectedPitch.value);
    setShowComparisonFieldModal(false);
  };

  const handleRemoveComparison = (): void => {
    setSelectedPitch(null);
    onComparison(null);
  };

  const showAddComparisonField = pitchesOptions.length > 0 && !selectedPitch;

  return (
    <>
      {showAddComparisonField && (
        <div
          className="hidden mt-2 text-sm font-semibold text-blue cursor-pointer sm:flex"
          onClick={() => setShowComparisonFieldModal(true)}
        >
          <picture className="w-4 mr-2">
            <img
              src={comparisonIcon}
              srcSet={`${comparisonIcon} 1x, ${comparisonIcon2x} 2x`}
            />
          </picture>
          <p>Add comparison field</p>
        </div>
      )}
      {selectedPitch && (
        <div className="flex py-2 pl-2 pr-4 space-x-2 bg-blue shadow-blue-dark rounded-3xl">
          <div
            className="w-4 h-4 text-sm font-semibold leading-3 text-center text-blue rounded-lg cursor-pointer"
            onClick={handleRemoveComparison}
          >
            <picture>
              <img src={close} srcSet={`${close} 1x, ${close2x} 2x`} />
            </picture>
          </div>
          <p className="text-sm font-semibold text-white">
            {selectedPitch.label}
          </p>
        </div>
      )}
      <Modal
        showModal={showComparisonFieldModal}
        setShowModal={setShowComparisonFieldModal}
        title="Select a field for comparison"
      >
        <div className="w-80">
          <Select
            options={pitchesOptions}
            selectedValue={dropdownSelectedPitch}
            onChange={handleDropdownChange}
            isForm
            shadow
          />
          <Button
            className="mt-4"
            text="Add comparison"
            type={ButtonType.Secondary}
            onClick={handleAddComparison}
          />
        </div>
      </Modal>
    </>
  );
};
