import classNames from 'tailwindcss-classnames';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { RoutesPath } from '~/types';
import { useAlertStore } from '~/stores';

interface NavigationListItemProps {
  path: RoutesPath | string;
  label: string;
  icon?: string;
  icon2x?: string;
  isChildItem?: boolean;
  id?: string;
}

export const NavigationListItem: FC<NavigationListItemProps> = ({
  path,
  label,
  isChildItem = false,
  icon,
  icon2x,
  id,
}) => {
  const { userAlerts } = useAlertStore();
  const isDashboardPath = path === RoutesPath.Dashboard;

  const classes = classNames({
    'font-bold': !isChildItem,
    'mb-6': isDashboardPath,
  });

  return (
    <NavLink
      className={`flex items-center justify-between w-full h-10 border-l-4 border-primary-900 ${classes}`}
      activeClassName="border-green bg-primary-700"
      exact={isDashboardPath || path === RoutesPath.Facilities}
      strict
      to={path}
      id={id}
    >
      <span className="flex items-center justify-center w-3/16 h-full">
        {icon && (
          <picture>
            <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
          </picture>
        )}
      </span>
      <span className="w-10/16 text-lg truncate hover:text-primary-300">
        {label}
      </span>
      <span className="flex items-center justify-center w-3/16 h-full m-auto text-center">
        {isDashboardPath && userAlerts.length ? (
          <div className="flex items-center justify-center w-5 h-5 bg-red rounded-full">
            <span className="inline-block font-content text-xs font-semibold text-white">
              {userAlerts.length > 99 ? '99+' : userAlerts.length}
            </span>
          </div>
        ) : (
          ''
        )}
      </span>
    </NavLink>
  );
};
