import React, { FC, useMemo } from 'react';
import { Route, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { addDays, compareAsc } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import {
  CalendarEventType,
  MaintenanceStatus,
  TrackingType,
} from '@intelligent-play-v2/domain';
import {
  CalendarEventParams,
  getCalendarEvents,
  getPitchesMaintenanceStatus,
} from '~/api';
import { usePageTitle } from '~/hooks';
import { FacilityTask, RoutesPath } from '~/types';
import { useMetadataStore } from '~/stores';
import { getDueMaintenanceTasks } from '~/utils';
import { ContentTab, ContentTabs } from '~/components/tabs';
import { FacilityOverviewContentRouter } from '~/router/FacilityOverviewContentRouter';
import { ContentContainer } from '~/components/layout';
import { KeyStatisticsRow } from '~/components/data';
import { TaskList } from '~/components/taskList';
import { FacilityOverview } from '~/components/facilityOverview';

export const FacilityOverviewPage: FC = () => {
  const { facilities } = useMetadataStore();
  const { facilityId } = useParams<{ facilityId: string }>();
  const selectedFacility = facilities.find(f => f.id === +facilityId);
  const pitchIds = selectedFacility?.pitches?.map(pitch => pitch.id) ?? [];
  const timezone = selectedFacility?.tzDatabaseTimezone ?? 'Utc';
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

  usePageTitle(`Overview - ${selectedFacility?.name} - Intelligent Play`);

  const upcomingCalendarEventsParams: CalendarEventParams = {
    from: new Date(),
    to: addDays(new Date(), 7),
    pitchIds: selectedFacility?.pitches?.map(p => p.id) ?? [],
  };
  const { data: upcomingCalendarEvents } = useQuery(
    ['getEvents', facilityId],
    async () => getCalendarEvents(upcomingCalendarEventsParams)
  );

  const { data: facilityMaintenanceStatuses, isLoading } = useQuery(
    ['maintenanceStatus', pitchIds],
    async () => getPitchesMaintenanceStatus({ pitchIds, timezone })
  );

  const tasks: FacilityTask[] = useMemo(() => {
    const trackedTasks: FacilityTask[] = getDueMaintenanceTasks(
      facilityMaintenanceStatuses
    );
    const manualTasks: FacilityTask[] =
      upcomingCalendarEvents
        ?.filter(
          event =>
            (event.eventTypeId === CalendarEventType.FieldTesting ||
              CalendarEventType.MaintenanceTask) &&
            !event.completedAt
        )
        .map((event, index) => {
          return {
            pitchId: event.pitchId,
            title: event.title,
            dueDate: new Date(event.startDate),
            status: MaintenanceStatus.Due,
            trackingType: TrackingType.Manual,
            id: `Manual${index}`,
          };
        }) ?? [];
    return [...trackedTasks, ...manualTasks].sort((a, b) => {
      if (!a.dueDate || !b.dueDate) {
        return !a.dueDate ? 1 : -1;
      }
      const dateA = new Date(a.dueDate);
      const dateB = new Date(b.dueDate);
      return compareAsc(dateA, dateB);
    });
  }, [facilityMaintenanceStatuses, upcomingCalendarEvents]);

  const showContentTabsPaths = [
    RoutesPath.FacilityOverview,
    RoutesPath.FacilityTeam,
    RoutesPath.FacilityAbout,
    isMobile ? '' : RoutesPath.FacilityWeather,
  ];
  const showKeyStatsPaths = showContentTabsPaths.concat(
    RoutesPath.FacilityOverviewKeyStats
  );

  const showTaskListPaths = showContentTabsPaths.concat(
    RoutesPath.FacilityOverviewFeed
  );

  return (
    <ContentContainer className="my-4 sm:my-12">
      <div className="flex flex-wrap space-y-10 lg:space-x-7.5 lg:flex-nowrap lg:space-y-0 xl:space-x-10 2xl:space-x-15">
        <Route exact path={showKeyStatsPaths}>
          <div className="w-full lg:w-2/3">
            <KeyStatisticsRow facility={selectedFacility} />
          </div>
        </Route>
        <Route exact path={showTaskListPaths}>
          <div className="w-full lg:w-1/3">
            <TaskList taskList={tasks} isLoading={isLoading} />
          </div>
        </Route>
        <Route exact path={RoutesPath.FacilityOverviewFields}>
          <div className="w-full">
            <FacilityOverview />
          </div>
        </Route>
      </div>
      <Route exact path={showContentTabsPaths}>
        <ContentTabs>
          <ContentTab
            strict
            exact
            title="Fields"
            path={RoutesPath.FacilityOverview.replace(
              ':facilityId',
              facilityId
            )}
          />
          <ContentTab
            strict
            exact
            title="Team"
            path={RoutesPath.FacilityTeam.replace(':facilityId', facilityId)}
          />
          <ContentTab
            strict
            exact
            title="About"
            path={RoutesPath.FacilityAbout.replace(':facilityId', facilityId)}
          />
          <ContentTab
            strict
            exact
            title="Weather"
            path={RoutesPath.FacilityWeather.replace(':facilityId', facilityId)}
          />
        </ContentTabs>
      </Route>
      <FacilityOverviewContentRouter />
    </ContentContainer>
  );
};
