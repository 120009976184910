import React, { FC } from 'react';

interface FacilityInfoProps {
  text: string;
  title: string;
  icon: string;
  icon2x: string;
}

export const FacilityInfo: FC<FacilityInfoProps> = ({
  text,
  title,
  icon,
  icon2x,
}) => {
  return (
    <div className="flex">
      <div className="flex items-center justify-center bg-primary-100 rounded-sm h-9 w-9">
        <picture>
          <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
        </picture>
      </div>
      <div className="ml-2.5">
        <div className="text-lg font-semibold">{text}</div>
        <div className="mt-1 text-xs font-medium text-primary-400">{title}</div>
      </div>
    </div>
  );
};
