import React, { FC } from 'react';
import scheduledIcon from 'assets/images/pages/field/snapshots/icon__snapshot-automated.png';
import scheduledIcon2x from 'assets/images/pages/field/snapshots/icon__snapshot-automated@2x.png';
import triggeredIcon from 'assets/images/pages/field/snapshots/icon__snapshot-manual.png';
import triggeredIcon2x from 'assets/images/pages/field/snapshots/icon__snapshot-manual@2x.png';
import {
  SnapshotReason,
  Snapshot as SnapshotType,
} from '@intelligent-play-v2/domain';
import { utcToZonedTime } from 'date-fns-tz';
import { classnames } from 'tailwindcss-classnames';
import { SnapshotTypeIcon } from './SnapshotTypeIcon';
import { formatDateGbUs } from '~/utils';
import { Tooltip, TooltipParent } from '../tooltip';

interface SnapshotProps {
  snapshot: SnapshotType;
  timezone: string;
  onClick?: () => void;
}

export const SnapshotThumbnail: FC<SnapshotProps> = ({
  snapshot,
  timezone,
  onClick,
}) => {
  const { reason, imageUrl, timestamp } = snapshot;

  let icon, icon2x;
  switch (reason as SnapshotReason) {
    case SnapshotReason.scheduled:
      icon = scheduledIcon;
      icon2x = scheduledIcon2x;
      break;
    case SnapshotReason.triggered:
      icon = triggeredIcon;
      icon2x = triggeredIcon2x;
      break;
    default:
      icon = scheduledIcon;
      icon2x = scheduledIcon2x;
  }

  const imageContainerColour = classnames(
    { 'bg-blue': reason === SnapshotReason.triggered },
    { 'bg-green-dark': reason !== SnapshotReason.triggered }
  );

  const snapshotStyles = classnames(
    {
      'cursor-pointer': onClick !== undefined,
    },
    'relative'
  );
  return (
    <div onClick={onClick} className={snapshotStyles}>
      <img src={imageUrl} />
      <div className="absolute top-3 left-3">
        <TooltipParent>
          <SnapshotTypeIcon
            icon={icon}
            icon2x={icon2x}
            imageContainerColour={imageContainerColour}
          />
          <Tooltip isBordered pointerLeftPosition={18}>
            <div className="px-2 py-1 capitalize">{reason}</div>
          </Tooltip>
        </TooltipParent>
      </div>
      <div className="absolute px-2 py-1 text-sm font-bold bg-white rounded-sm shadow-250 bottom-3 left-3">
        {formatDateGbUs(utcToZonedTime(timestamp, timezone), 'E d MMM @ k:mm')}
      </div>
    </div>
  );
};
