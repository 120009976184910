import { ResourceFaq } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { ContactFaqQuestion } from './ContactFaqQuestion';

interface ContactFaqQuestionsProps {
  questions: ResourceFaq[];
}

export const ContactFaqQuestions: FC<ContactFaqQuestionsProps> = ({
  questions,
}) => {
  return (
    <div className="flex flex-col space-y-3">
      {questions.map(question => {
        return <ContactFaqQuestion question={question} key={question.id} />;
      })}
    </div>
  );
};
