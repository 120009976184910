import {
  MaintenanceStatus,
  PitchMaintenanceStatus,
  TrackingType,
} from '@intelligent-play-v2/domain';
import { FacilityTask } from '~/types';

export const getDueMaintenanceTasks = (
  tasks: PitchMaintenanceStatus[] | undefined
): FacilityTask[] => {
  return (
    tasks
      ?.filter(
        taskItem =>
          taskItem.status !== MaintenanceStatus.NotDue &&
          taskItem.status !== MaintenanceStatus.Complete &&
          !!taskItem.dueDate
      )
      .map((pitchStatus, index) => {
        return {
          pitchId: pitchStatus.id,
          title: 'Machine brush',
          dueDate: pitchStatus.dueDate!,
          status: pitchStatus.status,
          trackingType: TrackingType.Tracked,
          id: `Tracked${index}`,
        };
      }) ?? []
  );
};
