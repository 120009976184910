import { CalendarEvent, StatsDates } from '@intelligent-play-v2/domain';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  CalendarEventParams,
  getCalendarEvents,
} from '~/api/queries/calendar/getCalendarEvents';
import { Calendar } from '~/components/calendar';
import {
  getCalendarDateRange,
  getSelectedPitchIds,
} from '~/components/calendar/calendar.utils';
import { GenericEventModal } from '~/components/calendar/GenericEventModal';
import { ContentContainer, PageHeader } from '~/components/layout';
import {
  EventDeleteModal,
  EventViewModal,
  MaintenanceEventModal,
} from '~/components/modals';
import { usePageTitle } from '~/hooks';
import { useDeleteEventModal } from '~/hooks/useDeleteEventModalHook';
import { useMetadataStore } from '~/stores';
import { useCalendarStore } from '~/stores/useCalendarStore';

export const CalendarPage: FC = () => {
  usePageTitle('Calendar - Intelligent Play');
  const { facilities } = useMetadataStore();
  const {
    selectedFacility,
    selectedPitch,
    showGenericEventModal,
    setShowGenericEventModal,
  } = useCalendarStore();

  const initialPitchIds = getSelectedPitchIds(
    facilities,
    selectedFacility,
    selectedPitch
  );

  const [showMaintenanceEventModal, setShowMaintenanceEventModal] = useState(
    false
  );
  const [showViewEventModal, setShowViewEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );
  const [eventTypeId, setEventTypeId] = useState<number | null>(null);
  const [selectedPitchId, setSelectedPitchId] = useState<number | null>();

  useEffect(() => {
    if (
      showMaintenanceEventModal === false &&
      showGenericEventModal === false &&
      showViewEventModal === false
    ) {
      setSelectedEvent(null);
      setSelectedPitchId(null);
    }
  }, [showMaintenanceEventModal, showGenericEventModal, showViewEventModal]);

  const initialDateRange = getCalendarDateRange(new Date());

  const [pitchIds, setPitchIds] = useState<number[]>(initialPitchIds);
  const [dateRange, setDateRange] = useState<StatsDates>(initialDateRange);

  useEffect(() => {
    setPitchIds(
      getSelectedPitchIds(facilities, selectedFacility, selectedPitch)
    );
  }, [facilities, selectedFacility, selectedPitch]);

  const calendarEventParams: CalendarEventParams = {
    pitchIds: pitchIds.sort((a, b) => a - b),
    from: dateRange.startDate,
    to: dateRange.endDate,
  };

  const { data: events } = useQuery(
    ['getEvents', calendarEventParams],
    async () => getCalendarEvents(calendarEventParams),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onChangeView = (date: Date): void => {
    setDateRange(getCalendarDateRange(date));
  };

  const editMaintenanceEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setSelectedPitchId(event.pitchId);
    setShowMaintenanceEventModal(true);
  };

  const editGenericEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setSelectedPitchId(event.pitchId);
    setShowGenericEventModal(true);
  };

  const deleteEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setSelectedPitchId(event.pitchId);
    setShowDeleteEventModal(true);
  };

  const viewEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setSelectedPitchId(event.pitchId);
    setShowViewEventModal(true);
  };

  const scheduleAgainHandler = (): void => {
    setEventTypeId(selectedEvent?.eventTypeId ?? null);
    setSelectedEvent(null);
    setShowMaintenanceEventModal(true);
  };

  const onCompleteDeletion = (): void => {
    setSelectedEvent(null);
    setSelectedPitchId(null);
    setShowMaintenanceEventModal(false);
    setShowGenericEventModal(false);
  };

  const {
    isDeleting,
    isDeletingRecurrence,
    onDelete,
    onDeleteRecurrence,
    showDeleteEventModal,
    setShowDeleteEventModal,
  } = useDeleteEventModal(onCompleteDeletion);

  return (
    <>
      <PageHeader title="Calendar" />
      <ContentContainer className="py-12.5">
        <Calendar
          events={events || []}
          showFilters
          onChangeView={onChangeView}
          dateRange={dateRange}
          editMaintenanceEvent={editMaintenanceEventHandler}
          editGenericEvent={editGenericEventHandler}
          deleteEvent={deleteEventHandler}
          viewEvent={viewEventHandler}
        />
        <MaintenanceEventModal
          pitchId={selectedPitchId ?? undefined}
          eventTypeId={eventTypeId ?? undefined}
          showModal={showMaintenanceEventModal}
          setShowModal={setShowMaintenanceEventModal}
          calendarEvent={selectedEvent}
          openDeleteModal={() => setShowDeleteEventModal(true)}
        />
        <EventDeleteModal
          isDeleting={isDeleting}
          isDeletingRecurrence={isDeletingRecurrence}
          showRecurrenceDelete={
            !!selectedEvent && !!selectedEvent.calendarRecurrenceEventId
          }
          showModal={showDeleteEventModal}
          closeDeleteModal={() => setShowDeleteEventModal(false)}
          onDelete={() => onDelete(selectedEvent!.id)}
          onDeleteRecurrence={() =>
            selectedEvent?.calendarRecurrenceEventId &&
            onDeleteRecurrence(selectedEvent.calendarRecurrenceEventId)
          }
        />
        <GenericEventModal
          showModal={showGenericEventModal}
          setShowModal={setShowGenericEventModal}
          calendarEvent={selectedEvent}
          openDeleteModal={() => setShowDeleteEventModal(true)}
        />
        <EventViewModal
          showModal={showViewEventModal}
          setShowModal={setShowViewEventModal}
          openNewTaskModal={() => scheduleAgainHandler()}
          calendarEvent={selectedEvent}
        />
      </ContentContainer>
    </>
  );
};
