import React, { FC, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useContainerDimensions } from '~/hooks';
import { DataSeries, DatePoint } from '~/types';
import { toDatePoint } from '~/utils';
import { Graph, Legend } from '.';
import { getMaintenanceHours } from '~/api';
import { useParams } from 'react-router-dom';
import { Loading } from '../loading';

interface MaintenanceGraphProps {
  timezone: string;
}

export const MaintenanceGraph: FC<MaintenanceGraphProps> = ({ timezone }) => {
  const componentRef = useRef(null);
  const { pitchId, facilityId } = useParams<{
    pitchId: string;
    facilityId: string;
  }>();

  const [playerCount, setPlayerCount] = useState<DatePoint[] | null>(null);
  const [maintenanceHours, setMaintenanceHours] = useState<DatePoint[] | null>(
    null
  );
  const [showPlayerCount, setShowPlayerCount] = useState(true);
  const [showMaintenanceHours, setShowMaintenanceHours] = useState(true);

  const { width, handleResize: handleGraphResize } = useContainerDimensions(
    componentRef
  );

  const { data, isLoading } = useQuery(
    ['maintenanceHours', pitchId],
    async () => getMaintenanceHours(pitchId, timezone)
  );

  useEffect(() => {
    if (data && Object.keys(data.playerCount).length) {
      setPlayerCount(toDatePoint(data.playerCount, timezone));
      setMaintenanceHours(toDatePoint(data.maintenanceHours, timezone));
    }
  }, [data, timezone]);

  useEffect(() => {
    if (playerCount) {
      handleGraphResize();
    }
  }, [playerCount, handleGraphResize]);

  const toggleShowPlayerCount = (): void => {
    setShowPlayerCount(!showPlayerCount);
  };

  const toggleShowMaintenanceHours = (): void => {
    setShowMaintenanceHours(!showMaintenanceHours);
  };

  const dataSeries: DataSeries[] = [
    { data: playerCount || [], showLine: showPlayerCount, color: '#519c42' },
    {
      data: maintenanceHours || [],
      showLine: showMaintenanceHours,
      color: '#7c7f8b',
    },
  ];

  return (
    <div className={'bg-white rounded shadow-250'}>
      <div className="flex flex-row justify-between px-4 py-3 border-b border-primary-100">
        <div className="font-semibold">Maintenance timeline</div>
        <div className="flex items-center">
          <Legend
            text="Hours played"
            color="green"
            onClick={toggleShowPlayerCount}
            isShowing={showPlayerCount}
          />
          <Legend
            text="Hours maintained"
            color="grey"
            onClick={toggleShowMaintenanceHours}
            isShowing={showMaintenanceHours}
          />
        </div>
      </div>
      <div>
        {isLoading || !playerCount ? (
          <div className="h-90">
            {isLoading ? <Loading /> : 'No stats data found'}
          </div>
        ) : (
          <Graph
            dataSeries={dataSeries}
            primaryAxisLabel="Player hours"
            secondaryAxisLabel="Maintenance hours"
            tooltipValueLabel="hours"
            height={360}
            width={width}
            componentRef={componentRef}
            isSmall
            isUsingTwoAxis
            enableTooltip
            facilityId={+facilityId}
          />
        )}
      </div>
    </div>
  );
};
