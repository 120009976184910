import React, { FC } from 'react';
import { MaintenanceStatus } from '@intelligent-play-v2/domain';
import { PitchRow } from './PitchSummaryCard';
import { NavLink, useParams } from 'react-router-dom';
import { RoutesPath } from '~/types';

interface PitchImageLabelProps {
  pitch: PitchRow;
  imageHeight: number;
  imageWidth: number;
  displayHeight: number;
  displayWidth: number;
  onHover: (event: React.MouseEvent<SVGElement>) => void;
}

const maintenanceStatusColours: Record<MaintenanceStatus, string> = {
  [MaintenanceStatus.Overdue]: '#e24259',
  [MaintenanceStatus.Due]: '#c99f3a',
  [MaintenanceStatus.Complete]: '#519c42',
  [MaintenanceStatus.NotDue]: '#519c42',
};

export const PitchImageLabel: FC<PitchImageLabelProps> = ({
  pitch,
  imageHeight,
  imageWidth,
  displayHeight,
  displayWidth,
  onHover,
}) => {
  const { facilityId } = useParams<{ facilityId: string }>();

  const { centerPixelsX, centerPixelsY, facilityPitchNumber } = pitch;
  const x = ((centerPixelsX || 0) / imageWidth) * displayWidth;
  const y = ((centerPixelsY || 0) / imageHeight) * displayHeight;

  const pitchLabelXOffset =
    facilityPitchNumber! === 1 ? -4 : facilityPitchNumber! < 10 ? -5 : -7;

  const maintenanceStatus: MaintenanceStatus = pitch.maintenanceStatus
    ? pitch.maintenanceStatus.status
    : MaintenanceStatus.NotDue;

  const path = RoutesPath.PitchUsage.replace(':facilityId', facilityId).replace(
    ':pitchId',
    pitch.id.toString()
  );
  return (
    <NavLink to={path}>
      <g onMouseEnter={onHover} transform={`translate(${x}, ${y})`}>
        <circle r={13} fill={maintenanceStatusColours[maintenanceStatus]} />
        <text
          stroke="white"
          fill="white"
          dominantBaseline="central"
          cursor="pointer"
          dx={pitchLabelXOffset}
        >
          {facilityPitchNumber}
        </text>
      </g>
    </NavLink>
  );
};
