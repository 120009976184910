import { Pitch } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { useMetadataStore } from '~/stores';
import { useCalendarStore } from '~/stores/useCalendarStore';
import { compareFacilitiesByName } from '~/utils/facilitySort.utils';

import { Select, SelectOptionType } from '../select';

interface CalendarFiltersProps {
  allowAll: boolean;
  defaultFacility?: SelectOptionType;
  defaultPitch?: SelectOptionType;
}

export const CalendarFilters: FC<CalendarFiltersProps> = ({
  allowAll,
  defaultFacility,
  defaultPitch,
}) => {
  const { facilities } = useMetadataStore();
  const {
    allFacilitiesOption,
    selectedFacility,
    setSelectedFacility,
    allPitchesOption,
    selectedPitch,
    setSelectedPitch,
  } = useCalendarStore();

  const facilityOptions: SelectOptionType[] = [
    ...facilities
      .sort((a, b) => compareFacilitiesByName(a, b, true))
      .map(({ name, id }) => ({
        label: name || '',
        value: id.toString(),
      })),
  ];

  let pitches: Pitch[] = [];
  const enablePitchSelect =
    selectedFacility && selectedFacility.value !== 'all';

  if (enablePitchSelect) {
    pitches =
      facilities.find(({ id }) => id === +selectedFacility.value)!.pitches ||
      [];
  }

  const pitchOptions = [
    ...pitches.map(({ name, id }) => ({
      label: name || '',
      value: id.toString(),
    })),
  ];

  if (allowAll) {
    facilityOptions.unshift(allFacilitiesOption);
    pitchOptions.unshift(allPitchesOption);
  }

  return (
    <div className="inline-flex w-full space-x-1">
      <div className="w-1/2">
        <Select
          options={facilityOptions}
          selectedValue={
            defaultFacility || selectedFacility || facilityOptions[0]
          }
          onChange={setSelectedFacility}
          bordered
          bigger
          shadow
        />
      </div>
      <div className="w-1/2">
        <Select
          options={pitchOptions}
          selectedValue={defaultPitch || selectedPitch || pitchOptions[0]}
          onChange={setSelectedPitch}
          bordered
          bigger
          shadow
          disabled={!enablePitchSelect}
        />
      </div>
    </div>
  );
};
