import { RequestUserInviteBody } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const postRequestUserInvite = async (
  params: RequestUserInviteBody
): Promise<void> => {
  const { data } = await queryBuilder(
    'POST',
    'users/requestUserInvite',
    undefined,
    {
      ...params,
    }
  );
  return data;
};
