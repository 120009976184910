import React, { FC } from 'react';
import '~/styles/toggle.css';

interface ToggleProps {
  textBefore?: string;
  textAfter?: string;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
}

export const Toggle: FC<ToggleProps> = ({
  textBefore,
  textAfter,
  checked,
  onChange,
}) => {
  return (
    <div>
      <span className="pr-2 text-sm font-semibold">{textBefore}</span>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
        <span className="slider" />
      </label>
      <span className="pl-2 text-sm font-semibold">{textAfter}</span>
    </div>
  );
};
