import React, { FC } from 'react';

interface ContactInfoProps {
  icon: string;
  icon2x: string;
  text: string;
}

export const ContactInfo: FC<ContactInfoProps> = ({ icon, icon2x, text }) => {
  return (
    <div className="flex items-center">
      <div className="flex items-center justify-center flex-shrink-0 w-4 h-4 bg-primary-100 rounded-xs">
        <picture>
          <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
        </picture>
      </div>
      <div className="text-xs ml-1.5 truncate">{text}</div>
    </div>
  );
};
