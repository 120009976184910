import { Heatmap } from '@intelligent-play-v2/domain';

export enum HeatmapSectorsDivision {
  Halves = 'Halves',
  Thirds = 'Thirds',
  Quarters = 'Quarters',
}

export interface HeatmapSectorData {
  averageCount: number[][];
  totalEuh: number;
}

export const getClosestColor = (value: number, max: number): string => {
  const min = 0;
  const width = max - min;
  const lowerThird = width / 3 + min;
  const upperThird = (width * 2) / 3 + min;

  if (value < lowerThird) {
    return `82,175,39`; // dark green
  } else if (value < upperThird) {
    return '248,190,0'; // orange
  }
  return '237,79,68'; // red
};

export const getAverageData = (
  heatmap: Heatmap,
  division: HeatmapSectorsDivision,
  benchmarkPlayerCount: number
): HeatmapSectorData => {
  let width = 0,
    height = 0;

  switch (division) {
    case HeatmapSectorsDivision.Halves:
      width = 2;
      height = 1;
      break;
    case HeatmapSectorsDivision.Thirds:
      width = 3;
      height = 1;
      break;
    case HeatmapSectorsDivision.Quarters:
      width = 2;
      height = 2;
      break;
    default:
      height = 0;
      width = 0;
  }

  const averageCount = Array.from({ length: height }).map(() =>
    Array.from({ length: width }).map(() => 0)
  );

  if (!heatmap || !heatmap.length) {
    return { averageCount, totalEuh: 0 };
  }

  let totalEuh = 0;
  const heatmapHeight = heatmap.length;
  const heatmapWidth = heatmap[0].length;
  heatmap.forEach((row, y) => {
    row.forEach((value, x) => {
      totalEuh += value;
      const xBin = Math.floor((x / heatmapWidth) * width);
      const yBin = Math.floor((y / heatmapHeight) * height);
      averageCount[yBin][xBin] += value / benchmarkPlayerCount;
    });
  });
  return { averageCount, totalEuh: totalEuh / benchmarkPlayerCount };
};
