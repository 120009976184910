import React, { FC, useEffect, useRef, useState } from 'react';

import { useContainerDimensions } from '~/hooks';
import { PitchRow } from './PitchSummaryCard';
import { PitchImageLabel } from './PitchImageLabel';
import { Loading } from '../loading';

interface FacilityImageProps {
  imageUrl: string;
  isLoading: boolean;
  pitchRows: PitchRow[];
  setFocusedPitch: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const FacilityImage: FC<FacilityImageProps> = ({
  imageUrl,
  isLoading,
  pitchRows,
  setFocusedPitch,
}) => {
  const componentRef = useRef(null);
  const { width: componentWidth, handleResize } = useContainerDimensions(
    componentRef
  );

  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    const imageElement = document.createElement('img');
    imageElement.src = imageUrl || '';
    imageElement.onload = () => {
      setImageWidth(imageElement.width);
      setImageHeight(imageElement.height);
    };
  }, [imageUrl]);

  const width = componentWidth;
  const height = (componentWidth * imageHeight) / imageWidth;

  return (
    <div className="w-full md:w-1/2 lg:w-1/3" ref={componentRef}>
      {isLoading ? (
        <Loading />
      ) : !imageUrl || !width || !height ? (
        'No image'
      ) : (
        <svg
          width={width}
          height={height}
          className="border border-primary-200 rounded shadow-250"
        >
          <image href={imageUrl} width="100%" />

          {pitchRows?.map(pitch => {
            return (
              <PitchImageLabel
                onHover={() =>
                  setFocusedPitch(pitch.facilityPitchNumber || undefined)
                }
                key={pitch.id}
                pitch={pitch}
                imageHeight={imageHeight}
                imageWidth={imageWidth}
                displayWidth={width}
                displayHeight={height}
              />
            );
          })}
        </svg>
      )}
    </div>
  );
};
