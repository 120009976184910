import { ResourceTutorial } from '@intelligent-play-v2/domain';
import { PostTutorialBody, queryBuilder } from '~/api';

export interface PatchTutorialsParams extends PostTutorialBody {
  id: number;
}

export const patchTutorial = async (
  params: PatchTutorialsParams
): Promise<ResourceTutorial> => {
  const { data } = await queryBuilder(
    'PATCH',
    `resources/tutorials/${params.id}`,
    undefined,
    params
  );
  return data;
};
