import React, { FC } from 'react';
import { NavbarElementProps } from 'react-day-picker';
import { format } from 'date-fns';

import nextIcon from 'assets/images/pages/calendar/icon__month-next.png';
import nextIcon2x from 'assets/images/pages/calendar/icon__month-next@2x.png';
import prevIcon from 'assets/images/pages/calendar/icon__month-prev.png';
import prevIcon2x from 'assets/images/pages/calendar/icon__month-prev@2x.png';
import { Loading } from '~/components/loading';
import { classnames } from 'tailwindcss-classnames';

interface DatePickerNavbarProps extends NavbarElementProps {
  legend?: React.ReactNode;
  isLoading?: boolean;
  size?: 'small' | 'default';
}

export const DatePickerNavbar: FC<DatePickerNavbarProps> = ({
  onPreviousClick,
  onNextClick,
  month,
  legend,
  className,
  isLoading,
  size = 'default',
}) => {
  const monthClass = classnames(
    { 'text-xl': size === 'default' },
    { 'text-base': size === 'small' }
  );

  const navClass = classnames(
    { 'ml-4': size === 'small' },
    { 'pb-1': size === 'small' },
    { 'pb-4': size === 'default' }
  );

  const imgSizeClass = classnames({ 'w-5': size === 'small' });

  const nextImgSizeClass = classnames(
    imgSizeClass,
    { 'ml-2': size === 'default' },
    { 'ml-1.5': size === 'small' }
  );

  return (
    <div className={`flex justify-between ${className} ${navClass}`}>
      <div className="flex items-center">
        <button className="flex-shrink-0" onClick={() => onPreviousClick()}>
          <picture>
            <img
              className={imgSizeClass}
              src={prevIcon}
              srcSet={`${prevIcon} 1x, ${prevIcon2x} 2x`}
            />
          </picture>
        </button>
        <button className="flex-shrink-0" onClick={() => onNextClick()}>
          <picture>
            <img
              className={nextImgSizeClass}
              src={nextIcon}
              srcSet={`${nextIcon} 1x, ${nextIcon2x} 2x`}
            />
          </picture>
        </button>
        <span className={`flex-shrink-0 ml-4 font-semibold ${monthClass}`}>
          {format(month, 'MMMM yyyy')}
        </span>
      </div>
      {isLoading ? <Loading /> : legend}
    </div>
  );
};
