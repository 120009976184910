import React, { FC, ReactNode } from 'react';
import { classnames } from 'tailwindcss-classnames';

interface SectionHeaderProps {
  title?: string;
  subtitle?: ReactNode;
  showOnMobile?: boolean;
}

export const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  subtitle,
  showOnMobile,
  children,
}) => {
  const titlesContainerClass = classnames(
    { 'items-center': !title },
    { 'items-baseline': !!title },
    'flex',
    'sm:space-x-4',
    'justify-center'
  );

  const showOnMobileClass = classnames(
    { flex: showOnMobile },
    { hidden: !showOnMobile },
    { 'sm:flex': !showOnMobile }
  );

  const sectionHeaderClass = classnames({ 'sm:items-start': !!title });

  return (
    <div
      className={`flex justify-between pb-2.5 items-center ${sectionHeaderClass}`}
    >
      <div className={titlesContainerClass}>
        {title ? (
          <h5 className={`text-xl font-semibold ${showOnMobileClass}`}>
            {title}
          </h5>
        ) : null}
        <div className="flex text-base text-primary-300 transform">
          {subtitle}
        </div>
      </div>
      <div className="flex justify-end flex-grow sm:justify-start">
        {children}
      </div>
    </div>
  );
};
