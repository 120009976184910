import { DailyWeather } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api/queryBuilder';

export interface WeatherParams {
  latLong: string;
}

export const getWeatherSummary = async ({
  latLong,
}: WeatherParams): Promise<Record<string, DailyWeather>> => {
  const { data } = await queryBuilder('GET', 'weather/summary', {
    latLong,
  });
  return data;
};
