import { User } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { classnames } from 'tailwindcss-classnames';
import { Tooltip, TooltipPosition } from '~/components/tooltip';
import { UserThumbnail, UserThumbnailType } from '~/components/userThumbnail';
import { getUserInitials } from '~/utils/getUserInitials';

interface FacilityUsersTooltipProps {
  users: User[];
}

export const FacilityUsersTooltip: FC<FacilityUsersTooltipProps> = ({
  users,
}) => {
  const tooltipPosition = useMediaQuery({ query: '(max-width: 640px)' })
    ? TooltipPosition.Left
    : TooltipPosition.Center;

  const tooltipPositionClass = classnames(
    { 'left-1/2': tooltipPosition === TooltipPosition.Center },
    { 'left-full': tooltipPosition === TooltipPosition.Left }
  );

  const width = 280;

  const pointerPosition =
    tooltipPosition === TooltipPosition.Center ? 140 : width - 10;
  return (
    <div className={`absolute z-10 ${tooltipPositionClass}`}>
      <Tooltip
        pointerLeftPosition={pointerPosition}
        width={280}
        isHoverable
        tooltipPosition={tooltipPosition}
      >
        <div
          className="p-2.5 grid grid-cols-2 gap-2 overflow-auto max-h-48 border rounded-sm border-primary-250"
          style={{ width: width }}
        >
          {users.map(user => {
            return (
              <div className="flex" key={user.id}>
                <UserThumbnail
                  text={getUserInitials(user.name)}
                  type={UserThumbnailType.UserTooltip}
                  key={user.id}
                  imageSrc={user.imageThumbnailUrl}
                />
                <div className="overflow-hidden ml-1.5 flex flex-col justify-center">
                  <div className="text-sm leading-4 text-primary-900 truncate">
                    {user.name}
                  </div>
                  <div className="text-xs font-semibold text-primary-400 truncate">
                    {user.jobTitle}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Tooltip>
    </div>
  );
};
