import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { User } from '@intelligent-play-v2/domain';
import { Checkbox } from '~/components/checkbox';
import { UserThumbnail, UserThumbnailType } from '~/components/userThumbnail';
import { getUserInitials } from '~/utils/getUserInitials';

interface UserCheckboxProps {
  user: User;
  isChecked: boolean;
  setIsChecked: (newValue: boolean, userId: number) => void;
}

export const UserCheckbox: FC<UserCheckboxProps> = ({
  user,
  isChecked,
  setIsChecked,
}) => {
  const containerClass = classnames(
    { 'border-green': isChecked },
    { 'shadow-green': isChecked },
    { 'border-primary-250': !isChecked },
    { 'shadow-250': !isChecked }
  );
  return (
    <div
      className={`flex items-center px-4 py-3 border rounded shadow-250 border-primary-250 cursor-pointer ${containerClass}`}
      onClick={() => setIsChecked(!isChecked, user.id)}
    >
      <Checkbox isChecked={isChecked} size="large" />
      <div className="ml-4">
        <UserThumbnail
          text={getUserInitials(user.name)}
          type={UserThumbnailType.UserCheckbox}
          imageSrc={user.imageThumbnailUrl}
        />
      </div>
      <div className="ml-2.5 overflow-hidden">
        <div className="text-lg font-semibold text-primary-900">
          {user.name}
        </div>
        <div className="mt-1 text-xs font-medium text-primary-400">
          {user.jobTitle}
        </div>
        <div className="mt-1 text-xs font-medium text-primary-400 truncate">
          {user.email}
        </div>
      </div>
    </div>
  );
};
