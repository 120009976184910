import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SectionHeader } from '~/components/layout';
import { SearchBar } from '~/components/searchBar';
import { Select, SelectOptionType } from '~/components/select';
import { useMetadataStore } from '~/stores';
import { AdminFacilityRow } from '~/types';
import {
  compareFacilitiesByDateAdded,
  compareFacilitiesByName,
} from '~/utils/facilitySort.utils';
import { Table } from '..';
import { adminFacilityColumns } from './adminFacilityColumns';

interface AdminFacilityTableProps {
  showTitle: boolean;
  xlWidth?: 'full' | 'half';
  showTableHeaders?: boolean;
  showCount?: boolean;
  rowsPerPage?: number;
}

export const AdminFacilityTable: FC<AdminFacilityTableProps> = ({
  showTitle,
  xlWidth = 'full',
  showTableHeaders,
  showCount,
  rowsPerPage,
}) => {
  let tableWidth = xlWidth;
  if (useMediaQuery({ query: '(max-width: 1536px)' })) {
    tableWidth = 'full';
  }

  const { facilities } = useMetadataStore();

  const [sortedFacilities, setSortedFacilities] = useState<AdminFacilityRow[]>(
    []
  );

  const [searchText, setSearchText] = useState('');

  const [sortBy, setSortBy] = useState<SelectOptionType>(sortingOptions[0]);

  useEffect(() => {
    const filteredRows = facilities.filter(facility =>
      facility.name?.toUpperCase().includes(searchText.toUpperCase())
    );

    filteredRows.sort((a, b) => {
      if (sortBy.value === 'a-z' || sortBy.value === 'z-a') {
        const isAsc = sortBy.value === 'a-z';
        return compareFacilitiesByName(a, b, isAsc);
      } else {
        return compareFacilitiesByDateAdded(a, b);
      }
    });

    setSortedFacilities(filteredRows);
  }, [facilities, searchText, sortBy]);

  return (
    <div>
      <SectionHeader
        title={showTitle ? 'Facilities' : undefined}
        subtitle={
          showCount ? (
            <span>
              Total facilities:
              <span className="font-semibold text-primary-900">
                {` ${facilities.length}`}
              </span>
            </span>
          ) : null
        }
      >
        <div className="ml-auto w-36">
          <Select
            key="sort"
            options={sortingOptions}
            selectedValue={sortBy}
            onChange={option => setSortBy(option || sortingOptions[0])}
          />
        </div>
      </SectionHeader>
      <SearchBar searchText={searchText} onChange={setSearchText} />
      <Table
        rows={sortedFacilities}
        columns={adminFacilityColumns(tableWidth)}
        showTableHeaders={showTableHeaders}
        rowsPerPage={rowsPerPage}
      />
    </div>
  );
};

const sortingOptions: SelectOptionType[] = [
  {
    label: 'A-Z',
    value: 'a-z',
  },
  {
    label: 'Z-A',
    value: 'z-a',
  },
  {
    label: 'Recently Added',
    value: 'recentlyAdded',
  },
];
