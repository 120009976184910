import React, { FC, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { PatchFacilityBody } from '@intelligent-play-v2/domain';
import { useMetadataStore } from '~/stores';
import {
  PatchFacilityParams,
  PutFacilityPitchStaffParams,
  getUsers,
  patchFacility,
  putFacilityPitchStaff,
} from '~/api';
import { RoutesPath } from '~/types';
import { useCheckedRecordArray } from '~/hooks';

import { Button, ButtonType } from '~/components/button';
import { ContentContainer, PageHeaderWithButtons } from '~/components/layout';
import {
  AdminFacilityPitches,
  AdminUpdateTeam,
  FacilityDetails,
} from '~/components/admin/adminFacility';
import { getPitchesUserPermissionsObj } from '~/utils';

export const AdminFacilityPage: FC = () => {
  const queryClient = useQueryClient();
  const { facilities } = useMetadataStore();

  const { facilityId } = useParams<{ facilityId: string }>();

  const facility = useMemo(() => facilities.find(f => f.id === +facilityId), [
    facilities,
    facilityId,
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset,
  } = useForm({});

  useEffect(() => {
    setValue('name', facility?.name ?? '');
    setValue('address', facility?.address ?? '');
    setValue('postcode', facility?.postcode ?? '');
  }, [facility, setValue]);

  const { mutate: mutateSaveFacility, isLoading } = useMutation(patchFacility, {
    onSuccess: () => {
      toast.success('Successfully saved facility');
      queryClient.invalidateQueries('facilities');
      reset();
    },
    onError: e => {
      alert(e);
    },
  });

  const {
    mutate: mutatePatchFacilityStaff,
    isLoading: isSavingStaff,
  } = useMutation(putFacilityPitchStaff, {
    onSuccess: () => {
      toast.success('Successfully updated staff');
      queryClient.invalidateQueries('facilities');
      queryClient.invalidateQueries('users');
    },
    onError: e => {
      alert(e);
    },
  });

  const onSaveHandler: SubmitHandler<PatchFacilityBody> = data => {
    if (!facility) {
      return;
    }
    const params: PatchFacilityParams = {
      id: facility.id,
      ...data,
    };
    mutateSaveFacility(params);
  };

  const { data: users } = useQuery('users', async () => getUsers());

  const setIsChecked = (newValue: boolean, userId: number): void => {
    const checkedPitchUsers =
      facility?.pitches?.reduce((obj: Record<string, number[]>, pitch) => {
        obj[pitch.id] = [userId];
        return obj;
      }, {}) ?? {};
    toggleCheckedManyUser(checkedPitchUsers, newValue);
  };

  const {
    checkedItems: checkedUsers,
    toggleCheckedMany: toggleCheckedManyUser,
    setCheckedItems: setCheckedItems,
  } = useCheckedRecordArray({});

  useEffect(() => {
    const pitchesUserPermissions = getPitchesUserPermissionsObj(
      facility,
      users ?? []
    );
    setCheckedItems(pitchesUserPermissions);
  }, [facility, users, setCheckedItems]);

  const handleSavePermissions = (): void => {
    const params: PutFacilityPitchStaffParams = {
      id: facility!.id,
      pitchUserAccess: checkedUsers,
    };
    mutatePatchFacilityStaff(params);
  };

  if (!facility) {
    return null;
  }

  return (
    <>
      <PageHeaderWithButtons
        title={facility.name ?? ''}
        backTitle="Facilities"
        backPath={RoutesPath.AdminFacilities}
      >
        <Button
          text="Save changes"
          type={ButtonType.Secondary}
          onClick={handleSubmit(onSaveHandler)}
          disabled={!isDirty}
          isLoading={isLoading}
        />
      </PageHeaderWithButtons>
      <ContentContainer className="my-10">
        <FacilityDetails
          facility={facility}
          register={register}
          errors={errors}
        />
        {users && (
          <AdminUpdateTeam
            users={users}
            checkedUsers={checkedUsers}
            setIsChecked={setIsChecked}
            handleSave={handleSavePermissions}
            isSaving={isSavingStaff}
          />
        )}
        <AdminFacilityPitches
          pitches={facility.pitches ?? []}
          facilityId={facility.id}
        />
      </ContentContainer>
    </>
  );
};
