import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { PatchUserParams, patchUser } from '~/api';
import { Button, ButtonType } from '~/components/button';
import {
  ContentContainer,
  HeaderTab,
  PageHeaderWithButtons,
  PageHeaderWithTabs,
} from '~/components/layout';
import { UserDetails, UserFacilitiesList } from '~/components/userDetails';
import { useAuthStore } from '~/stores';
import { classnames } from 'tailwindcss-classnames';

// eslint-disable-next-line no-shadow
enum UserProfileMobileTabs {
  Details = 'Details',
  Facilities = 'Facilities',
}

export const UserProfilePage: FC = () => {
  const history = useHistory();
  const { user, fetchUser } = useAuthStore();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const userInputImageRef = useRef<HTMLInputElement | null>(null);
  const [imageFile, setImageFile] = useState<File>();
  const [imagePreview, setImagePreview] = useState('');

  const [selectedMobileTab, setSelectedMobileTab] = useState(
    UserProfileMobileTabs.Details
  );

  const { mutate: mutatePatchUser, isLoading: isSaving } = useMutation(
    patchUser,
    {
      onSuccess: () => {
        toast.success('Successfully saved user');
        fetchUser();
      },
      onError: () => {
        toast.error('An error occured when saving user');
      },
    }
  );

  useEffect(() => {
    if (imageFile) {
      if (imageFile.size > 5000000) {
        toast.error('Maximum image size exceeded - 5mb');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImagePreview('');
    }
  }, [imageFile]);

  if (!user) {
    return null;
  }

  const onSubmit = (data: PatchUserParams): void => {
    const patchUserParams: PatchUserParams = {
      userId: user.id,
    };
    if (data.phoneNumber !== user.phoneNumber) {
      patchUserParams.phoneNumber = data.phoneNumber;
    }
    if (data.jobTitle !== user.jobTitle) {
      patchUserParams.jobTitle = data.jobTitle;
    }
    if (imagePreview) {
      patchUserParams.image = imagePreview.split(',')[1];
    }
    mutatePatchUser(patchUserParams);
  };

  const detailsClass = classnames(
    {
      hidden: selectedMobileTab !== UserProfileMobileTabs.Details,
    },
    'sm:block'
  );

  const facilitiesClass = classnames(
    {
      hidden: selectedMobileTab !== UserProfileMobileTabs.Facilities,
    },
    'sm:block'
  );

  const fixedSaveButtonClass = classnames(
    {
      hidden: selectedMobileTab !== UserProfileMobileTabs.Details,
    },
    'sm:block'
  );

  const saveButton = (
    <Button
      type={ButtonType.Secondary}
      text="Save changes"
      className="pb-0.75"
      onClick={handleSubmit(onSubmit)}
      disabled={isSaving}
      isLoading={isSaving}
    />
  );
  return (
    <>
      <div className="hidden sm:block">
        <PageHeaderWithButtons title="My profile">
          <div className="flex space-x-5">
            <Button
              type={ButtonType.Outline}
              text="Return"
              className="pb-0.75 px-4"
              onClick={history.goBack}
            />
            {saveButton}
          </div>
        </PageHeaderWithButtons>
      </div>
      <div className="sm:hidden">
        <PageHeaderWithTabs title="My profile">
          <HeaderTab
            title={UserProfileMobileTabs.Details}
            isSelected={selectedMobileTab === UserProfileMobileTabs.Details}
            selectMobileTab={() =>
              setSelectedMobileTab(UserProfileMobileTabs.Details)
            }
          />
          <HeaderTab
            title={UserProfileMobileTabs.Facilities}
            isSelected={selectedMobileTab === UserProfileMobileTabs.Facilities}
            selectMobileTab={() =>
              setSelectedMobileTab(UserProfileMobileTabs.Facilities)
            }
          />
        </PageHeaderWithTabs>
      </div>
      <ContentContainer className="sm:py-12.5 py-6">
        <div className="sm:space-y-8 lg:space-y-0 lg:flex lg:space-x-15">
          <div className={`w-full mb-20 sm:mb-0 lg:w-2/3 ${detailsClass}`}>
            <UserDetails
              user={user}
              register={register}
              setValue={setValue}
              errors={errors}
              userInputImageRef={userInputImageRef}
              setImageFile={setImageFile}
              imagePreview={imagePreview}
            />
          </div>
          <div className={`w-full lg:w-1/3 ${facilitiesClass}`}>
            <UserFacilitiesList user={user} />
          </div>
        </div>
        <div
          className={`fixed bottom-5 right-5 left-5 sm:hidden ${fixedSaveButtonClass}`}
        >
          {saveButton}
        </div>
      </ContentContainer>
    </>
  );
};
