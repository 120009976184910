/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { Facility } from '@intelligent-play-v2/domain';
import { Table, TableColumn } from '~/components/table';
import { PitchStatisticRow } from '~/types/PitchStatisticRow';
import { useStatisticTableData } from './useStatisticTableData.hook';
import { getTableReportAreaColumn } from './utils';

interface StatisticTablesProps {
  facility: Facility;
  isPrintable?: boolean;
}

export const StatisticTables: FC<StatisticTablesProps> = ({
  facility,
  isPrintable,
}) => {
  const { reportAreaTables, pitchStatisticRows } = useStatisticTableData(
    facility
  );

  const columnTables = reportAreaTables.map((table, index) => {
    const pitchNameColumn: TableColumn = {
      heading: 'Pitch name',
      widthClass: 'w-4/12',
      value: (row: PitchStatisticRow) => (
        <div className="flex items-center">
          <div className="font-semibold">{row.name}</div>
        </div>
      ),
    };
    const statisticColumns = table.map(columnTypes =>
      getTableReportAreaColumn(columnTypes)
    );
    return {
      columns: [pitchNameColumn, ...statisticColumns],
      tableNumber: index,
    };
  });

  return (
    <div>
      {columnTables.map(columnTable => {
        return (
          <Table
            key={columnTable.tableNumber}
            rows={pitchStatisticRows}
            columns={columnTable.columns}
            isPrintable={isPrintable}
            showTableHeaders
          />
        );
      })}
    </div>
  );
};
