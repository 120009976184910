import { Facility, User } from '@intelligent-play-v2/domain';

export const getPitchesUserPermissionsObj = (
  facility: Facility | undefined,
  users: User[]
): Record<string, number[]> => {
  const checkedArrays =
    facility?.pitches?.reduce((obj: Record<string, number[]>, pitch) => {
      const userIds = users
        ?.filter(user => user.pitchAccess.find(pitchId => pitchId === pitch.id))
        .map(user => user.id);
      obj[pitch.id] = userIds;
      return obj;
    }, {}) ?? {};
  return checkedArrays;
};
