import { CalendarEvent } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';

import dateIcon from 'assets/images/pages/field/field_testing/icon__field-test.png';
import dateIcon2x from 'assets/images/pages/field/field_testing/icon__field-test@2x.png';
import attachmentIcon from 'assets/images/pages/field/field_testing/icon__attachments.png';
import attachmentIcon2x from 'assets/images/pages/field/field_testing/icon__attachments@2x.png';
import editIcon from '/assets/images/pages/calendar/icon__task-edit.png';
import editIcon2x from '/assets/images/pages/calendar/icon__task-edit@2x.png';
import deleteIcon from '/assets/images/pages/calendar/icon__task-delete.png';
import deleteIcon2x from '/assets/images/pages/calendar/icon__task-delete@2x.png';
import { format } from 'date-fns';
import { classnames } from 'tailwindcss-classnames';

interface FieldTestingRowProps {
  calendarEvent: CalendarEvent;
  onClickView: (id: number) => void;
  onClickEdit: (id: number) => void;
  onClickDelete: (id: number) => void;
  showActions?: boolean;
}

export const FieldTestingRow: FC<FieldTestingRowProps> = ({
  calendarEvent,
  showActions,
  onClickView,
  onClickEdit,
  onClickDelete,
}) => {
  const attachmentColor = classnames({
    'text-primary-400': !calendarEvent.attachments.length,
  });

  return (
    <>
      <div className="h-16.5 flex items-center bg-white rounded shadow-250 p-4 mb-2">
        <div className="w-12/16 md:flex md:w-11/16">
          {/* DATE */}
          <div className="w-full md:w-9/16">
            <div className="flex items-center space-x-2">
              <div className="bg-green-lightest rounded w-7.5 h-7.5 flex items-center justify-center flex-shrink-0">
                <picture>
                  <img
                    src={dateIcon2x}
                    srcSet={`${dateIcon} 1x, ${dateIcon2x} 2x`}
                  />
                </picture>
              </div>
              <span className="font-semibold overflow-ellipsis">
                {format(new Date(calendarEvent.startDate), 'EEE d MMM yyyy')}
              </span>
            </div>
          </div>

          {/* TYPE */}
          <div className="w-full pl-[38px] font-semibold md:w-7/16 md:pl-0">
            <span className="font-semibold align-middle">
              {calendarEvent.title}
            </span>
          </div>
        </div>

        {/* ATTACHMENTS */}
        <div className="w-2/16 md:w-3/16">
          <div className="flex items-center space-x-2">
            <picture className="flex-shrink-0">
              <img
                className={
                  !calendarEvent.attachments.length ? 'opacity-40' : ''
                }
                src={attachmentIcon2x}
                srcSet={`${attachmentIcon} 1x, ${attachmentIcon2x} 2x`}
              />
            </picture>
            <span className={`hidden md:block truncate ${attachmentColor}`}>
              {calendarEvent.attachments.length === 1
                ? calendarEvent.attachments.length + ' attachment'
                : calendarEvent.attachments.length + ' attachments'}
            </span>
            <span className={`md:hidden ${attachmentColor}`}>
              {calendarEvent.attachments.length}
            </span>
          </div>
        </div>

        {/* BUTTONS */}
        <div className="w-2/16 md:w-2/16">
          {showActions ? (
            <div className="flex justify-end space-x-2">
              <div
                className="flex-shrink-0 cursor-pointer"
                onClick={() => onClickEdit(calendarEvent.id)}
              >
                <picture>
                  <img
                    src={editIcon2x}
                    srcSet={`${editIcon} 1x, ${editIcon2x} 2x`}
                  />
                </picture>
              </div>
              <div
                className="flex-shrink-0 cursor-pointer"
                onClick={() => onClickDelete(calendarEvent.id)}
              >
                <picture>
                  <img
                    src={deleteIcon2x}
                    srcSet={`${deleteIcon} 1x, ${deleteIcon2x} 2x`}
                  />
                </picture>
              </div>
            </div>
          ) : (
            <div
              className="flex justify-end "
              onClick={() => onClickView(calendarEvent.id)}
            >
              <span className="font-semibold text-green cursor-pointer">
                View
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
