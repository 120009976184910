import React, { FC } from 'react';
import { HeaderTab, PageHeaderWithTabs } from '~/components/layout';
import { RoutesPath } from '~/types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { usePageTitle } from '~/hooks';
import { ResourcesTutorialsPage } from './ResourcesTutorials.page';

export const ResourcesPage: FC = () => {
  usePageTitle('Resources - Intelligent Play');
  return (
    <>
      <PageHeaderWithTabs title="Resources">
        <HeaderTab title="Tutorials" path={RoutesPath.ResourcesTutorials} />
        {/* <HeaderTab
          title="Webinars"
          path={RoutesPath.ResourcesWebinars}
          isDisabled
        />
        <HeaderTab
          title="Suppliers"
          path={RoutesPath.ResourcesSuppliers}
          isDisabled
        />
        <HeaderTab
          title="Field Management"
          path={RoutesPath.ResourcesFieldManagement}
          isDisabled
        /> */}
      </PageHeaderWithTabs>
      <Switch>
        <Route strict exact path={RoutesPath.Resources}>
          <Redirect to={RoutesPath.ResourcesTutorials} />
        </Route>
        <Route strict path={RoutesPath.ResourcesTutorials}>
          <ResourcesTutorialsPage />
        </Route>
        <Route strict path={RoutesPath.ResourcesWebinars}>
          WEBINARS
        </Route>
        <Route strict path={RoutesPath.ResourcesSuppliers}>
          SUPPLIERS
        </Route>
        <Route strict path={RoutesPath.ResourcesFieldManagement}>
          FIELD MANAGEMENT
        </Route>
      </Switch>
    </>
  );
};
