/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

import { Button, ButtonType } from '~/components/button';
import { TableColumn } from '../Table';
import { FacilityName } from './FacilityName';
import { FacilityStats } from './FacilityStats';
import { FacilityRow, RoutesPath } from '~/types';
import { FacilityUsers } from './FacilityUsers';

export const facilityColumns = (width: '2/3' | 'full'): TableColumn[] => {
  return [
    {
      heading: 'Facility name',
      widthClass: 'w-7/16 sm:w-5/16 lg:w-5/16 xl:5/16 2xl:w-4/16',
      value: (facilityRow: FacilityRow) => (
        <FacilityName facility={facilityRow.facility} />
      ),
    },
    {
      heading: 'Last 7 days stats (vs previous 7 days)',
      widthClass: 'w-6/16 sm:w-5/16 lg:w-7/16 xl:w-7/16 2xl:w-8/16 ',
      value: (facilityRow: FacilityRow) => (
        <FacilityStats
          facility={facilityRow.facility}
          data={facilityRow.facilityAggregate}
          width={width}
        />
      ),
    },
    {
      heading: 'Dashboard Users',
      widthClass: 'hidden sm:block sm:w-3/16 lg:w-2/16: ml-auto',
      value: (facilityRow: FacilityRow) => (
        <FacilityUsers users={facilityRow.facility.staff ?? []} />
      ),
    },
    {
      heading: '',
      widthClass: 'w-3/16 lg:w-2/16 ml-auto',
      value: (facilityRow: FacilityRow, index?: number) => (
        <Link
          to={{
            pathname: RoutesPath.FacilityOverview.replace(
              ':facilityId',
              String(facilityRow.facility.id)
            ),
          }}
          id={index === 0 ? 'first-view' : undefined}
        >
          <Button text="View" type={ButtonType.Secondary} size="small" />
        </Link>
      ),
    },
  ];
};
