import React, { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  PitchFieldTestingEventsParams,
  getPitchFieldTestingEvents,
} from '~/api/queries';
import { useAuthStore } from '~/stores';

import { useParams } from 'react-router-dom';
import { CalendarEvent, CalendarEventType } from '@intelligent-play-v2/domain';
import { Button, ButtonType } from '~/components/button';
import {
  EventDeleteModal,
  EventViewModal,
  MaintenanceEventModal,
} from '~/components/modals';

import addIcon from 'assets/images/pages/field/field_testing/icon__add-button.png';
import addIcon2x from 'assets/images/pages/field/field_testing/icon__add-button@2x.png';
import { useDeleteEventModal } from '~/hooks/useDeleteEventModalHook';
import { userHasCalendarWriteAccess } from '@intelligent-play-v2/lib';
import { FieldTestingRow } from '~/components/fieldTesting';

export const FieldTesting: FC = () => {
  const { user } = useAuthStore();
  const queryClient = useQueryClient();

  const onCompleteDeletion = (): void => {
    queryClient.invalidateQueries('getPitchFieldTestingEvents');
    setShowDeleteModal(false);
    setShowEventModal(false);
    setSelectedEvent(null);
  };

  const {
    isDeleting,
    isDeletingRecurrence,
    onDelete,
    onDeleteRecurrence,
    showDeleteEventModal: showDeleteModal,
    setShowDeleteEventModal: setShowDeleteModal,
  } = useDeleteEventModal(onCompleteDeletion);

  const [showViewModal, setShowViewModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );

  const [isShowingMorePrevious, setIsShowingMorePrevious] = useState(false);
  const [isShowingMoreUpcoming, setIsShowingMoreUpcoming] = useState(false);

  const { pitchId } = useParams<{
    pitchId: string;
  }>();

  const addHandler = (): void => {
    setSelectedEvent(null);
    setShowEventModal(true);
  };
  const viewHandler = (eventId: number): void => {
    setSelectedEvent(getEvent(eventId));
    setShowViewModal(true);
  };
  const editHandler = (eventId: number): void => {
    setSelectedEvent(getEvent(eventId));
    setShowEventModal(true);
  };
  const deleteHandler = (eventId: number): void => {
    setSelectedEvent(getEvent(eventId));
    setShowDeleteModal(true);
  };

  const getEvent = (eventId: number): CalendarEvent | null => {
    const allEvents = data?.previous.concat(data?.upcoming) || null;
    return allEvents
      ? allEvents.find(event => event.id === eventId) || null
      : null;
  };

  const pitchFieldTestingEventsParams: PitchFieldTestingEventsParams = {
    pitchId: +pitchId,
  };
  const { data } = useQuery(
    ['getPitchFieldTestingEvents', pitchFieldTestingEventsParams],
    async () => getPitchFieldTestingEvents(pitchFieldTestingEventsParams)
  );

  return (
    <>
      <div className="flex flex-col mx-4 mt-8 space-x-0 md:mx-0 lg:flex-row lg:space-x-4 xl:space-x-16">
        <div className="w-full pb-8 lg:pb-0">
          <div className="flex flex-col justify-center h-10 pb-4">
            <div className="text-xl font-semibold">Previous Tests</div>
          </div>
          {data?.previous.length ? (
            <div>
              {(isShowingMorePrevious
                ? data.previous
                : data.previous.slice(0, 5)
              ).map(event => (
                <FieldTestingRow
                  key={event.id}
                  calendarEvent={event}
                  onClickView={() => viewHandler(event.id)}
                  onClickEdit={() => editHandler(event.id)}
                  onClickDelete={() => deleteHandler(event.id)}
                />
              ))}
              {data.previous.length > 5 && (
                <span
                  className="font-semibold cursor-pointer"
                  onClick={() =>
                    setIsShowingMorePrevious(!isShowingMorePrevious)
                  }
                >
                  {isShowingMorePrevious ? 'Show less' : 'Show more'}
                </span>
              )}
            </div>
          ) : (
            <div className="h-16.5 flex items-center bg-white rounded shadow-250 p-4">
              No previous tests
            </div>
          )}
        </div>
        <div className="w-full">
          <div className="flex items-center justify-between h-10 pb-4">
            <div className="text-xl font-semibold">Upcoming Tests</div>
            {user && userHasCalendarWriteAccess(user) && (
              <div>
                <Button
                  text="Add test"
                  size="small"
                  icon={addIcon}
                  icon2x={addIcon2x}
                  type={ButtonType.Secondary}
                  onClick={() => addHandler()}
                />
              </div>
            )}
          </div>
          {data?.upcoming.length ? (
            <div>
              {(isShowingMoreUpcoming
                ? data.upcoming
                : data.upcoming.slice(0, 5)
              ).map(event => (
                <FieldTestingRow
                  key={event.id}
                  calendarEvent={event}
                  onClickView={() => viewHandler(event.id)}
                  onClickEdit={() => editHandler(event.id)}
                  onClickDelete={() => deleteHandler(event.id)}
                  showActions
                />
              ))}
              {data.upcoming.length > 5 && (
                <span
                  className="font-semibold cursor-pointer"
                  onClick={() =>
                    setIsShowingMoreUpcoming(!isShowingMoreUpcoming)
                  }
                >
                  {isShowingMoreUpcoming ? 'Show less' : 'Show more'}
                </span>
              )}
            </div>
          ) : (
            <div className="h-16.5 flex items-center bg-white rounded shadow-250 p-4">
              No upcoming tests
            </div>
          )}
        </div>
      </div>
      <EventDeleteModal
        isDeleting={isDeleting}
        isDeletingRecurrence={isDeletingRecurrence}
        showRecurrenceDelete={
          !!selectedEvent && !!selectedEvent.calendarRecurrenceEventId
        }
        showModal={showDeleteModal}
        closeDeleteModal={() => setShowDeleteModal(false)}
        onDelete={() => onDelete(selectedEvent!.id)}
        onDeleteRecurrence={() =>
          selectedEvent?.calendarRecurrenceEventId &&
          onDeleteRecurrence(selectedEvent.calendarRecurrenceEventId)
        }
      />
      <MaintenanceEventModal
        showModal={showEventModal}
        setShowModal={setShowEventModal}
        pitchId={+pitchId}
        eventTypeId={CalendarEventType.FieldTesting}
        openDeleteModal={() => setShowDeleteModal(true)}
        calendarEvent={selectedEvent}
      />
      <EventViewModal
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        openNewTaskModal={() => addHandler()}
        calendarEvent={selectedEvent}
      />
    </>
  );
};
