import { FaqCategory } from '@intelligent-play-v2/domain';
import startCase from 'lodash.startcase';
import React, { FC } from 'react';

import arrowLeftIcon from 'assets/images/navs/icon__return1-white.png';
import arrowLeftIcon2x from 'assets/images/navs/icon__return1-white@2x.png';
import { getWhiteCategoryIcons } from '~/utils/contactFaq.utils';

interface ContactFaqMobileHeaderProps {
  category: FaqCategory;
  entries: number;
  setSelectedCategory: (category: FaqCategory | null) => void;
}

export const ContactFaqMobileHeader: FC<ContactFaqMobileHeaderProps> = ({
  category,
  entries,
  setSelectedCategory,
}) => {
  const [icon, icon2x] = getWhiteCategoryIcons(category);

  return (
    <div
      className="flex flex-row items-center w-auto h-12 px-4 py-2 -mx-5 -mt-8 space-x-4 text-white bg-green cursor-pointer"
      onClick={() => setSelectedCategory(null)}
    >
      <picture>
        <img
          className="object-cover"
          src={arrowLeftIcon}
          srcSet={`${arrowLeftIcon} 1x, ${arrowLeftIcon2x} 2x`}
        />
      </picture>
      <picture>
        <img className="w-6" src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
      </picture>
      <div className="text-base font-semibold text-center">
        {startCase(category)} FAQs
      </div>
      <div className="ml-auto mr-6 text-sm">{entries} Entries</div>
    </div>
  );
};
