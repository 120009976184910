import React, { FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import mobile from 'is-mobile';

import { KeyStatisticsRow } from '~/components/data';
import {
  PitchFieldTestingPage,
  PitchHeatmapPage,
  PitchMaintenancePage,
  PitchSnapshotsPage,
  PitchUsagePage,
} from '~/pages/pitch';
import { PitchCalendarPage } from '~/pages/pitch/PitchCalendar.page';
import { useMetadataStore } from '~/stores';

import { RoutesPath } from '~/types';
import { PrivateRoute } from './PrivateRoute';

const mobilePaths = [RoutesPath.PitchUsage, RoutesPath.PitchCalendar];
const desktopPaths = [RoutesPath.PitchStats];

export const PitchContentRouter: FC = () => {
  const { facilityId, pitchId } = useParams<{
    facilityId: string;
    pitchId: string;
  }>();
  const { facilities } = useMetadataStore();
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();
  const location = useLocation();
  const pathName = location.pathname
    .replace(facilityId, ':facilityId')
    .replace(pitchId, ':pitchId') as RoutesPath;
  const history = useHistory();
  const facility = facilities.find(f => f.id === +facilityId);

  useEffect(() => {
    if (isMobile) {
      if (mobilePaths.includes(pathName)) {
        history.replace(
          RoutesPath.PitchStats.replace(':facilityId', facilityId).replace(
            ':pitchId',
            pitchId
          )
        );
      }
    } else {
      if (desktopPaths.includes(pathName)) {
        history.replace(
          RoutesPath.PitchUsage.replace(':facilityId', facilityId).replace(
            ':pitchId',
            pitchId
          )
        );
      }
    }
  }, [history, isMobile, pathName, facilityId, pitchId]);
  return (
    <Switch>
      <Route exact strict path={RoutesPath.PitchStats}>
        <div className="mx-4 my-4">
          <KeyStatisticsRow facility={facility} pitchId={+pitchId} />
        </div>
      </Route>
      <PrivateRoute
        exact
        strict
        path={RoutesPath.PitchUsage}
        component={PitchUsagePage}
      />
      <PrivateRoute
        exact
        strict
        path={RoutesPath.PitchHeatmap}
        component={PitchHeatmapPage}
      />
      <PrivateRoute
        exact
        strict
        path={RoutesPath.PitchSnapshots}
        component={PitchSnapshotsPage}
      />
      <PrivateRoute
        exact
        strict
        path={RoutesPath.PitchMaintenance}
        component={PitchMaintenancePage}
      />
      <PrivateRoute
        exact
        strict
        path={RoutesPath.PitchFieldTesting}
        component={PitchFieldTestingPage}
      />
      <PrivateRoute
        exact
        strict
        path={RoutesPath.PitchCalendar}
        component={PitchCalendarPage}
      />
    </Switch>
  );
};
