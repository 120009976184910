import React, { FC, useMemo } from 'react';
import ct from 'countries-and-timezones';
import { AdminStat } from '.';
import usersIcon from 'assets/images/pages/admin/icon__users.png';
import usersIcon2x from 'assets/images/pages/admin/icon__users@2x.png';
import facilitiesIcon from 'assets/images/pages/admin/icon__facilities.png';
import facilitiesIcon2x from 'assets/images/pages/admin/icon__facilities@2x.png';
import fieldsIcon from 'assets/images/pages/admin/icon__fields.png';
import fieldsIcon2x from 'assets/images/pages/admin/icon__fields@2x.png';
import countriesIcon from 'assets/images/pages/admin/icon__countries.png';
import countriesIcon2x from 'assets/images/pages/admin/icon__countries@2x.png';
import sportsIcon from 'assets/images/pages/admin/icon__sports.png';
import sportsIcon2x from 'assets/images/pages/admin/icon__sports@2x.png';
import { SectionHeader } from '~/components/layout';
import { useMetadataStore } from '~/stores';
import { useQuery } from 'react-query';
import { getUsers } from '~/api';
import { SportType } from '@intelligent-play-v2/domain';

export const AdminStats: FC = () => {
  const { facilities } = useMetadataStore();

  const numberOfPitches = useMemo(() => {
    return facilities.reduce(
      (accum: number, facility) => accum + (facility.pitches?.length ?? 0),
      0
    );
  }, [facilities]);

  const countries = useMemo(() => {
    return facilities.reduce((array: string[], facility) => {
      const country = ct.getCountryForTimezone(
        facility.tzDatabaseTimezone || ''
      );
      if (country && !array.includes(country.name)) {
        array.push(country.name);
      }
      return array;
    }, []);
  }, [facilities]);

  const { data: users } = useQuery('users', async () => getUsers());

  const sports = useMemo(() => {
    return facilities.reduce((array: SportType[], facility) => {
      facility.pitches?.forEach(pitch => {
        pitch.sportTypes?.forEach(sportType => {
          if (!array.includes(sportType)) {
            array.push(sportType);
          }
        });
      });
      return array;
    }, []);
  }, [facilities]);

  return (
    <div>
      <SectionHeader title="Admin stats" />
      {/* eslint-disable-next-line max-len */}
      <div className="rounded shadow-250 sm:rounded-t pb-0 sm:pb-0.5 xl:pb-0 overflow-hidden sm:shadow-none xl:rounded xl:shadow-250 grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:w-5/6 2xl:grid-cols-5 mt-2.5">
        <AdminStat
          title="Total users"
          icon={usersIcon}
          icon2x={usersIcon2x}
          value={users?.length}
          childNumber={1}
        />
        <AdminStat
          title="Total facilities"
          icon={facilitiesIcon}
          icon2x={facilitiesIcon2x}
          value={facilities.length}
          childNumber={2}
        />
        <AdminStat
          title="Total fields"
          icon={fieldsIcon}
          icon2x={fieldsIcon2x}
          value={numberOfPitches}
          childNumber={3}
        />
        <AdminStat
          title="Total countries"
          icon={countriesIcon}
          icon2x={countriesIcon2x}
          value={countries.length}
          childNumber={4}
        />
        <AdminStat
          title="Total sports"
          icon={sportsIcon}
          icon2x={sportsIcon2x}
          value={sports.length}
          childNumber={5}
        />
      </div>
    </div>
  );
};
