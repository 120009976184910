import { queryBuilder } from '~/api/queryBuilder';

export interface PatchUserParams {
  userId: number;
  phoneNumber?: string;
  jobTitle?: string;
  image?: string;
}

export const patchUser = async (params: PatchUserParams): Promise<void> => {
  const { data } = await queryBuilder(
    'PATCH',
    `admin/users/${params.userId}`,
    undefined,
    {
      ...params,
    }
  );
  return data;
};
