import create from 'zustand';
import { ReportArea } from '@intelligent-play-v2/domain';
import { HeatmapSectorsDivision } from '~/components/heatmap/utils';

interface ReportStore {
  selectedReportAreaIds?: ReportArea[];
  setInitialPdfSettings: (
    facilityIds: number[],
    pitchIds: number[],
    selectedReportAreaIds: ReportArea[]
  ) => void;
  clearReportPdfSettings: () => void;
  pdfIncludedFacilityIds: Map<number, boolean>;
  togglePdfIncludedFacilityId: (facilityId: number) => void;
  pdfIncludedGraphIds: Map<string, boolean>;
  togglePdfIncludedGraphId: (id: string) => void;
  pdfIncludedPitchHeatmaps: Map<number, HeatmapSectorsDivision | null>;
  setPdfIncludedPitchHeatmap: (
    pitchId: number,
    division: HeatmapSectorsDivision | null
  ) => void;
}

export const useReportStore = create<ReportStore>(set => ({
  selectedReportAreaIds: undefined,
  setInitialPdfSettings: (facilityIds, pitchIds, selectedReportAreaIds) => {
    set(() => {
      const pdfIncludedFacilityIds = new Map(
        facilityIds.map(facilityId => [facilityId, true])
      );
      const pdfIncludedPitchHeatmaps = pitchIds.reduce(
        (heatmapSettings, pitchId) => {
          heatmapSettings.set(pitchId, HeatmapSectorsDivision.Thirds);
          return heatmapSettings;
        },
        new Map<number, HeatmapSectorsDivision | null>()
      );
      return {
        pdfIncludedFacilityIds,
        pdfIncludedPitchHeatmaps,
        selectedReportAreaIds,
      };
    });
  },
  clearReportPdfSettings: () => {
    set(() => {
      return {
        pdfIncludedFacilityIds: new Map<number, boolean>(),
        pdfIncludedGraphIds: new Map<string, boolean>(),
        pdfIncludedPitchHeatmaps: new Map<
          number,
          HeatmapSectorsDivision | null
        >(),
        selectedReportAreaIds: undefined,
      };
    });
  },
  pdfIncludedFacilityIds: new Map<number, boolean>(),
  togglePdfIncludedFacilityId: facilityId => {
    set(({ pdfIncludedFacilityIds }) => {
      pdfIncludedFacilityIds?.set(
        facilityId,
        !pdfIncludedFacilityIds.get(facilityId)
      );
      return {
        pdfIncludedFacilityIds,
      };
    });
  },
  pdfIncludedGraphIds: new Map<string, boolean>(),
  togglePdfIncludedGraphId: (id: string) => {
    set(({ pdfIncludedGraphIds }) => {
      pdfIncludedGraphIds.set(id, !pdfIncludedGraphIds.get(id));
      return {
        pdfIncludedGraphIds,
      };
    });
  },
  pdfIncludedPitchHeatmaps: new Map<number, HeatmapSectorsDivision | null>(),
  setPdfIncludedPitchHeatmap: (pitchId, division) => {
    set(({ pdfIncludedPitchHeatmaps }) => {
      pdfIncludedPitchHeatmaps?.set(pitchId, division);
      return {
        pdfIncludedPitchHeatmaps,
      };
    });
  },
}));
