import React, { FC, ReactNode } from 'react';
import { useQuery } from 'react-query';
import {
  parse,
  roundToNearestMinutes,
  setHours,
  setMinutes,
  setSeconds,
  subMinutes,
} from 'date-fns';
import { format } from 'date-fns-tz';
import {
  PitchMaintenanceStatusParams,
  getPitchMaintenanceStatus,
  getPitchStateHeatmap,
} from '~/api';
import { useDebounce, useTimeRange } from '~/hooks';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { HeatmapContainer } from '~/components/heatmap/heatmapContainer';
import { getFacilityNow } from '~/utils/getFacilityNow';
import { Pitch, TimePeriod } from '@intelligent-play-v2/domain';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime/index.js';
import { MaintenanceCarriedOut } from '~/types';
import { MaintenanceCarriedOutMarker } from './MaintenanceCarriedOutMarker';

interface MaintenanceHeatmapProps {
  pitch: Pitch;
  selectedDate: Date;
  timezone: string;
  title: string;
}

export const MaintenanceHeatmap: FC<MaintenanceHeatmapProps> = ({
  pitch,
  selectedDate,
  timezone,
  title,
}) => {
  const facilityNow = getFacilityNow(timezone);

  const initialSliderTime = format(
    subMinutes(
      roundToNearestMinutes(facilityNow, {
        nearestTo: 5,
      }),
      15
    ),
    'HH:mm'
  );

  const {
    startTime,
    startTimes,
    handleStartTimeChange,
    setStartTime,
  } = useTimeRange(5, initialSliderTime);

  const [hours, minutes] = startTime.value.split(':');

  const selectedDateTime = setSeconds(
    setMinutes(
      setHours(selectedDate, parseInt(hours, 10)),
      parseInt(minutes, 10)
    ),
    0
  );

  const dateTimeLocal = format(selectedDateTime, `dd-MM-yyyy'T'HH:mm:ss xxx`, {
    timeZone: timezone,
  });

  const dateTimeUtc = parse(
    dateTimeLocal,
    `dd-MM-yyyy'T'HH:mm:ss xxx`,
    new Date()
  );

  const heatmapRequestParams = useDebounce(
    {
      pitchId: pitch.id.toString(),
      date: dateTimeUtc,
    },
    100
  );

  const { data: pitchStateHeatmap, isLoading: isLoadingHeatmap } = useQuery(
    ['getPitchStateHeatmap', heatmapRequestParams],
    async () => getPitchStateHeatmap(heatmapRequestParams)
  );
  const localDateTime = utcToZonedTime(dateTimeUtc, timezone);

  const pitchMaintenanceStatusParams: PitchMaintenanceStatusParams = {
    timezone,
    timePeriod: TimePeriod.Custom,
    pitchId: pitch.id.toString(),
    from: format(localDateTime, 'yyyy-MM-dd'),
    to: format(localDateTime, 'yyyy-MM-dd'),
  };

  const { data: pitchMaintenanceStatus } = useQuery(
    ['pitchMaitenanceStatus', pitchMaintenanceStatusParams],
    async () => getPitchMaintenanceStatus(pitchMaintenanceStatusParams)
  );

  const maintenanceCarriedOut: MaintenanceCarriedOut[] =
    pitchMaintenanceStatus &&
    pitchMaintenanceStatus[0] &&
    pitchMaintenanceStatus[0].carriedOutTimes
      ? pitchMaintenanceStatus[0].carriedOutTimes.map(time => {
          const date = utcToZonedTime(new Date(time), timezone);
          const pct =
            ((date.getHours() * 3600 +
              date.getMinutes() * 60 +
              date.getSeconds() +
              date.getMilliseconds() / 1000) /
              86400) *
            100;
          return { time, pct };
        })
      : [];

  const handleTimeSliderChange = (index: number): void => {
    const newStartTime = startTimes[index];
    handleStartTimeChange(newStartTime);
  };

  const handleCarriedOutTimeSelected = (date: string): void => {
    const time = format(utcToZonedTime(new Date(date), timezone), 'HH:mm');
    setStartTime({ value: time, label: time });
  };

  return (
    <div className="flex flex-col space-y-3 bg-white border-b border-primary-100">
      <div className="flex flex-col w-15/16 h-full m-auto space-y-3 pb-7">
        <div className="flex flex-row">
          <span className="font-semibold">{title}</span>
        </div>
        <HeatmapContainer
          heatmapImage={pitchStateHeatmap || ''}
          isLoadingHeatmap={isLoadingHeatmap}
          pitchWidth={pitch.widthMeters}
          pitchLength={pitch.lengthMeters}
        />
        <div>Selected time: {format(selectedDateTime, 'HH:mm')}</div>
        <div className="relative px-3">
          <Slider
            value={startTimes.findIndex(time => time.label === startTime.label)}
            disabled={isLoadingHeatmap}
            included={false}
            min={0}
            max={24 * 12}
            onChange={handleTimeSliderChange}
            marks={startTimes.reduce((marks, { label }, i) => {
              const [hrs, mins] = label.split(':');
              if (parseInt(mins, 10) === 0 && parseInt(hrs, 10) % 3 === 0) {
                marks[i] = label;
              }
              return marks;
            }, {} as Record<string, ReactNode>)}
          />
          <div className="absolute h-2 right-3 -top-3 left-3">
            {maintenanceCarriedOut?.map(carriedOut => (
              <MaintenanceCarriedOutMarker
                maintenanceCarriedOut={carriedOut}
                onClick={handleCarriedOutTimeSelected}
                key={carriedOut.time}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
