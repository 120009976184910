import classnames from 'classnames';
import React, { FC } from 'react';
import remove from 'assets/images/pages/report/icon__preset-remove.png';
import remove2x from 'assets/images/pages/report/icon__preset-remove@2x.png';

interface ToggleButtonProps {
  text: string;
  onClick: () => void;
  active?: boolean;
  size?: 'regular' | 'large';
  onDelete?: (() => void) | null;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  text,
  onClick,
  active,
  size = 'regular',
  onDelete,
}) => {
  const btnClass = classnames(
    { 'bg-green': active },
    { 'text-white': active },
    { 'text-green': !active },
    { 'text-sm': size === 'regular' },
    { 'px-2': size === 'regular' },
    { 'pt-1': size === 'regular' },
    { 'pb-1.5': size === 'regular' },
    { 'text-base': size === 'large' },
    { 'px-2.5': size === 'large' },
    { 'pt-1.5': size === 'large' },
    { 'pb-2': size === 'large' }
  );

  const onClickHandler = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    onClick();
  };

  return (
    <button
      className={`flex items-center font-semibold border border-green rounded-xs ${btnClass}`}
      onClick={onClickHandler}
    >
      {onDelete && (
        <picture
          className="mr-1.5 mt-0.5"
          style={{ filter: active ? 'brightness(0) invert(1)' : '' }}
          onClick={onDelete}
        >
          <img src={remove} srcSet={`${remove} 1x, ${remove2x} 2x`} />
        </picture>
      )}
      <div>{text}</div>
    </button>
  );
};
