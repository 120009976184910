import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { FacilityMap } from '~/components/map';
import { usePageTitle } from '~/hooks';
import { useMetadataStore } from '~/stores';
import { FacilityRow } from '~/types';

export const FacilityAboutPage: FC = () => {
  const { facilities } = useMetadataStore();
  const { facilityId } = useParams<{ facilityId: string }>();

  const selectedFacility = facilities.find(f => f.id === +facilityId);

  usePageTitle(`About - ${selectedFacility?.name} - Intelligent Play`);

  if (!selectedFacility) {
    return null;
  }

  const selectedFacilityRow: FacilityRow = {
    facility: selectedFacility,
    id: selectedFacility.id,
    facilityAggregate: undefined,
  };

  return (
    <div className="mt-5 lg:w-120">
      <FacilityMap
        facilityRows={[selectedFacilityRow]}
        height={400}
        disableRedirect={true}
      />
    </div>
  );
};
