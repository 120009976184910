import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useMetadataStore } from '~/stores';
import toast from 'react-hot-toast';

import addIcon from 'assets/images/pages/facility/team/icon__team-add.png';
import addIcon2x from 'assets/images/pages/facility/team/icon__team-add@2x.png';
import { Modal } from '../modals';
import { InviteUserForm } from './InviteUserForm';
import { FacilityTeamRow } from './FacilityTeamRow';

export const FacilityTeam: FC = () => {
  const { facilities } = useMetadataStore();
  const { facilityId } = useParams<{ facilityId: string }>();
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  const facility = facilities.find(({ id }) => id === +facilityId);

  const handleInviteSuccess = (): void => {
    toast.success('Successfully sent request');
    setShowInviteUserModal(false);
  };

  return (
    <div className="flex 2xl:space-x-15">
      <div className="flex flex-col w-full my-6 bg-white rounded-sm shadow-250 2xl:w-2/3 ">
        {facility?.staff?.map(staffMember => (
          <FacilityTeamRow key={staffMember.id} staffMember={staffMember} />
        ))}

        <div
          className="flex flex-row items-center p-3 space-x-2 border-b border-primary-100 cursor-pointer hover:bg-primary-100"
          onClick={() => setShowInviteUserModal(true)}
        >
          <div className="grid w-8 h-8 border border-blue border-dashed rounded-full place-items-center">
            <picture>
              <img src={addIcon} srcSet={`${addIcon} 1x, ${addIcon2x} 2x`} />
            </picture>
          </div>
          <span className="font-semibold text-blue transform -translate-y-px">
            Request a new user
          </span>
        </div>
      </div>

      {/* empty div for layout */}
      <div className="w-0 2xl:w-1/3" />

      <Modal
        showModal={showInviteUserModal}
        setShowModal={setShowInviteUserModal}
        title="Request new user"
      >
        <InviteUserForm
          onSuccess={handleInviteSuccess}
          facilityName={facility?.name || ''}
        />
      </Modal>
    </div>
  );
};
