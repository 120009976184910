import React, { FC } from 'react';
import editIcon from 'assets/images/pages/calendar/icon__task-edit.png';
import editIcon2x from 'assets/images/pages/calendar/icon__task-edit@2x.png';
import { useAuthStore } from '~/stores';
import { ResourceTutorial, UserType } from '@intelligent-play-v2/domain';

interface TutorialCardProps {
  tutorial: ResourceTutorial;
  action?: React.ReactNode;
  onClickEdit: (tutorial: ResourceTutorial) => void;
  hideEditButton?: boolean;
}
export const TutorialCard: FC<TutorialCardProps> = ({
  tutorial,
  action,
  onClickEdit,
  hideEditButton,
}) => {
  const { user } = useAuthStore();
  const isAdmin = user?.userTypeId === UserType.SystemAdmin;
  const showEditButton = isAdmin && !hideEditButton;
  const youtubeEmbedLink =
    'https://www.youtube.com/embed/' + tutorial.youtubeId;

  return (
    <div className="bg-white rounded-sm shadow-250 max-w-80 h-80">
      <div className="flex flex-row justify-between w-full h-10 px-4 py-2 font-semibold border-b border-primary-100">
        <div className="truncate overflow-ellipsis">{tutorial.title}</div>
        {showEditButton && (
          <div className="flex flex-row flex-shrink-0 pt-0.5 pl-2 space-x-2">
            <div
              className="cursor-pointer"
              onClick={() => onClickEdit(tutorial)}
            >
              <picture>
                <img
                  src={editIcon}
                  srcSet={`${editIcon} 1x, ${editIcon2x} 2x`}
                />
              </picture>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col object-fill p-4 h-[280px]">
        <div className="text-base whitespace-pre-wrap">
          {tutorial.description}
        </div>
        {tutorial.youtubeId && (
          <div className="mt-auto">
            <iframe className="w-full" src={youtubeEmbedLink}></iframe>
          </div>
        )}
        {action && <div className="mt-auto">{action}</div>}
      </div>
    </div>
  );
};
