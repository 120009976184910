import create from 'zustand';
import { Facility } from '@intelligent-play-v2/domain';

interface MetadataStore {
  facilities: Facility[];
  setFacilities: (facilities: Facility[]) => void;
}

export const useMetadataStore = create<MetadataStore>(set => ({
  facilities: [],
  setFacilities: facilities => {
    set(() => ({ facilities: facilities }));
  },
}));
