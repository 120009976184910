import React, { FC } from 'react';

interface SnapshotTypeIconProps {
  icon: string;
  icon2x: string;
  imageContainerColour: string;
}
export const SnapshotTypeIcon: FC<SnapshotTypeIconProps> = ({
  icon,
  icon2x,
  imageContainerColour,
}) => {
  return (
    <div
      className={`flex items-center justify-center w-8 h-8 rounded-full ${imageContainerColour}`}
    >
      <picture>
        <img
          className="transform -translate-y-px"
          src={icon}
          srcSet={`${icon} 1x, ${icon2x} 2x`}
        />
      </picture>
    </div>
  );
};
