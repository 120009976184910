import React, { ChangeEvent, FC } from 'react';
import checkIcon from 'assets/images/pages/report/icon__check-selected.svg';
import largeCheckIcon from 'assets/images/pages/admin/icon__check-selected.svg';
import { classnames } from 'tailwindcss-classnames';

interface CheckboxProps {
  size?: 'regular' | 'large';
  isChecked: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

export const Checkbox: FC<CheckboxProps> = ({
  size = 'regular',
  isChecked,
  onChange,
  value,
}) => {
  const inputClass = classnames(
    { 'w-8': size === 'large' },
    { 'h-8': size === 'large' },
    { 'w-5': size === 'regular' },
    { 'h-5': size === 'regular' }
  );
  const hiddenInputClass = classnames(
    inputClass,
    'absolute',
    'opacity-0',
    'cursor-pointer'
  );

  const checkboxClass = classnames(
    inputClass,
    { 'border-primary-250': !isChecked },
    { border: !isChecked || size === 'regular' },
    { 'border-2': isChecked && size === 'large' },
    { 'border-green': isChecked }
  );

  const imgClass = classnames({ hidden: !isChecked }, { block: isChecked });

  const icon = size === 'regular' ? checkIcon : largeCheckIcon;

  return (
    <div className={`${inputClass}`}>
      <input
        type="checkbox"
        className={hiddenInputClass}
        checked={isChecked}
        readOnly
        onChange={onChange}
        value={value}
      />
      <div
        className={`flex items-center justify-center flex-shrink-0 bg-white rounded-xs focus-within:border-primary-500 ${checkboxClass}`}
      >
        <img className={imgClass} src={icon} />
      </div>
    </div>
  );
};
