import { SnapshotSchedule } from '@intelligent-play-v2/domain';
import { queryBuilder } from '../../queryBuilder';

export const putSnapshotSchedule = async (
  body: SnapshotSchedule
): Promise<SnapshotSchedule> => {
  const { data } = await queryBuilder(
    'PUT',
    `snapshot_schedules/${body.customScheduleId || 0}`,
    undefined,
    {
      ...body,
    }
  );
  return data;
};
