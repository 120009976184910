import { CalendarEvent } from '@intelligent-play-v2/domain';
import { formatISO } from 'date-fns';
import { queryBuilder } from '~/api';

export interface CalendarEventParams {
  pitchIds: number[];
  eventTypeIds?: number[];
  from: Date;
  to: Date;
}

export const getCalendarEvents = async (
  params: CalendarEventParams
): Promise<CalendarEvent[]> => {
  const { pitchIds, eventTypeIds, from, to } = params;

  const { data } = await queryBuilder('GET', 'calendar', {
    pitchIds: pitchIds.join(','),
    eventTypeIds: eventTypeIds ? eventTypeIds.join(',') : undefined,
    from: formatISO(from),
    to: formatISO(to),
  });

  return data;
};
