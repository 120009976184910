import { DailyWeather } from '@intelligent-play-v2/domain';
import { format, isSameDay } from 'date-fns';
import React, { FC, useRef, useState } from 'react';
import MediaQuery from 'react-responsive';

import { useParams } from 'react-router-dom';
import { classnames } from 'tailwindcss-classnames';
import { Loading } from '~/components/loading';
import { Toggle } from '~/components/toggle';
import {
  NavButton,
  SelectedWeatherCard,
  WeatherCard,
} from '~/components/weather';
import { useWeatherInWeeks } from '~/hooks';
import { useMetadataStore } from '~/stores';
import { isBrowserLanguageAmerican } from '~/utils';

enum WeekOption {
  PrevWeek,
  CurrentWeek,
  NextWeek,
}

const SM_SCREEN_WIDTH = 600;

export const FacilityWeatherPage: FC = () => {
  const { facilities } = useMetadataStore();
  const { facilityId } = useParams<{ facilityId: string }>();
  const [isCelsius, setIsCelsius] = useState(!isBrowserLanguageAmerican()); // todo locale specific

  const weatherContainerRef = useRef<HTMLDivElement>(null);

  const selectedFacility = facilities.find(f => f.id === +facilityId);

  const { weather: weatherForecast, isLoading } = useWeatherInWeeks(
    selectedFacility?.id || 0
  );

  const [currentlyViewingWeek, setCurrentlyViewingWeek] = useState<WeekOption>(
    WeekOption.CurrentWeek
  );

  const dateToday = format(new Date(), 'yyyy-MM-dd');
  const [selectedDate, setSelectedDate] = useState(dateToday);

  const onClickPrevWeek = (): void => {
    if (currentlyViewingWeek === WeekOption.NextWeek) {
      setCurrentlyViewingWeek(WeekOption.CurrentWeek);
    } else {
      setCurrentlyViewingWeek(WeekOption.PrevWeek);
    }

    if (weatherContainerRef && weatherContainerRef.current) {
      weatherContainerRef.current.scrollLeft = 0;
    }
  };

  const onClickNextWeek = (): void => {
    if (currentlyViewingWeek === WeekOption.PrevWeek) {
      setCurrentlyViewingWeek(WeekOption.CurrentWeek);
    } else {
      setCurrentlyViewingWeek(WeekOption.NextWeek);
    }

    if (weatherContainerRef && weatherContainerRef.current) {
      weatherContainerRef.current.scrollLeft = 0;
    }
  };

  if (!selectedFacility) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="w-full my-4">
        <Loading />
      </div>
    );
  }

  const weatherDates: DailyWeather[] = weatherForecast[currentlyViewingWeek];

  const styles = classnames({
    '-translate-x-28': currentlyViewingWeek !== WeekOption.PrevWeek,
  });

  return (
    <div>
      <div className="flex flex-row w-full my-4 text-base font-semibold">
        <div className="flex flex-row justify-between w-14/16">
          {currentlyViewingWeek !== WeekOption.PrevWeek ? (
            <NavButton onClick={onClickPrevWeek} label="Previous 7 days" />
          ) : (
            <div />
          )}
          {currentlyViewingWeek !== WeekOption.NextWeek && (
            <NavButton onClick={onClickNextWeek} label="Next 7 days" />
          )}
        </div>
        <div className="flex flex-row justify-end w-4/16 sm:w-3/16 md:w-2/16">
          <Toggle
            textBefore="°F"
            textAfter="°C"
            checked={isCelsius}
            onChange={() => setIsCelsius(!isCelsius)}
          />
        </div>
      </div>
      <div
        ref={weatherContainerRef}
        className={`flex flex-col sm:flex-row space-y-3 sm:space-x-3 sm:space-y-0 transform overflow-y-hidden w-full sm:w-extra sm:overflow-x-auto sm:${styles}`}
      >
        <MediaQuery minWidth={SM_SCREEN_WIDTH}>
          {currentlyViewingWeek !== WeekOption.PrevWeek && (
            <WeatherCard
              date={weatherForecast[currentlyViewingWeek - 1][6].date}
              weather={weatherForecast[currentlyViewingWeek - 1][6]}
              setSelectedDate={setSelectedDate}
              isFaded
              onClick={onClickPrevWeek}
              isCelsius={isCelsius}
            />
          )}
        </MediaQuery>

        {weatherDates.map((dayOfWeather, i) => {
          const { date } = dayOfWeather;
          const isSelected = isSameDay(new Date(date), new Date(selectedDate));

          return isSelected ? (
            <SelectedWeatherCard
              key={i}
              date={date}
              weather={dayOfWeather}
              setSelectedDate={setSelectedDate}
              isCelsius={isCelsius}
            />
          ) : (
            <WeatherCard
              key={i}
              date={date}
              weather={dayOfWeather}
              setSelectedDate={setSelectedDate}
              isCelsius={isCelsius}
            />
          );
        })}
        <MediaQuery minWidth={SM_SCREEN_WIDTH}>
          {currentlyViewingWeek !== WeekOption.NextWeek && (
            <WeatherCard
              date={weatherForecast[currentlyViewingWeek + 1][0].date}
              weather={weatherForecast[currentlyViewingWeek + 1][0]}
              setSelectedDate={setSelectedDate}
              isFaded
              onClick={onClickNextWeek}
              isCelsius={isCelsius}
            />
          )}
        </MediaQuery>
      </div>
    </div>
  );
};
