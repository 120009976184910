import { isWeekend } from 'date-fns';
import React, { FC } from 'react';
import { DateCellWrapperProps } from 'react-big-calendar';

export const CalendarDateCellWrapper: FC<DateCellWrapperProps> = props => {
  const isOutsideSelectedMonth = props.children.props.className.includes(
    'rbc-off-range-bg'
  );

  const backgroundColor = isWeekend(props.value) ? '#f5f7f8' : 'white';

  const style: React.CSSProperties = isOutsideSelectedMonth
    ? {
        background: `repeating-linear-gradient(-45deg, #d8d8d8, #d8d8d8 0.1px, ${backgroundColor} 1px, ${backgroundColor} 4px)`,
      }
    : {
        backgroundColor,
      };

  const inheritClasses = props.children.props.className;

  return <div className={inheritClasses} style={style} />;
};
