import React, { FC } from 'react';

import dropdownIcon from 'assets/images/ctas_inputs_modals/icon__input-dropdown.png';
import dropdownIcon2x from 'assets/images/ctas_inputs_modals/icon__input-dropdown@2x.png';
import disabledDropdownIcon from 'assets/images/ctas_inputs_modals/icon__input-dropdown-disabled.png';
import disabledDropdownIcon2x from 'assets/images/ctas_inputs_modals/icon__input-dropdown-disabled@2x.png';

interface SelectDropdownIconProps {
  isDisabled?: boolean;
}

export const SelectDropdownIcon: FC<SelectDropdownIconProps> = ({
  isDisabled,
}) => (
  <div className="p-2">
    <picture>
      {isDisabled ? (
        <img
          src={disabledDropdownIcon}
          srcSet={`${disabledDropdownIcon} 1x, ${disabledDropdownIcon2x} 2x`}
        />
      ) : (
        <img
          src={dropdownIcon}
          srcSet={`${dropdownIcon} 1x, ${dropdownIcon2x} 2x`}
        />
      )}
    </picture>
  </div>
);
