import React, { FC } from 'react';
import { useMetadataStore } from '~/stores';

import { RoutesPath } from '~/types';
import { NavigationListItem } from './NavigationListItem';

export const FacilitiesList: FC = () => {
  const { facilities } = useMetadataStore();

  if (facilities.length > 6) {
    return null;
  }

  return (
    <>
      {facilities.map(facility => (
        <NavigationListItem
          isChildItem
          key={facility.id}
          path={RoutesPath.FacilityOverview.replace(
            ':facilityId',
            facility.id.toString()
          )}
          label={facility.name || ''}
        />
      ))}
    </>
  );
};
