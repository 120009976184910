import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PlayerCountGraph } from '~/components/graphs';
import { usePageTitle } from '~/hooks';
import { useMetadataStore } from '~/stores';

export const PitchUsagePage: FC = () => {
  const { facilities } = useMetadataStore();
  const { facilityId, pitchId } = useParams<{
    facilityId: string;
    pitchId: string;
  }>();

  const selectedFacility = facilities.find(f => f.id === +facilityId);
  const selectedPitch = selectedFacility?.pitches?.find(p => p.id === +pitchId);

  usePageTitle(
    `Usage - ${selectedPitch?.name} - ${selectedFacility?.name} - Intelligent Play`
  );

  if (!selectedFacility || !selectedPitch) {
    // error handled on parent
    return null;
  }

  return (
    <div>
      <PlayerCountGraph
        facilityId={+facilityId}
        pitchId={+pitchId}
        timezone={selectedFacility.tzDatabaseTimezone || 'Etc/UTC'}
      />
    </div>
  );
};
