/* eslint-disable react/display-name */
import {
  Facility,
  ReportArea,
  StatsDates,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import React, { FC, Ref } from 'react';
import { useReportStore } from '~/stores/useReportStore';
import { PitchUsageHeatmap } from '..';
import { PrintableFacilityReport } from './PrintableFacilityReport';

import { pageBreakStyle } from '~/components/data/styles';
import { PDFHeaderAndFooter } from '~/components/layout/pdf';

interface PrintableReportProps {
  reportedPitchFacilities: Facility[];
  timePeriod: TimePeriod;
  customDates: StatsDates | null;
  ref: Ref<HTMLDivElement>;
}

export const PrintableReport: FC<PrintableReportProps> = React.forwardRef(
  ({ reportedPitchFacilities, timePeriod, customDates }, ref) => {
    const {
      pdfIncludedFacilityIds,
      pdfIncludedPitchHeatmaps,
      selectedReportAreaIds,
    } = useReportStore();

    if (!pdfIncludedFacilityIds || !pdfIncludedPitchHeatmaps) {
      return null;
    }

    const includeHeatmap =
      selectedReportAreaIds &&
      selectedReportAreaIds.includes(ReportArea.Heatmap);

    return (
      <div ref={ref} style={{ width: '780px' }} className="overflow-y-hidden">
        <PDFHeaderAndFooter>
          <div>
            <div>
              {reportedPitchFacilities
                .filter(({ id }) => pdfIncludedFacilityIds.get(id))
                .map(facility => {
                  const filteredPitches = facility.pitches!.filter(({ id }) =>
                    pdfIncludedPitchHeatmaps?.get(id)
                  );

                  return (
                    <div
                      className="p-4"
                      style={pageBreakStyle}
                      key={facility.id}
                    >
                      <PrintableFacilityReport
                        facility={facility}
                        timePeriod={timePeriod}
                        customDates={customDates}
                      />
                      {includeHeatmap && (
                        <>
                          {filteredPitches.map(filteredPitch => {
                            return (
                              <PitchUsageHeatmap
                                isPrintableVersion
                                pitch={filteredPitch}
                                timezone={facility.tzDatabaseTimezone ?? 'UTC'}
                                timePeriod={timePeriod}
                                customDates={customDates}
                                key={filteredPitch.id}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </PDFHeaderAndFooter>
      </div>
    );
  }
);
