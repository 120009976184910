import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';

import { KeyStatistic } from '~/components/data/KeyStatistic';
import { useContainerDimensions, useTimePeriodSelect } from '~/hooks';
import { KeyStatisticType } from '~/types';
import { getStats } from '~/api';
import { SectionHeader } from '../layout';
import { Select, SelectOptionType } from '../select';

import { statsClass, statsClassFull, statsMessageClass } from './styles';
import {
  Facility,
  FacilityAggregate,
  PitchAggregate,
  StatsAggregate,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import { Loading } from '../loading';
import { ComparisonField } from '.';
import { getStatsParams } from '~/utils';

interface KeyStatisticsRowProps {
  facility?: Facility;
  pitchId?: number;
}
type AggregateExtend = StatsAggregate | FacilityAggregate | PitchAggregate;

export const KeyStatisticsRow: FC<KeyStatisticsRowProps> = ({
  facility,
  pitchId,
}) => {
  const componentRef = useRef(null);

  const [comparisonPitchId, setComparisonPitchId] = useState<number | null>(
    null
  );

  const { width, handleResize: handleGraphResize } = useContainerDimensions(
    componentRef
  );

  const pitchesOptions: SelectOptionType[] = (
    facility?.pitches?.filter(pitch => pitchId && pitch.id !== pitchId) ?? []
  ).map(pitch => ({
    label: pitch.name || '',
    value: pitch.id.toString(),
  }));

  const timePeriods = [
    TimePeriod.Today,
    TimePeriod.Last7Days,
    TimePeriod.Last4Weeks,
    TimePeriod.Last3Months,
    TimePeriod.Last12Months,
    TimePeriod.Total,
  ];

  const defaultTimePeriod = TimePeriod.Last7Days;
  const { selectedTimePeriod, setSelectedTimePeriod } = useTimePeriodSelect(
    defaultTimePeriod,
    null
  );

  const timePeriodOptions = timePeriods.map(key => ({
    label: key,
    value: key,
  }));

  const selectedOption =
    timePeriodOptions.find(option => option.label === selectedTimePeriod) ||
    timePeriodOptions[0];

  const handleTimePeriodChange = (option: SelectOptionType | null): void => {
    const newValue = option
      ? (option.value as TimePeriod)
      : (timePeriodOptions[0].value as TimePeriod);
    setSelectedTimePeriod(newValue);
  };

  const timePeriod = selectedTimePeriod ?? defaultTimePeriod;

  const statsParams = getStatsParams(timePeriod, facility);

  const { isLoading, data } = useQuery(['stats', statsParams], async () =>
    getStats(statsParams)
  );

  const filterData = useCallback(
    (
      dataParam: StatsAggregate,
      facilityIdParam?: number,
      pitchIdParam?: number
    ): AggregateExtend => {
      let filtered: AggregateExtend;
      filtered = dataParam;

      if (facilityIdParam) {
        const selectedFacility = (filtered as StatsAggregate).facilities.find(
          f => f.id === +facilityIdParam
        );
        if (selectedFacility) {
          filtered = selectedFacility;
          if (pitchIdParam) {
            const selectedPitch = (filtered as FacilityAggregate).pitches.find(
              p => p.id === +pitchIdParam
            );
            if (selectedPitch) {
              filtered = selectedPitch;
            }
          }
        }
      }
      return filtered;
    },
    []
  );

  const filteredData = useMemo(() => {
    return data ? filterData(data, facility?.id, pitchId) : null;
  }, [data, facility?.id, filterData, pitchId]);

  const comparisonData = useMemo(() => {
    return data && comparisonPitchId
      ? filterData(data, facility?.id, comparisonPitchId)
      : null;
  }, [comparisonPitchId, data, facility?.id, filterData]);

  useEffect(() => {
    if (filteredData) {
      handleGraphResize();
    }
  }, [filteredData, handleGraphResize]);

  return (
    <>
      <SectionHeader title="Key statistics">
        <div className="flex flex-row w-full sm:space-x-2 sm:w-auto sm:ml-auto">
          <ComparisonField
            pitchesOptions={pitchesOptions}
            onComparison={selectedPitchId =>
              setComparisonPitchId(selectedPitchId)
            }
          />
          <div className="w-full sm:w-36">
            <Select
              options={timePeriodOptions}
              selectedValue={selectedOption}
              onChange={handleTimePeriodChange}
              shadow
            />
          </div>
        </div>
      </SectionHeader>

      {isLoading || !filteredData ? (
        <div className={statsMessageClass}>
          {isLoading ? <Loading /> : 'No stats data found'}
        </div>
      ) : (
        <div className={facility ? statsClass : statsClassFull}>
          <KeyStatistic
            data={filteredData.playerHours}
            comparisonData={comparisonData?.playerHours}
            width={width}
            timePeriod={selectedTimePeriod ?? defaultTimePeriod}
            componentRef={componentRef}
            keyStatisticType={KeyStatisticType.PlayerHours}
          />
          <KeyStatistic
            data={filteredData.usageHours}
            comparisonData={comparisonData?.usageHours}
            width={width}
            timePeriod={selectedTimePeriod ?? defaultTimePeriod}
            keyStatisticType={KeyStatisticType.UsageHours}
          />
          <KeyStatistic
            data={filteredData.euh}
            comparisonData={comparisonData?.euh}
            width={width}
            timePeriod={selectedTimePeriod ?? defaultTimePeriod}
            keyStatisticType={KeyStatisticType.EquivalentUserHours}
          />
          <KeyStatistic
            data={filteredData.maintenanceHours}
            comparisonData={comparisonData?.maintenanceHours}
            width={width}
            timePeriod={selectedTimePeriod ?? defaultTimePeriod}
            keyStatisticType={KeyStatisticType.MaintenanceHours}
          />
          {!facility && (
            <>
              <KeyStatistic
                data={(filteredData as StatsAggregate).overdueMaintenanceDays}
                width={width}
                timePeriod={selectedTimePeriod ?? defaultTimePeriod}
                keyStatisticType={KeyStatisticType.OverdueMaintananeDays}
              />
              <KeyStatistic
                data={(filteredData as StatsAggregate).outOfHoursAlerts}
                width={width}
                timePeriod={selectedTimePeriod ?? defaultTimePeriod}
                keyStatisticType={KeyStatisticType.OutOfHoursAlerts}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};
