/* eslint-disable max-len */
import React, { FC, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { AuthLayout, AuthText, AuthTitle } from '~/components/auth';
import { Button } from '~/components/button';
import { TextInput } from '~/components/forms';
import { RoutesPath } from '~/types';
import { postResetPassword } from '~/api';

import check from 'assets/images/pages/login/icon__check-circle.png';
import check2x from 'assets/images/pages/login/icon__check-circle@2x.png';
import { usePageTitle } from '~/hooks';
import { AxiosError } from 'axios';

type FormValues = {
  password: string;
  confirmPassword: string;
};

export const ResetPasswordPage: FC = () => {
  usePageTitle('Reset Password - Intelligent Play');
  const history = useHistory();
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
  } = useForm();

  const { mutate: mutateResetPassword, isLoading } = useMutation(
    postResetPassword,
    {
      onSuccess: () => {
        setError(null);
        setIsPasswordReset(true);
      },
      onError: (err: AxiosError) => {
        setError(err);
      },
    }
  );

  const onSubmitHandler: SubmitHandler<FormValues> = (data): void => {
    mutateResetPassword({ password: data.password, token: token || '' });
  };

  const returnToLoginHandler = (): void => {
    history.push(RoutesPath.Login);
  };

  return (
    <AuthLayout>
      {!isPasswordReset ? (
        <>
          <AuthTitle text="Create a new password" />
          <AuthText text="Password must be 8 characters long." />
          <TextInput
            label="New password"
            placeholder="Enter password"
            required={true}
            type="password"
            errorMessage={errors.password?.message}
            register={register('password', {
              minLength: {
                value: 8,
                message: 'Your password must be at least 8 characters long.',
              },
            })}
          />
          <TextInput
            label="Confirm new password"
            placeholder="Enter password"
            required={true}
            type="password"
            errorMessage={errors.confirmPassword?.message}
            register={register('confirmPassword', {
              validate: value =>
                value === getValues().password || 'The passwords do not match',
            })}
          />
          <Button
            onClick={handleSubmit(onSubmitHandler)}
            text="Create password"
            disabled={!dirtyFields.password || !dirtyFields.confirmPassword}
            disabledStyle="dark"
            className="mt-auto lg:mt-12.5"
            isLoading={isLoading}
          />
          {error && (
            <div>
              <p className="mt-4 text-red">
                {getErrorMessage(error)}{' '}
                {isTokenExpiredError(error) && (
                  <NavLink
                    to={RoutesPath.ForgotPassword}
                    className="text-left text-green cursor-pointer hover:text-green-dark"
                  >
                    here
                  </NavLink>
                )}
              </p>
            </div>
          )}
          <NavLink
            to={RoutesPath.Login}
            className="mt-10 text-base text-left text-green cursor-pointer hover:text-green-dark"
          >
            Return to sign in
          </NavLink>
        </>
      ) : (
        <>
          <AuthTitle
            badge={check}
            badge2x={check2x}
            text="Password successfully reset"
          />
          <AuthText text="Your new password has been updated for your account" />
          <AuthText text="Please login using your new password" />
          <Button
            onClick={returnToLoginHandler}
            text="Return to sign in"
            className="mt-auto lg:mt-12.5"
          />
        </>
      )}
    </AuthLayout>
  );
};

const getErrorMessage = (error: AxiosError): string => {
  if (isTokenExpiredError(error)) {
    return 'Invite link expired. You can request a new password reset link';
  } else {
    return 'An error occured when setting the new password';
  }
};

const isTokenExpiredError = (error: AxiosError): boolean => {
  return error.message.includes('401');
};
