import React, { FC, useEffect, useMemo, useState } from 'react';
import { User, UserType } from '@intelligent-play-v2/domain';
import { usePagination } from '~/hooks';
import { SearchBar } from '~/components/searchBar';
import { Select, SelectOptionType } from '~/components/select';
import { PageControls } from '~/components/table/PageControls';
import { UserChecklist } from '~/components/admin/userChecklist';
import { sortUsers } from '~/utils';
import { Button, ButtonType } from '~/components/button';

interface AdminUpdateTeamProps {
  users: User[];
  checkedUsers: Record<string, number[]>;
  setIsChecked: (newValue: boolean, userId: number) => void;
  handleSave?: () => void;
  isSaving: boolean;
  pitchId?: string; // optional to be used when using as a update pitch team component
}
export const AdminUpdateTeam: FC<AdminUpdateTeamProps> = ({
  users,
  checkedUsers,
  setIsChecked,
  handleSave,
  isSaving,
  pitchId,
}) => {
  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState<SelectOptionType>(sortingOptions[0]);
  const [sortedUsers, setSortedUsers] = useState<User[]>([]);
  const [sortedCheckedUsers, setSortedCheckedUsers] = useState<User[]>([]);

  useEffect(() => {
    const filteredUsers =
      users.filter(
        user =>
          user.name?.toUpperCase().includes(searchText.toUpperCase()) &&
          user.userTypeId !== UserType.SystemAdmin
      ) ?? [];

    sortUsers(filteredUsers, sortBy);

    setSortedUsers(filteredUsers);
  }, [searchText, sortBy, users]);

  const checkUsers = useMemo(() => {
    if (pitchId) {
      return checkedUsers[pitchId] ?? [];
    } else {
      return Object.values(checkedUsers).reduce((arr: number[], userIds) => {
        userIds.forEach(userId => {
          if (!arr.includes(userId)) {
            arr.push(userId);
          }
        });
        return arr;
      }, []);
    }
  }, [checkedUsers, pitchId]);

  useEffect(() => {
    const checkedFilter = sortedUsers.sort((a, b) => {
      const isAChecked = checkUsers.includes(a.id) ? 1 : 0;
      const isBChecked = checkUsers.includes(b.id) ? 1 : 0;
      return isAChecked <= isBChecked ? 1 : -1;
    });
    setSortedCheckedUsers(checkedFilter);
  }, [sortedUsers, checkUsers]);

  const { paginatedItems, pageNumber, setPageNumber } = usePagination(
    sortedCheckedUsers,
    40
  );

  const paginatedUsers = paginatedItems as User[][];

  return (
    <div className="mt-10 text-primary-900 bg-white rounded shadow-250">
      <div className="flex items-center px-5 py-3 border-b border-primary-100">
        <div className="text-lg font-semibold">{`${
          pitchId ? 'Pitch' : 'Facility'
        } team`}</div>
        <div className="ml-4 text-sm font-medium text-primary-400">
          Selected:{' '}
          <span className="text-primary-900">{checkUsers.length}</span>
        </div>
        {handleSave && (
          <div className="w-24 ml-auto">
            <Button
              text="Save"
              size="xsmall"
              type={ButtonType.Secondary}
              isLoading={isSaving}
              onClick={handleSave}
            />
          </div>
        )}
      </div>
      <div className="p-5">
        <div className="flex space-x-4">
          <SearchBar
            searchText={searchText}
            onChange={setSearchText}
            bordered
          />
          <div className="w-44">
            <Select
              key="sort"
              options={sortingOptions}
              selectedValue={sortBy}
              onChange={option => setSortBy(option || sortingOptions[0])}
              shadow
              bordered
              bigger
            />
          </div>
        </div>
        {paginatedUsers.length > 0 && (
          <UserChecklist
            users={paginatedUsers[pageNumber - 1]}
            checkedUsers={checkedUsers}
            setIsChecked={setIsChecked}
            pitchId={pitchId}
          />
        )}
        <PageControls
          pageNumber={pageNumber}
          totalPages={paginatedItems.length}
          setPageNumber={setPageNumber}
        />
      </div>
    </div>
  );
};

const sortingOptions: SelectOptionType[] = [
  {
    label: 'A-Z',
    value: 'a-z',
  },
  {
    label: 'Z-A',
    value: 'z-a',
  },
];
