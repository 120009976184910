/* eslint-disable max-len */
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { AuthLayout, AuthText, AuthTitle } from '~/components/auth';
import { Button } from '~/components/button';
import check from 'assets/images/pages/login/icon__check-circle.png';
import check2x from 'assets/images/pages/login/icon__check-circle@2x.png';

import { RoutesPath } from '~/types';
import { postForgotPassword } from '~/api';
import { usePageTitle } from '~/hooks';
import { TextInput } from '~/components/forms';

type FormValues = {
  email: string;
  confirmEmail: string;
};

export const ForgotPasswordPage: FC = () => {
  usePageTitle('Forgot Password - Intelligent Play');
  const history = useHistory();
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
  } = useForm();

  const { mutate: mutateForgotPassword, isLoading } = useMutation(
    postForgotPassword,
    {
      onSuccess: () => {
        setErrorMessage('');
        setIsLinkSent(true);
      },
      onError: () => {
        setErrorMessage('An error occured');
      },
    }
  );

  const onSubmitHandler: SubmitHandler<FormValues> = (data): void => {
    mutateForgotPassword({ username: data.email });
  };

  return (
    <AuthLayout>
      {!isLinkSent ? (
        <>
          <AuthTitle text="Forgotten password" />
          <AuthText text="We'll send you an email with a link to reset your password." />
          <TextInput
            register={register('email', {
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: 'Please enter a valid email address',
              },
            })}
            label="Email address"
            placeholder="Enter email"
            required={true}
            type="email"
            errorMessage={errors.email?.message}
          />
          <TextInput
            label="Confirm email address"
            placeholder="Enter email"
            required={true}
            type="email"
            errorMessage={errors.confirmEmail?.message}
            register={register('confirmEmail', {
              validate: value =>
                value === getValues().email || 'The emails do not match',
            })}
          />
          <Button
            onClick={handleSubmit(onSubmitHandler)}
            text="Send link"
            disabled={!dirtyFields.email || !dirtyFields.confirmEmail}
            disabledStyle="dark"
            className="mt-auto lg:mt-12.5"
            isLoading={isLoading}
          />
        </>
      ) : (
        <>
          <AuthTitle badge={check} badge2x={check2x} text="Reset link sent" />
          <AuthText
            text={`A password reset link has been sent to ${getValues().email}`}
          />
          <AuthText text="If you cannot see the email, remember to check your spam or junk inbox. Click the link within the email within 24 hours to be redirected back to the site and choose a new password." />
        </>
      )}
      {errorMessage && <p className="mt-4 text-red">{errorMessage}</p>}
      <button
        onClick={() => history.push(RoutesPath.Login)}
        className="mt-10 text-base text-left text-green cursor-pointer hover:text-green-dark"
      >
        Return to sign in
      </button>
    </AuthLayout>
  );
};
