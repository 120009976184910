import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { format, isToday, parseISO } from 'date-fns';
import { RoutesPath } from '~/types';

import { AlertType, UserAlert } from '@intelligent-play-v2/domain';
import { useMutation } from 'react-query';
import { putDismissUserAlert } from '~/api';
import { useAlertStore } from '~/stores';
import { Loading } from '~/components/loading';
import startCase from 'lodash.startcase';
import { formatDateGbUs } from '~/utils';
import capitalize from 'lodash.capitalize';

const getAlertTypeLabel = (alertType: AlertType): string => {
  switch (alertType) {
    case AlertType.CalendarRecurrenceEventAssigned:
      return 'Recurring calendar event assigned';
    default:
      return capitalize(startCase(AlertType[alertType]));
  }
};

export const UserAlertRow: FC<{ alert: UserAlert }> = ({
  alert: {
    alertId,
    alertType,
    sentAt,
    dueDate,
    facilityId,
    pitchId,
    facilityName,
    pitchName,
    eventTitle,
  },
}) => {
  const { removeUserAlert } = useAlertStore();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatAlertTime = (alertTime: any): string => {
    const time = parseISO(alertTime);

    if (isToday(time)) {
      return format(time, 'HH:mm');
    } else {
      return formatDateGbUs(time, 'dd MMM');
    }
  };

  let pitchRoute = RoutesPath.PitchUsage.replace(
    ':facilityId',
    facilityId.toString()
  ).replace(':pitchId', pitchId.toString());

  if (eventTitle) {
    pitchRoute = `${pitchRoute}/calendar?date=${new Date(
      dueDate
    ).toISOString()}`;
  }

  const { mutate: dismissAlert, isLoading } = useMutation(putDismissUserAlert, {
    onSuccess: () => {
      removeUserAlert(alertId);
    },
  });

  return (
    <Link to={pitchRoute}>
      <div className="p-3 border-b border-primary-200 h-18">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="flex flex-row justify-between h-full space-x-2">
            {/* alert content */}
            <div className="flex flex-col w-8/12 space-y-px">
              <span className="text-sm">
                <span className="font-semibold">{facilityName}</span>
                <span className="text-primary-600"> {pitchName}</span>
              </span>
              <span className="text-base font-bold">
                {getAlertTypeLabel(alertType)}
                {eventTitle && (
                  <span className="font-normal"> - {eventTitle}</span>
                )}
              </span>
            </div>

            {/* timestamp, dismiss */}
            <div className="flex flex-col items-end justify-between">
              <span className="text-sm text-primary-600">
                {formatAlertTime(sentAt)}
              </span>
              <span
                className="text-sm font-semibold text-blue-dark hover:text-opacity-80"
                onClick={e => {
                  e.preventDefault();
                  dismissAlert(alertId);
                }}
              >
                Dismiss
              </span>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};
