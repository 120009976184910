import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  differenceInHours,
  format,
  isSameDay,
  subMilliseconds,
} from 'date-fns';
import { CalendarEvent } from '@intelligent-play-v2/domain';
import { formatDateGbUs } from '~/utils';
import { RoutesPath } from '~/types';
import eventIcon from 'assets/images/pages/field/next_event/icon__next-event.png';
import eventIcon2x from 'assets/images/pages/field/next_event/icon__next-event@2x.png';

interface NextEventProps {
  calendarEvent: CalendarEvent | null;
  facilityId: number;
}

export const NextEvent: FC<NextEventProps> = ({
  calendarEvent,
  facilityId,
}) => {
  if (!calendarEvent) {
    return null;
  }
  const startDate = new Date(calendarEvent.startDate);
  const endDate = new Date(calendarEvent.endDate);
  const startDay = formatDateGbUs(startDate, 'E d MMM');
  const endDay = formatDateGbUs(endDate, 'E d MMM');
  const startTime = format(startDate, 'HH:mm');
  const endTime = format(endDate, 'HH:mm');

  return (
    <Link
      to={RoutesPath.PitchCalendar.replace(
        ':facilityId',
        facilityId.toString()
      ).replace(':pitchId', calendarEvent.pitchId.toString())}
      className="flex items-center w-full mt-2 bg-white rounded shadow-250 p-2.5 text-base font-semibold justify-between md:justify-start"
    >
      <div className="flex items-center">
        <div className="bg-green-lightest rounded w-7.5 h-7.5 flex items-center justify-center">
          <picture>
            <img
              src={eventIcon}
              srcSet={`${eventIcon} 1x, ${eventIcon2x} 2x`}
            />
          </picture>
        </div>
        <div className="ml-2 text-green">Next event</div>
      </div>
      <div className="ml-2 md:ml-7.5">
        {isSameDay(startDate, subMilliseconds(endDate, 1)) ? (
          <>
            {startDay}
            <span className="ml-2 font-normal">
              {differenceInHours(endDate, startDate) === 24
                ? 'All day'
                : `${startTime} - ${endTime}`}
            </span>
          </>
        ) : (
          <>
            <span className="whitespace-nowrap">{startDay}</span> -{' '}
            <span className="whitespace-nowrap">{endDay}</span>
          </>
        )}
      </div>
      <div className="ml-2 md:ml-7.5">{calendarEvent.title}</div>
    </Link>
  );
};
