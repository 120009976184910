import { TriggerSnapshotAccessResponse } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const requestTriggerSnapshotAccess = async (): Promise<TriggerSnapshotAccessResponse> => {
  const { data } = await queryBuilder(
    'POST',
    `snapshots/requestTriggerSnapshotAccess`
  );
  return data;
};
