import { HeatmapResponse, TimePeriod } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface HeatmapParams {
  pitchId: number;
  timePeriod: TimePeriod;
  timezone: string;
  from?: string;
  to?: string;
  includedDays?: Day[];
  dailyStartTime?: string;
  dailyEndTime?: string;
}

export const getUsageHeatmap = async ({
  pitchId,
  timePeriod,
  from,
  to,
  timezone,
  includedDays,
  dailyStartTime,
  dailyEndTime,
}: HeatmapParams): Promise<HeatmapResponse> => {
  const { data } = await queryBuilder(
    'GET',
    `pitches/${pitchId}/usageHeatmap`,
    {
      timePeriod,
      from,
      to,
      timezone,
      includedDays: includedDays?.join(','),
      dailyStartTime,
      dailyEndTime,
    }
  );
  return data;
};
