import { useState } from 'react';
import {
  toggleCheckedItem,
  toggleCheckedItems,
} from '~/utils/checkedArrayHelpers';

interface CheckedArrayHook {
  checkedItems: number[];
  setCheckedItems: React.Dispatch<React.SetStateAction<number[]>>;
  toggleChecked: (item: number) => void;
  toggleCheckedMany: (items: number[], newCheckedValue: boolean) => void;
}

export const useCheckedArray = (
  initialCheckedItems: number[]
): CheckedArrayHook => {
  const [checkedItems, setCheckedItems] = useState<number[]>(
    initialCheckedItems
  );

  const toggleCheckedMany = (
    items: number[],
    newCheckedValue: boolean
  ): void => {
    setCheckedItems(toggleCheckedItems(items, newCheckedValue, checkedItems));
  };

  const toggleChecked = (item: number): void => {
    setCheckedItems(toggleCheckedItem(item, checkedItems));
  };

  return {
    checkedItems,
    setCheckedItems,
    toggleCheckedMany,
    toggleChecked,
  };
};
