import {
  PitchMaintenanceStatus,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface PitchMaintenanceStatusParams {
  pitchId: string;
  timePeriod: TimePeriod;
  timezone: string;
  from?: string;
  to?: string;
}

export const getPitchMaintenanceStatus = async ({
  pitchId,
  timezone,
  timePeriod,
  from,
  to,
}: PitchMaintenanceStatusParams): Promise<PitchMaintenanceStatus[]> => {
  const { data } = await queryBuilder(
    'GET',
    `pitches/${pitchId}/maintenanceStatus`,
    {
      from,
      to,
      timezone,
      timePeriod,
    }
  );
  return data;
};
