export const seriesColors = [
  '#519C42',
  '#4054B2',
  '#E24259',
  '#B68D40',
  '#805AE9',
  '#46c7e0',
  '#030A2E',
  '#ff69b4',
  '#eee8aa',
  '#2f4f4f',
  '#760188',
  '#ffff00',
  '#498c3b',
  '#3a4ca0',
  '#cb3b50',
  '#a47f3a',
  '#7351d2',
  '#e65fa2',
  '#6a017a',
  '#e6e600',
  '#417d35',
  '#33438e',
  '#b53547',
  '#927133',
  '#6648ba',
  '#cc5490',
  '#5e016d',
  '#cccc00',
  '#396d2e',
  '#2d3b7d',
  '#9e2e3e',
  '#7f632d',
  '#5a3fa3',
  '#b34a7e',
  '#53015f',
  '#b3b300',
  '#315e28',
  '#26326b',
  '#882835',
  '#6d5526',
  '#4d368c',
  '#993f6c',
  '#470152',
  '#999900',
  '#294e21',
  '#202a59',
  '#71212d',
  '#5b4720',
  '#402d75',
  '#80355a',
  '#3b0144',
  '#808000',
];

export const getSeriesColor = (index: number): string => {
  return seriesColors[index % seriesColors.length];
};
