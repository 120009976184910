import {
  CalendarEvent,
  PatchCalendarEventBody,
} from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface PatchCalendarEventParams extends PatchCalendarEventBody {
  id: number;
}

export const patchCalendarEvent = async (
  params: PatchCalendarEventParams
): Promise<CalendarEvent> => {
  const { attachments, ...rest } = params;

  const { data } = await queryBuilder(
    'PATCH',
    `calendar/event/${params.id}`,
    undefined,
    rest,
    attachments
  );
  return data;
};
