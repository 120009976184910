/* eslint-disable react/display-name */
import React from 'react';
import { TableColumn } from '../Table';
import { FacilityName } from '../facilityTable/FacilityName';
import fieldIcon from 'assets/images/pages/admin/icon__facility-fields.png';
import fieldIcon2x from 'assets/images/pages/admin/icon__facility-fields@2x.png';
import liveDateIcon from 'assets/images/pages/admin/icon__facility-live-date.png';
import liveDateIcon2x from 'assets/images/pages/admin/icon__facility-live-date@2x.png';
import { FacilityInfo } from './FacilityInfo';
import { FacilityUsers } from '../facilityTable/FacilityUsers';
import { Link } from 'react-router-dom';
import { AdminFacilityRow, RoutesPath } from '~/types';
import editIcon from 'assets/images/pages/admin/icon__user-edit.png';
import editIcon2x from 'assets/images/pages/admin/icon__user-edit@2x.png';
import { formatDateGbUs, getFacilityInstallDate } from '~/utils';

export const adminFacilityColumns = (width: string): TableColumn[] => {
  const isFullWidth = width === 'full';

  return [
    {
      heading: 'Facility name',
      widthClass: `${
        isFullWidth ? 'w-9/16 lg:w-6/16 xl:6/16 2xl:w-6/16' : 'w-9/16'
      }`,
      value: (adminFacilityRow: AdminFacilityRow) => (
        <FacilityName facility={adminFacilityRow} />
      ),
    },
    {
      heading: 'Facility info',
      widthClass: isFullWidth ? 'hidden lg:w-7/16 lg:block' : 'hidden',
      value: (adminFacilityRow: AdminFacilityRow) => (
        <div className="flex lg:space-x-8 xl:space-x-20">
          <FacilityInfo
            text={adminFacilityRow.pitches?.length.toString() || '0'}
            title="Fields"
            icon={fieldIcon}
            icon2x={fieldIcon2x}
          />
          <FacilityInfo
            text={formatDateGbUs(
              getFacilityInstallDate(adminFacilityRow),
              'd MMM yyyy'
            )}
            title="Dashboard live date"
            icon={liveDateIcon}
            icon2x={liveDateIcon2x}
          />
        </div>
      ),
    },
    {
      heading: 'All users',
      widthClass: isFullWidth ? 'w-6/16 lg:w-2/16' : 'w-6/16',
      value: (adminFacilityRow: AdminFacilityRow) => (
        <FacilityUsers users={adminFacilityRow.staff ?? []} />
      ),
    },
    {
      heading: '',
      widthClass: 'w-1/16',
      value: (adminFacilityRow: AdminFacilityRow) => (
        <div className="flex">
          <Link
            className={'flex-shrink-0 ml-auto'}
            to={{
              pathname: RoutesPath.AdminFacility.replace(
                ':facilityId',
                adminFacilityRow.id.toString()
              ),
            }}
          >
            <picture className="">
              <img src={editIcon} srcSet={`${editIcon} 1x, ${editIcon2x} 2x`} />
            </picture>
          </Link>
        </div>
      ),
    },
  ];
};
