import React, { FC } from 'react';
import { match, matchPath, useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import H from 'history';
import mobile from 'is-mobile';

import { useMetadataStore } from '~/stores';
import { RoutesPath } from '~/types';
import { FacilityContentRouter } from '~/router/FacilityContentRouter';
import { PromptModal } from '~/components/modals';
import { Button } from '~/components/button';
import { HeaderTab, PageHeaderWithTabs } from '~/components/layout';
import { usePageTitle } from '~/hooks';
import { sortByPitchNumber } from '~/utils';
import { useMediaQuery } from 'react-responsive';

export const FacilityPage: FC = () => {
  const { facilities } = useMetadataStore();
  const history = useHistory();
  const { facilityId } = useParams<{
    facilityId: string;
  }>();

  const selectedFacility = facilities.find(f => f.id === +facilityId);
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();
  const location = useLocation();
  const isMatchingPitchUrl = matchPath(
    location.pathname,
    RoutesPath.PitchUsage
  );

  usePageTitle(`${selectedFacility?.name} - Intelligent Play`);

  const renderPitchesHeaderTabs = (): JSX.Element | null => {
    if (selectedFacility && selectedFacility.pitches) {
      const sortedPitches = selectedFacility.pitches.sort(sortByPitchNumber);
      return (
        <>
          {sortedPitches.map(pitch => (
            <HeaderTab
              key={pitch.id}
              title={pitch.name || ''}
              path={RoutesPath.PitchUsage.replace(
                ':facilityId',
                facilityId
              ).replace(':pitchId', pitch.id.toString())}
            />
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  const overviewIsActive = <Params extends { [K in keyof Params]?: string }>(
    matchParam: match<Params> | null,
    loc: H.Location<H.LocationState>
  ): boolean => {
    if (!matchParam) {
      return false;
    }
    if (
      loc.pathname.includes('pitches') &&
      !matchParam.path.includes('pitches')
    ) {
      return false;
    } else {
      return true;
    }
  };

  if (!selectedFacility) {
    return (
      <PromptModal
        title="Facility not found"
        type="danger"
        text="This facility does not exist, or you do not have permission to view it"
        primaryButton={
          <Button
            text="Close"
            size="small"
            onClick={() => history.push(RoutesPath.Facilities)}
          />
        }
        showModal={true}
      />
    );
  }
  return (
    <>
      {!(isMobile && isMatchingPitchUrl) && (
        <PageHeaderWithTabs title={selectedFacility?.name || ''} showWeather>
          <div className="flex sm:hidden">
            <HeaderTab
              title={'Feed'}
              path={RoutesPath.FacilityOverviewFeed.replace(
                ':facilityId',
                facilityId
              )}
            />
            <HeaderTab
              title={'Key statistics'}
              path={RoutesPath.FacilityOverviewKeyStats.replace(
                ':facilityId',
                facilityId
              )}
              exact
              strict
            />
            <HeaderTab
              title={'Fields'}
              path={RoutesPath.FacilityOverviewFields.replace(
                ':facilityId',
                facilityId
              )}
              exact
              strict
            />
            <HeaderTab
              title={'Weather'}
              path={RoutesPath.FacilityWeather.replace(
                ':facilityId',
                facilityId
              )}
              exact
              strict
            />
          </div>
          <div className="hidden sm:flex">
            <HeaderTab
              strict
              title="Overview"
              path={RoutesPath.FacilityOverview.replace(
                ':facilityId',
                facilityId
              )}
              isActive={overviewIsActive}
            />
            {renderPitchesHeaderTabs()}
          </div>
        </PageHeaderWithTabs>
      )}
      <FacilityContentRouter />
    </>
  );
};
