import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';

interface NavButtonProps {
  label: 'Next 7 days' | 'Previous 7 days';
  onClick: () => void;
}
export const NavButton: FC<NavButtonProps> = ({ onClick, label }) => {
  const isNext = label === 'Next 7 days';
  const layoutStyles = classnames({
    'flex-row': !isNext,
    'flex-row-reverse': isNext,
  });

  const arrowStyles: React.CSSProperties = {
    border: 'solid white',
    borderWidth: '0 1px 1px 0',
    display: 'inline-block',
    padding: 1.5,
    marginRight: isNext ? 2 : -1,
    transform: isNext ? 'rotate(-45deg)' : 'rotate(135deg)',
    WebkitTransform: isNext ? 'rotate(-45deg)' : 'rotate(135deg)',
  };

  return (
    <div
      className={`flex space-x-1 cursor-pointer ${layoutStyles}`}
      onClick={onClick}
    >
      <div className="grid w-3 h-3 transform translate-y-1 bg-blue rounded-full place-items-center">
        <div style={arrowStyles} />
      </div>
      <span className="pr-1 text-blue transform translate-y-px">{label}</span>
    </div>
  );
};
