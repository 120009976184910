import React, { FC } from 'react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { classnames } from 'tailwindcss-classnames';

import scheduledIcon from 'assets/images/pages/field/snapshots/icon__snapshot-automated.png';
import scheduledIcon2x from 'assets/images/pages/field/snapshots/icon__snapshot-automated@2x.png';
import triggeredIcon from 'assets/images/pages/field/snapshots/icon__snapshot-manual.png';
import triggeredIcon2x from 'assets/images/pages/field/snapshots/icon__snapshot-manual@2x.png';
import arrowReturnIcon from 'assets/images/pages/field/snapshots/icon__arrow-return.png';
import arrowReturnIcon2x from 'assets/images/pages/field/snapshots/icon__arrow-return@2x.png';

import {
  SnapshotReason,
  Snapshot as SnapshotType,
} from '@intelligent-play-v2/domain';
import { SnapshotTypeIcon } from './SnapshotTypeIcon';
import { formatDateGbUs } from '~/utils';

interface SnapshotProps {
  snapshot: SnapshotType;
  timezone: string;
  onClick: () => void;
}

export const SnapshotFullSize: FC<SnapshotProps> = ({
  snapshot,
  timezone,
  onClick,
}) => {
  const { reason, imageUrl, timestamp } = snapshot;

  let icon, icon2x;
  switch (reason as SnapshotReason) {
    case SnapshotReason.scheduled:
      icon = scheduledIcon;
      icon2x = scheduledIcon2x;
      break;
    case SnapshotReason.triggered:
      icon = triggeredIcon;
      icon2x = triggeredIcon2x;
      break;
    default:
      icon = scheduledIcon;
      icon2x = scheduledIcon2x;
  }

  const imageContainerColour = classnames(
    { 'bg-blue': reason === SnapshotReason.triggered },
    { 'bg-green-dark': reason !== SnapshotReason.triggered }
  );

  return (
    <div className="relative">
      <img src={imageUrl} />
      <div
        onClick={onClick}
        className="absolute flex items-center justify-center p-2 space-x-2 bg-white rounded-sm shadow-250 cursor-pointer top-3 left-3 hover:bg-primary-100"
      >
        <picture>
          <img
            src={arrowReturnIcon}
            srcSet={`${arrowReturnIcon} 1x, ${arrowReturnIcon2x} 2x`}
          />
        </picture>
        <span className="text-sm font-bold">Return to snapshots</span>
      </div>

      <div className="absolute flex flex-row p-2 space-x-2 text-sm font-bold bg-white rounded-sm shadow-250 bottom-3 left-3">
        <SnapshotTypeIcon
          icon={icon}
          icon2x={icon2x}
          imageContainerColour={imageContainerColour}
        />
        <div className="flex flex-col">
          <span className="text-base">
            <span>
              {formatDateGbUs(utcToZonedTime(timestamp, timezone), 'E d MMM')}
            </span>
            <span className="font-normal">
              {format(utcToZonedTime(timestamp, timezone), ' @ k:mm')}
            </span>
          </span>
          <span className="font-normal">
            <span className="capitalize">{reason}</span> shot
          </span>
        </div>
      </div>
    </div>
  );
};
