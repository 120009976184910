import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import editIcon from 'assets/images/pages/admin/icon__edit-avatar.png';
import editIcon2x from 'assets/images/pages/admin/icon__edit-avatar@2x.png';

interface UserThumbnailProps {
  text?: string;
  zIndex?: number;
  type: UserThumbnailType;
  onClick?: () => void;
  imageSrc?: string;
}

export enum UserThumbnailType {
  UserProfile,
  UsersColumn,
  UserDetails,
  UserCheckbox,
  UserTooltip,
}

export const UserThumbnail: FC<UserThumbnailProps> = ({
  text,
  zIndex,
  type,
  onClick,
  imageSrc,
}) => {
  const thumnbnailClass = classnames(
    { 'w-10': type === UserThumbnailType.UserProfile },
    { 'h-10': type === UserThumbnailType.UserProfile },
    { 'text-lg': type === UserThumbnailType.UserProfile },
    { 'font-semibold': type === UserThumbnailType.UserProfile },
    { 'text-white': type === UserThumbnailType.UserProfile },
    { 'shadow-100': type === UserThumbnailType.UserProfile },
    { 'bg-primary-500': type === UserThumbnailType.UserProfile },
    { 'sm:w-8.5': type === UserThumbnailType.UsersColumn },
    { 'sm:h-8.5': type === UserThumbnailType.UsersColumn },
    { 'w-5': type === UserThumbnailType.UsersColumn },
    { 'h-5': type === UserThumbnailType.UsersColumn },
    { 'text-2xs': type === UserThumbnailType.UsersColumn },
    { 'sm:text-sm': type === UserThumbnailType.UsersColumn },
    { border: type === UserThumbnailType.UsersColumn },
    { 'sm:border-2': type === UserThumbnailType.UsersColumn },
    { 'border-white': type === UserThumbnailType.UsersColumn },
    { 'font-bold': type === UserThumbnailType.UsersColumn },
    { 'text-primary-800': type === UserThumbnailType.UsersColumn },
    { 'shadow-none': type === UserThumbnailType.UsersColumn },
    { 'bg-primary-100': type === UserThumbnailType.UsersColumn },
    { 'w-28': type === UserThumbnailType.UserDetails },
    { 'h-28': type === UserThumbnailType.UserDetails },
    { 'text-5xl': type === UserThumbnailType.UserDetails },
    { 'font-semibold': type === UserThumbnailType.UserDetails },
    { 'text-white': type === UserThumbnailType.UserDetails },
    { 'shadow-100': type === UserThumbnailType.UserDetails },
    { 'bg-primary-500': type === UserThumbnailType.UserDetails },
    { 'w-9': type === UserThumbnailType.UserCheckbox },
    { 'h-9': type === UserThumbnailType.UserCheckbox },
    { 'text-sm': type === UserThumbnailType.UserCheckbox },
    { 'font-semibold': type === UserThumbnailType.UserCheckbox },
    { 'text-white': type === UserThumbnailType.UserCheckbox },
    { 'shadow-100': type === UserThumbnailType.UserCheckbox },
    { 'bg-primary-500': type === UserThumbnailType.UserCheckbox },
    { 'font-bold': type === UserThumbnailType.UserTooltip },
    { 'text-primary-800': type === UserThumbnailType.UserTooltip },
    { 'shadow-none': type === UserThumbnailType.UserTooltip },
    { 'bg-primary-100': type === UserThumbnailType.UserTooltip },
    { 'w-7.5': type === UserThumbnailType.UserTooltip },
    { 'h-7.5': type === UserThumbnailType.UserTooltip },
    { 'text-sm': type === UserThumbnailType.UserTooltip },
    { 'cursor-pointer': !!onClick }
  );
  return (
    <div
      className={`${thumnbnailClass} flex items-center justify-center flex-shrink-0 rounded-full bg relative`}
      style={{ zIndex: zIndex }}
      onClick={onClick}
    >
      {imageSrc ? (
        <picture className="flex w-full h-full overflow-hidden rounded-full">
          <img src={imageSrc} />
        </picture>
      ) : (
        text
      )}
      {type === UserThumbnailType.UserDetails && (
        <div className="absolute top-1 right-1">
          <picture>
            <img src={editIcon} srcSet={`${editIcon} 1x, ${editIcon2x} 2x`} />
          </picture>
        </div>
      )}
    </div>
  );
};
