import React, { FC } from 'react';
import { SportType } from '@intelligent-play-v2/domain';
import {
  getIconForSportType,
  getLocalisedSportName,
} from '~/utils/pitchSportType';
import { Country } from 'countries-and-timezones';

interface PitchAdminPrimaryUseProps {
  sportTypeId: SportType;
  country: Country | null;
}

export const AdminPitchSportType: FC<PitchAdminPrimaryUseProps> = ({
  sportTypeId,
  country,
}) => {
  const [icon, icon2x] = getIconForSportType(sportTypeId);
  const localisedSportName = getLocalisedSportName(sportTypeId, country);

  return (
    <div className="flex items-center justify-center">
      {icon && icon2x && (
        <div className="flex items-center justify-center w-6 h-6 bg-primary-100 rounded-sm">
          <picture>
            <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
          </picture>
        </div>
      )}
      <div className="ml-2 text-base font-semibold leading-none">
        {localisedSportName}
      </div>
    </div>
  );
};
