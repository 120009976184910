import faqSystem from 'assets/images/pages/contact_us/icon__faq-system.png';
import faqSystem2x from 'assets/images/pages/contact_us/icon__faq-system@2x.png';
import faqUsage from 'assets/images/pages/contact_us/icon__faq-usage.png';
import faqUsage2x from 'assets/images/pages/contact_us/icon__faq-usage@2x.png';
import faqMaintenance from 'assets/images/pages/contact_us/icon__faq-maintenance.png';
import faqMaintenance2x from 'assets/images/pages/contact_us/icon__faq-maintenance@2x.png';
import faqFacility from 'assets/images/pages/contact_us/icon__faq-facility.png';
import faqFacility2x from 'assets/images/pages/contact_us/icon__faq-facility@2x.png';
import faqUser from 'assets/images/pages/contact_us/icon__faq-user.png';
import faqUser2x from 'assets/images/pages/contact_us/icon__faq-user@2x.png';

// White icons
import faqSystemWhite from 'assets/images/pages/contact_us/icon__faq-system-white.png';
import faqSystemWhite2x from 'assets/images/pages/contact_us/icon__faq-system-white@2x.png';
import faqUsageWhite from 'assets/images/pages/contact_us/icon__faq-usage-white.png';
import faqUsageWhite2x from 'assets/images/pages/contact_us/icon__faq-usage-white@2x.png';
import faqMaintenanceWhite from 'assets/images/pages/contact_us/icon__faq-maintenance-white.png';
import faqMaintenanceWhite2x from 'assets/images/pages/contact_us/icon__faq-maintenance-white@2x.png';
import faqFacilityWhite from 'assets/images/pages/contact_us/icon__faq-facility-white.png';
import faqFacilityWhite2x from 'assets/images/pages/contact_us/icon__faq-facility-white@2x.png';
import faqUserWhite from 'assets/images/pages/contact_us/icon__faq-user-white.png';
import faqUserWhite2x from 'assets/images/pages/contact_us/icon__faq-user-white@2x.png';

import { FaqCategory, ResourceFaq } from '@intelligent-play-v2/domain';

export const getCategoryIcons = (category: FaqCategory): [string, string] => {
  switch (category) {
    case FaqCategory.SystemUser:
      return [faqUser, faqUser2x];
    case FaqCategory.Usage:
      return [faqUsage, faqUsage2x];
    case FaqCategory.Maintenance:
      return [faqMaintenance, faqMaintenance2x];
    case FaqCategory.Facility:
      return [faqFacility, faqFacility2x];
    case FaqCategory.Dashboard:
      return [faqSystem, faqSystem2x];
    default:
      return ['', ''];
  }
};

export const getWhiteCategoryIcons = (
  category: FaqCategory
): [string, string] => {
  switch (category) {
    case FaqCategory.SystemUser:
      return [faqUserWhite, faqUserWhite2x];
    case FaqCategory.Usage:
      return [faqUsageWhite, faqUsageWhite2x];
    case FaqCategory.Maintenance:
      return [faqMaintenanceWhite, faqMaintenanceWhite2x];
    case FaqCategory.Facility:
      return [faqFacilityWhite, faqFacilityWhite2x];
    case FaqCategory.Dashboard:
      return [faqSystemWhite, faqSystemWhite2x];
    default:
      return ['', ''];
  }
};

export const getEntriesNumber = (
  faqList: ResourceFaq[],
  category: FaqCategory
): number => {
  return faqList.filter(faq => faq.category === category).length;
};
