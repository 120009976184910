export enum RoutesPath {
  Login = '/login',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Dashboard = '/',
  Facilities = '/facilities',
  FacilityOverview = '/facilities/:facilityId',
  FacilityOverviewFeed = '/facilities/:facilityId/feed',
  FacilityOverviewKeyStats = '/facilities/:facilityId/keyStats',
  FacilityOverviewFields = '/facilities/:facilityId/fields',
  FacilityTeam = '/facilities/:facilityId/team',
  FacilityAbout = '/facilities/:facilityId/about',
  FacilityWeather = '/facilities/:facilityId/weather',
  PitchUsage = '/facilities/:facilityId/pitches/:pitchId',
  PitchStats = '/facilities/:facilityId/pitches/:pitchId/stats',
  PitchHeatmap = '/facilities/:facilityId/pitches/:pitchId/heatmap',
  PitchSnapshots = '/facilities/:facilityId/pitches/:pitchId/snapshots',
  PitchMaintenance = '/facilities/:facilityId/pitches/:pitchId/maintenance',
  PitchFieldTesting = '/facilities/:facilityId/pitches/:pitchId/field-testing',
  PitchCalendar = '/facilities/:facilityId/pitches/:pitchId/calendar',
  Calendar = '/calendar',
  Report = '/report',
  ReportResults = '/report/results',
  Resources = '/resources',
  ResourcesTutorials = '/resources/tutorials',
  ResourcesWebinars = '/resources/webinars',
  ResourcesSuppliers = '/resources/suppliers',
  ResourcesFieldManagement = '/resources/field-management',
  Contact = '/contact',
  ContactHelpCentre = '/contact/help-centre',
  ContactContactUs = '/contact/contact-us',
  ContactDetails = '/contact/details',
  Admin = '/admin',
  AdminOverview = '/admin/overview',
  AdminUsers = '/admin/users',
  AdminUserProfile = '/admin/users/:userId',
  AdminFacilities = '/admin/facilities',
  AdminFacility = '/admin/facilities/:facilityId',
  AdminPitch = '/admin/facilities/:facilityId/pitches/:pitchId',
  Settings = '/settings',
  UserProfile = '/user/profile',
}
