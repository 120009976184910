import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { isAfter, isBefore, isSameDay } from 'date-fns';

interface DatePickerDayProps {
  day: Date;
  dateFrom?: Date | undefined;
  dateTo?: Date | undefined;
  selectedDay?: Date;
  size?: 'small' | 'default';
}

export const DatePickerDay: FC<DatePickerDayProps> = ({
  day,
  dateFrom,
  dateTo,
  selectedDay,
  size = 'default',
}) => {
  const date = day.getDate();
  const isStartDate = dateFrom && isSameDay(day, dateFrom);
  const isEndDate = dateTo && isSameDay(day, dateTo);
  const isMiddleDate =
    dateFrom && dateTo && isAfter(day, dateFrom) && isBefore(day, dateTo);
  const isSelected = selectedDay && isSameDay(day, selectedDay);
  const rangeBoxClass = classnames(
    {
      'bg-green-lightest':
        (isMiddleDate || isStartDate || isEndDate) && !!dateFrom && !!dateTo,
    },
    { 'w-full': isMiddleDate },
    'h-7.5',
    { 'w-1/2': isStartDate || isEndDate },
    { 'ml-auto': isStartDate },
    { 'mr-auto': isEndDate }
  );

  const sizeClass = classnames(
    { 'h-11': size === 'default' },
    { 'h-9': size === 'small' },
    { 'w-15': size === 'default' }
  );

  const dayClass = classnames(
    sizeClass,
    { 'bg-green': isStartDate || isEndDate || isSelected },
    { 'hover:bg-green-lightest': !(isStartDate || isEndDate || isSelected) },
    { 'max-w-11': size === 'default' },
    { 'max-w-9': size === 'small' },
    { 'w-9': size === 'small' },
    { 'text-primary-900': isMiddleDate },
    { 'text-white': isSelected },
    'rounded-full',
    'flex',
    'items-center',
    'justify-center',
    'mx-auto',
    'font-semibold'
  );

  return (
    <div className={`relative flex items-center justify-center ${sizeClass}`}>
      <div className={rangeBoxClass} />
      <div className="absolute inset-0">
        <div className={dayClass}>{date}</div>
      </div>
    </div>
  );
};
