import React, { FC } from 'react';

export const ContentTabs: FC = ({ children }) => {
  return (
    <div className="border-b border-primary-900 border-opacity-10 mt-10 lg:mt-12.5">
      <ul className="h-7.5 flex overflow-x-auto whitespace-nowrap">
        {children}
      </ul>
    </div>
  );
};
