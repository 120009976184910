/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, RefObject, useMemo } from 'react';
import { Bar, Line, LinePath } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import { curveMonotoneX } from '@visx/curve';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { bisector } from 'd3-array';
import { Tooltip, defaultStyles, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { classnames } from 'tailwindcss-classnames';

import { formatNumber } from '~/utils';
import { DatePoint, ToggleableDataSeries, ToolTipDatePoint } from '~/types';
import {
  datePointToDecimalTime,
  dateToDecimalTime,
  decimalTimeToHumanTime,
  getHoursValue,
  getTicksForDay,
  humanTimeToDecimalTime,
} from './utils';
import { useReportQueryParams } from '~/hooks/useReportQueryParams.hook';

interface DayGraphProps {
  dataSeries: ToggleableDataSeries[];
  maxSeriesValue: number;
  primaryAxisLabel: string;
  width: number;
  height: number;
  componentRef?: RefObject<HTMLDivElement>;
}

export const DayGraph: FC<DayGraphProps> = ({
  dataSeries,
  maxSeriesValue,
  primaryAxisLabel,
  width,
  height,
  componentRef,
}) => {
  // STYLES
  const lightGrey = '#e0e0e0';
  const margin = {
    top: 20,
    bottom: 70,
    left: width > 500 ? 120 : 40,
    right: 50,
  };
  const green = '#519c42';
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const tooltipStyles = {
    ...defaultStyles,
    background: 'white',
    color: 'black',
    border: `${green} solid 1px`,
    transform: 'translateX(-75%)',
    padding: '2px 4px',
    boxShadow: 'none',
    zIndex: 999,
  };
  const tooltipTextClass = classnames(
    'text-green',
    'text-xs',
    'font-semibold',
    'whitespace-nowrap'
  );

  const bisect = bisector<DatePoint, number>(d => datePointToDecimalTime(d))
    .left;

  const { dailyStartTime, dailyEndTime } = useReportQueryParams();

  const { ticks, tickLabels, gridTicks } = useMemo(
    () =>
      getTicksForDay(
        dailyStartTime,
        dailyEndTime,
        new Date(dataSeries[0].data[0].date)
      ),
    [dailyEndTime, dailyStartTime, dataSeries]
  );

  const formatXTicksValues = (value: any): string => {
    const tickLabel = tickLabels.find(timeAsDecimal => timeAsDecimal === value);

    if (tickLabel || (tickLabel === 0 && tickLabels[0] === 0)) {
      return decimalTimeToHumanTime(tickLabel);
    }

    return '';
  };

  // SCALES
  const hoursValueScale = useMemo(() => {
    return scaleLinear({
      range: [yMax, 0],
      domain: [0, maxSeriesValue],
      nice: false,
    });
  }, [maxSeriesValue, yMax]);

  const endTime = dailyEndTime === '00:00' ? '24:00' : dailyEndTime;
  const linearScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        domain: [
          humanTimeToDecimalTime(dailyStartTime),
          humanTimeToDecimalTime(endTime),
        ],
        nice: true,
        round: true,
      }),
    [dailyStartTime, endTime, xMax]
  );

  const { tooltipData, tooltipTop, showTooltip, hideTooltip } = useTooltip<
    ToolTipDatePoint[]
  >();

  const handleTooltip = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
  ): void => {
    const scale = hoursValueScale;
    const { x } = localPoint(event) || { x: 0 };
    const x0 = linearScale.invert(x - margin.left);

    const tooltipDataArray = dataSeries.reduce(
      (array: ToolTipDatePoint[], dataSeriesItem) => {
        const index = bisect(dataSeriesItem.data, x0, 1);
        const d0 = dataSeriesItem.data[index - 1];
        const d1 = dataSeriesItem.data[index];
        let d = d0;
        if (d1 && dateToDecimalTime(new Date(d.date))) {
          d =
            x0 - datePointToDecimalTime(d) > datePointToDecimalTime(d) - x0
              ? d1
              : d0;
        }
        if (d) {
          array.push({
            ...d,
            lineColour: dataSeriesItem.color,
            showLine: dataSeriesItem.showLine,
            label: dataSeriesItem.name,
          });
        }
        return array;
      },
      []
    );

    const tooltipSeriesIndex = tooltipDataArray.findIndex(
      dataSeriesItem => dataSeriesItem.showLine
    );
    if (tooltipSeriesIndex === -1) {
      return;
    }

    showTooltip({
      tooltipTop:
        scale(getHoursValue(tooltipDataArray[tooltipSeriesIndex])) + margin.top,
      tooltipData: tooltipDataArray,
      tooltipLeft: margin.left,
    });
  };

  const isTooltipFinalPoint =
    tooltipData &&
    dateToDecimalTime(new Date(tooltipData[0].date)) ===
      humanTimeToDecimalTime(dailyEndTime);
  return (
    <div ref={componentRef} className="relative">
      <svg width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={hoursValueScale}
            width={xMax}
            height={yMax}
            numTicks={6}
            stroke={lightGrey}
          />
          <GridColumns
            scale={linearScale}
            width={xMax}
            height={yMax}
            tickValues={gridTicks}
            stroke={lightGrey}
          />
          <AxisLeft
            scale={hoursValueScale}
            tickClassName={' font-semibold'}
            numTicks={6}
            hideAxisLine
            hideTicks
            tickLabelProps={() => ({
              fontSize: 12,
              textAnchor: 'end',
              verticalAnchor: 'middle',
            })}
          />
          <AxisBottom
            top={yMax}
            scale={linearScale}
            stroke={lightGrey}
            tickClassName={' font-semibold text-sm '}
            tickStroke={lightGrey}
            tickFormat={formatXTicksValues}
            tickValues={ticks}
            tickLabelProps={() => ({
              y: 30,
              textAnchor: 'middle',
              margin: '50px',
            })}
            tickLength={8}
          />
          <text
            x={xMax / 2}
            y={yMax + 55}
            fontSize={14}
            className="font-semibold"
            textAnchor="middle"
          >
            Time
          </text>
          <text
            x={-yMax / 2}
            y={'-60'}
            transform="rotate(-90)"
            fontSize={14}
            className="font-semibold"
            textAnchor="middle"
          >
            {primaryAxisLabel}
          </text>
          {dataSeries
            .filter(({ showLine }) => showLine)
            .map((serie, index) => {
              return (
                <LinePath
                  key={index}
                  data={serie.data}
                  curve={curveMonotoneX}
                  x={d => linearScale(datePointToDecimalTime(d)) ?? 0}
                  y={d => hoursValueScale(getHoursValue(d)) ?? 0}
                  stroke={serie.color}
                  strokeWidth={2}
                />
              );
            })}
          <Bar
            width={xMax}
            height={yMax}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
        </Group>

        {/* horizontal line to tooltip */}
        {tooltipData && (
          <>
            <circle
              cx={
                linearScale(datePointToDecimalTime(tooltipData[0])) +
                  margin.left ?? margin.left
              }
              cy={tooltipTop ?? margin.top}
              r={3}
              fill="transparent"
              stroke={green}
              strokeWidth={1}
              pointerEvents="none"
            />
            {!isTooltipFinalPoint && (
              <Line
                from={{
                  x:
                    linearScale(datePointToDecimalTime(tooltipData[0])) +
                      margin.left ?? margin.left,
                  y: tooltipTop ?? margin.top,
                }}
                to={{
                  x:
                    linearScale(datePointToDecimalTime(tooltipData[0])) +
                      margin.left +
                      30 ?? margin.left,
                  y: tooltipTop ?? margin.top,
                }}
                stroke={green}
                strokeWidth={1}
                pointerEvents="none"
              />
            )}
          </>
        )}
      </svg>

      {/* Tooltip */}
      <div className="absolute inset-0 pointer-events-none">
        {tooltipData && (
          <Tooltip
            key={Math.random()}
            top={tooltipTop ? tooltipTop - 30 : margin.top}
            left={
              isTooltipFinalPoint
                ? linearScale(datePointToDecimalTime(tooltipData[0])) + 75
                : linearScale(datePointToDecimalTime(tooltipData[0])) +
                  margin.left +
                  90
            }
            style={tooltipStyles}
          >
            <div className={tooltipTextClass}>
              {decimalTimeToHumanTime(
                dateToDecimalTime(new Date(tooltipData[0].date))
              )}
            </div>
            {tooltipData.map((toolTipDataItem, index) => {
              const serieDashStyle = {
                width: '10px',
                height: '2px',
                backgroundColor: toolTipDataItem.lineColour,
              };
              return (
                toolTipDataItem.showLine && (
                  <div key={index} className="flex items-center">
                    <div style={serieDashStyle} />
                    <div className="ml-1 text-base font-semibold whitespace-nowrap">
                      {`${formatNumber(getHoursValue(tooltipData[index]))}
                        ${toolTipDataItem.label}`}
                    </div>
                  </div>
                )
              );
            })}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
