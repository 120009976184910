/* eslint-disable max-len */
import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';

interface SearchBarProps {
  searchText: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  bordered?: boolean;
  placeholder?: string;
}

export const SearchBar: FC<SearchBarProps> = ({
  searchText,
  onChange,
  bordered,
  placeholder,
}) => {
  const inputClass = classnames(
    { border: bordered },
    { 'border-primary-100': bordered }
  );
  return (
    <input
      placeholder={placeholder || 'Quick search'}
      value={searchText}
      onChange={e => onChange(e.currentTarget.value)}
      className={`w-full h-10 pl-10 mb-5 text-sm text-primary-900 placeholder-primary-900 bg-white bg-left-3 bg-no-repeat bg-search rounded-sm shadow-250 outline-none placeholder-opacity-40 ${inputClass}`}
    />
  );
};
