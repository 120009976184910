import React, { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import { useQuery } from 'react-query';

import { useAuthStore, useMetadataStore } from '~/stores';
import { getFacilities, getUserAlerts } from '~/api';
import { PrivateRouter } from '~/router/PrivateRouter';
import { Layout } from '~/layouts';
import { PromptModal } from '~/components/modals';
import { Button } from '~/components/button';
import { useAlertStore } from '~/stores/useAlertStore';

export const AppProvider: FC = () => {
  const { setFacilities } = useMetadataStore();
  const { setUserAlerts } = useAlertStore();

  const { isTimedOutError, logout } = useAuthStore();

  useQuery('facilities', getFacilities, {
    refetchOnWindowFocus: false,
    suspense: true,
    onSuccess: data => setFacilities(data),
  });

  useQuery('userAlerts', getUserAlerts, {
    refetchOnWindowFocus: false,
    onSuccess: data => setUserAlerts(data),
  });

  return (
    <Layout>
      <Toaster />
      <PrivateRouter />
      <PromptModal
        title="Session timed out"
        showModal={isTimedOutError}
        primaryButton={<Button text="Close" onClick={logout} size="small" />}
      />
    </Layout>
  );
};
