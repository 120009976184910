import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Facility } from '@intelligent-play-v2/domain';
import pin from 'assets/images/pages/facilities/map/icon__pin.png';
import mapMarker1 from 'assets/images/pages/facilities/map/mapMarkers/m1.png';
import mapMarker2 from 'assets/images/pages/facilities/map/mapMarkers/m2.png';
import mapMarker3 from 'assets/images/pages/facilities/map/mapMarkers/m3.png';
import mapMarker4 from 'assets/images/pages/facilities/map/mapMarkers/m4.png';
import mapMarker5 from 'assets/images/pages/facilities/map/mapMarkers/m5.png';
import { FacilityInfoWindow } from '.';
import { GOOGLE_MAP_API_KEY, googleMapOptions } from './constants';
import { getLatLong } from '~/utils/getLatLong';
import { FacilityRow } from '~/types';

// https://www.npmjs.com/package/@react-google-maps/api

interface MapProps {
  facilityRows: FacilityRow[];
  height: number;
  disableRedirect?: boolean;
}

export const FacilityMap: FC<MapProps> = ({
  facilityRows,
  height,
  disableRedirect,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const [clickedFacility, setClickedFacility] = useState<Facility | null>(null);
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);

  const MAP_MARKER_WIDTH_LIST = [53, 56, 66, 78, 90];

  const clusterStyles = [
    { url: mapMarker1 },
    { url: mapMarker2 },
    { url: mapMarker3 },
    { url: mapMarker4 },
    { url: mapMarker5 },
  ].map((s, index) => ({
    textColor: 'black',
    height: MAP_MARKER_WIDTH_LIST[index],
    width: MAP_MARKER_WIDTH_LIST[index],
    lineHeight: MAP_MARKER_WIDTH_LIST[index],
    ...s,
  }));

  const getGoogleLatLong = (facility: Facility): google.maps.LatLng => {
    const latLong = getLatLong(facility);
    return new google.maps.LatLng(latLong.x, latLong.y);
  };

  const onLoad = useCallback(map => setGoogleMap(map), []);

  useEffect(() => {
    if (googleMap) {
      const bounds = new window.google.maps.LatLngBounds();
      facilityRows.map(facilityRow => {
        bounds.extend(getGoogleLatLong(facilityRow.facility));
      });
      googleMap.fitBounds(bounds);
      if (facilityRows.length === 1) {
        googleMap.setZoom(10);
      }
    }
  }, [googleMap, facilityRows]);

  return (
    <div className="-mx-5 -mb-12.5 md:mx-auto md:mb-auto">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ height: height }}
          options={googleMapOptions}
          zoom={10}
          onLoad={onLoad}
          onClick={() => setClickedFacility(null)}
        >
          <MarkerClusterer styles={clusterStyles}>
            {clusterer =>
              facilityRows.map(facilityRow => (
                <Marker
                  key={facilityRow.id}
                  position={getGoogleLatLong(facilityRow.facility)}
                  onClick={() => setClickedFacility(facilityRow.facility)}
                  icon={pin}
                  clusterer={clusterer}
                />
              ))
            }
          </MarkerClusterer>
          {clickedFacility && (
            <FacilityInfoWindow
              position={getGoogleLatLong(clickedFacility)}
              facility={clickedFacility}
              allowRedirect={!disableRedirect}
            />
          )}
        </GoogleMap>
      )}
    </div>
  );
};
