import { Pitch } from '@intelligent-play-v2/domain';
import { getCountryForTimezone } from 'countries-and-timezones';
import startCase from 'lodash.startcase';
import React, { FC } from 'react';
import { formatDateGbUs, getPitchInstallDate } from '~/utils';
import { PitchSportType } from '../facilityOverview/PitchSportType';

interface FieldInformationProps {
  pitch: Pitch;
}

export const FieldInformation: FC<FieldInformationProps> = ({ pitch }) => {
  const pitchInstallDate = getPitchInstallDate(pitch);
  const country = pitch.timezone ? getCountryForTimezone(pitch.timezone) : null;

  const getLastResurfaceDate = (): string => {
    if (pitch.lastResurfaceDate) {
      return formatDateGbUs(new Date(pitch.lastResurfaceDate), 'd MMMM yyyy');
    } else {
      return 'Unknown';
    }
  };

  const getFootballFoundationFrameworkYear = (): string => {
    // If the oldest device installation date is older than the 13/01/2020 then it's the 2015-2019 Framework
    return pitchInstallDate < new Date(2020, 0, 13)
      ? 'Framework 2015-2019'
      : 'Framework 2020-2024';
  };

  const semiboldRightAlignClass = 'font-semibold text-right';

  return (
    <div className="px-5 pt-4 pb-5 space-y-4 bg-white rounded shadow-250">
      <div className="flex justify-between">
        <span>Primary use(s)</span>
        <span className="flex flex-wrap justify-end gap-x-2">
          {pitch.sportTypes?.map(sport => (
            <PitchSportType key={sport} sportTypeId={sport} country={country} />
          ))}
        </span>
      </div>
      <div className="flex justify-between">
        <span>Turf </span>
        <span className={semiboldRightAlignClass}>{pitch.turfType || ''}</span>
      </div>
      <div className="flex justify-between">
        <span>Turf manufacturer</span>
        <span className={semiboldRightAlignClass}>
          {pitch.turfManufacturer || ''}
        </span>
      </div>
      <div className="flex justify-between">
        <span>Turf product</span>
        <span className={semiboldRightAlignClass}>
          {pitch.turfProduct || ''}
        </span>
      </div>
      <div className="flex justify-between">
        <span>Installation contractor</span>
        <span className={semiboldRightAlignClass}>
          {startCase(pitch.installer || '')}
        </span>
      </div>
      <div className="flex justify-between">
        <span>Last resurface date</span>
        <span className={semiboldRightAlignClass}>
          {getLastResurfaceDate()}
        </span>
      </div>
      <div className="flex justify-between">
        <span>Dashboard live date</span>
        <span className={semiboldRightAlignClass}>
          {formatDateGbUs(pitchInstallDate, 'd MMMM yyyy')}
        </span>
      </div>
      {pitch.pitchFinderId !== null && pitch.pitchFinderId > 0 && (
        <div className="flex justify-between">
          <span>Football Foundation Framework</span>
          <span className={semiboldRightAlignClass}>
            {getFootballFoundationFrameworkYear()}
          </span>
        </div>
      )}
    </div>
  );
};
