import { ReportPreset, ReportPresetOptions } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const postPreset = async (
  params: ReportPresetOptions
): Promise<ReportPreset> => {
  const { data } = await queryBuilder('POST', 'reports/presets', undefined, {
    ...params,
  });
  return data;
};
