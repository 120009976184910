import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';

interface AdminStatProps {
  title: string;
  icon: string;
  icon2x: string;
  value: number | undefined;
  childNumber: number;
}

export const AdminStat: FC<AdminStatProps> = ({
  title,
  icon,
  icon2x,
  value,
  childNumber,
}) => {
  const shadowClass = classnames(
    'shadow-none',
    {
      'sm:shadow-250': childNumber > 3,
    },
    {
      'lg:shadow-250': childNumber > 2,
    },
    'xl:shadow-none'
  );

  const radiusBottomLeftClass = classnames(
    'rounded-bl-none',
    {
      'sm:rounded-bl-md': childNumber === 5,
    },
    {
      'lg:rounded-bl-none': childNumber === 5,
    },
    {
      'lg:rounded-bl-md': childNumber === 4,
    },
    'xl:rounded-bl-none'
  );

  const radiusBottomRightClass = classnames(
    'rounded-br-none',
    {
      'sm:rounded-br-md': childNumber === 5 || childNumber === 4,
    },
    {
      'lg:rounded-br-none': childNumber === 4,
    },
    {
      'lg:rounded-br-md': childNumber === 3,
    },
    'xl:rounded-br-none'
  );

  return (
    <div
      className={`flex p-5 font-semibold text-primary-900 bg-white shadow-250 ${shadowClass} ${radiusBottomLeftClass} ${radiusBottomRightClass}`}
    >
      <div className="w-12.5 h-12.5 bg-green-lightest rounded-sm flex items-center justify-center">
        <picture>
          <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
        </picture>
      </div>
      <div className="ml-2.5">
        <div className="text-sm leading-none">{title}</div>
        <div className="text-5xl">{value}</div>
      </div>
    </div>
  );
};
