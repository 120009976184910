import React, { FC } from 'react';
import { TArg, classnames } from 'tailwindcss-classnames';

interface TooltipParentProps {
  className?: string | TArg;
  onClick?: () => void;
}

export const TooltipParent: FC<TooltipParentProps> = ({
  children,
  className = '',
  onClick,
}) => {
  const parentClassnames = (className as string).split(' ') as TArg[];

  const parentClass = classnames('relative', 'group', ...parentClassnames);

  return (
    <div onClick={onClick} className={parentClass}>
      {children}
    </div>
  );
};
