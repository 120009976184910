import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import {
  Day,
  Facility,
  ReportArea,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import {
  getMachineMaintenanceSessions,
  getMaintenanceHoursStats,
  getPlayerHours,
  getUsageHours,
} from '~/api';
import {
  FilteredStatisticsParams,
  PitchStatisticRow,
  TableReportArea,
} from '~/types';
import { getPitchStatisticRows } from './utils';
import { useReportQueryParams } from '~/hooks/useReportQueryParams.hook';
import { useReportStore } from '~/stores/useReportStore';

interface UseStatisticTableDataHook {
  reportAreaTables: TableReportArea[][];
  pitchStatisticRows: PitchStatisticRow[];
}

export const useStatisticTableData = (
  facility: Facility
): UseStatisticTableDataHook => {
  const { selectedReportAreaIds } = useReportStore();
  const { includedDays, dailyStartTime, dailyEndTime } = useReportQueryParams();

  const pitchIds = facility.pitches?.map(pitch => pitch.id) ?? [];
  const timezone = facility.tzDatabaseTimezone ?? 'UTC';

  const [pitchStatisticRows, setPitchStatisticRows] = useState(
    getPitchStatisticRows(facility)
  );

  const tableReportAreas: TableReportArea[] = [];
  if (selectedReportAreaIds?.includes(ReportArea.PlayerHours)) {
    tableReportAreas.push(TableReportArea.PlayerHours);
  }
  if (selectedReportAreaIds?.includes(ReportArea.UsageHours)) {
    tableReportAreas.push(TableReportArea.UsageHours);
  }
  if (selectedReportAreaIds?.includes(ReportArea.EUH)) {
    tableReportAreas.push(TableReportArea.EUH);
  }
  if (selectedReportAreaIds?.includes(ReportArea.MachineMaintenanceHours)) {
    tableReportAreas.push(TableReportArea.MachineMaintenanceHours);
  }
  if (selectedReportAreaIds?.includes(ReportArea.MachineMaintenanceSessions)) {
    tableReportAreas.push(TableReportArea.MachineMaintenanceSessions);
  }
  if (selectedReportAreaIds?.includes(ReportArea.HighestParticipationDay)) {
    tableReportAreas.push(TableReportArea.HighestParticipationDay);
  }
  if (selectedReportAreaIds?.includes(ReportArea.LowestParticipationDay)) {
    tableReportAreas.push(TableReportArea.LowestParticipationDay);
  }
  if (selectedReportAreaIds?.includes(ReportArea.FieldRanking)) {
    tableReportAreas.push(TableReportArea.FieldRanking);
  }
  if (selectedReportAreaIds?.includes(ReportArea.Weekdays)) {
    tableReportAreas.push(TableReportArea.Weekdays);
  }
  if (selectedReportAreaIds?.includes(ReportArea.Weekends)) {
    tableReportAreas.push(TableReportArea.Weekends);
  }
  const { timePeriod, customDates } = useReportQueryParams();

  const filteredStatisticsParams: FilteredStatisticsParams = {
    timePeriod,
    pitchIds,
    timezone,
    dailyStartTime,
    dailyEndTime,
    includedDays,
  };

  if (timePeriod === TimePeriod.Custom && customDates) {
    filteredStatisticsParams.from = format(customDates.startDate, 'yyyy-MM-dd');
    filteredStatisticsParams.to = format(customDates?.endDate, 'yyyy-MM-dd');
  }

  const { data: usageHoursData } = useQuery(
    ['usageHours', filteredStatisticsParams],
    async () => getUsageHours(filteredStatisticsParams),
    {
      suspense: true,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        tableReportAreas.includes(TableReportArea.UsageHours) ||
        tableReportAreas.includes(TableReportArea.HighestParticipationDay) ||
        tableReportAreas.includes(TableReportArea.LowestParticipationDay) ||
        tableReportAreas.includes(TableReportArea.FieldRanking),
    }
  );

  useEffect(() => {
    if (usageHoursData) {
      setPitchStatisticRows(prevState => {
        const dataCopied = [...prevState];
        usageHoursData.sort((a, b) => b.usageHours.total - a.usageHours.total);
        usageHoursData.map((dataItem, index) => {
          const row = dataCopied.find(
            data => data.pitchId === dataItem.pitchId
          );
          if (row) {
            row.usageHours = dataItem.usageHours.total.toFixed(2);
            row.highestParticipationDay =
              Day[dataItem.participationDays.highest];
            row.lowestParticipationDay = Day[dataItem.participationDays.lowest];
            row.fieldRanking = (index + 1).toString();
            row.weekdays = dataItem.weekSectionUsage.weekdays;
            row.weekends = dataItem.weekSectionUsage.weekends;
          }
        });
        return dataCopied;
      });
    }
  }, [usageHoursData]);

  const { data: playerHoursData } = useQuery(
    ['playerHours', filteredStatisticsParams],
    async () => getPlayerHours(filteredStatisticsParams),
    {
      suspense: true,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        tableReportAreas.includes(TableReportArea.PlayerHours) ||
        tableReportAreas.includes(TableReportArea.EUH),
    }
  );

  useEffect(() => {
    if (playerHoursData) {
      setPitchStatisticRows(prevState => {
        const dataCopied = [...prevState];
        playerHoursData.map(dataItem => {
          const row = dataCopied.find(
            data => data.pitchId === dataItem.pitchId
          );
          if (row) {
            row.playerHours = dataItem.playerHours.total.toFixed(2);
            row.euh = dataItem.euh.total.toFixed(2);
          }
        });
        return dataCopied;
      });
    }
  }, [playerHoursData]);

  const { data: maintenanceHours } = useQuery(
    ['maintenanceHours', filteredStatisticsParams],
    async () => getMaintenanceHoursStats(filteredStatisticsParams),
    {
      suspense: true,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: tableReportAreas.includes(
        TableReportArea.MachineMaintenanceHours
      ),
    }
  );

  useEffect(() => {
    if (maintenanceHours) {
      setPitchStatisticRows(prevState => {
        const dataCopied = [...prevState];
        maintenanceHours.map(dataItem => {
          const row = dataCopied.find(
            data => data.pitchId === dataItem.pitchId
          );
          if (row) {
            row.machineMaintenanceHours = dataItem.machineMaintenanceHours.total.toFixed(
              2
            );
          }
        });
        return dataCopied;
      });
    }
  }, [maintenanceHours]);

  const { data: machineMaintenanceSessions } = useQuery(
    ['machineMaintenanceSessions', filteredStatisticsParams],
    async () => getMachineMaintenanceSessions(filteredStatisticsParams),
    {
      suspense: true,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: tableReportAreas.includes(
        TableReportArea.MachineMaintenanceSessions
      ),
    }
  );

  useEffect(() => {
    if (machineMaintenanceSessions) {
      setPitchStatisticRows(prevState => {
        const dataCopied = [...prevState];
        machineMaintenanceSessions.map(dataItem => {
          const row = dataCopied.find(
            data => data.pitchId === dataItem.pitchId
          );
          if (row) {
            row.machineMaintenanceSessions = dataItem.machineMaintenanceSessions.total.toString();
          }
        });
        return dataCopied;
      });
    }
  }, [machineMaintenanceSessions]);

  const perChunk = 4;
  const reportAreaTables = tableReportAreas.reduce(
    (groupedArray: TableReportArea[][], item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!groupedArray[chunkIndex]) {
        groupedArray[chunkIndex] = [];
      }
      groupedArray[chunkIndex].push(item);
      return groupedArray;
    },
    []
  );
  return { reportAreaTables, pitchStatisticRows };
};
