import React, { FC } from 'react';
import { BeatLoader, ScaleLoader } from 'react-spinners';
import { classnames } from 'tailwindcss-classnames';

interface LoadingProps {
  isFullScreen?: boolean;
  size?: number;
  message?: React.ReactNode;
}

export const Loading: FC<LoadingProps> = ({
  isFullScreen = false,
  size = 10,
  message,
}) => {
  const classes = classnames({
    'h-screen': isFullScreen,
    'h-full': !isFullScreen,
  });

  return (
    <div
      className={`flex flex-col items-center justify-center w-full ${classes}`}
    >
      {isFullScreen ? (
        <ScaleLoader height={50} width={10} />
      ) : (
        <BeatLoader size={size} />
      )}
      {message}
    </div>
  );
};
