export const toggleCheckedItem = (
  item: number,
  checkedItems: number[]
): number[] => {
  const isItemChecked = checkedItems.includes(item);
  const newItems = isItemChecked
    ? checkedItems.filter(checkedItem => checkedItem !== item)
    : [...checkedItems, item];
  return newItems;
};

export const toggleCheckedItems = (
  items: number[],
  newCheckedValue: boolean,
  checkedItems: number[]
): number[] => {
  let newIds;

  if (newCheckedValue) {
    const uncheckedItems = items.filter(item => !checkedItems.includes(item));
    newIds = [...checkedItems, ...uncheckedItems];
  } else {
    newIds = checkedItems.filter(item => !items.includes(item));
  }
  return newIds;
};
