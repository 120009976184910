import { useEffect, useState } from 'react';
import { ListItem } from '~/types';

interface PaginationHook {
  paginatedItems: ListItem[][];
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

export const usePagination = (
  items: ListItem[],
  itemsPerPage: number | undefined
): PaginationHook => {
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedItems, setPaginatedItems] = useState<ListItem[][]>([[]]);

  useEffect(() => {
    setPaginatedItems(
      itemsPerPage
        ? items.reduce((groupedArray: ListItem[][], item, index) => {
            const pageIndex = Math.floor(index / itemsPerPage);
            if (!groupedArray[pageIndex]) {
              groupedArray[pageIndex] = [];
            }
            groupedArray[pageIndex].push(item);
            return groupedArray;
          }, [])
        : [items]
    );
  }, [items, itemsPerPage]);

  return {
    paginatedItems,
    pageNumber,
    setPageNumber,
  };
};
