/* eslint-disable react/display-name */
import React from 'react';
import { Facility } from '@intelligent-play-v2/domain';
import { TableColumn } from '~/components/table';
import { PitchStatisticRow, TableReportArea } from '~/types';
import { TablePitchStat } from './TablePitchStat';
import { sortByPitchNumber } from '~/utils';

export const getTableReportAreaColumn = (
  statisticType: TableReportArea
): TableColumn => {
  switch (statisticType) {
    case TableReportArea.PlayerHours:
      return {
        heading: TableReportArea.PlayerHours,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.playerHours ?? '0'} />
        ),
      };
    case TableReportArea.PlayerCount:
      return {
        heading: TableReportArea.PlayerCount,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.playerCount ?? '0'} />
        ),
      };
    case TableReportArea.UsageHours:
      return {
        heading: TableReportArea.UsageHours,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.usageHours ?? '0'} />
        ),
      };
    case TableReportArea.EUH:
      return {
        heading: TableReportArea.EUH,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.euh ?? '0'} />
        ),
      };
    case TableReportArea.MachineMaintenanceHours:
      return {
        heading: TableReportArea.MachineMaintenanceHours,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.machineMaintenanceHours ?? '0'} />
        ),
      };
    case TableReportArea.MachineMaintenanceSessions:
      return {
        heading: TableReportArea.MachineMaintenanceSessions,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.machineMaintenanceSessions ?? '0'} />
        ),
      };
    case TableReportArea.HighestParticipationDay:
      return {
        heading: TableReportArea.HighestParticipationDay,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.highestParticipationDay ?? '-'} />
        ),
      };
    case TableReportArea.LowestParticipationDay:
      return {
        heading: TableReportArea.LowestParticipationDay,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.lowestParticipationDay ?? '-'} />
        ),
      };
    case TableReportArea.Weekends:
      return {
        heading: TableReportArea.Weekends,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.weekends ?? '-'} />
        ),
      };
    case TableReportArea.Weekdays:
      return {
        heading: TableReportArea.Weekdays,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.weekdays ?? '-'} />
        ),
      };
    case TableReportArea.FieldRanking:
      return {
        heading: TableReportArea.FieldRanking,
        widthClass: 'w-2/12',
        value: (data: PitchStatisticRow) => (
          <TablePitchStat value={data.fieldRanking ?? '-'} />
        ),
      };

    default:
      break;
  }
  return {
    heading: '',
    widthClass: '',
    value: () => <TablePitchStat value="" />,
  };
};

export const getPitchStatisticRows = (
  facility: Facility
): PitchStatisticRow[] => {
  return (
    facility.pitches?.sort(sortByPitchNumber).map(pitch => {
      return {
        pitchId: pitch.id,
        name: pitch.name ?? '',
        id: pitch.id,
      };
    }) ?? []
  );
};
