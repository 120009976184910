import React, { FC } from 'react';

import { RoutesPath } from '~/types';
import { FacilitiesList } from './FacilitiesList';
import { NavigationListItem } from './NavigationListItem';

import dashboardIcon from 'assets/images/navs/icon__dashboard.png';
import facilitiesIcon from 'assets/images/navs/icon__facilities.png';
import reportIcon from 'assets/images/navs/icon__report.png';
import calendarIcon from 'assets/images/navs/icon__calendar.png';
import resourcesIcon from 'assets/images/navs/icon__resources.png';
import contactIcon from 'assets/images/navs/icon__contact-us.png';
import adminIcon from 'assets/images/navs/icon__admin.png';

import dashboardIcon2x from 'assets/images/navs/icon__dashboard@2x.png';
import facilitiesIcon2x from 'assets/images/navs/icon__facilities@2x.png';
import reportIcon2x from 'assets/images/navs/icon__report@2x.png';
import calendarIcon2x from 'assets/images/navs/icon__calendar@2x.png';
import resourcesIcon2x from 'assets/images/navs/icon__resources@2x.png';
import contactIcon2x from 'assets/images/navs/icon__contact-us@2x.png';
import adminIcon2x from 'assets/images/navs/icon__admin@2x.png';
import { useAuthStore } from '~/stores';
import { UserType } from '@intelligent-play-v2/domain';

export const NavigationList: FC = () => {
  const { user } = useAuthStore();
  return (
    <ul>
      <NavigationListItem
        path={RoutesPath.Dashboard}
        label="Dashboard"
        icon={dashboardIcon}
        icon2x={dashboardIcon2x}
      />
      <NavigationListItem
        path={RoutesPath.Facilities}
        label="Facilities"
        icon={facilitiesIcon}
        icon2x={facilitiesIcon2x}
      />
      <FacilitiesList />
      <div className="hidden md:block">
        <NavigationListItem
          path={RoutesPath.Calendar}
          label="Calendar"
          icon={calendarIcon}
          icon2x={calendarIcon2x}
        />
      </div>
      <div className="hidden md:block">
        <NavigationListItem
          path={RoutesPath.Report}
          label="Report"
          icon={reportIcon}
          icon2x={reportIcon2x}
          id="report-nav"
        />
      </div>
      <NavigationListItem
        path={RoutesPath.Resources}
        label="Resources"
        icon={resourcesIcon}
        icon2x={resourcesIcon2x}
      />
      <NavigationListItem
        path={RoutesPath.Contact}
        label="Contact us"
        icon={contactIcon}
        icon2x={contactIcon2x}
      />
      {user?.userTypeId === UserType.SystemAdmin && (
        <NavigationListItem
          path={RoutesPath.Admin}
          label="Admin"
          icon={adminIcon}
          icon2x={adminIcon2x}
        />
      )}
    </ul>
  );
};
