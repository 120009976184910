import { Facility, StatsDates } from '@intelligent-play-v2/domain';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { SelectOptionType } from '~/components/select';

export const getCalendarDateRange = (date: Date): StatsDates => {
  const startDate = startOfWeek(startOfMonth(date), {
    weekStartsOn: 1,
  });
  const endDate = endOfWeek(endOfMonth(date), { weekStartsOn: 1 });

  return { startDate, endDate };
};

export const getSelectedPitchIds = (
  facilities: Facility[],
  selectedFacility: SelectOptionType | null,
  selectedPitch: SelectOptionType | null
): number[] => {
  const isFacilitySelected =
    selectedFacility && selectedFacility.value !== 'all';
  const isPitchSelected = selectedPitch && selectedPitch.value !== 'all';

  return facilities
    .filter(
      facility => !isFacilitySelected || +selectedFacility.value === facility.id
    )
    .map(facility => facility.pitches!.map(({ id }) => id))
    .flat()
    .filter(id => !isPitchSelected || +selectedPitch.value === id);
};
