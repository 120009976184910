import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import {
  getFacilityImageUrl,
  getPitchesMaintenanceStatus,
  getStats,
} from '~/api';
import { useMetadataStore } from '~/stores';
import { TimePeriod } from '@intelligent-play-v2/domain';
import { FacilityImage } from './FacilityImage';
import { PitchRow } from './PitchSummaryCard';
import { PitchSummaryCards } from './PitchSummaryCards';
import { getStatsParams } from '~/utils';

export const FacilityOverview: FC = () => {
  const { facilities } = useMetadataStore();
  const { facilityId } = useParams<{ facilityId: string }>();

  const facility = facilities.find(f => f.id === +facilityId);

  const statsParams = getStatsParams(TimePeriod.Last7Days, facility);

  const { data } = useQuery(['stats', statsParams], async () =>
    getStats(statsParams)
  );

  const pitchIds = facility?.pitches?.map(pitch => pitch.id) ?? [];
  const timezone = facility?.tzDatabaseTimezone ?? 'Utc';

  const {
    data: facilityMaintenanceStatuses,
    isLoading: isLoadingMaintenanceStatuses,
  } = useQuery(['maintenanceStatus', pitchIds], async () =>
    getPitchesMaintenanceStatus({ pitchIds, timezone })
  );

  const {
    data: facilityImageUrl,
    isLoading: isLoadingFacilityImage,
  } = useQuery(['facilityImage', facilityId], async () =>
    getFacilityImageUrl(facilityId)
  );

  const [focusedPitch, setFocusedPitch] = useState<number>();

  const facilityStatsData = data?.facilities.find(f => f.id === +facilityId);

  if (!facility) {
    return null;
  }

  const pitchRows: PitchRow[] =
    facility?.pitches?.map(pitch => {
      const aggregate =
        facilityStatsData?.pitches.find(
          pitchAggregate => pitchAggregate.id === pitch.id
        ) ?? null;
      const maintenanceStatus =
        facilityMaintenanceStatuses?.find(status => status.id === pitch.id) ??
        null;
      return {
        ...pitch,
        aggregate,
        maintenanceStatus,
      };
    }) ?? [];

  return (
    <div className="flex flex-col-reverse sm:pt-4 md:space-x-8 flex-rev md:flex-row">
      <FacilityImage
        imageUrl={facilityImageUrl || ''}
        isLoading={isLoadingFacilityImage}
        pitchRows={pitchRows}
        setFocusedPitch={setFocusedPitch}
      />
      <PitchSummaryCards
        pitches={pitchRows}
        focusedPitch={focusedPitch}
        isLoading={isLoadingMaintenanceStatuses}
      />
    </div>
  );
};
