import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { UserType } from '@intelligent-play-v2/domain';
import { getPitchOutOfHours, getUserAlertSubscriptions } from '~/api';
import { ContentContainer, PageHeader } from '~/components/layout';
import { Loading } from '~/components/loading';
import { FacilitySettings } from '~/components/settings/FacilitySettings';
import { usePageTitle } from '~/hooks';
import { useAuthStore, useMetadataStore } from '~/stores';

export const SettingsPage: FC = () => {
  usePageTitle('Settings - Intelligent Play');

  const { facilities } = useMetadataStore();
  const { user } = useAuthStore();
  const isAdmin =
    user?.userTypeId === UserType.SystemAdmin ||
    user?.userTypeId === UserType.FacilityAdmin;

  const {
    data: userAlertSubscriptions,
    isLoading: isLoadingAlertSubscriptions,
  } = useQuery('userAlertSubscriptions', async () =>
    getUserAlertSubscriptions()
  );

  const {
    data: outOfHours,
    isLoading: isLoadingOutOfHours,
  } = useQuery('getPitchOutOfHours', async () => getPitchOutOfHours());

  const isLoading = isLoadingAlertSubscriptions || isLoadingOutOfHours;

  const helpMessage = `To set up your individual Alerts, check the boxes below the field name that you want to receive Alerts for and then click Save changes.`;

  // eslint-disable-next-line max-len
  const adminHelpMessage = `You can also adjust the out of hours time ranges and scheduled snapshot times for each individual field. After making any adjustments to the timings, click Save changes. Please note, any changes made to these timings will be reflected for all individuals who have dashboard access to the field.`;

  return (
    <>
      <PageHeader title="Settings" />
      <ContentContainer className="py-6">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="bg-white rounded shadow-250">
              <div className="p-3 text-sm">{helpMessage}</div>
              {isAdmin && (
                <div className="p-3 pt-0 text-sm">{adminHelpMessage}</div>
              )}
            </div>
            <div className="flex flex-col mt-4 space-y-6">
              {facilities.map(facility => {
                const facilityPitchIds =
                  facility.pitches?.map(({ id }) => id) || [];

                const facilityOutOfHours =
                  outOfHours?.filter(({ pitchId }) =>
                    facilityPitchIds.includes(pitchId)
                  ) || [];

                const facilityAlertSubscriptions =
                  userAlertSubscriptions?.filter(({ pitchId }) =>
                    facilityPitchIds.includes(pitchId)
                  ) || [];

                return (
                  <FacilitySettings
                    key={facility.id}
                    facility={facility}
                    selectedAlertSubscriptions={facilityAlertSubscriptions}
                    outOfHours={facilityOutOfHours}
                  />
                );
              })}
            </div>
          </>
        )}
      </ContentContainer>
    </>
  );
};
