import axios, { Method } from 'axios';

interface TriggerSnapshotParams {
  deviceId: number;
  jwt: string;
  domain: string;
}

interface TriggerSnapshotResponse {
  averagePlayerCount: number;
  deviceId: number;
  deviceUid: null | string;
  reason: string;
  snapshotName: string;
  timestamp: number;
}

export const postTriggerDeviceSnapshot = async (
  params: TriggerSnapshotParams
): Promise<TriggerSnapshotResponse> => {
  const { deviceId, jwt, domain } = params;
  const method: Method = 'POST';
  const { data } = await axios({
    method,
    url: `${domain}${deviceId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    timeout: 1000 * 60,
  });
  return data;
};
