import { queryBuilder } from '../../queryBuilder';
import { OutOfHours, PutOutOfHoursBody } from '@intelligent-play-v2/domain';

export const putOutOfHours = async (
  outOfHoursId: number,
  body: PutOutOfHoursBody
): Promise<OutOfHours> => {
  const { data } = await queryBuilder(
    'PUT',
    `out-of-hours/${outOfHoursId}`,
    undefined,
    body
  );
  return data;
};
