import React, { FC } from 'react';
import { Pitch } from '@intelligent-play-v2/domain';
import { PitchRow } from './PitchRow';

interface AdminFacilityPitchesProps {
  pitches: Pitch[];
  facilityId: number;
}

export const AdminFacilityPitches: FC<AdminFacilityPitchesProps> = ({
  pitches,
  facilityId,
}) => {
  return (
    <div className="mt-10 text-primary-900 bg-white rounded shadow-250">
      <div className="flex items-center px-5 py-3 border-b border-primary-100">
        <div className="text-lg font-semibold">Facility fields</div>
      </div>
      <div className="p-5">
        {pitches.map(pitch => (
          <PitchRow key={pitch.id} pitch={pitch} facilityId={facilityId} />
        ))}
      </div>
    </div>
  );
};
