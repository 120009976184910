import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { getEntriesNumber } from '~/utils/contactFaq.utils';
import { ContactFaqCard } from './ContactFaqCard';
import { FaqCategory, ResourceFaq } from '@intelligent-play-v2/domain';

interface ContactFaqCardsProps {
  faqList: ResourceFaq[];
  selectedCategory: FaqCategory | null;
  setSelectedCategory: (category: FaqCategory) => void;
}

export const ContactFaqCards: FC<ContactFaqCardsProps> = ({
  faqList,
  selectedCategory,
  setSelectedCategory,
}) => {
  const showCardsClass = classnames({ hidden: selectedCategory !== null });

  return (
    <div
      className={`sm:flex flex-col justify-between space-x-0 space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row ${showCardsClass}`}
    >
      {Object.values(FaqCategory).map(category => (
        <ContactFaqCard
          key={category}
          category={category}
          entries={getEntriesNumber(faqList, category)}
          isSelected={selectedCategory === category}
          setSelectedCategory={setSelectedCategory}
        />
      ))}
    </div>
  );
};
