import React, { FC } from 'react';
import { Facility, Pitch } from '@intelligent-play-v2/domain';
import { FacilitySelector } from '~/components/createReport';
import { LabelledCheckbox } from '~/components/labelledCheckbox';

interface UserPitchPermissionsProps {
  checkedPitchIds: number[];
  toggleCheckedPitchIds: (items: number[], newCheckedValue: boolean) => void;
  allPitchIds: number[];
  sortedFacilities: Facility[];
}

export const UserPitchPermissions: FC<UserPitchPermissionsProps> = ({
  checkedPitchIds,
  toggleCheckedPitchIds,
  allPitchIds,
  sortedFacilities,
}) => {
  const isAllPitchesChecked =
    checkedPitchIds.sort().toString() === allPitchIds.sort().toString();

  const facilitySelectedPitches = (pitches: Pitch[]): number[] => {
    return pitches.reduce((accum: number[], pitch) => {
      if (checkedPitchIds.includes(pitch.id)) {
        accum.push(pitch.id);
      }
      return accum;
    }, []);
  };

  return (
    <div className="bg-white rounded shadow-250">
      <div className="flex flex-row p-3 border-b border-primary-100">
        <span className="font-semibold">User pitch permissions</span>
      </div>
      <div className="p-3">
        <LabelledCheckbox
          title="All fields"
          isChecked={isAllPitchesChecked}
          toggleChecked={() =>
            toggleCheckedPitchIds(allPitchIds, !isAllPitchesChecked)
          }
        />
        <div className="space-y-2.5 mt-2">
          {sortedFacilities.map(facility => (
            <FacilitySelector
              facility={facility}
              key={facility.id}
              selectedPitches={facilitySelectedPitches(facility.pitches ?? [])}
              onChangeCheckboxHandler={toggleCheckedPitchIds}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
