/* eslint-disable react/display-name */
import React from 'react';
import { TableColumn } from '../Table';
import { UserRow } from '~/types';
import { getUserTypeString } from '@intelligent-play-v2/domain';
import { UserThumbnail, UserThumbnailType } from '~/components/userThumbnail';
import { ContactInfo } from './ContactInfo';
import { UserStatus } from './UserStatus';
import { PermissionsInfo } from './PermissionsInfo';
import emailIcon from 'assets/images/pages/admin/icon__user-email.png';
import emailIcon2x from 'assets/images/pages/admin/icon__user-email@2x.png';
import phoneIcon from 'assets/images/pages/admin/icon__user-phone.png';
import phoneIcon2x from 'assets/images/pages/admin/icon__user-phone@2x.png';
import { getUserInitials } from '~/utils/getUserInitials';

export const userColumns = (width: string): TableColumn[] => {
  const isFullWidth = width === 'full';

  return [
    {
      heading: 'User name',
      widthClass: `${isFullWidth ? 'w-1/2 sm:w-5/16 xl:w-4/16' : 'w-5/16'}`,
      value: (userRow: UserRow) => (
        <div className="flex">
          <UserThumbnail
            type={UserThumbnailType.UserProfile}
            text={getUserInitials(userRow.name)}
            imageSrc={userRow.imageThumbnailUrl}
          />
          <div className="grid ml-2 overflow-hidden">
            <div className="text-lg font-semibold text-primary-900 truncate">
              {userRow.name}
            </div>
            <div className="text-xs font-medium text-primary-400 truncate">
              {userRow.jobTitle}
            </div>
          </div>
        </div>
      ),
    },
    {
      heading: 'User contact details',
      widthClass: `${
        isFullWidth ? 'w-1/2 sm:w-5/16 xl:w-4/16 overflow-hidden' : 'w-5/16'
      }`,
      value: (userRow: UserRow) => (
        <div className="space-y-0.75 text-sm text-primary-900">
          <ContactInfo
            icon={emailIcon}
            icon2x={emailIcon2x}
            text={userRow.email ?? ''}
          />
          <ContactInfo
            icon={phoneIcon}
            icon2x={phoneIcon2x}
            text={userRow.phoneNumber ?? ''}
          />
        </div>
      ),
    },
    {
      heading: 'User permission',
      widthClass: 'hidden sm:block sm:w-3/16',
      value: (userRow: UserRow) => <PermissionsInfo userRow={userRow} />,
    },
    {
      heading: 'User type',
      widthClass: `${isFullWidth ? 'hidden xl:w-2/16 xl:block' : 'hidden'}`,
      value: (userRow: UserRow) => (
        <div className="text-sm font-semibold text-primary-900">
          {getUserTypeString(userRow.userTypeId)}
        </div>
      ),
    },
    {
      heading: '',
      widthClass: 'hidden sm:block sm:w-3/16',
      value: (userRow: UserRow) => (
        <UserStatus userRow={userRow} isFullWidth={isFullWidth} />
      ),
    },
  ];
};
