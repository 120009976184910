import React, { FC } from 'react';
import { useAuthStore } from '~/stores';

import ipLogo from 'assets/images/logos/logo__IP_grey.png';
import ftLogo from 'assets/images/logos/logo__field-turf-nav-desktop@2x.png';
import { Client } from '@intelligent-play-v2/domain';

export const PDFHeaderAndFooter: FC = ({ children }) => {
  const { user } = useAuthStore();
  return (
    <table className="w-full">
      <thead className="w-full">
        <tr>
          <th>
            <div className="h-12 flex justify-end mt-6 mr-4 mb-2">
              {user?.clientId === Client.FieldTurf ? (
                <img src={ftLogo} className="h-12" />
              ) : (
                <img src={ipLogo} className="h-12" />
              )}
            </div>
          </th>
        </tr>
      </thead>
      <tfoot className="w-full">
        <tr>
          <td>
            {/* Reserving the space to prevent overlapping */}
            <div>
              <div className="h-12"></div>
              <div className="report-footer text-primary-200 fixed bottom-2 hidden">
                <p>intelligent-play.com</p>
                <p>info@intelligent-play.com</p>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
      <tbody className="w-full">
        <tr>
          <td>{children}</td>
        </tr>
      </tbody>
    </table>
  );
};
