import { format } from 'date-fns';
import React, { FC } from 'react';
import { HeaderProps } from 'react-big-calendar';

export const CalendarMonthHeader: FC<HeaderProps> = props => {
  return (
    <div className="px-1 py-3 pt-4 text-sm text-left border-l-0 border-r-0">
      {format(props.date, 'EEEE')}
    </div>
  );
};
