import { Facility, User, UserType } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useMetadataStore } from '~/stores';
import { RoutesPath } from '~/types';
import { Button, ButtonType } from '../button';
import { FacilityName } from '../table/facilityTable/FacilityName';

interface UserFacilitiesListProps {
  user: User;
}

export const UserFacilitiesList: FC<UserFacilitiesListProps> = ({ user }) => {
  const { facilities } = useMetadataStore();

  const isAdmin = user.userTypeId === UserType.SystemAdmin;

  const userFacilities: Facility[] = isAdmin
    ? []
    : facilities.filter(facility => user.facilityAccess.includes(facility.id));

  const showFacilities = userFacilities && userFacilities.length > 0;
  const showNoFacilities =
    !isAdmin && (!userFacilities || userFacilities.length === 0);

  return (
    <div>
      <div className="pb-4 font-semibold">Facilities</div>
      {showNoFacilities && <div>No facilities</div>}
      {isAdmin && (
        <div className="flex h-16.5 p-4 bg-white rounded shadow-250 justify-between mb-2">
          <div className="font-semibold leading-8 truncate">All facilities</div>
          <div className="w-24">
            <Link
              to={{
                pathname: RoutesPath.Facilities,
              }}
            >
              <Button size="xsmall" type={ButtonType.Secondary} text="View" />
            </Link>
          </div>
        </div>
      )}
      {showFacilities &&
        userFacilities.map(facility => {
          return (
            <div
              className="flex h-16.5 p-4 bg-white rounded shadow-250 justify-between mb-2"
              key={facility.id}
            >
              <FacilityName facility={facility} />
              <div className="w-24">
                <Link
                  to={{
                    pathname: RoutesPath.FacilityOverview.replace(
                      ':facilityId',
                      String(facility.id)
                    ),
                  }}
                >
                  <Button
                    size="xsmall"
                    type={ButtonType.Secondary}
                    text="View"
                  />
                </Link>
              </div>
            </div>
          );
        })}
    </div>
  );
};
