import { addDays, format, isSameDay, subDays } from 'date-fns';
import React, { FC } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import mobile from 'is-mobile';
import { celsiusToFahrenheit } from './utils';
import { getWeatherIcon } from '~/utils';
import { DailyWeather } from '@intelligent-play-v2/domain';

interface WeatherCardProps {
  date: string;
  weather: DailyWeather;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  isFaded?: boolean;
  onClick?: () => void;
  isCelsius: boolean;
}

const SM_SCREEN_WIDTH = 600;

export const WeatherCard: FC<WeatherCardProps> = ({
  date,
  weather,
  setSelectedDate,
  isFaded = false,
  onClick,
  isCelsius,
}) => {
  const dateObject = new Date(date);
  const isYesterday = isSameDay(addDays(dateObject, 1), new Date());
  const isToday = isSameDay(dateObject, new Date());
  const isTomorrow = isSameDay(subDays(dateObject, 1), new Date());
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();

  const dateString = isYesterday
    ? 'Yesterday'
    : isToday
    ? 'Today'
    : isTomorrow
    ? 'Tomorrow'
    : format(dateObject, 'EEE d MMM');

  const handleClick = (): void => {
    setSelectedDate(date);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className="relative flex flex-row items-center w-full bg-white rounded shadow-250 cursor-pointer h-content sm:flex-col sm:w-40 sm:h-52"
      style={isMobile ? {} : { aspectRatio: '5 / 4' }}
      onClick={handleClick}
    >
      {/* Dates */}
      <div className="flex flex-row justify-between w-6/16 px-3 py-2 text-lg border-primary-200 sm:text-base sm:w-full sm:border-b">
        <span className="font-semibold">{dateString}</span>
        <MediaQuery minWidth={SM_SCREEN_WIDTH}>
          {isToday && <span>{format(dateObject, 'EEE d MMM')}</span>}
        </MediaQuery>
      </div>

      {/* Weather overview */}
      <div className="flex flex-row-reverse justify-around w-9/16 sm:pr-0 sm:w-full sm:h-full sm:pb-3 sm:pt-4 sm:flex-col place-items-center">
        <div className="flex flex-row items-center w-full overflow-hidden text-sm sm:flex-col sm:text-base">
          <img
            className="object-contain w-6 h-5 ml-4 md:ml-0 md:w-15 md:h-16"
            src={getWeatherIcon(weather.weatherCode)}
          />
          <span className="ml-2 truncate md:ml-0 md:px-2 md:py-1 md:text-center md:two-lines-ellipsis text-ellipsis">
            {weather.summary}
          </span>
        </div>
        <div className="flex flex-row justify-around w-5/16 space-x-1.5 text-lg sm:text-4xl sm:w-full sm:space-x-0">
          <span className="w-full font-bold text-center border-primary-200 sm:border-r">
            {isCelsius
              ? weather.highTempCelsius
              : celsiusToFahrenheit(weather.highTempCelsius)}
            °
          </span>
          <span className="w-full text-center text-primary-400">
            {isCelsius
              ? weather.lowTempCelsius
              : celsiusToFahrenheit(weather.lowTempCelsius)}
            °
          </span>
        </div>
      </div>

      {isFaded && (
        <div className="absolute top-0 w-full h-full bg-white bg-opacity-70" />
      )}
    </div>
  );
};
