import React, { FC, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { PitchPlayerCountParams, getPitchPlayerCount } from '~/api/queries';
import { useContainerDimensions, useTimePeriodSelect } from '~/hooks';
import { ToggleButton } from '../button';
import { DataSeries, DatePoint } from '~/types';
import { TimePeriod } from '@intelligent-play-v2/domain';
import { toDatePoint } from '~/utils';
import { Graph, Legend, PrintablePlayerCountGraph } from '.';
import { DateRangeModal } from '../modals';
import { DownloadButton } from '../uiButton';
import { Loading } from '../loading';
import { getFacilityNow } from '~/utils/getFacilityNow';
import { useReactToPrint } from 'react-to-print';

interface PlayerCountGraphProps {
  facilityId: number;
  pitchId: number;
  timezone: string;
}

export const PlayerCountGraph: FC<PlayerCountGraphProps> = ({
  facilityId,
  pitchId,
  timezone,
}) => {
  const componentRef = useRef(null);
  const pdfRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showSelectedRange, setShowSelectedRange] = useState(true);
  const [showAverage, setShowAverage] = useState(true);

  const [currentPlayerCount, setCurrentPlayerCount] = useState<
    DatePoint[] | null
  >(null);
  const [averagePlayerCount, setAveragePlayerCount] = useState<
    DatePoint[] | null
  >(null);

  const {
    selectedTimePeriod,
    setSelectedTimePeriod,
    customDates,
    setCustomDates,
    customDatesText,
  } = useTimePeriodSelect(TimePeriod.Last7Days, null);

  const { width, handleResize: handleGraphResize } = useContainerDimensions(
    componentRef
  );

  const playerCountParams: PitchPlayerCountParams = {
    pitchId: +pitchId,
    timePeriod: selectedTimePeriod || TimePeriod.Last7Days,
    includeAverage: true,
    timezone,
  };
  if (selectedTimePeriod === TimePeriod.Custom && customDates) {
    playerCountParams.from = format(customDates.startDate, 'yyyy-MM-dd');
    playerCountParams.to = format(customDates.endDate, 'yyyy-MM-dd');
  }

  const { data, isLoading } = useQuery(
    ['pitchPlayerCount', playerCountParams],
    async () => getPitchPlayerCount(playerCountParams)
  );

  useEffect(() => {
    if (data && data.current) {
      setCurrentPlayerCount(toDatePoint(data.current, timezone));

      if (data.average) {
        setAveragePlayerCount(toDatePoint(data.average, timezone));
      }
    }
  }, [data, pitchId, timezone]);

  useEffect(() => {
    if (currentPlayerCount) {
      handleGraphResize();
    }
  }, [currentPlayerCount, handleGraphResize]);

  const applyCustomDatesHandler = (
    customFrom: Date | null,
    customTo: Date | null
  ): void => {
    if (customFrom && customTo) {
      setSelectedTimePeriod(TimePeriod.Custom);
      setCustomDates({
        startDate: customFrom,
        endDate: customTo,
      });
    } else {
      setSelectedTimePeriod(null);
    }
  };

  const toggleShowSelectedRange = (): void => {
    setShowSelectedRange(!showSelectedRange);
  };

  const toggleShowAverage = (): void => {
    setShowAverage(!showAverage);
  };

  const dataSeries: DataSeries[] = [
    {
      data: currentPlayerCount || [],
      showLine: showSelectedRange,
      color: '#519c42',
    },
    { data: averagePlayerCount || [], showLine: showAverage, color: '#7c7f8b' },
  ];

  const nowFacility = getFacilityNow(timezone);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current || null,
    documentTitle: 'Player Count Graph',
    pageStyle: `@page { margin-top: 0px !important; margin-bottom: 0px !important; size: landscape;}`,
  });

  return (
    <div className={'bg-white rounded shadow-250 my-4 print:max-w-xl'}>
      <div className="flex flex-row justify-between px-4 py-3.5 border-b border-primary-100">
        <div className="flex flex-row space-x-1">
          <ToggleButton
            text={TimePeriod.Today}
            onClick={() => setSelectedTimePeriod(TimePeriod.Today)}
            active={selectedTimePeriod === TimePeriod.Today}
          />
          <ToggleButton
            text={TimePeriod.Yesterday}
            onClick={() => setSelectedTimePeriod(TimePeriod.Yesterday)}
            active={selectedTimePeriod === TimePeriod.Yesterday}
          />
          <ToggleButton
            text={TimePeriod.Last7Days}
            onClick={() => setSelectedTimePeriod(TimePeriod.Last7Days)}
            active={selectedTimePeriod === TimePeriod.Last7Days}
          />
          <ToggleButton
            text={TimePeriod.Last4Weeks}
            onClick={() => setSelectedTimePeriod(TimePeriod.Last4Weeks)}
            active={selectedTimePeriod === TimePeriod.Last4Weeks}
          />
          <ToggleButton
            text={customDatesText}
            onClick={() => {
              setShowModal(!showModal);
            }}
            active={selectedTimePeriod === 'Custom'}
          />
        </div>
        <div className="flex items-center">
          <Legend
            text="Selected range"
            color="green"
            onClick={toggleShowSelectedRange}
            isShowing={showSelectedRange}
          />
          <Legend
            text="Average"
            color="grey"
            onClick={toggleShowAverage}
            isShowing={showAverage}
          />
          <DownloadButton onClick={handlePrint} />
        </div>
      </div>
      <div>
        {isLoading || !currentPlayerCount ? (
          <div className="h-111">
            {isLoading ? <Loading /> : 'No stats data found'}
          </div>
        ) : (
          <Graph
            dataSeries={dataSeries}
            primaryAxisLabel="Player count"
            tooltipValueLabel="players"
            height={445}
            width={width}
            componentRef={componentRef}
            enableTooltip
            facilityId={facilityId}
          />
        )}
      </div>
      <DateRangeModal
        disableAfter={nowFacility}
        showModal={showModal}
        setShowModal={setShowModal}
        permittedDateRange={{ months: 6 }}
        applyDates={applyCustomDatesHandler}
      />
      <div className="h-0 overflow-hidden">
        <PrintablePlayerCountGraph
          dataSeries={dataSeries}
          facilityId={facilityId}
          componentRef={pdfRef}
        />
      </div>
    </div>
  );
};
