import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import DayPicker from 'react-day-picker';
import { isEqual, isSameDay, isWithinInterval, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { classnames } from 'tailwindcss-classnames';

import { useAuthStore, useMetadataStore } from '~/stores';
import { useContainerDimensions } from '~/hooks';
import { usePitchMaintenanceStatus } from './usePitchMaintenanceStatus.hook';
import { DatePickerNavbar } from '~/components/datePicker';
import { PitchMaintenanceLegend } from './pitchMaintenanceLegend';
import { PitchMaintenanceDay } from './PitchMaintenanceDay';
import 'react-day-picker/lib/style.css';
import './styles.css';
import { Button, ButtonType } from '~/components/button';
import { EventDeleteModal, MaintenanceEventModal } from '~/components/modals';
import { userHasCalendarWriteAccess } from '@intelligent-play-v2/lib';
import { CalendarEvent, CalendarEventType } from '@intelligent-play-v2/domain';
import { useDeleteEventModal } from '~/hooks/useDeleteEventModalHook';

interface PitchMaintenanceCalendarProps {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  today: Date;
}

export const PitchMaintenanceCalendar: FC<PitchMaintenanceCalendarProps> = ({
  selectedDate,
  setSelectedDate,
  today,
}) => {
  const { pitchId, facilityId } = useParams<{
    pitchId: string;
    facilityId: string;
  }>();

  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );

  const { facilities } = useMetadataStore();
  const { user } = useAuthStore();
  const [showMaintenanceEventModal, setShowMaintenanceEventModal] = useState(
    false
  );

  const currentFacility = facilities.find(f => f.id === +facilityId);

  const timezone = currentFacility?.tzDatabaseTimezone ?? 'UTC';

  const componentRef = useRef<HTMLDivElement>(null);
  const { width: calendarWidth } = useContainerDimensions(componentRef);
  const {
    setCurrentMonth,
    isLoading,
    pitchMaintenanceStatuses,
    manualMaintenanceEvents,
  } = usePitchMaintenanceStatus(facilityId, pitchId);

  const onCompleteDeletion = (): void => {
    setSelectedEvent(null);
    setShowMaintenanceEventModal(false);
  };

  const {
    isDeleting,
    isDeletingRecurrence,
    onDelete,
    onDeleteRecurrence,
    showDeleteEventModal,
    setShowDeleteEventModal,
  } = useDeleteEventModal(onCompleteDeletion);

  const renderDay = (day: Date): React.ReactNode => {
    const pitchMaintenanceStatus = pitchMaintenanceStatuses.find(status =>
      isEqual(utcToZonedTime(status.date, timezone), startOfDay(day))
    );

    const manualEventsForDay: CalendarEvent[] = manualMaintenanceEvents.filter(
      event => {
        const start = new Date(event.startDate);
        const end = new Date(event.endDate);
        return (
          isWithinInterval(day, {
            start,
            end,
          }) ||
          isSameDay(day, start) ||
          isSameDay(day, end)
        );
      }
    );

    return (
      <PitchMaintenanceDay
        day={day}
        calendarWidth={calendarWidth}
        pitchMaintenanceStatus={pitchMaintenanceStatus}
        manualMaintenanceEvents={manualEventsForDay}
        isSelected={isSameDay(day, selectedDate)}
        editMaintenanceEvent={editMaintenanceEventHandler}
        deleteEvent={deleteEventHandler}
      />
    );
  };

  const isSmallCalendarWidth = calendarWidth < 400;

  const calendarNavBarClass = useMemo(
    () =>
      classnames({
        'h-10': isSmallCalendarWidth,
        'h-14': !isSmallCalendarWidth,
      }),
    [isSmallCalendarWidth]
  );
  const editMaintenanceEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setShowMaintenanceEventModal(true);
  };

  const deleteEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setShowDeleteEventModal(true);
  };

  const weekdays = isSmallCalendarWidth
    ? ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const CalDatePickerNavbar = useCallback(
    props => (
      <DatePickerNavbar
        {...props}
        className={`px-4 mb-2.5 border-b border-primary-100 ${calendarNavBarClass}`}
        isLoading={isLoading}
        legend={<PitchMaintenanceLegend showInfoIcon={isSmallCalendarWidth} />}
      />
    ),
    [calendarNavBarClass, isLoading, isSmallCalendarWidth]
  );

  return (
    <div className="bg-white rounded shadow-250">
      <div className="mx-2" ref={componentRef} />
      <DayPicker
        className="mt-5 PitchMaintenance"
        renderDay={renderDay}
        onDayClick={(day, { disabled }) => !disabled && setSelectedDate(day)}
        enableOutsideDaysClick={false}
        showOutsideDays={true}
        navbarElement={CalDatePickerNavbar}
        captionElement={<></>}
        weekdaysShort={weekdays}
        firstDayOfWeek={1}
        onMonthChange={month => setCurrentMonth(month)}
        disabledDays={{ after: today }}
      />
      {user && userHasCalendarWriteAccess(user) && (
        <div className="px-6 pb-6">
          <Button
            text="Schedule maintenance task"
            type={ButtonType.Secondary}
            onClick={() => setShowMaintenanceEventModal(true)}
          />
        </div>
      )}
      <MaintenanceEventModal
        pitchId={+pitchId}
        eventTypeId={CalendarEventType.MaintenanceTask}
        showModal={showMaintenanceEventModal}
        setShowModal={setShowMaintenanceEventModal}
        calendarEvent={selectedEvent}
        openDeleteModal={() => setShowDeleteEventModal(true)}
      />
      <EventDeleteModal
        isDeleting={isDeleting}
        isDeletingRecurrence={isDeletingRecurrence}
        showRecurrenceDelete={
          !!selectedEvent && !!selectedEvent.calendarRecurrenceEventId
        }
        showModal={showDeleteEventModal}
        closeDeleteModal={() => setShowDeleteEventModal(false)}
        onDelete={() => onDelete(selectedEvent!.id)}
        onDeleteRecurrence={() =>
          selectedEvent?.calendarRecurrenceEventId &&
          onDeleteRecurrence(selectedEvent.calendarRecurrenceEventId)
        }
      />
    </div>
  );
};
