import React, { Dispatch, FC, SetStateAction, useRef } from 'react';
import { Attachment } from '@intelligent-play-v2/domain';
import { AttachmentCell } from './AttachmentCell';

import addAttachmentIcon from 'assets/images/pages/field/modals/icon__attachment-add.png';
import addAttachmentIcon2x from 'assets/images/pages/field/modals/icon__attachment-add@2x.png';
import { useAuthStore } from '~/stores';
import { userHasCalendarWriteAccess } from '@intelligent-play-v2/lib';
import toast from 'react-hot-toast';

interface AttachmentListProps {
  eventId?: number;
  attachments: Attachment[];
  setAttachments: Dispatch<SetStateAction<Attachment[]>>;
}

const FILE_SIZE_LIMIT_BYTES = 10 * 1048576; // 10MB

export const AttachmentList: FC<AttachmentListProps> = ({
  eventId,
  attachments,
  setAttachments,
}) => {
  const { user } = useAuthStore();
  const attachmentInputRef = useRef<HTMLInputElement>(null);

  const inputOnChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event && event.target && event.target.files) {
      const { files } = event.target;
      if (files) {
        const discardedFiles = Object.values(files).filter(
          file => file.size >= FILE_SIZE_LIMIT_BYTES
        );

        if (discardedFiles.length) {
          toast.error(
            `The following files cannot be uploaded because they are larger than 10MB: ${discardedFiles
              .map(({ name }) => name)
              .join(', ')}`
          );
        }

        const permittedAttachments = Object.values(files)
          .filter(file => file.size < FILE_SIZE_LIMIT_BYTES)
          .map((file, i) => ({
            fileName: file.name,
            fileType: file.type,
            id: attachments.length + i,
            file: file,
          }));

        setAttachments([...attachments, ...permittedAttachments]);
      }
    }
  };

  const deleteLocalAttachment = (id: number): void => {
    const newAttachments = attachments.filter(
      attachment => attachment.id !== id
    );
    setAttachments(newAttachments);
  };

  const onNewAttachmentClick = (): void => {
    if (attachmentInputRef.current) {
      attachmentInputRef.current.click();
    }
  };

  return (
    <div>
      <div className="text-sm font-semibold">Attachments</div>
      {attachments.map(attachment => (
        <AttachmentCell
          key={attachment.id}
          eventId={eventId}
          attachment={attachment}
          deleteLocalAttachment={deleteLocalAttachment}
        />
      ))}
      <input
        ref={attachmentInputRef}
        type="file"
        multiple
        accept="image/*, .pdf, .csv"
        className="hidden"
        onChange={inputOnChangeHandler}
      />
      {user && userHasCalendarWriteAccess(user) && (
        <button
          className="flex items-center mt-3.5 hover:opacity-80"
          onClick={onNewAttachmentClick}
        >
          <picture>
            <img
              src={addAttachmentIcon}
              srcSet={`${addAttachmentIcon} 1x, ${addAttachmentIcon2x} 2x`}
            />
          </picture>
          <div className="ml-2 text-base font-semibold text-blue">
            Add new attachment
          </div>
        </button>
      )}
    </div>
  );
};
