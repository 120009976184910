import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import mobile from 'is-mobile';
import { KeyStatisticsRow } from '~/components/data';
import {
  FieldInformation,
  LiveDetections,
} from '~/components/fieldInformation';
import {
  ContentContainer,
  HeaderTab,
  PageHeaderWithTabsAndReturn,
} from '~/components/layout';
import { ContentTab, ContentTabs } from '~/components/tabs';
import { PitchContentRouter } from '~/router/PitchContentRouter';
import { useMetadataStore } from '~/stores';
import { RoutesPath } from '~/types';
import { classnames } from 'tailwindcss-classnames';
import { PromptModal } from '~/components/modals';
import { Button } from '~/components/button';
import { useHistory } from 'react-router';
import { NextEvent } from '~/components/nextEvent';
import { getNextPitchEvent } from '~/api';

export const PitchPage: FC = () => {
  const { facilities } = useMetadataStore();
  const history = useHistory();
  const { facilityId, pitchId } = useParams<{
    facilityId: string;
    pitchId: string;
  }>();
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();

  const selectedFacility = facilities.find(f => f.id === +facilityId);
  const selectedPitch = selectedFacility?.pitches?.find(p => p.id === +pitchId);

  const { data: nextCalendarEvent } = useQuery(
    ['nextPitchEvent', pitchId],
    async () => getNextPitchEvent(pitchId)
  );

  const [isShowingFieldInformation, setIsShowingFieldInformation] = useState(
    true
  );

  if (!selectedFacility) {
    // error handled in parent
    return null;
  }

  if (!selectedPitch) {
    return (
      <PromptModal
        title="Pitch not found"
        type="danger"
        text="This pitch is not found in this facility"
        primaryButton={
          <Button
            text="Close"
            size="small"
            onClick={() =>
              history.push(
                RoutesPath.FacilityOverview.replace(':facilityId', facilityId)
              )
            }
          />
        }
        showModal={true}
      />
    );
  }

  const fieldInfoButtonClass = classnames({
    'text-primary-400': isShowingFieldInformation,
    'text-primary-900': !isShowingFieldInformation,
  });
  const liveViewButtonClass = classnames({
    'text-primary-400': !isShowingFieldInformation,
    'text-primary-900': isShowingFieldInformation,
  });

  return isMobile ? (
    <>
      <PageHeaderWithTabsAndReturn
        title={selectedPitch?.name || ''}
        subtitle={selectedFacility?.name || ''}
      >
        <HeaderTab
          title={'Stats'}
          path={RoutesPath.PitchStats.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <HeaderTab
          title={'Heatmap'}
          path={RoutesPath.PitchHeatmap.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <HeaderTab
          title={'Snapshots'}
          path={RoutesPath.PitchSnapshots.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <HeaderTab
          title={'Maintenance'}
          path={RoutesPath.PitchMaintenance.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <HeaderTab
          title={'Field Testing'}
          path={RoutesPath.PitchFieldTesting.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
      </PageHeaderWithTabsAndReturn>
      <PitchContentRouter />
    </>
  ) : (
    <ContentContainer className="my-12">
      <div className="flex flex-wrap space-y-10 lg:space-x-7.5 lg:flex-nowrap lg:space-y-0 xl:space-x-10 2xl:space-x-15">
        <div className="w-full lg:w-2/3">
          <KeyStatisticsRow facility={selectedFacility} pitchId={+pitchId} />
          <NextEvent
            calendarEvent={nextCalendarEvent ?? null}
            facilityId={+facilityId}
          />
        </div>
        <div className="w-full lg:w-1/3">
          <div className="mb-4">
            <button
              className={`font-content text-xl font-semibold ${liveViewButtonClass}`}
              onClick={() => setIsShowingFieldInformation(true)}
            >
              Field information
            </button>
            <button
              className={`ml-4 font-content text-xl font-semibold ${fieldInfoButtonClass}`}
              onClick={() => setIsShowingFieldInformation(false)}
            >
              Live view
            </button>
          </div>
          {isShowingFieldInformation ? (
            <FieldInformation pitch={selectedPitch} />
          ) : (
            <LiveDetections pitch={selectedPitch} />
          )}
        </div>
      </div>

      <ContentTabs>
        <ContentTab
          strict
          exact
          title="Usage"
          path={RoutesPath.PitchUsage.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <ContentTab
          strict
          exact
          title="Heatmap"
          path={RoutesPath.PitchHeatmap.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <ContentTab
          strict
          exact
          title="Snapshots"
          path={RoutesPath.PitchSnapshots.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <ContentTab
          strict
          exact
          title="Maintenance"
          path={RoutesPath.PitchMaintenance.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <ContentTab
          strict
          exact
          title="Field Testing"
          path={RoutesPath.PitchFieldTesting.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
        <ContentTab
          strict
          exact
          title="Calendar"
          path={RoutesPath.PitchCalendar.replace(
            ':facilityId',
            facilityId
          ).replace(':pitchId', pitchId)}
        />
      </ContentTabs>
      <PitchContentRouter />
    </ContentContainer>
  );
};
