import { UserActivity } from '@intelligent-play-v2/domain';
import { format } from 'date-fns';
import React, { FC, useState } from 'react';

interface UserActivityHistoryProps {
  userActivity: UserActivity[];
}

export const UserActivityHistory: FC<UserActivityHistoryProps> = ({
  userActivity,
}) => {
  const [userActivityLimit, setUserActivityLimit] = useState<number>(10);
  const userHasActivity = userActivity && userActivity.length !== 0;
  const showLoadMore =
    userHasActivity && userActivity.length > 10 && userActivityLimit === 10;

  return (
    <div className="bg-white rounded shadow-250">
      <div className="flex flex-row p-3 border-b border-primary-100">
        <span className="font-semibold">User activity history</span>
      </div>
      <div className="flex-col px-5 pt-3 pb-5">
        {!userHasActivity && <span className="">No activity</span>}
        {userHasActivity &&
          userActivity.slice(0, userActivityLimit).map(userActivityItem => {
            return (
              <div
                className="flex justify-between text-sm font-semibold leading-loose "
                key={userActivityItem.id}
              >
                <span>{userActivityItem.type}</span>
                <span>
                  {format(
                    new Date(userActivityItem.startTime),
                    'd MMM yyyy @ H:mm'
                  )}
                </span>
              </div>
            );
          })}
        {showLoadMore && (
          <div
            className="pt-3 font-semibold text-green cursor-pointer"
            onClick={() => setUserActivityLimit(20)}
          >
            Load more
          </div>
        )}
      </div>
    </div>
  );
};
