import React, { FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { utcToZonedTime } from 'date-fns-tz/esm';
import {
  Facility,
  ReportArea,
  StatsDates,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import { getDateRangeFromPeriod } from '@intelligent-play-v2/lib';
import { useReportStore } from '~/stores/useReportStore';
import { formatDateGbUs, setDataResponseAsToggleableDataStore } from '~/utils';
import {
  getMachineMaintenanceSessions,
  getPlayerCount,
  getPlayerHours,
  getUsageHours,
} from '~/api';
import { useQuery } from 'react-query';
import { useReportQueryParams } from '~/hooks/useReportQueryParams.hook';
import { ToggleableReportGraph } from '../graphs/ToggleableReportGraph';
import { format } from 'date-fns';
import { FilteredStatisticsParams, ReportGraphType } from '~/types';

import { StatisticTables } from './statisticTables/StatisticTables';
import { getReportMaintenanceHours } from '~/api/queries/stats/getReportMaintenanceHours';
import { LabelledCheckbox } from '../labelledCheckbox';
import { ReportLoadingContext } from '~/contexts';
import {
  useEuhDataStore,
  useMaintenanceHoursDataStore,
  useMaintenanceSessionsDataStore,
  usePlayerCountDataStore,
  usePlayerHoursDataStore,
  useUsageHoursDataStore,
} from '~/stores';

interface FacilityReportProps {
  facility: Facility;
  timePeriod: TimePeriod;
  pitchesStats: ReactNode;
  customDates: StatsDates | null;
}

export const FacilityReport: FC<FacilityReportProps> = ({
  facility,
  timePeriod,
  pitchesStats,
  customDates,
}) => {
  const { isLoading } = useContext(ReportLoadingContext);

  const {
    pdfIncludedFacilityIds,
    togglePdfIncludedFacilityId,
    selectedReportAreaIds,
  } = useReportStore();

  const {
    isContinuous,
    includedDays,
    dailyStartTime,
    dailyEndTime,
  } = useReportQueryParams();

  const timezone = useMemo(() => facility.tzDatabaseTimezone ?? 'UTC', [
    facility,
  ]);

  const { startDate, endDate } =
    timePeriod === TimePeriod.Custom && customDates
      ? { ...customDates }
      : getDateRangeFromPeriod(timePeriod, timezone);

  const isIncluded =
    pdfIncludedFacilityIds.has(facility.id) &&
    pdfIncludedFacilityIds.get(facility.id);

  const pitchIds = useMemo(() => facility.pitches?.map(({ id }) => id) ?? [], [
    facility,
  ]);

  const requestParams: FilteredStatisticsParams = {
    pitchIds,
    timePeriod,
    timezone,
    dailyStartTime,
    dailyEndTime,
    includedDays,
  };

  if (timePeriod === TimePeriod.Custom && customDates) {
    requestParams.from = format(customDates.startDate, 'yyyy-MM-dd');
    requestParams.to = format(customDates.endDate, 'yyyy-MM-dd');
  }

  // PLAYER HOURS
  const shouldQueryPlayerHours =
    selectedReportAreaIds &&
    (selectedReportAreaIds.includes(ReportArea.PlayerHours) ||
      selectedReportAreaIds.includes(ReportArea.EUH));
  const includePlayerHours =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.PlayerHours);

  const { data: playerHoursData } = useQuery(
    ['playerHours', requestParams],
    async () => getPlayerHours(requestParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      suspense: true,
      enabled: shouldQueryPlayerHours,
    }
  );

  // EUH - Sharing the player hours request
  const includeEuh =
    selectedReportAreaIds && selectedReportAreaIds.includes(ReportArea.EUH);

  // PLAYER COUNT
  const includePlayerCount =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.PlayerCount);

  const { data: playerCountData } = useQuery(
    ['playerCount', requestParams],
    async () => getPlayerCount(requestParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      suspense: true,
      enabled: includePlayerCount,
    }
  );

  // USAGE HOURS
  const includeUsageHours =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.UsageHours);

  const { data: usageHoursData } = useQuery(
    ['usageHours', requestParams],
    async () => getUsageHours(requestParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      suspense: true,
      enabled: includeUsageHours,
    }
  );

  // MAINTENANCE HOURS
  const includeMaintenanceHours =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.MachineMaintenanceHours);

  const { data: maintenanceHoursData } = useQuery(
    ['maintenanceHours', requestParams],
    async () => getReportMaintenanceHours(requestParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      suspense: true,
      enabled: includeMaintenanceHours,
    }
  );

  // MAINTENANCE SESSIONS
  const includeMachineMaintenanceSessions =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.MachineMaintenanceSessions);

  const { data: machineMaintenanceSessionsData } = useQuery(
    ['machineMaintenanceSessions', requestParams],
    async () => getMachineMaintenanceSessions(requestParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      suspense: true,
      enabled: includeMachineMaintenanceSessions,
    }
  );

  const {
    setFacilityDataSeries: setPlayerHoursDataSeries,
    facilityDataSeries: playerHoursDataSeries,
    toggleLine: togglePlayerHoursLine,
    dailySeriesCollections: playerHoursDailySeriesCollections,
    setDailySeriesCollections: setPlayerHoursDailySeriesCollections,
    toggleCollectionLine: togglePlayerHoursCollectionLine,
  } = usePlayerHoursDataStore();

  useEffect(() => {
    if (playerHoursData) {
      setDataResponseAsToggleableDataStore({
        isContinuous,
        facility,
        timezone,
        dataResponse: playerHoursData,
        reportGraphType: ReportGraphType.PlayerHours,
        setContinuousData: setPlayerHoursDataSeries,
        setNonContinuousData: setPlayerHoursDailySeriesCollections,
      });
    }
  }, [
    isContinuous,
    facility,
    timezone,
    playerHoursData,
    setPlayerHoursDailySeriesCollections,
    setPlayerHoursDataSeries,
  ]);

  const {
    setFacilityDataSeries: setEuhDataSeries,
    facilityDataSeries: euhDataSeries,
    toggleLine: toggleEuhLine,
    dailySeriesCollections: euhDailySeriesCollections,
    setDailySeriesCollections: setEuhDailySeriesCollections,
    toggleCollectionLine: toggleEuhCollectionLine,
  } = useEuhDataStore();

  useEffect(() => {
    if (playerHoursData) {
      setDataResponseAsToggleableDataStore({
        isContinuous,
        facility,
        timezone,
        dataResponse: playerHoursData,
        reportGraphType: ReportGraphType.EUH,
        setContinuousData: setEuhDataSeries,
        setNonContinuousData: setEuhDailySeriesCollections,
      });
    }
  }, [
    facility,
    isContinuous,
    playerHoursData,
    setEuhDailySeriesCollections,
    setEuhDataSeries,
    timezone,
  ]);

  const {
    setFacilityDataSeries: setPlayerCountDataSeries,
    facilityDataSeries: playerCountDataSeries,
    toggleLine: togglePlayerCountLine,
    dailySeriesCollections: playerCountDailySeriesCollections,
    setDailySeriesCollections: setPlayerCountDailySeriesCollections,
    toggleCollectionLine: togglePlayerCountCollectionLine,
  } = usePlayerCountDataStore();

  useEffect(() => {
    if (playerCountData) {
      setDataResponseAsToggleableDataStore({
        isContinuous,
        facility,
        timezone,
        dataResponse: playerCountData,
        reportGraphType: ReportGraphType.PlayerCount,
        setContinuousData: setPlayerCountDataSeries,
        setNonContinuousData: setPlayerCountDailySeriesCollections,
      });
    }
  }, [
    facility,
    isContinuous,
    playerCountData,
    setPlayerCountDailySeriesCollections,
    setPlayerCountDataSeries,
    timezone,
  ]);

  const {
    setFacilityDataSeries: setUsageHoursDataSeries,
    facilityDataSeries: usageHoursDataSeries,
    toggleLine: toggleUsageHoursLine,
    dailySeriesCollections: usageHoursDailySeriesCollections,
    setDailySeriesCollections: setUsageHoursDailySeriesCollections,
    toggleCollectionLine: toggleUsageHoursCollectionLine,
  } = useUsageHoursDataStore();

  useEffect(() => {
    if (usageHoursData) {
      setDataResponseAsToggleableDataStore({
        isContinuous,
        facility,
        timezone,
        dataResponse: usageHoursData,
        reportGraphType: ReportGraphType.UsageHours,
        setContinuousData: setUsageHoursDataSeries,
        setNonContinuousData: setUsageHoursDailySeriesCollections,
      });
    }
  }, [
    facility,
    isContinuous,
    usageHoursData,
    setUsageHoursDailySeriesCollections,
    setUsageHoursDataSeries,
    timezone,
  ]);

  const {
    setFacilityDataSeries: setMaintenanceHoursDataSeries,
    facilityDataSeries: maintenanceHoursDataSeries,
    toggleLine: toggleMaintenanceHoursLine,
    dailySeriesCollections: maintenanceHoursDailySeriesCollections,
    setDailySeriesCollections: setMaintenanceHoursDailySeriesCollections,
    toggleCollectionLine: toggleMaintenanceHoursCollectionLine,
  } = useMaintenanceHoursDataStore();

  useEffect(() => {
    if (maintenanceHoursData) {
      setDataResponseAsToggleableDataStore({
        isContinuous,
        facility,
        timezone,
        dataResponse: maintenanceHoursData,
        reportGraphType: ReportGraphType.MachineMaintenanceHours,
        setContinuousData: setMaintenanceHoursDataSeries,
        setNonContinuousData: setMaintenanceHoursDailySeriesCollections,
      });
    }
  }, [
    facility,
    isContinuous,
    maintenanceHoursData,
    setMaintenanceHoursDailySeriesCollections,
    setMaintenanceHoursDataSeries,
    timezone,
  ]);

  const {
    setFacilityDataSeries: setMaintenanceSessionsDataSeries,
    facilityDataSeries: maintenanceSessionsDataSeries,
    toggleLine: toggleMaintenanceSessionsLine,
    dailySeriesCollections: maintenanceSessionsDailySeriesCollections,
    setDailySeriesCollections: setMaintenanceSessionsDailySeriesCollections,
    toggleCollectionLine: toggleMaintenanceSessionsCollectionLine,
  } = useMaintenanceSessionsDataStore();

  useEffect(() => {
    if (machineMaintenanceSessionsData) {
      setDataResponseAsToggleableDataStore({
        isContinuous,
        facility,
        timezone,
        dataResponse: machineMaintenanceSessionsData,
        reportGraphType: ReportGraphType.MachineMaintenanceSessions,
        setContinuousData: setMaintenanceSessionsDataSeries,
        setNonContinuousData: setMaintenanceSessionsDailySeriesCollections,
      });
    }
  }, [
    facility,
    isContinuous,
    machineMaintenanceSessionsData,
    setMaintenanceSessionsDailySeriesCollections,
    setMaintenanceSessionsDataSeries,
    timezone,
  ]);

  return (
    <>
      <div className="flex justify-between pb-4.5 mt-20 text-primary-900 border-b border-primary-900 border-opacity-10">
        <div className="flex mt-3 space-x-5">
          <div className="font-tw-cent text-3xl font-bold">{facility.name}</div>
          <div>
            {formatDateGbUs(
              utcToZonedTime(startDate, timezone),
              'E d MMM yyyy'
            )}
            {' - '}
            {formatDateGbUs(utcToZonedTime(endDate, timezone), 'E d MMM yyyy')}
          </div>
        </div>
        <LabelledCheckbox
          title={'Include this facility in PDF Export'}
          isChecked={isIncluded || false}
          toggleChecked={() => togglePdfIncludedFacilityId(facility.id)}
        />
      </div>
      <div className="mt-10 text-xl font-semibold">
        Stats overview for {facility.pitches?.length} Field
        {facility.pitches!.length > 1 && 's'}
      </div>
      <div className="mt-5">
        <StatisticTables facility={facility} />
      </div>
      <div className="mt-10">
        <div className="text-xl font-semibold">Field Usage</div>
        {includePlayerHours &&
          (isContinuous
            ? playerHoursDataSeries && (
                <ToggleableReportGraph
                  graphType={ReportGraphType.PlayerHours}
                  toggleableDataSeries={
                    playerHoursDataSeries[facility.id] ?? []
                  }
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={togglePlayerHoursLine}
                />
              )
            : (
                playerHoursDailySeriesCollections[facility.id] || []
              ).map(collection => (
                <ToggleableReportGraph
                  graphType={ReportGraphType.PlayerHours}
                  key={collection.key}
                  toggleableDataSeries={collection.data}
                  pitchName={collection.name}
                  isDailyGraph
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={togglePlayerHoursCollectionLine}
                />
              )))}
        {includeEuh &&
          (isContinuous
            ? euhDataSeries && (
                <ToggleableReportGraph
                  graphType={ReportGraphType.EUH}
                  toggleableDataSeries={euhDataSeries[facility.id] ?? []}
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={toggleEuhLine}
                />
              )
            : (euhDailySeriesCollections[facility.id] ?? []).map(collection => (
                <ToggleableReportGraph
                  graphType={ReportGraphType.EUH}
                  key={collection.key}
                  toggleableDataSeries={collection.data}
                  pitchName={collection.name}
                  isDailyGraph
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={toggleEuhCollectionLine}
                />
              )))}
        {includePlayerCount &&
          (isContinuous
            ? playerCountDataSeries && (
                <ToggleableReportGraph
                  graphType={ReportGraphType.PlayerCount}
                  toggleableDataSeries={
                    playerCountDataSeries[facility.id] ?? []
                  }
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={togglePlayerCountLine}
                />
              )
            : (
                playerCountDailySeriesCollections[facility.id] ?? []
              ).map(collection => (
                <ToggleableReportGraph
                  graphType={ReportGraphType.PlayerCount}
                  key={collection.key}
                  toggleableDataSeries={collection.data}
                  pitchName={collection.name}
                  isDailyGraph
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={togglePlayerCountCollectionLine}
                />
              )))}

        {includeUsageHours &&
          (isContinuous
            ? usageHoursDataSeries && (
                <ToggleableReportGraph
                  graphType={ReportGraphType.UsageHours}
                  toggleableDataSeries={usageHoursDataSeries[facility.id] ?? []}
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={toggleUsageHoursLine}
                />
              )
            : (
                usageHoursDailySeriesCollections[facility.id] ?? []
              ).map(collection => (
                <ToggleableReportGraph
                  graphType={ReportGraphType.UsageHours}
                  key={collection.key}
                  toggleableDataSeries={collection.data}
                  pitchName={collection.name}
                  isDailyGraph
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={toggleUsageHoursCollectionLine}
                />
              )))}

        {includeMaintenanceHours &&
          (isContinuous
            ? maintenanceHoursDataSeries && (
                <ToggleableReportGraph
                  graphType={ReportGraphType.MachineMaintenanceHours}
                  toggleableDataSeries={
                    maintenanceHoursDataSeries[facility.id] ?? []
                  }
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={toggleMaintenanceHoursLine}
                />
              )
            : (
                maintenanceHoursDailySeriesCollections[facility.id] ?? []
              ).map(collection => (
                <ToggleableReportGraph
                  graphType={ReportGraphType.MachineMaintenanceHours}
                  key={collection.key}
                  toggleableDataSeries={collection.data}
                  pitchName={collection.name}
                  isDailyGraph
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={toggleMaintenanceHoursCollectionLine}
                />
              )))}
        {includeMachineMaintenanceSessions &&
          (isContinuous
            ? maintenanceSessionsDataSeries && (
                <ToggleableReportGraph
                  graphType={ReportGraphType.MachineMaintenanceSessions}
                  toggleableDataSeries={
                    maintenanceSessionsDataSeries[facility.id] ?? []
                  }
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={toggleMaintenanceSessionsLine}
                />
              )
            : (
                maintenanceSessionsDailySeriesCollections[facility.id] ?? []
              ).map(collection => (
                <ToggleableReportGraph
                  graphType={ReportGraphType.MachineMaintenanceSessions}
                  key={collection.key}
                  toggleableDataSeries={collection.data}
                  pitchName={collection.name}
                  isDailyGraph
                  facilityId={facility.id}
                  isLoading={isLoading}
                  toggleLine={toggleMaintenanceSessionsCollectionLine}
                />
              )))}
      </div>
      <div>{pitchesStats}</div>
    </>
  );
};
