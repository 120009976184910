import React, { FC, useState } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { Loading } from '../loading';
import { FacilityTaskItem } from './TaskItem';
import { FacilityTask } from '~/types';
import { MinimiseButton } from './MinimiseButton';

interface TaskListProps {
  taskList?: FacilityTask[];
  isLoading: boolean;
  hideView?: boolean;
}

export const TaskList: FC<TaskListProps> = ({
  taskList,
  isLoading,
  hideView,
}) => {
  const [isShowingAllTasks, setIsShowingAllTasks] = useState(false);
  const minimisedTasksNum = 3;
  if (!taskList || taskList.length === 0) {
    return (
      <div className="p-4 text-base font-semibold text-center bg-white rounded shadow-250">
        {isLoading ? <Loading /> : 'You have no tasks'}
      </div>
    );
  }

  const displayedTasks = taskList?.slice(0, minimisedTasksNum);

  const hiddenTasks = taskList.slice(minimisedTasksNum);

  const taskContainer = classnames({ 'lg:mb-10': isShowingAllTasks });

  return (
    <div className="relative">
      <div className="hidden text-xl font-semibold md:block">
        Tasks overview
      </div>
      <div className={`mt-4 bg-white rounded shadow-250 ${taskContainer}`}>
        {displayedTasks.map(facilityTask => (
          <FacilityTaskItem
            facilityTask={facilityTask}
            key={facilityTask.id}
            hideView={hideView}
          />
        ))}

        <div className="left-0 right-0 z-10 bg-white lg:absolute">
          {isShowingAllTasks && (
            <>
              {hiddenTasks.map(facilityTask => (
                <FacilityTaskItem
                  facilityTask={facilityTask}
                  key={facilityTask.id}
                  hideView={hideView}
                />
              ))}
            </>
          )}
          {taskList?.slice(minimisedTasksNum).length > 0 && (
            <div className="left-0 right-0 lg:absolute">
              <MinimiseButton
                text={
                  isShowingAllTasks
                    ? 'Minimise tasks'
                    : `+${taskList?.slice(minimisedTasksNum).length} more tasks`
                }
                onClick={() => setIsShowingAllTasks(!isShowingAllTasks)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
