import { DailyWeather } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { getPreferredTemperature, getWeatherIcon } from '~/utils';

interface WeatherPreviewCardProps {
  weather: DailyWeather;
  dateString: string;
  isLastElement?: boolean;
}

export const WeatherPreviewCard: FC<WeatherPreviewCardProps> = ({
  weather,
  dateString,
  isLastElement,
}) => {
  // Tailwind v1 does not support :last
  const borderStyle = classnames({ 'border-r': !isLastElement });

  return (
    <div className={`${borderStyle} flex-grow w-20 border-primary-200 pb-2`}>
      <div className="mt-1.5 mx-2">
        <div className="text-xs text-center text-primary-300">{dateString}</div>

        <div className="pt-1 pl-1.5">
          <picture className="absolute">
            <img
              className="relative w-6 left-0.5"
              src={getWeatherIcon(weather.weatherCode)}
            />
          </picture>
          <div className="relative pt-1 text-center">
            <span className="text-xl font-semibold">
              {getPreferredTemperature(weather.highTempCelsius)}°
            </span>
          </div>
        </div>

        <div className="text-xs font-semibold text-center two-lines-ellipsis">
          {weather.summary}
        </div>
      </div>
    </div>
  );
};
