/* eslint-disable @typescript-eslint/no-explicit-any */

import { Attachment } from '@intelligent-play-v2/domain';
import axios, { AxiosResponse, Method } from 'axios';
import { getApiUrl } from '~/utils';

export const API_URL = getApiUrl();

export const queryBuilder = async (
  method: Method,
  endpoint: string,
  params?: Record<string, unknown>,
  data?: Record<string, any>,
  attachments?: Attachment[]
): Promise<AxiosResponse<any>> => {
  let requestData = data;

  if (attachments && attachments.length && data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value as string | Blob);
    });

    attachments.forEach(attachment => {
      if (attachment.file) {
        formData.append('attachments', attachment.file);
      }
    });

    requestData = formData;
  }

  return axios({
    method,
    url: `${API_URL}/${endpoint}`,
    data: requestData,
    params,
    withCredentials: true,
    headers:
      attachments && attachments.length
        ? {
            'Content-Type': 'multipart/form-data',
          }
        : {},
  });
};
