import React, { FC, Suspense, useState } from 'react';
import { Loading } from '~/components/loading';

import { ReportPreview } from '~/components/reportPreview';
import { ReportLoadingContext } from '~/contexts';
import { usePageTitle } from '~/hooks';
import { ReportLoadingMessage } from './ReportLoadingMessage';

export const ReportResultsPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  usePageTitle('Report Result - Intelligent Play');
  return (
    <ReportLoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <Suspense
        fallback={<Loading isFullScreen message={<ReportLoadingMessage />} />}
      >
        <ReportPreview />
      </Suspense>
    </ReportLoadingContext.Provider>
  );
};
