import React, { FC } from 'react';
import MediaQuery from 'react-responsive';

import { Facility, FacilityAggregate } from '@intelligent-play-v2/domain';

import iconEuh from 'assets/images/pages/dashboard/facilities/icon__EUH.png';
import iconMaintenance from 'assets/images/pages/dashboard/facilities/icon__maintenance.png';
import iconPlayerHours from 'assets/images/pages/facilities/facilities/icon__player-hours.png';
import iconUsageHours from 'assets/images/pages/facilities/facilities/icon__usage-hours.png';
import iconEuh2x from 'assets/images/pages/dashboard/facilities/icon__EUH@2x.png';
import iconMaintenance2x from 'assets/images/pages/dashboard/facilities/icon__maintenance@2x.png';
import iconPlayerHours2x from 'assets/images/pages/facilities/facilities/icon__player-hours@2x.png';
import iconUsageHours2x from 'assets/images/pages/facilities/facilities/icon__usage-hours@2x.png';
import classnames from 'tailwindcss-classnames';

import { StatSummarySmall } from './StatSummarySmall';

interface FacilityStatsProps {
  facility: Facility;
  data: FacilityAggregate | undefined;
  width: '2/3' | 'full';
}

const XXL_SCREEN_WIDTH_PX = 1536;

export const FacilityStats: FC<FacilityStatsProps> = ({ data, width }) => {
  const containerClass = classnames(
    {
      '2xl:grid-cols-2': width === '2/3',
    },
    {
      '2xl:grid-cols-4': width === 'full',
    }
  );
  return (
    <div
      className={`grid grid-cols-2 sm:block sm:space-y-1 transform translate-y-0.5 lg:grid lg:grid-cols-2 lg:space-y-0 2xl:grid-cols-4 lg:translate-y-0 ${containerClass}`}
    >
      {width === 'full' && (
        <>
          <MediaQuery minWidth={XXL_SCREEN_WIDTH_PX}>
            <StatSummarySmall
              icon={iconPlayerHours}
              icon2x={iconPlayerHours2x}
              name="Player hours"
              value={data?.playerHours.datesTotal}
              percentageChange={data?.playerHours.percentageChange ?? null}
            />
          </MediaQuery>
          <MediaQuery minWidth={XXL_SCREEN_WIDTH_PX}>
            <StatSummarySmall
              icon={iconUsageHours}
              icon2x={iconUsageHours2x}
              name="Usage hours"
              value={data?.usageHours.datesTotal}
              percentageChange={data?.usageHours.percentageChange ?? null}
            />
          </MediaQuery>
        </>
      )}

      <StatSummarySmall
        icon={iconEuh}
        icon2x={iconEuh2x}
        name="Total EUH"
        value={data?.euh.datesTotal}
        percentageChange={data?.euh.percentageChange ?? null}
      />
      <StatSummarySmall
        icon={iconMaintenance}
        icon2x={iconMaintenance2x}
        name="Maintenance hours"
        value={data?.maintenanceHours.datesTotal}
        percentageChange={data?.maintenanceHours.percentageChange ?? null}
      />
    </div>
  );
};
