import React, { FC } from 'react';
import { ContentContainer } from '~/components/layout';
import { UsersTable } from '~/components/table/usersTable';

export const AdminUsersPage: FC = () => {
  return (
    <ContentContainer className="py-12.5">
      <UsersTable
        showTitle
        showTableHeaders
        showAddUserButton
        showCount
        rowsPerPage={10}
      />
    </ContentContainer>
  );
};
