import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonType } from '~/components/button';
import { FacilityRow, RoutesPath } from '~/types';
import { FacilityName } from './FacilityName';
import { FacilityStats } from './FacilityStats';
import { FacilityUsers } from './FacilityUsers';

interface FacilityRowMobileProps {
  facilityRow: FacilityRow;
  index: number;
}

export const FacilityRowMobile: FC<FacilityRowMobileProps> = ({
  facilityRow,
  index,
}) => {
  return (
    <div className="bg-white rounded shadow-250">
      <div className="flex justify-between p-4 space-x-4 border-b border-primary-100">
        <FacilityName facility={facilityRow.facility} />
        <Link
          to={{
            pathname: RoutesPath.FacilityOverview.replace(
              ':facilityId',
              String(facilityRow.facility.id)
            ),
          }}
          id={index === 0 ? 'first-view' : undefined}
        >
          <Button
            text="View"
            type={ButtonType.Secondary}
            size="small"
            className="pl-5 pr-5"
          />
        </Link>
      </div>
      <div className="py-2.5 px-4 flex justify-between items-center">
        <FacilityStats
          facility={facilityRow.facility}
          data={facilityRow.facilityAggregate}
          width="full"
        />
        <FacilityUsers users={facilityRow.facility.staff ?? []} />
      </div>
    </div>
  );
};
