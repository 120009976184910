import React, { FC, useEffect, useState } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Select, SelectOptionType } from '~/components/select';
import { UserThumbnail, UserThumbnailType } from '~/components/userThumbnail';
import { TextInput } from '~/components/forms';
import { User, UserType, getUserTypeString } from '@intelligent-play-v2/domain';
import { enumToObjectArray } from '~/utils/enumToObjectArray';
import { getUserInitials } from '~/utils/getUserInitials';
import { Button, ButtonType } from '../button';
import { ChangePasswordModal } from '../modals/modal/changePasswordModal/changePasswordModal';

interface UserDetailsProps {
  user: User;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: { [x: string]: any };
  userInputImageRef: React.MutableRefObject<HTMLInputElement | null>;
  setImageFile: (value: React.SetStateAction<File | undefined>) => void;
  imagePreview: string;
}

export const UserDetails: FC<UserDetailsProps> = ({
  user,
  register,
  setValue,
  errors,
  userInputImageRef,
  setImageFile,
  imagePreview,
}) => {
  const [showChangePasswordModal, setshowChangePasswordModal] = useState(false);

  // User type
  const userTypeOptions: SelectOptionType[] = enumToObjectArray(
    UserType as never
  ).map(({ id }) => ({
    label: getUserTypeString(id as UserType),
    value: id.toString(),
  }));

  const [selectedUserType, setSelectedUserType] = useState<SelectOptionType>(
    userTypeOptions.find(userType => user.userTypeId === +userType.value) ||
      userTypeOptions[0]
  );
  const handleUserTypeChange = (option: SelectOptionType | null): void => {
    const newValue = option ? option : userTypeOptions[0];
    setValue('userTypeId', newValue.value);
    setSelectedUserType(newValue);
  };

  let initials = '';
  let firstName: string, lastName: string;
  if (user.name) {
    [firstName, lastName] = user.name.split(' ');
    initials = getUserInitials(user.name);
  }

  useEffect(() => {
    if (user.name) {
      setValue('firstName', firstName);
      setValue('lastName', lastName);
    }
    setValue('email', user.email || '');
    setValue('phoneNumber', user.phoneNumber || '');
    setValue('userTypeId', selectedUserType.value);
    setValue('jobTitle', user.jobTitle || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setValue]);

  const onProfileImageClick = (): void => {
    if (userInputImageRef.current) {
      userInputImageRef.current.click();
    }
  };

  const inputOnChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        setImageFile(file);
      }
    }
  };

  const firstNameInput = (
    <TextInput
      register={register('firstName', {
        required: 'Please enter a first name',
      })}
      label="First name"
      placeholder="Enter first name"
      required
      type="text"
      isLightMode
      errorMessage={errors.firstName?.message}
      disabled
    />
  );

  const lastNameInput = (
    <TextInput
      register={register('lastName', {
        required: 'Please enter a last name',
      })}
      label="Last name"
      placeholder="Enter last name"
      required
      type="text"
      isLightMode
      errorMessage={errors.lastName?.message}
      disabled
    />
  );

  return (
    <div className="rounded sm:shadow-250 sm:bg-white">
      <div className="flex-row hidden p-3 border-primary-100 sm:border-b sm:flex">
        <span className="font-semibold">Personal details</span>
      </div>
      <form className="flex flex-col sm:px-4 sm:pt-6 sm:pb-8 lg:px-8 lg:space-x-8 lg:flex-row">
        <div className="flex w-auto space-x-8">
          <div>
            <input
              ref={userInputImageRef}
              type="file"
              accept="image/png, image/gif, image/jpeg"
              className="hidden"
              onChange={inputOnChangeHandler}
            />
            <UserThumbnail
              type={UserThumbnailType.UserDetails}
              text={initials}
              onClick={onProfileImageClick}
              imageSrc={
                imagePreview.length > 0 ? imagePreview : user.imageThumbnailUrl
              }
            />
          </div>
          <div className="block w-full space-y-4 md:hidden">
            {firstNameInput}
            {lastNameInput}
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-col space-y-5">
            <div className="hidden w-full mt-6 space-x-3 md:flex">
              <div className="w-1/2">{firstNameInput}</div>
              <div className="w-1/2">{lastNameInput}</div>
            </div>
            <div className="w-full space-y-5 sm:space-x-3 sm:flex sm:space-y-0">
              <div className="w-full sm:w-1/2">
                <TextInput
                  register={register('email', {
                    pattern: {
                      value: /^\S+@\S+\.\S+$/,
                      message: 'Please enter a valid email address',
                    },
                    required: 'Please enter a valid email address',
                  })}
                  label="Email address"
                  placeholder="Enter email address"
                  type="email"
                  required
                  isLightMode
                  errorMessage={errors.email?.message}
                  disabled
                />
              </div>
              <div className="w-full sm:w-1/2">
                <TextInput
                  register={register('phoneNumber')}
                  label="Phone number"
                  placeholder="Enter phone number"
                  isLightMode
                  errorMessage={errors.phoneNumber?.message}
                />
              </div>
            </div>

            <div className="w-full space-y-5 sm:space-x-3 sm:flex sm:space-y-0">
              <div className="w-1/2">
                <div className="text-sm font-semibold text-primary-400">
                  <p>User type</p>
                </div>
                <div className="mt-2.5 cursor-not-allowed">
                  <Select
                    options={userTypeOptions}
                    selectedValue={selectedUserType}
                    onChange={handleUserTypeChange}
                    isForm
                    shadow
                    disabled
                  />
                </div>
              </div>
              <div className="w-full sm:w-1/2">
                <TextInput
                  register={register('jobTitle')}
                  label="Job title"
                  placeholder="Enter job title"
                  isLightMode
                  errorMessage={errors.jobTitle?.message}
                />
              </div>
            </div>
            <div className="flex w-full">
              <div className="pb-6">
                <Button
                  size="small"
                  text="Change Password"
                  type={ButtonType.Outline}
                  onClick={() => setshowChangePasswordModal(true)}
                />
              </div>
            </div>
            <ChangePasswordModal
              user={user}
              showModal={showChangePasswordModal}
              setShowModal={setshowChangePasswordModal}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
