import React, { ChangeEvent, FC, MouseEvent } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { Checkbox } from '../checkbox';

interface LabelledCheckboxProps {
  title: string;
  isChecked: boolean;
  toggleChecked: (
    event: MouseEvent<HTMLElement> | ChangeEvent<HTMLInputElement>
  ) => void;
  disabled?: boolean;
}

export const LabelledCheckbox: FC<LabelledCheckboxProps> = ({
  title,
  isChecked,
  toggleChecked,
  disabled,
}) => {
  const checkbossClass = classnames(
    { 'pointer-events-none': disabled },
    { 'opacity-50': disabled }
  );
  return (
    <div
      onClick={toggleChecked}
      className={`flex p-2 space-x-2 bg-white rounded-xs cursor-pointer ${checkbossClass}`}
    >
      <Checkbox isChecked={isChecked} />
      <div className="text-base">{title}</div>
    </div>
  );
};
