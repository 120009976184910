import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { classnames } from 'tailwindcss-classnames';
import { RoutesPath, UserRow } from '~/types';
import { formatDateGbUs } from '~/utils';
import editIcon from 'assets/images/pages/admin/icon__user-edit.png';
import editIcon2x from 'assets/images/pages/admin/icon__user-edit@2x.png';
import { useMediaQuery } from 'react-responsive';
import { UserActivityType } from '@intelligent-play-v2/domain';
import { isAfter, subHours } from 'date-fns';

interface UserStatusProps {
  userRow: UserRow;
  isFullWidth: boolean;
}

export const UserStatus: FC<UserStatusProps> = ({ userRow, isFullWidth }) => {
  const isSmallerThanLg = useMediaQuery({ query: '(max-width: 1024px)' });
  const linkEnabled = !isSmallerThanLg && isFullWidth;

  const lastLoginActivity = userRow.userActivity?.find(
    userActivity => userActivity.type === UserActivityType.LoggedIn
  );

  const onlineTime = subHours(new Date(), 8);
  const isOnline =
    lastLoginActivity &&
    isAfter(new Date(lastLoginActivity.startTime), onlineTime);

  const statusIndicatorClass = classnames(
    { 'bg-red': !isOnline },
    { 'bg-green': isOnline }
  );

  return (
    <div className="flex items-center space-x-5">
      {linkEnabled && (
        <Link
          className={`${
            isFullWidth ? 'flex-shrink-0 hidden ml-auto lg:block' : 'hidden'
          }`}
          to={{
            pathname: RoutesPath.AdminUserProfile.replace(
              ':userId',
              userRow.id.toString()
            ),
          }}
        >
          <picture className="">
            <img src={editIcon} srcSet={`${editIcon} 1x, ${editIcon2x} 2x`} />
          </picture>
        </Link>
      )}
      <div
        className={`${
          isFullWidth
            ? `${statusIndicatorClass} w-2.5 h-2.5 rounded-full flex-shrink-0 hidden lg:block`
            : 'hidden'
        }`}
      />
      <div className="text-xs text-primary-400">
        {lastLoginActivity &&
          `Last login: ${formatDateGbUs(
            new Date(lastLoginActivity.startTime),
            'E d MMM yyyy'
          )}`}
      </div>
    </div>
  );
};
