import { Facility, TimePeriod } from '@intelligent-play-v2/domain';
import { StatsParams } from '~/api';

/*
This helper is to ensure stats param logic is kept together

Get stats params for a single facility or all facilities 
depending on if the facility timezone matches the client timezone
to get more accurate timezone data 
*/
export const getStatsParams = (
  timePeriod: TimePeriod,
  facility?: Facility
): StatsParams => {
  const timezone =
    facility?.tzDatabaseTimezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const statsParams: StatsParams = {
    timePeriod,
    timezone,
  };

  if (
    facility &&
    facility.tzDatabaseTimezone !==
      Intl.DateTimeFormat().resolvedOptions().timeZone
  ) {
    statsParams.facilityIds = [facility.id];
    statsParams.pitchIds = facility.pitches?.map(pitch => pitch.id);
  }
  return statsParams;
};
