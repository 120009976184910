import React, { FC } from 'react';
import editIcon from 'assets/images/pages/report/icon__edit.png';
import editIcon2x from 'assets/images/pages/report/icon__edit@2x.png';
import { Link } from 'react-router-dom';
import { RoutesPath } from '~/types';
import { useReportStore } from '~/stores/useReportStore';

interface EditReportCardProps {
  title: string;
  options?: string[];
}

export const EditReportCard: FC<EditReportCardProps> = ({ title, options }) => {
  const { clearReportPdfSettings } = useReportStore();
  return (
    <div>
      <div className="bg-white rounded shadow-250 min-w-87.5 px-4.5 py-4 text-primary-900 mr-2.5 mb-2.5">
        <div className="flex items-center justify-between text-xl font-semibold">
          {title}
          <Link
            to={{ pathname: RoutesPath.Report, search: location.search }}
            onClick={clearReportPdfSettings}
            className="ml-4.5 cursor-pointer"
          >
            <picture>
              <img src={editIcon} srcSet={`${editIcon} 1x, ${editIcon2x} 2x`} />
            </picture>
          </Link>
        </div>
        {options && (
          <div className="mt-1 text-base leading-6">
            {options.map(option => (
              <div key={option}>{option}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
