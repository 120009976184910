import React, { FC } from 'react';
import ReactSelect, { ValueType } from 'react-select';
import { classnames } from 'tailwindcss-classnames';
import { SelectOptionType } from '.';
import { SelectDropdownIcon } from './SelectDropdownIcon';
import { SelectOption } from './SelectOption';

interface MultiSelectProps {
  options: SelectOptionType[];
  selectedValues: SelectOptionType[];
  disabled?: boolean;
  bordered?: boolean;
  shadow?: boolean;
  bigger?: boolean;
  isForm?: boolean;
  onChange: (value: ValueType<SelectOptionType, true>) => void;
}

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  selectedValues,
  disabled,
  bigger,
  isForm,
  onChange,
  bordered,
  shadow,
}) => {
  const minHeight = isForm ? 50 : bigger ? 40 : 30;

  const selectClass = classnames({
    'shadow-250': shadow,
    'rounded-sm': shadow,
    'rounded-xs': !shadow,
  });

  return (
    <ReactSelect
      isMulti
      className={`text-sm font-medium z-10 ${selectClass}`}
      isSearchable={false}
      options={options}
      value={selectedValues}
      isDisabled={disabled}
      onChange={onChange}
      closeMenuOnSelect={false}
      components={{
        IndicatorSeparator: null,
        // eslint-disable-next-line react/display-name
        DropdownIndicator: () => <SelectDropdownIcon isDisabled={disabled} />,
      }}
      formatOptionLabel={SelectOption}
      formatGroupLabel={() => 'blah'}
      styles={{
        control: styles => ({
          ...styles,
          backgroundColor: 'white',
          minHeight,
          width: '100%',
          border: isForm
            ? '1px solid #c9d1d7'
            : bordered
            ? '1px solid #E7EAED'
            : 'none',
          cursor: 'pointer',
          '&:hover': {
            border: '1px solid #c9d1d7',
          },
        }),
        singleValue: (styles, state) => ({
          ...styles,
          color: '#202431',
          opacity: state.isDisabled ? '30%' : '100%',
          transform: 'translateY(-8px)',
          paddingLeft: isForm ? '8px' : styles.paddingLeft,
        }),
        menu: styles => ({
          ...styles,
          margin: 0,
          paddingBottom: 0,
          border: bordered ? '1px solid #E7EAED' : 'none',
          borderRadius: '0 0 2px 2px',
          '--tw-shadow': !bordered ? '0 2px 0 0 #c9d1d7' : '',
          boxShadow: !bordered
            ? 'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)'
            : '',
          '> div': {
            padding: 0,
          },
        }),
        option: styles => ({
          ...styles,
          borderTop: '1px solid lightgrey',
          backgroundColor: 'white',
          color: '#202431',
          '&:hover': {
            backgroundColor: '#e5f0e3',
          },
          cursor: 'pointer',
          '> div': {
            transform: 'translateY(-1px)',
          },
          minHeight,
          lineHeight: isForm ? '30px' : styles.lineHeight,
          alignItems: 'center',
          display: 'flex',
        }),
        menuList: styles => ({
          ...styles,
          maxHeight: '150px',
        }),
      }}
    />
  );
};
