import { ResourceFaq } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface PatchFaqBody {
  id: number;
  question?: string;
  answer?: string;
}

export const patchFaq = async (params: PatchFaqBody): Promise<ResourceFaq> => {
  const { data } = await queryBuilder(
    'PATCH',
    `resources/faq/${params.id}`,
    undefined,
    params
  );
  return data;
};
