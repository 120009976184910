import { User } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { TooltipParent } from '~/components/tooltip';
import { UserThumbnail, UserThumbnailType } from '~/components/userThumbnail';
import { getUserInitials } from '~/utils/getUserInitials';
import { FacilityUsersTooltip } from './FacilityUsersTooltip';

interface FacilityUsersProps {
  users: User[];
  numberOfUserThumbnails?: number;
}

export const FacilityUsers: FC<FacilityUsersProps> = ({
  users,
  numberOfUserThumbnails = 3,
}) => {
  let numThumbnails = numberOfUserThumbnails;
  if (useMediaQuery({ query: '(max-width: 1024px)' })) {
    numThumbnails = 2;
  }
  if (useMediaQuery({ query: '(max-width: 640px)' })) {
    numThumbnails = 1;
  }

  return (
    <div className="flex">
      <TooltipParent className="flex">
        <div className="relative flex -space-x-1 sm:-space-x-2">
          {users.slice(0, numThumbnails).map((user, index) => {
            return (
              <UserThumbnail
                text={getUserInitials(user.name)}
                zIndex={numThumbnails - index}
                type={UserThumbnailType.UsersColumn}
                key={user.id}
                imageSrc={user.imageThumbnailUrl}
              />
            );
          })}
          {users.length > numThumbnails && (
            <UserThumbnail
              type={UserThumbnailType.UsersColumn}
              text={`+${users.length - numThumbnails}`}
              zIndex={0}
            />
          )}
        </div>
        <div className="absolute inset-0">
          <FacilityUsersTooltip users={users} />
        </div>
      </TooltipParent>
    </div>
  );
};
