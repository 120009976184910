import { FaqCategory } from '@intelligent-play-v2/domain';
import React, { FC, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getFaq } from '~/api';
import { getEntriesNumber } from '~/utils/contactFaq.utils';
import { ContactFaqCards } from './ContactFaqCards';
import { ContactFaqMobileHeader } from './ContactFaqMobileHeader';
import { ContactFaqQuestions } from './ContactFaqQuestions';

export const ContactFaq: FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<FaqCategory | null>(
    null
  );

  const { data } = useQuery('faq', async () => getFaq());

  const selectedCategoryQuestions = useMemo(
    () => data?.filter(faq => faq.category === selectedCategory) ?? [],
    [selectedCategory, data]
  );

  return (
    <>
      <div className="sm:hidden">
        {selectedCategory && (
          <ContactFaqMobileHeader
            category={selectedCategory}
            entries={getEntriesNumber(data || [], selectedCategory)}
            setSelectedCategory={setSelectedCategory}
          />
        )}
      </div>

      <div className="rounded sm:shadow-250 sm:bg-white">
        <div className="flex-row items-baseline hidden p-4 border-b border-primary-100 sm:flex">
          <span className="font-semibold">Help centre</span>
          <span className="p-1 ml-3 text-xs font-semibold text-green bg-green-lightest rounded-xs">
            Recommended
          </span>
        </div>
        <div className="sm:p-5">
          <ContactFaqCards
            faqList={data ?? []}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          <ContactFaqQuestions questions={selectedCategoryQuestions} />
        </div>
      </div>
    </>
  );
};
