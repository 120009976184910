import { MaintenanceHoursResponse } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const getMaintenanceHours = async (
  pitchId: string,
  timezone: string
): Promise<MaintenanceHoursResponse> => {
  const { data } = await queryBuilder(
    'GET',
    `pitches/${pitchId}/maintenanceHours`,
    { timezone }
  );
  return data;
};
