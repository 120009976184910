import React, { FC } from 'react';
import { WeatherPreview } from '~/components/weather';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  showWeather?: boolean;
}

export const PageHeaderWithTabs: FC<PageHeaderProps> = ({
  title,
  subtitle,
  children,
  showWeather,
}) => {
  return (
    <div className="sticky z-20 flex justify-between max-w-full bg-white border-b border-primary-100 sm:static top-15 sm:top-0 sm:z-0">
      <div className="overflow-x-auto">
        <div className="flex px-5 pb-4 pt-7.5 md:px-15 md:pt-14 md:pb-6">
          <div className="font-tw-cent text-3xl font-bold">{title}</div>
          <div className="text-primary-300 ml-5 mb-0.5">{subtitle}</div>
        </div>
        <div className="px-5 md:pl-15">
          <ul className="h-7.5 flex whitespace-nowrap">{children}</ul>
        </div>
      </div>
      {showWeather && (
        <div className="items-center hidden pl-5 md:pr-5 lg:pr-10 2xl:pr-15 md:flex">
          <WeatherPreview />
        </div>
      )}
    </div>
  );
};
