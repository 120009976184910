import { TasksOverviewItem } from '@intelligent-play-v2/domain';
import { format, getDate } from 'date-fns';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { classnames } from 'tailwindcss-classnames';
import { RoutesPath } from '~/types';
import maintenanceIcon from '/assets/images/pages/dashboard/key_stats/icon__overdue.png';
import maintenanceIcon2x from '/assets/images/pages/dashboard/key_stats/icon__overdue@2x.png';

interface TasksOverviewProps {
  tasksOverview: TasksOverviewItem[] | undefined;
}

export const TasksOverview: FC<TasksOverviewProps> = ({ tasksOverview }) => {
  return (
    <>
      <div className="hidden text-xl font-semibold md:block">
        Tasks overview
      </div>
      <div className="bg-white rounded shadow-250 md:mt-4">
        {tasksOverview?.map(task => {
          const {
            facilityId,
            pitchId,
            date,
            facilityName,
            pitchName,
            title,
          } = task;

          const dateContainer = classnames(
            { 'bg-primary-100': !!date },
            { 'group-hover:bg-white': !!date },
            { 'bg-yellow-lightest': !date }
          );

          const link = date
            ? RoutesPath.PitchCalendar.replace(
                ':facilityId',
                facilityId.toString()
              ).replace(':pitchId', pitchId.toString())
            : RoutesPath.PitchMaintenance.replace(
                ':facilityId',
                facilityId.toString()
              ).replace(':pitchId', pitchId.toString());
          return (
            <Link
              to={link}
              key={task.title + task.pitchId + date}
              className="flex py-2.5 px-4 items-center border-b border-primary-100 last:border-b-0 hover:bg-primary-100 group hover:border-white"
            >
              <div
                className={`flex flex-col items-center justify-center w-15 h-15 rounded flex-shrink-0 ${dateContainer}`}
              >
                {date ? (
                  <>
                    <div className="-mt-1 text-4xl font-bold">
                      {getDate(new Date(date))}
                    </div>
                    <div className="-mt-1 text-sm font-medium">
                      {format(new Date(date), 'MMM')}
                    </div>
                  </>
                ) : (
                  <picture>
                    <img
                      className="m-auto my-1.5"
                      src={maintenanceIcon}
                      srcSet={`${maintenanceIcon} 1x, ${maintenanceIcon2x} 2x`}
                    />
                  </picture>
                )}
              </div>
              <div className="ml-4 overflow-hidden">
                <div className="text-xl font-semibold truncate">{title}</div>
                <div className="flex text-sm mt-1.5">
                  <div className="truncate">{facilityName}</div>
                  <div className="text-primary-400 ml-1.5 truncate">
                    {pitchName}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};
