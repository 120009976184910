import React, { FC, ReactElement } from 'react';
import './styles.css';

interface PitchMaintenanceLegendProps {
  showInfoIcon: boolean;
}

export const PitchMaintenanceLegend: FC<PitchMaintenanceLegendProps> = ({
  showInfoIcon,
}) => {
  const renderLegendInfo = (): ReactElement => (
    <div className="space-y-3 text-sm leading-none">
      <div className="flex justify-end space-x-2 text-primary-500">
        <div className="inline-flex">
          <div className="mt-0.5 mr-1 h-2.5 w-2.5 border border-primary-500 rounded-full" />
          Tracked
        </div>
        <div className="inline-flex">
          <div className="mt-0.5 mr-1 h-2.5 w-2.5 border border-primary-500" />
          Manual
        </div>
      </div>
      <div className="flex space-x-3">
        <div className="text-red">Overdue</div>
        <div className="text-yellow">Due</div>
        <div className="text-green">Completed</div>
      </div>
    </div>
  );

  return showInfoIcon ? (
    <div className="flex items-center justify-center">
      <div className="relative inline-block LegendTooltip">
        <div className="flex items-center justify-center w-5 h-5 text-white bg-green rounded-full cursor-pointer text-bold">
          i
        </div>
        <div className="LegendTooltipInfo">{renderLegendInfo()}</div>
      </div>
    </div>
  ) : (
    renderLegendInfo()
  );
};
