import { PatchFacilityBody, ReportPreset } from '@intelligent-play-v2/domain';
import { queryBuilder } from '../queryBuilder';

export interface PatchFacilityParams extends PatchFacilityBody {
  id: number;
}

export const patchFacility = async (
  params: PatchFacilityParams
): Promise<ReportPreset> => {
  const { data } = await queryBuilder(
    'PATCH',
    `facilities/${params.id}`,
    undefined,
    {
      ...params,
    }
  );
  return data;
};
