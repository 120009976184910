import { ResourceTutorial } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface PostTutorialBody {
  title: string;
  description: string;
  youtubeId: string;
}

export const postTutorial = async (
  body: PostTutorialBody
): Promise<ResourceTutorial> => {
  const { data } = await queryBuilder(
    'POST',
    'resources/tutorials',
    undefined,
    body
  );
  return data;
};
