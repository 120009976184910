import { StatsAggregate, TimePeriod } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface StatsParams {
  timePeriod: TimePeriod;
  from?: string;
  to?: string;
  facilityIds?: number[];
  pitchIds?: number[];
  timezone?: string;
}

export const getStats = async ({
  pitchIds,
  timePeriod,
  from,
  to,
  facilityIds,
  timezone,
}: StatsParams): Promise<StatsAggregate> => {
  const { data } = await queryBuilder('GET', 'stats/aggregate', {
    timePeriod,
    from,
    to,
    facilityIds: facilityIds?.join(','),
    pitchIds: pitchIds?.join(','),
    timezone,
  });
  return data;
};
