import React, { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import mobile from 'is-mobile';
import { getStats } from '~/api/queries';
import {
  compareFacilitiesByDateAdded,
  compareFacilitiesByName,
  getStatsParams,
} from '~/utils';
import { SectionHeader } from '~/components/layout';
import { FacilityMap } from '~/components/map';
import { SearchBar } from '~/components/searchBar';
import { Select, SelectOptionType } from '~/components/select';
import { Toggle } from '~/components/toggle';
import { useMetadataStore } from '~/stores';
import { FacilityRow } from '~/types';
import { TimePeriod } from '@intelligent-play-v2/domain';
import { Table } from '..';
import { facilityColumns } from './facilityColumns';
import { FacilitiesSubtitle } from './FacilitySubtitle';
import { FacilityRowMobile } from './FacilityRowMobile';

interface FacilitiesTableProps {
  showTitle: boolean;
  width?: '2/3' | 'full';
}

export const FacilitiesTable: FC<FacilitiesTableProps> = React.memo(
  ({ showTitle, width = 'full' }) => {
    const { facilities } = useMetadataStore();
    const [facilityRows, setFacilityRows] = useState<FacilityRow[]>([]);
    const [sortedFacilityRows, setSortedFacilityRows] = useState<FacilityRow[]>(
      []
    );
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' }) || mobile();
    const [searchText, setSearchText] = useState('');

    const [sortBy, setSortBy] = useState<SelectOptionType>(sortingOptions[0]);

    const [isMapView, setIsMapView] = useState(false);

    const statsParams = getStatsParams(TimePeriod.Last7Days);

    const { data: aggregatedStats } = useQuery(
      ['stats', statsParams],
      async () => getStats(statsParams)
    );

    const facilityCols = useMemo(() => facilityColumns(width), [width]);

    useEffect(() => {
      setFacilityRows(
        facilities.map(facility => ({
          facility,
          facilityAggregate: aggregatedStats?.facilities.find(
            facilityAggregate => facilityAggregate.id === facility.id
          ),
          id: facility.id,
        }))
      );
    }, [facilities, aggregatedStats]);

    useEffect(() => {
      const filteredRows = facilityRows.filter(facilityRow =>
        facilityRow.facility.name
          ?.toUpperCase()
          .includes(searchText.toUpperCase())
      );

      filteredRows.sort((a, b) => {
        if (sortBy.value === 'a-z' || sortBy.value === 'z-a') {
          const isAsc = sortBy.value === 'a-z';
          return compareFacilitiesByName(a.facility, b.facility, isAsc);
        } else {
          return compareFacilitiesByDateAdded(a.facility, b.facility);
        }
      });

      setSortedFacilityRows(filteredRows);
    }, [searchText, facilityRows, sortBy]);

    const sortDropDown = (
      <Select
        key="sort"
        options={sortingOptions}
        selectedValue={sortBy}
        disabled={isMapView}
        onChange={option => setSortBy(option || sortingOptions[0])}
        shadow
      />
    );

    return (
      <>
        <SectionHeader
          title={showTitle ? 'My facilities' : undefined}
          subtitle={<FacilitiesSubtitle facilities={facilities} />}
        >
          <div className="ml-auto">
            <Toggle
              key="toggle"
              textBefore="Map view"
              checked={isMapView}
              onChange={toggled => setIsMapView(toggled)}
            />
          </div>
          <div className="hidden sm:block w-36">{sortDropDown}</div>
        </SectionHeader>
        <SearchBar
          searchText={searchText}
          onChange={setSearchText}
          placeholder={isMapView ? 'Search for a facility' : undefined}
        />
        <div className="mb-4 sm:hidden">{sortDropDown}</div>
        {isMapView ? (
          <FacilityMap facilityRows={sortedFacilityRows} height={580} />
        ) : (
          <>
            {isMobile ? (
              <div className="space-y-4">
                {sortedFacilityRows.map((facilityRow, index) => (
                  <FacilityRowMobile
                    key={facilityRow.id}
                    facilityRow={facilityRow}
                    index={index}
                  />
                ))}
              </div>
            ) : (
              <div>
                <Table
                  rows={sortedFacilityRows}
                  columns={facilityCols}
                  showTableHeaders
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }
);

FacilitiesTable.displayName = 'FacilitiesTable';

const sortingOptions: SelectOptionType[] = [
  {
    label: 'A-Z',
    value: 'a-z',
  },
  {
    label: 'Z-A',
    value: 'z-a',
  },
  {
    label: 'Recently Added',
    value: 'recentlyAdded',
  },
];
