import create from 'zustand';
import { SelectOptionType } from '~/components/select';

interface CalendarStore {
  allFacilitiesOption: SelectOptionType;
  selectedFacility: SelectOptionType | null;
  setSelectedFacility: (facility: SelectOptionType | null) => void;
  allPitchesOption: SelectOptionType;
  selectedPitch: SelectOptionType | null;
  setSelectedPitch: (pitch: SelectOptionType | null) => void;
  showGenericEventModal: boolean;
  setShowGenericEventModal: (showGenericEventModal: boolean) => void;
}

export const useCalendarStore = create<CalendarStore>(set => ({
  allFacilitiesOption: { label: 'All facilities', value: 'all' },
  selectedFacility: null,
  setSelectedFacility: selectedFacility => {
    set(({ allPitchesOption }) => ({
      selectedFacility,
      selectedPitch: allPitchesOption,
    }));
  },

  allPitchesOption: { label: 'All pitches', value: 'all' },
  selectedPitch: null,
  setSelectedPitch: selectedPitch => {
    set(() => ({ selectedPitch }));
  },
  showGenericEventModal: false,
  setShowGenericEventModal: showGenericEventModal => {
    set(() => ({ showGenericEventModal }));
  },
}));
