import { DatePoints } from '@intelligent-play-v2/domain';
import { DatePoint } from '~/types';
import { utcToZonedTime } from 'date-fns-tz';

export const toDatePoint = (
  input: DatePoints,
  timezone: string
): DatePoint[] => {
  return Object.entries(input).map(([date, hours]) => ({
    date: utcToZonedTime(date, timezone).toISOString(),
    hours,
  }));
};
