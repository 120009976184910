import { Day, ReportPreset } from '@intelligent-play-v2/domain';

export const getIncludedDays = (reportPreset: ReportPreset): Day[] => {
  const includedDays: Day[] = [];
  if (reportPreset.includeSunday) {
    includedDays.push(Day.Sunday);
  }
  if (reportPreset.includeMonday) {
    includedDays.push(Day.Monday);
  }
  if (reportPreset.includeTuesday) {
    includedDays.push(Day.Tuesday);
  }
  if (reportPreset.includeWednesday) {
    includedDays.push(Day.Wednesday);
  }
  if (reportPreset.includeThursday) {
    includedDays.push(Day.Thursday);
  }
  if (reportPreset.includeFriday) {
    includedDays.push(Day.Friday);
  }
  if (reportPreset.includeSaturday) {
    includedDays.push(Day.Saturday);
  }
  return includedDays;
};

export const getDaysIncluded = (
  includedDays: Day[]
): {
  includeSunday: boolean;
  includeMonday: boolean;
  includeTuesday: boolean;
  includeWednesday: boolean;
  includeThursday: boolean;
  includeFriday: boolean;
  includeSaturday: boolean;
} => {
  return {
    includeSunday: includedDays.includes(Day.Sunday),
    includeMonday: includedDays.includes(Day.Monday),
    includeTuesday: includedDays.includes(Day.Tuesday),
    includeWednesday: includedDays.includes(Day.Wednesday),
    includeThursday: includedDays.includes(Day.Thursday),
    includeFriday: includedDays.includes(Day.Friday),
    includeSaturday: includedDays.includes(Day.Saturday),
  };
};
