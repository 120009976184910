import { ChangePasswordRequestBody } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const postChangePassword = async (
  params: ChangePasswordRequestBody
): Promise<Response> => {
  const { data } = await queryBuilder(
    'POST',
    'auth/changePassword',
    undefined,
    params
  );
  return data;
};
