import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { deleteCalendarEvent, deleteCalendarRecurrence } from '~/api';

interface UseDeleteEventModalHook {
  showDeleteEventModal: boolean;
  setShowDeleteEventModal: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleting: boolean;
  isDeletingRecurrence: boolean;
  onDelete: (eventId: number) => void;
  onDeleteRecurrence: (recurrenceId: number) => void;
}

export const useDeleteEventModal = (
  onCompleteDeletion: () => void
): UseDeleteEventModalHook => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const { mutate: mutateDeleteEvent, isLoading: isDeleting } = useMutation(
    deleteCalendarEvent,
    {
      onSuccess: () => {
        setShowModal(false);
        onCompleteDeletion();
        queryClient.invalidateQueries('getEvents');
        toast.success('Succesfully deleted the event');
      },
      onError: () => {
        toast.error('Error deleting the event');
      },
    }
  );

  const {
    mutate: mutateDeleteRecurrence,
    isLoading: isDeletingRecurrence,
  } = useMutation(deleteCalendarRecurrence, {
    onSuccess: () => {
      setShowModal(false);
      onCompleteDeletion();
      queryClient.invalidateQueries('getEvents');
      toast.success('Succesfully deleted all recurring events');
    },
    onError: () => {
      toast.error('Error deleting recurring events');
    },
  });

  return {
    showDeleteEventModal: showModal,
    setShowDeleteEventModal: setShowModal,
    isDeleting,
    isDeletingRecurrence,
    onDelete: (eventId: number) => mutateDeleteEvent(eventId),
    onDeleteRecurrence: (recurrenceId: number) =>
      mutateDeleteRecurrence(recurrenceId),
  };
};
