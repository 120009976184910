import React, { ChangeEvent, FC, useEffect, useMemo, useRef } from 'react';
import { useContainerDimensions } from '~/hooks';
import {
  ReportGraphType,
  ToggleableDataSeries,
  getReportGraphAxisLabel,
  getReportTooltipLabel,
} from '~/types';
import { max } from 'd3-array';

import { DayGraph, Graph } from '.';
import { pageBreakStyle } from '../data/styles';
import { ReportGraphToggle } from './ReportGraphToggle';
import { getHoursValue } from './utils';
import { useReportQueryParams } from '~/hooks/useReportQueryParams.hook';
import { classnames } from 'tailwindcss-classnames';
import { useMetadataStore } from '~/stores';
import { getLatLong } from '~/utils/getLatLong';
import {
  HistoricalWeatherParams,
  getHistoricalWeather,
} from '~/api/queries/weather/getHistoricalWeather';
import { useQuery } from 'react-query';

interface ToggleableReportGraphProps {
  toggleableDataSeries: ToggleableDataSeries[];
  graphType: ReportGraphType;
  pitchName?: string;
  isPrintableVersion?: boolean;
  isDailyGraph?: boolean;
  facilityId: number;
  isLoading: boolean;
  toggleLine?: (facilityId: number, key: string) => void;
}

export const ToggleableReportGraph: FC<ToggleableReportGraphProps> = React.memo(
  ({
    toggleableDataSeries,
    graphType,
    isPrintableVersion = false,
    isDailyGraph = false,
    pitchName,
    facilityId,
    isLoading,
    toggleLine,
  }) => {
    const { facilities } = useMetadataStore();
    const componentRef = useRef(null);
    const { width, handleResize: handleGraphResize } = useContainerDimensions(
      componentRef
    );

    const { dailyStartTime, dailyEndTime } = useReportQueryParams();

    const dates = isDailyGraph
      ? toggleableDataSeries.map(dataItem =>
          dataItem.key.slice(dataItem.key.length - 10)
        )
      : [];

    const facility = facilities.find(({ id }) => id === facilityId);

    const latLong = facility ? getLatLong(facility) : { x: 0, y: 0 };

    const weatherParams: HistoricalWeatherParams = {
      latLong: `${latLong.x},${latLong.y}`,
      dates: dates.join(','),
    };

    const { data: weather } = useQuery(
      ['weather', weatherParams],
      async () => getHistoricalWeather(weatherParams),
      {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: dates.length > 0,
      }
    );

    useEffect(() => {
      if (isLoading === false) {
        handleGraphResize();
      }
    }, [handleGraphResize, isLoading]);

    const maxSeriesValue = useMemo(() => {
      let absoluteMax = 1;
      toggleableDataSeries.forEach(({ data }) => {
        const currentSerieMax = max(data, getHoursValue) || 0;
        const newLocal = currentSerieMax > absoluteMax;
        absoluteMax = newLocal ? currentSerieMax : absoluteMax;
      });
      return absoluteMax < 1 ? 1 : absoluteMax + absoluteMax / 10;
    }, [toggleableDataSeries]);

    if (!toggleableDataSeries || !dailyEndTime || !dailyStartTime) {
      return null;
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
      if (toggleLine) {
        toggleLine(facilityId, event.target.value);
      }
    };

    const componentClassNames = classnames({
      border: isPrintableVersion,
      'border-primary-200': isPrintableVersion,
      'mb-4': !isPrintableVersion,
    });
    const graphClassNames = classnames({
      'border-t': isPrintableVersion,
      'border-primary-200': isPrintableVersion,
    });

    return (
      <div
        style={pageBreakStyle}
        className={`${componentClassNames} mt-4 bg-white rounded shadow-250 w-full`}
      >
        <div className="flex flex-row space-x-5 px-4 py-3.5 border-b border-primary-100">
          {isDailyGraph && (
            <div className="text-xl font-semibold">{pitchName}</div>
          )}
          <span>Show data for:</span>
          <div className="flex flex-wrap">
            {toggleableDataSeries.map(dataItem => {
              const isIncluded = dataItem.showLine;
              const date = dataItem.key.slice(dataItem.key.length - 10);
              return (
                <ReportGraphToggle
                  key={dataItem.key}
                  checked={isIncluded}
                  color={dataItem.color}
                  value={dataItem.key}
                  name={dataItem.name}
                  onChange={handleOnChange}
                  weather={weather && weather[date] ? weather[date] : undefined}
                />
              );
            })}
          </div>
        </div>
        {toggleableDataSeries.length > 0 ? (
          <div className={graphClassNames}>
            {isDailyGraph ? (
              <DayGraph
                dataSeries={toggleableDataSeries}
                maxSeriesValue={maxSeriesValue}
                primaryAxisLabel={getReportGraphAxisLabel(graphType)}
                height={isPrintableVersion ? 290 : 445}
                width={width}
                componentRef={componentRef}
              />
            ) : (
              <Graph
                dataSeries={toggleableDataSeries}
                primaryAxisLabel={getReportGraphAxisLabel(graphType)}
                height={isPrintableVersion ? 290 : 445}
                width={width}
                componentRef={componentRef}
                isReportPage
                facilityId={facilityId}
                enableTooltip
                tooltipValueLabel={getReportTooltipLabel(graphType)}
              />
            )}
          </div>
        ) : (
          <div className="p-4">
            {`No ${getReportGraphAxisLabel(graphType).toLowerCase()} data
          available`}
          </div>
        )}
      </div>
    );
  }
);
