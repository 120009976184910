import { useEffect } from 'react';

export const usePageTitle = (title = 'Intelligent Play'): void => {
  useEffect(() => {
    document.title = title;

    // Reset the title when the page unmounts
    return () => {
      document.title = 'Intelligent Play';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
