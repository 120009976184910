import { queryBuilder } from '~/api';
import { ResetPassword } from '@intelligent-play-v2/domain';

export const postResetPassword = async (
  param: ResetPassword
): Promise<Response> => {
  const { data } = await queryBuilder(
    'POST',
    'auth/resetPassword',
    undefined,
    param
  );
  return data;
};
