import React, { FC, RefObject } from 'react';
import errorIcon from 'assets/images/pages/report/icon__title-error.png';
import errorIcon2x from 'assets/images/pages/report/icon__title-error@2x.png';
import { classnames } from 'tailwindcss-classnames';

interface ReportOptionProps {
  title: string;
  titleTopPadding?: string;
  error?: string;
  divRef?: RefObject<HTMLDivElement>;
}

export const ReportOption: FC<ReportOptionProps> = ({
  children,
  title,
  error,
  divRef,
  titleTopPadding,
}) => {
  const titleClass = classnames(
    { 'text-red': !!error },
    'text-xl',
    'font-semibold'
  );
  return (
    <div
      className="py-7.5 scroll-m-10 border-b border-primary-900 border-opacity-10 border-solid last:border-b-0 flex flex-col sm:flex-row space-y-5 sm:space-y-0"
      ref={divRef}
    >
      <div className="w-full sm:w-3/12 2xl:w-1/6">
        <div className="flex items-center space-x-2">
          {error && (
            <picture>
              <img
                src={errorIcon}
                srcSet={`${errorIcon} 1x, ${errorIcon2x} 2x`}
              />
            </picture>
          )}
          <div className={titleClass} style={{ paddingTop: titleTopPadding }}>
            {title}
          </div>
        </div>
        {error && <div className="text-sm text-red mt-2.5">{error}</div>}
      </div>
      <div className="w-full sm:w-9/12 2xl:w-5/6">{children}</div>
    </div>
  );
};
