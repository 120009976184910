import { Client, UserType } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface PatchUserAsAdminParams {
  userId: number;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  userTypeId?: UserType;
  jobTitle?: string;
  clientId?: Client;
  password?: string;
  image?: string;
  pitchAccess?: number[];
}

export const patchUserAsAdmin = async (
  params: PatchUserAsAdminParams
): Promise<void> => {
  const { data } = await queryBuilder(
    'PATCH',
    `admin/users/${params.userId}`,
    undefined,
    {
      ...params,
    }
  );
  return data;
};
