export enum TableReportArea {
  PlayerHours = 'Player hours',
  PlayerCount = 'Player count',
  UsageHours = 'Usage hours',
  EUH = 'EUH',
  MachineMaintenanceHours = 'Machine maintenance hours',
  MachineMaintenanceSessions = 'Machine maintenance sessions',
  HighestParticipationDay = 'Highest participation day',
  LowestParticipationDay = 'Lowest partcipation day',
  Weekends = 'Weekends',
  Weekdays = 'Weekdays',
  FieldRanking = 'Field ranking',
}
