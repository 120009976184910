/* eslint-disable react/display-name */
import {
  eachWeekOfInterval,
  format,
  getDay,
  parse,
  startOfWeek,
} from 'date-fns';
import { enGB } from 'date-fns/locale';
import React, { FC, useMemo } from 'react';
import {
  Calendar as BigCalendar,
  DateHeaderProps,
  EventProps,
  ToolbarProps,
  Views,
  dateFnsLocalizer,
} from 'react-big-calendar';
import { useMetadataStore } from '~/stores';
const locales = {
  'en-GB': enGB,
};

import { CalendarDateCellWrapper } from './CalendarDateCellWrapper';
import { CalendarEventWrapper } from './CalendarEventWrapper';
import { CalendarMonthDateHeader } from './CalendarMonthDateHeader';
import {
  CalendarMonthEvent,
  FacilityCalendarEvent,
} from './CalendarMonthEvent';
import { CalendarMonthHeader } from './CalendarMonthHeader';
import { CalendarToolbar } from './CalendarToolbar';
import './styles.css';

import {
  CalendarEvent,
  CalendarEventType,
  DailyWeather,
  StatsDates,
} from '@intelligent-play-v2/domain';

interface CalendarProps {
  events: CalendarEvent[];
  weather?: Record<string, DailyWeather>;
  showFilters?: boolean;
  onChangeView?: (date: Date) => void;
  defaultDate?: Date;
  dateRange: StatsDates;
  editMaintenanceEvent: (event: CalendarEvent) => void;
  editGenericEvent: (event: CalendarEvent) => void;
  deleteEvent: (event: CalendarEvent) => void;
  viewEvent: (event: CalendarEvent) => void;
}

export const Calendar: FC<CalendarProps> = ({
  events,
  weather = {},
  showFilters = false,
  onChangeView,
  defaultDate,
  dateRange,
  editMaintenanceEvent,
  editGenericEvent,
  deleteEvent,
  viewEvent,
}) => {
  const { facilities } = useMetadataStore();

  const facilityCalendarEvents = showFilters
    ? events.map(event => {
        let pitchName;
        const facility = facilities.find(facilityItem =>
          facilityItem.pitches?.find(pitch => {
            const isPitch = pitch.id === event.pitchId;
            if (isPitch) {
              pitchName = pitch.name;
            }
            return isPitch;
          })
        );
        return {
          ...event,
          facilityName: facility?.name,
          pitchName,
        };
      })
    : events;

  const components = useMemo(
    () => ({
      toolbar: (props: ToolbarProps) => (
        <CalendarToolbar {...props} showFilters={showFilters} />
      ),
      eventWrapper: CalendarEventWrapper,
      dateCellWrapper: CalendarDateCellWrapper,
      month: {
        header: CalendarMonthHeader,
        dateHeader: (props: DateHeaderProps) => (
          <CalendarMonthDateHeader
            {...props}
            weatherForDay={weather[format(props.date, 'yyyy-MM-dd')]}
          />
        ),
        event: (props: EventProps<CalendarEvent>) => (
          <CalendarMonthEvent
            {...props}
            onClickEdit={
              props.event.eventTypeId === CalendarEventType.Generic
                ? editGenericEvent
                : editMaintenanceEvent
            }
            onClickDelete={deleteEvent}
            onClickView={viewEvent}
          />
        ),
      },
    }),
    [
      showFilters,
      weather,
      editGenericEvent,
      editMaintenanceEvent,
      deleteEvent,
      viewEvent,
    ]
  );

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: (date: Date) => startOfWeek(date, { weekStartsOn: 1 }),
    getDay,
    locales,
  });

  const weeks = eachWeekOfInterval(
    { start: dateRange.startDate, end: dateRange.endDate },
    { weekStartsOn: 1 }
  );

  return (
    <div className="my-4" style={{ height: 120 + weeks.length * 134 }}>
      <BigCalendar<FacilityCalendarEvent>
        className="bg-white rounded-sm shadow-100"
        localizer={localizer}
        views={[Views.MONTH]}
        events={facilityCalendarEvents}
        date={defaultDate}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        components={components as any}
        startAccessor="startDate"
        endAccessor="endDate"
        onNavigate={onChangeView}
        popup
      />
    </div>
  );
};
