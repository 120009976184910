import { Heatmap } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { SectionHeader } from '../layout';
import { HeatmapSectorsHalves } from './HeatmapSectorsHalves';
import { HeatmapSectorsQuarters } from './HeatmapSectorsQuarters';
import { HeatmapSectorsThirds } from './HeatmapSectorsThirds';
import euhIcon from 'assets/images/pages/field/heatmap/icon__sectors-EUH.png';
import euhIcon2x from 'assets/images/pages/field/heatmap/icon__sectors-EUH@2x.png';
import { HeatmapSectorsDivision, getAverageData } from './utils';
import { ToggleButton } from '../button';
import { Loading } from '../loading';

interface HeatmapSectorsProps {
  heatmap?: Heatmap;
  benchmarkPlayerCount: number;
  isLoading: boolean;
  selectedDivision?: HeatmapSectorsDivision;
  setSelectedDivision: (division: HeatmapSectorsDivision) => void;
  isPrintableVersion?: boolean;
}

export const HeatmapSectors: FC<HeatmapSectorsProps> = ({
  heatmap,
  benchmarkPlayerCount,
  isLoading,
  selectedDivision = HeatmapSectorsDivision.Thirds,
  setSelectedDivision,
  isPrintableVersion = false,
}) => {
  const averageData = getAverageData(
    heatmap || [[]],
    selectedDivision,
    benchmarkPlayerCount
  );

  const sectorsStyle: React.CSSProperties = {
    width: '100%',
    aspectRatio: '13/8',
    height: isPrintableVersion ? '100%' : undefined,
  };

  return (
    <div className="h-full">
      {!isPrintableVersion && (
        <SectionHeader title="Sectors" showOnMobile>
          {!isLoading && heatmap && heatmap.length ? (
            <div className="flex flex-row space-x-1">
              <ToggleButton
                text={HeatmapSectorsDivision.Halves}
                onClick={() =>
                  setSelectedDivision(HeatmapSectorsDivision.Halves)
                }
                active={selectedDivision === HeatmapSectorsDivision.Halves}
              />
              <ToggleButton
                text={HeatmapSectorsDivision.Thirds}
                onClick={() =>
                  setSelectedDivision(HeatmapSectorsDivision.Thirds)
                }
                active={selectedDivision === HeatmapSectorsDivision.Thirds}
              />
              <ToggleButton
                text={HeatmapSectorsDivision.Quarters}
                onClick={() =>
                  setSelectedDivision(HeatmapSectorsDivision.Quarters)
                }
                active={selectedDivision === HeatmapSectorsDivision.Quarters}
              />
            </div>
          ) : null}
        </SectionHeader>
      )}
      {isLoading || !heatmap || !heatmap.length ? (
        <div>{isLoading ? <Loading /> : 'No heatmap data found'}</div>
      ) : (
        <div>
          <div style={sectorsStyle}>
            {
              {
                [HeatmapSectorsDivision.Halves]: (
                  <HeatmapSectorsHalves
                    averageCount={averageData.averageCount}
                    totalEuh={averageData.totalEuh}
                  />
                ),
                [HeatmapSectorsDivision.Thirds]: (
                  <HeatmapSectorsThirds
                    averageCount={averageData.averageCount}
                    totalEuh={averageData.totalEuh}
                  />
                ),
                [HeatmapSectorsDivision.Quarters]: (
                  <HeatmapSectorsQuarters
                    averageCount={averageData.averageCount}
                    totalEuh={averageData.totalEuh}
                  />
                ),
              }[selectedDivision]
            }
          </div>
          <div className="flex flex-row pt-1 space-x-2 align-baseline">
            <picture>
              <img
                className="h-4 transform translate-y-1"
                src={euhIcon2x}
                srcSet={`${euhIcon} 1x, ${euhIcon2x} 2x`}
              />
            </picture>
            <span>
              <span className="font-bold">
                {averageData.totalEuh.toFixed(2)}{' '}
              </span>
              <span className="pl-1 text-sm">Whole field EUH</span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
