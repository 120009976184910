import { PlayerCountResponse, TimePeriod } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface PitchPlayerCountParams {
  pitchId: number;
  timePeriod: TimePeriod;
  from?: string;
  to?: string;
  timezone?: string;
  includeAverage?: boolean;
}

export const getPitchPlayerCount = async ({
  pitchId,
  timePeriod,
  from,
  to,
  timezone,
  includeAverage,
}: PitchPlayerCountParams): Promise<PlayerCountResponse> => {
  const { data } = await queryBuilder('GET', `pitches/${pitchId}/playerCount`, {
    timePeriod,
    from,
    to,
    timezone,
    includeAverage,
  });
  return data;
};
