import create from 'zustand';
import { ToggleableDataSeries, ToggleableDataSeriesCollection } from '~/types';
import { toggleStoreCollectionLine, toggleStoreSeriesLine } from '~/utils';

interface ToggleDataStore {
  facilityDataSeries: Record<string, ToggleableDataSeries[]>;
  setFacilityDataSeries: (
    facilityId: number,
    dataSeries: ToggleableDataSeries[]
  ) => void;
  toggleLine: (facilityId: number, key: string) => void;
  dailySeriesCollections: Record<string, ToggleableDataSeriesCollection[]>;
  setDailySeriesCollections: (
    facilityId: number,
    dataSeries: ToggleableDataSeriesCollection[]
  ) => void;
  toggleCollectionLine: (facilityId: number, key: string) => void;
}

export const usePlayerHoursDataStore = create<ToggleDataStore>(set => ({
  facilityDataSeries: {},
  setFacilityDataSeries: (facilityId, toggleableData) => {
    set(({ facilityDataSeries }) => {
      facilityDataSeries[facilityId] = toggleableData;
      return {
        facilityDataSeries,
      };
    });
  },
  toggleLine: (facilityId: number, key: string) => {
    set(({ facilityDataSeries }) => {
      return {
        facilityDataSeries: toggleStoreSeriesLine(
          facilityDataSeries,
          facilityId,
          key
        ),
      };
    });
  },
  dailySeriesCollections: {},
  setDailySeriesCollections: (facilityId, toggleableData) => {
    set(({ dailySeriesCollections }) => {
      dailySeriesCollections[facilityId] = toggleableData;
      return {
        dailySeriesCollections,
      };
    });
  },
  toggleCollectionLine: (facilityId: number, key: string) => {
    set(({ dailySeriesCollections }) => {
      return {
        dailySeriesCollections: toggleStoreCollectionLine(
          dailySeriesCollections,
          facilityId,
          key
        ),
      };
    });
  },
}));
