import { SnapshotSchedule } from '@intelligent-play-v2/domain';
import React, { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Button, ButtonType } from '~/components/button';
import { Modal } from '~/components/modals';
import { useMutation, useQueryClient } from 'react-query';
import { postSnapshotSchedule, putSnapshotSchedule } from '~/api';
import DayPicker, { DateUtils, RangeModifier } from 'react-day-picker';
import { DatePickerDay, DatePickerNavbar } from '~/components/datePicker';
import { TextInput } from '~/components/forms';
import { formatDateGbUs } from '~/utils';
import {
  areIntervalsOverlapping,
  isAfter,
  isBefore,
  isSameDay,
  isWithinInterval,
} from 'date-fns';
import addIcon from 'assets/images/ctas_inputs_modals/icon__cta-add-16px.png';
import addIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-add-16px@2x.png';
import deleteIcon from 'assets/images/pages/calendar/icon__task-delete.png';
import deleteIcon2x from 'assets/images/pages/calendar/icon__task-delete@2x.png';

interface SnapshotScheduleModalProps {
  selectedSchedule?: SnapshotSchedule;
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  pitchId: number;
  pitchSnapshotSchedules: SnapshotSchedule[];
}

// eslint-disable-next-line no-shadow
enum DateRangeField {
  From = 'From',
  To = 'To',
}

export const SnapshotScheduleModal: FC<SnapshotScheduleModalProps> = ({
  selectedSchedule,
  showModal,
  setShowModal,
  pitchId,
  pitchSnapshotSchedules,
}) => {
  const queryClient = useQueryClient();

  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [snapshotTimes, setSnapshotTimes] = useState<string[]>([]);

  const [focusedField, setFocusedField] = useState<DateRangeField>();
  const selectedDays: [Date | undefined, RangeModifier] = [from, { from, to }];
  const modifiers = { start: from, end: to, today: undefined };

  const today = new Date();

  const isEditingDefaultSchedule =
    selectedSchedule && !selectedSchedule.customScheduleId;

  useEffect(() => {
    if (selectedSchedule) {
      setSnapshotTimes(selectedSchedule.times);
      if (
        selectedSchedule.customScheduleId &&
        selectedSchedule.startDate &&
        selectedSchedule.endDate
      ) {
        setFrom(new Date(selectedSchedule.startDate));
        setTo(new Date(selectedSchedule.endDate));
      }
    } else {
      setSnapshotTimes([]);
      setFrom(undefined);
      setTo(undefined);
    }
  }, [selectedSchedule, showModal]);

  const disabledRanges: RangeModifier[] = pitchSnapshotSchedules
    .filter(
      schedule =>
        !selectedSchedule ||
        schedule.customScheduleId !== selectedSchedule.customScheduleId
    )
    .filter(
      ({ customScheduleId, startDate, endDate }) =>
        !!customScheduleId && !!startDate && !!endDate
    )
    .map(schedule => ({
      from: new Date(schedule.startDate!),
      to: new Date(schedule.endDate!),
    }));

  const onDayClick = (day: Date): void => {
    if (
      isBefore(day, today) ||
      disabledRanges.some(range => isDayWithinIntervalInclusive(day, range))
    ) {
      return;
    }

    if (focusedField === DateRangeField.From) {
      if (to && isAfter(day, to)) {
        setTo(day);
      }
      setFrom(day);
      setFocusedField(DateRangeField.To);
    } else if (focusedField === DateRangeField.To) {
      if (from && isBefore(day, from)) {
        setFrom(day);
      }
      setTo(day);
      setFocusedField(undefined);
    } else {
      const range = DateUtils.addDayToRange(day, {
        from: from,
        to: to,
      });
      setFrom(range.from ? range.from : undefined);
      setTo(range.to ? range.to : undefined);
    }
  };

  const renderDay = (day: Date): React.ReactNode => {
    return <DatePickerDay day={day} dateFrom={from} dateTo={to} />;
  };

  const onFocusFieldHandler = (
    field: DateRangeField,
    isFocused: boolean
  ): void => {
    if (isFocused) {
      setFocusedField(field);
    }
  };

  const getErrorMessage = (): string | undefined => {
    if ((!from || !to) && !isEditingDefaultSchedule) {
      return 'Please select from and to dates';
    } else if (
      !isEditingDefaultSchedule &&
      from &&
      to &&
      disabledRanges.some(
        range =>
          range.from &&
          range.to &&
          areIntervalsOverlapping(
            { start: from, end: to },
            { start: range.from, end: range.to }
          )
      )
    ) {
      return 'Schedule must not overlap with an existing schedule';
    } else if (snapshotTimes.length === 0 || !snapshotTimes.some(t => !!t)) {
      return 'Please add at least one snapshot time';
    }
    return undefined;
  };

  const {
    mutate: mutateCreateSchedule,
    isLoading: isLoadingCreateSchedule,
  } = useMutation(postSnapshotSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        'getSnapshotSchedules',
        {
          pitchId,
        },
      ]);
      setShowModal(false);
      toast.success('Succesfully created new schedule');
    },
    onError: () => {
      toast.error('Error creating schedule');
    },
  });

  const {
    mutate: mutateUpdateSchedule,
    isLoading: isLoadingUpdateSchedule,
  } = useMutation(putSnapshotSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        'getSnapshotSchedules',
        {
          pitchId,
        },
      ]);
      setShowModal(false);
      toast.success('Succesfully updated schedule');
    },
    onError: () => {
      toast.error('Error updating schedule');
    },
  });

  const saveScheduleHandler = (): void => {
    const body: SnapshotSchedule = {
      pitchId,
      customScheduleId: null,
      startDate: null,
      endDate: null,
      times: snapshotTimes.filter(t => !!t),
    };

    if (isEditingDefaultSchedule) {
      mutateUpdateSchedule(body);
      return;
    } else if (!from || !to) {
      return;
    }

    body.startDate = from.toISOString();
    body.endDate = to.toISOString();

    if (selectedSchedule) {
      body.customScheduleId = selectedSchedule.customScheduleId;
      mutateUpdateSchedule(body);
    } else {
      mutateCreateSchedule(body);
    }
  };

  const handleChangeInput = (index: number, time: string): void => {
    const newSnapshotTimes = [...snapshotTimes];
    newSnapshotTimes[index] = time;
    setSnapshotTimes(newSnapshotTimes);
  };

  const handleNewSnapshotTime = (): void =>
    setSnapshotTimes([...snapshotTimes, '']);

  const handleDeleteSnapshotTime = (index: number): void => {
    const newSnapshotTimes = [...snapshotTimes];
    newSnapshotTimes.splice(index, 1);
    setSnapshotTimes(newSnapshotTimes);
  };

  const handleCloseModal = (): void => {
    setTo(undefined);
    setFrom(undefined);
    setSnapshotTimes([]);
    setShowModal(false);
  };

  const modalTitle = isEditingDefaultSchedule
    ? 'Edit default snapshot times'
    : selectedSchedule
    ? 'Edit custom snapshot schedule'
    : 'New custom snapshot schedule';

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={modalTitle}
      estimatedHeight={700 + snapshotTimes.length * 50}
    >
      {/* Day picker */}
      {!isEditingDefaultSchedule && (
        <div>
          <div className="flex space-x-5">
            <TextInput
              isLightMode
              label="From"
              value={from ? formatDateGbUs(from, 'dd/MM/yy') : ''}
              isFocused={'From' === focusedField}
              onChangeFocus={isFocused =>
                onFocusFieldHandler(DateRangeField.From, isFocused)
              }
              placeholder="Select start date"
              disableManualInput
              readOnly
            />
            <TextInput
              isLightMode
              label="To"
              value={to ? formatDateGbUs(to, 'dd/MM/yy') : ''}
              isFocused={'To' === focusedField}
              onChangeFocus={isFocused =>
                onFocusFieldHandler(DateRangeField.To, isFocused)
              }
              placeholder="Select end date"
              disableManualInput
              readOnly
            />
          </div>
          <DayPicker
            className="mt-7"
            selectedDays={selectedDays}
            disabledDays={[...disabledRanges, { before: today }]}
            onDayClick={onDayClick}
            modifiers={modifiers}
            renderDay={renderDay}
            enableOutsideDaysClick={false}
            showOutsideDays={true}
            navbarElement={props => <DatePickerNavbar {...props} />}
            captionElement={<></>}
            weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
            firstDayOfWeek={1}
            month={
              selectedSchedule && selectedSchedule.startDate
                ? new Date(selectedSchedule.startDate)
                : undefined
            }
          />
        </div>
      )}

      {/* times */}
      <div className="pb-4">
        <h5 className="font-semibold">Snapshot times</h5>
        <div className="flex flex-col">
          {snapshotTimes.map((time, i) => {
            return (
              <div className="inline-flex items-center space-x-2" key={i}>
                <TextInput
                  type="time"
                  value={time}
                  onChange={value => handleChangeInput(i, value)}
                  isLightMode
                />
                <picture>
                  <img
                    onClick={() => handleDeleteSnapshotTime(i)}
                    className="w-5 pt-2 cursor-pointer hover:opacity-70"
                    src={deleteIcon}
                    srcSet={`${deleteIcon} 1x, ${deleteIcon2x} 2x`}
                  />
                </picture>
              </div>
            );
          })}
        </div>
        {snapshotTimes.length < 10 ? (
          <Button
            className="mt-3"
            text="New snapshot time"
            size="xsmall"
            type={ButtonType.Secondary}
            icon={addIcon}
            icon2x={addIcon2x}
            onClick={handleNewSnapshotTime}
          />
        ) : (
          <span className="text-sm text-black">
            Maximum of snapshot times per day is 10
          </span>
        )}
      </div>

      {/* Buttons */}
      <div className="flex space-x-4">
        <div className="w-1/2 h-12.5">
          <Button
            type={ButtonType.Outline}
            text="Cancel"
            onClick={handleCloseModal}
          />
        </div>
        <div className="w-1/2">
          <Button
            disabled={
              !!getErrorMessage() ||
              isLoadingCreateSchedule ||
              isLoadingUpdateSchedule
            }
            isLoading={isLoadingCreateSchedule || isLoadingUpdateSchedule}
            text="Save"
            onClick={saveScheduleHandler}
          />
          <span className="text-sm text-red">{getErrorMessage()}</span>
        </div>
      </div>
    </Modal>
  );
};

const isDayWithinIntervalInclusive = (
  day: Date,
  range: RangeModifier
): boolean =>
  range && range.from && range.to
    ? isWithinInterval(day, { start: range.from, end: range.to }) ||
      isSameDay(day, new Date(range.to)) ||
      isSameDay(day, new Date(range.from))
    : false;
