import { format } from 'date-fns';
import { celsiusToFahrenheit } from '~/components/weather/utils';

type GbPatterns =
  | 'dd/MM'
  | 'E d MMM yyyy'
  | 'd MMMM yyyy'
  | 'E d MMM'
  | 'dd MMM'
  | 'dd/MM/yy'
  | 'dd/MM/yyyy'
  | 'd MMM yyyy'
  | 'dd/MM/yy h:mm aa'
  | 'E d MMM @ k:mm'
  | 'EEE dd/MM';

export const getPreferredTemperature = (celcius: number): number => {
  return isBrowserLanguageAmerican() ? celsiusToFahrenheit(celcius) : celcius;
};

export const getBrowserLanguage = (): string => {
  if (navigator.languages !== undefined) {
    return navigator.languages[0];
  }
  return navigator.language;
};

export const isBrowserLanguageAmerican = (): boolean => {
  return getBrowserLanguage() === 'en-US';
};

export const formatDateGbUs = (date: Date, gbPattern: GbPatterns): string => {
  const isAmerican = isBrowserLanguageAmerican();
  if (isAmerican) {
    switch (gbPattern) {
      case 'dd/MM':
        return format(date, 'MM/dd');
      case 'E d MMM yyyy':
        return format(date, 'E MMM d yyyy');
      case 'd MMMM yyyy':
        return format(date, 'MMMM d yyyy');
      case 'E d MMM':
        return format(date, 'E MMM d');
      case 'EEE dd/MM':
        return format(date, 'EEE MM/dd');
      case 'dd MMM':
        return format(date, 'MMM dd');
      case 'dd/MM/yy':
        return format(date, 'MM/dd/yy');
      case 'dd/MM/yyyy':
        return format(date, 'MM/dd/yyyy');
      case 'd MMM yyyy':
        return format(date, 'MMM d yyyy');
      case 'dd/MM/yy h:mm aa':
        return format(date, 'MM/dd/yy h:mm aa');
      case 'E d MMM @ k:mm':
        return format(date, 'E MMM d @ k:mm');
      default:
        break;
    }
  }
  return format(date, gbPattern);
};
