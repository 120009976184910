import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { addDays, addYears } from 'date-fns';
import { format } from 'date-fns-tz';

import DayPicker from 'react-day-picker';
import { ValueType } from 'react-select';

import {
  Attachment,
  CalendarEvent,
  CalendarEventType,
  CalendarRecurrenceFrequency,
  FieldTestingTaskType,
  MaintenanceTaskType,
  PostCalendarEventBody,
  PostCalendarRecurrenceEventBody,
} from '@intelligent-play-v2/domain';

import { TextInput } from '~/components/forms';
import { DatePickerDay, DatePickerNavbar } from '~/components/datePicker';
import { Button, ButtonType } from '~/components/button';
import { RadioButton } from '~/components/radiobutton';
import { AttachmentList } from '~/components/attachment';
import { LabelledCheckbox } from '~/components/labelledCheckbox';
import { Modal } from '~/components/modals';
import { MultiSelect, Select, SelectOptionType } from '~/components/select';
import {
  PatchCalendarEventParams,
  patchCalendarEvent,
  postCalendarEvent,
  postCalendarRecurrence,
} from '~/api';
import { useTimeRange } from '~/hooks';
import { dateToIsoStringAtTimezone, formatDateGbUs, sortUsers } from '~/utils';
import { useMetadataStore } from '~/stores';

import deleteIcon from 'assets/images/pages/field/modals/icon__button-delete.png';
import deleteIcon2x from 'assets/images/pages/field/modals/icon__button-delete@2x.png';

import 'react-day-picker/lib/style.css';
import '~/styles/dayPicker.css';

interface MaintenanceEventModalProps {
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  eventTypeId?: number;
  pitchId?: number; // pass in to create event
  calendarEvent?: CalendarEvent | null; // pass in calendar event to edit
  openDeleteModal?: () => void;
}

export const MaintenanceEventModal: FC<MaintenanceEventModalProps> = ({
  showModal,
  setShowModal,
  eventTypeId,
  pitchId,
  calendarEvent,
  openDeleteModal,
}) => {
  const queryClient = useQueryClient();
  const { facilities } = useMetadataStore();

  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const modalPitchId = useMemo(() => calendarEvent?.pitchId ?? pitchId, [
    calendarEvent?.pitchId,
    pitchId,
  ]);

  const modalEventTypeId = calendarEvent?.eventTypeId ?? eventTypeId;
  const facility = facilities.find(f =>
    f.pitches?.some(p => p.id === modalPitchId)
  );

  const staff = useMemo(() => {
    return (
      facility?.staff?.filter(staffMember =>
        modalPitchId ? staffMember.pitchAccess.includes(modalPitchId) : false
      ) ?? []
    );
  }, [facility?.staff, modalPitchId]);

  const taskTypeOptions = useMemo(() => {
    const taskTypes =
      modalEventTypeId === CalendarEventType.MaintenanceTask
        ? Object.values(MaintenanceTaskType)
        : Object.values(FieldTestingTaskType);

    return Object.values(taskTypes).map(taskTypeValue => {
      return {
        label: taskTypeValue,
        value: taskTypeValue,
      };
    });
  }, [modalEventTypeId]);

  const [isAllDayEvent, setIsAllDayEvent] = useState(false);
  const [formError, setFormError] = useState('');
  const [
    frequency,
    setFrequency,
  ] = useState<null | CalendarRecurrenceFrequency>(null);
  const [notes, setNotes] = useState('');

  const [selectedDay, setSelectedDay] = useState(new Date());
  const recurrenceEndDay = addYears(selectedDay, 1);

  const {
    startTime,
    startTimes,
    handleStartTimeChange,
    endTime,
    endTimes,
    handleEndTimeChange,
    setStartTime,
    setEndTime,
  } = useTimeRange(60, '00:00', '00:00');

  const userOptions: SelectOptionType[] = sortUsers(staff).map(staffMember => {
    return {
      label: staffMember.name ?? '',
      value: staffMember.id.toString(),
    };
  });

  const [selectedUsers, setSelectedUsers] = useState<SelectOptionType[]>([]);

  const handleUserChange = (
    options: ValueType<SelectOptionType, true>
  ): void => {
    setSelectedUsers([...options]);
  };

  const [selectedTaskType, setSelectedTaskType] = useState<SelectOptionType>(
    taskTypeOptions[0]
  );

  const handleChangeTaskType = (option: SelectOptionType | null): void => {
    const newValue = option ? option : taskTypeOptions[0];
    setSelectedTaskType(newValue);
  };

  const resetFields = useCallback(() => {
    setNotes('');
    setSelectedUsers([]);
    setSelectedTaskType(taskTypeOptions[0]);
    setSelectedDay(new Date());
    setFrequency(null);
    setIsAllDayEvent(false);
    setStartTime({ label: '08:00', value: '08:00' });
    setEndTime({ label: '12:00', value: '12:00' });
    setAttachments([]);
  }, [setEndTime, setStartTime, taskTypeOptions]);

  useEffect(() => {
    if (calendarEvent) {
      const {
        userIds: eventUserIds,
        startDate: eventStartDate,
        endDate: eventEndDate,
        notes: eventNotes,
        title: eventTitle,
        attachments: eventAttachments,
      } = calendarEvent;
      setNotes(eventNotes ?? '');
      if (eventUserIds.length > 0) {
        const assignedUsers = staff.filter(staffMember =>
          eventUserIds.includes(staffMember.id)
        );
        setSelectedUsers(
          assignedUsers.map(user => {
            return {
              label: user.name ?? '',
              value: user.id.toString(),
            };
          })
        );
      }
      const task = taskTypeOptions.find(type => type.label === eventTitle);
      if (task) {
        setSelectedTaskType(task);
      }

      const eventStartTime = format(new Date(eventStartDate), 'HH:mm');
      const eventEndTime = format(new Date(eventEndDate), 'HH:mm');

      setSelectedDay(new Date(eventStartDate));
      if (eventStartTime === eventEndTime) {
        setIsAllDayEvent(true);
      }
      setStartTime({ label: eventStartTime, value: eventStartTime });
      setEndTime({ label: eventEndTime, value: eventEndTime });
      setAttachments(eventAttachments);
    } else {
      resetFields();
    }
  }, [
    calendarEvent,
    setEndTime,
    setStartTime,
    staff,
    taskTypeOptions,
    resetFields,
  ]);

  const {
    mutate: mutateCalendarRecurrence,
    isLoading: isLoadingCreateRecurrence,
  } = useMutation(postCalendarRecurrence, {
    onSuccess: () => {
      setShowModal(false);
      invalidateQueries();
      resetFields();
      toast.success('Succesfully created recurring event');
    },
    onError: () => {
      setFormError('Error creating recurring event');
    },
  });

  const {
    mutate: mutateCalendarEvent,
    isLoading: isLoadingCreateEvent,
  } = useMutation(postCalendarEvent, {
    onSuccess: () => {
      setShowModal(false);
      resetFields();
      invalidateQueries();
      toast.success('Succesfully created an event');
    },
    onError: () => {
      setFormError('Error creating event');
    },
  });

  const createEventHandler = (): void => {
    if (!pitchId || !eventTypeId) {
      toast.error('Error creating event');
      setShowModal(false);
      return;
    }
    setFormError('');
    const startTimeValue = isAllDayEvent ? '00:00' : startTime.value;
    const endTimeValue = isAllDayEvent ? '00:00' : endTime.value;
    if (startTimeValue === endTimeValue && !isAllDayEvent) {
      setFormError('You must use a time range greater than 1 hour');
      return;
    }
    let endDay = selectedDay;
    if (endTimeValue === '00:00') {
      endDay = addDays(endDay, 1);
    }

    const timezone = facility?.tzDatabaseTimezone ?? 'UTC';

    if (frequency) {
      const body: PostCalendarRecurrenceEventBody = {
        pitchId,
        timezone,
        eventTypeId,
        notes,
        title: selectedTaskType.label,
        startTime: startTimeValue,
        endTime: endTimeValue,
        recurrenceStartDate: format(selectedDay, 'yyyy-MM-dd'),
        recurrenceEndDate: format(addYears(endDay, 1), 'yyyy-MM-dd'),
        userIds: selectedUsers.map(({ value }) => +value),
        recurrenceFrequencyId: frequency,
      };
      mutateCalendarRecurrence(body);
    } else {
      const startDate = dateToIsoStringAtTimezone(
        new Date(`${format(selectedDay, 'yyyy-MM-dd')}T${startTimeValue}`),
        timezone
      );
      const endDate = dateToIsoStringAtTimezone(
        new Date(`${format(endDay, 'yyyy-MM-dd')}T${endTimeValue}`),
        timezone
      );

      const body: PostCalendarEventBody = {
        notes,
        eventTypeId,
        pitchId,
        title: selectedTaskType.label,
        userIds: selectedUsers.map(({ value }) => value).join(','),
        startDate,
        endDate,
        attachments,
      };
      mutateCalendarEvent(body);
    }
  };

  const {
    mutate: mutateUpdateEvent,
    isLoading: isLoadingUpdateEvent,
  } = useMutation(patchCalendarEvent, {
    onSuccess: () => {
      setShowModal(false);
      invalidateQueries();
      toast.success('Succesfully updated the event');
    },
    onError: () => {
      setFormError('Error updating event');
    },
  });

  const updateEventHandler = (): void => {
    if (!calendarEvent) {
      return;
    }
    setFormError('');
    const startTimeValue = isAllDayEvent ? '00:00' : startTime.value;
    const endTimeValue = isAllDayEvent ? '00:00' : endTime.value;
    if (startTimeValue === endTimeValue && !isAllDayEvent) {
      setFormError('You must use a time range greater than 1 hour');
      return;
    }
    let endDay = selectedDay;
    if (endTimeValue === '00:00') {
      endDay = addDays(endDay, 1);
    }

    const timezone = facility?.tzDatabaseTimezone ?? 'UTC';

    const startDate = dateToIsoStringAtTimezone(
      new Date(`${format(selectedDay, 'yyyy-MM-dd')} ${startTimeValue}`),
      timezone
    );
    const endDate = dateToIsoStringAtTimezone(
      new Date(`${format(endDay, 'yyyy-MM-dd')} ${endTimeValue}`),
      timezone
    );

    const updatedEvent: PatchCalendarEventParams = {
      id: calendarEvent.id,
      notes,
      title: selectedTaskType.label,
      userIds: selectedUsers.map(user => +user.value).join(','),
      startDate,
      endDate,
      attachments,
    };
    mutateUpdateEvent(updatedEvent);
  };

  const invalidateQueries = (): void => {
    queryClient.invalidateQueries('getEvents');
    queryClient.invalidateQueries('getPitchFieldTestingEvents');
    queryClient.invalidateQueries('maintenanceCalendar');
    queryClient.invalidateQueries('userAlerts');
  };

  const renderDay = (day: Date): React.ReactNode => {
    return <DatePickerDay day={day} selectedDay={selectedDay} size="small" />;
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={
        calendarEvent ? 'Edit maintenance task' : 'Schedule maintenance task'
      }
      estimatedHeight={750}
    >
      <div className="w-full sm:w-140">
        <div className="flex flex-col justify-between sm:space-x-3 sm:flex-row ">
          <div className="w-full sm:w-1/2">
            <div className="text-sm font-semibold">Task type*</div>
            <div className="mt-2.5">
              <Select
                options={taskTypeOptions}
                selectedValue={selectedTaskType}
                onChange={handleChangeTaskType}
                isForm
                shadow
                bordered
              />
            </div>
          </div>
          <div className="w-full pt-4 sm:pt-0 sm:w-1/2">
            <div className="text-sm font-semibold">Assign to</div>
            <div className="mt-2.5">
              <MultiSelect
                options={userOptions}
                selectedValues={selectedUsers}
                onChange={handleUserChange}
                isForm
                shadow
                bordered
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-5">
          <TextInput
            value={notes}
            onChange={setNotes}
            label="Notes"
            placeholder="(optional)"
            isLightMode
            required={false}
            maxLength={5000}
            isMultiline
          />
        </div>
        <div className="flex flex-col mt-4 bg-white sm:items-center sm:flex-row">
          <div className="flex justify-start">
            <div className="w-22">
              <Select
                options={startTimes}
                selectedValue={startTime}
                onChange={handleStartTimeChange}
                bordered
                disabled={isAllDayEvent}
              />
            </div>
            <div className="px-2">-</div>
            <div className="w-22">
              <Select
                options={endTimes}
                selectedValue={endTime}
                onChange={handleEndTimeChange}
                bordered
                disabled={isAllDayEvent}
              />
            </div>
          </div>
          <div className="pt-2 -mx-2 sm:pt-0 sm:mx-0">
            <LabelledCheckbox
              title="Check to create all day event"
              isChecked={isAllDayEvent}
              toggleChecked={() => setIsAllDayEvent(!isAllDayEvent)}
            />
          </div>
        </div>

        <div className="flex flex-col justify-between mt-1 space-x-3 sm:flex-row ">
          <div className="w-full sm:w-1/2">
            <DayPicker
              className="flex w-full mt-4 DatePickerSmall"
              selectedDays={selectedDay}
              onDayClick={setSelectedDay}
              renderDay={renderDay}
              enableOutsideDaysClick={false}
              showOutsideDays={true}
              navbarElement={props => (
                <DatePickerNavbar {...props} size="small" />
              )}
              captionElement={<></>}
              weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              firstDayOfWeek={1}
              modifiers={{ today: undefined }}
              month={
                calendarEvent ? new Date(calendarEvent.startDate) : undefined
              }
            />
          </div>
          {!calendarEvent && (
            <div className="w-full mb-4 sm:w-1/2 sm:mb-0">
              <div className="text-sm font-semibold sm:mt-4">Frequency</div>
              <div className="mt-2.5 justify-start sm:space-x-0 space-y-3 flex flew-row sm:flex-col flex-wrap items-baseline mr-4">
                <RadioButton
                  label="One-time"
                  checked={frequency === null}
                  onChange={() => setFrequency(null)}
                />
                <RadioButton
                  label="Weekly"
                  checked={frequency === CalendarRecurrenceFrequency.Weekly}
                  onChange={() =>
                    setFrequency(CalendarRecurrenceFrequency.Weekly)
                  }
                />
                <RadioButton
                  label="Monthly"
                  checked={frequency === CalendarRecurrenceFrequency.Monthly}
                  onChange={() =>
                    setFrequency(CalendarRecurrenceFrequency.Monthly)
                  }
                />
                <RadioButton
                  label="Quarterly"
                  checked={frequency === CalendarRecurrenceFrequency.Quarterly}
                  onChange={() =>
                    setFrequency(CalendarRecurrenceFrequency.Quarterly)
                  }
                />
                <RadioButton
                  label="Half-yearly"
                  checked={frequency === CalendarRecurrenceFrequency.HalfYearly}
                  onChange={() =>
                    setFrequency(CalendarRecurrenceFrequency.HalfYearly)
                  }
                />
              </div>
              {frequency && (
                <div className="mt-3 mb-0 text-base sm:mb-4">
                  This event will repeat until:{' '}
                  {formatDateGbUs(recurrenceEndDay, 'd MMMM yyyy')}
                </div>
              )}
            </div>
          )}
        </div>
        {(!frequency || calendarEvent) && (
          <AttachmentList
            eventId={calendarEvent ? calendarEvent.id : undefined}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        )}
        {formError && <p className="mt-4 text-red">{formError}</p>}
        {calendarEvent ? (
          <div className="flex mt-5 space-x-5">
            <div className="w-1/2">
              <Button
                onClick={openDeleteModal}
                text="Delete task"
                type={ButtonType.OutlineDanger}
                icon={deleteIcon}
                icon2x={deleteIcon2x}
                className="border border-primary-250"
              />
            </div>
            <div className="w-1/2">
              <Button
                onClick={updateEventHandler}
                text="Save task"
                isLoading={isLoadingUpdateEvent}
              />
            </div>
          </div>
        ) : (
          <div className="w-full mt-5">
            <Button
              onClick={createEventHandler}
              text="Create task"
              isLoading={isLoadingCreateEvent || isLoadingCreateRecurrence}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
