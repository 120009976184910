import React, { FC } from 'react';
import { TableColumn } from './Table';

interface TableHeaderProps {
  columns: TableColumn[];
}
export const TableHeader: FC<TableHeaderProps> = ({ columns }) => {
  return (
    <div className="flex px-4 text-sm font-medium mb-2.5 items-end">
      {columns.map(({ heading, widthClass }) => {
        return (
          <span className={`${widthClass} mr-2 last:mr-0`} key={heading}>
            {heading}
          </span>
        );
      })}
    </div>
  );
};
