import { Pitch } from '@intelligent-play-v2/domain';
import { PitchRow } from '~/components/facilityOverview/PitchSummaryCard';

export const sortByPitchNumber = (
  a: Pitch | PitchRow,
  b: Pitch | PitchRow
): number => {
  const pitchNumberA = a.facilityPitchNumber ?? 0;
  const pitchNumberB = b.facilityPitchNumber ?? 0;
  return pitchNumberA < pitchNumberB ? -1 : 1;
};
