import { ChangePasswordRequestBody, User } from '@intelligent-play-v2/domain';
import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { postChangePassword } from '~/api';
import { Button, ButtonType } from '~/components/button';
import { TextInput } from '~/components/forms';
import { useAuthStore } from '~/stores';
import { Modal } from '../Modal';

interface ChangePasswordModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  user: User;
}
export const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  showModal,
  setShowModal,
  user,
}) => {
  const { fetchUser } = useAuthStore();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    trigger,
  } = useForm();

  useEffect(() => {
    if (!showModal) {
      setValue('oldPassword', '');
      setValue('newPassword', '');
      setValue('confirmPassword', '');
    }
  }, [setValue, showModal]);

  const { mutate: mutateChangePassword, isLoading: isSaving } = useMutation(
    postChangePassword,
    {
      onSuccess: () => {
        toast.success('Successfully changed password');
        fetchUser();
        setShowModal(false);
      },
      onError: () => {
        toast.error(
          'Failed to change psasword. Check your old password is correct.'
        );
      },
    }
  );

  const onSubmitHandler: SubmitHandler<ChangePasswordRequestBody> = (
    data
  ): void => {
    if (!user.email) {
      toast.error('Failed to save user, your session has expired.');
      return;
    }

    data.userEmail = user.email;
    mutateChangePassword(data);
  };

  const watchNewPassword = watch('newPassword');
  const watchConfirmPassword = watch('confirmPassword');
  useEffect(() => {
    trigger('confirmPassword'); // retriggers validation
  }, [trigger, watchConfirmPassword, watchNewPassword]);

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Change password"
      estimatedHeight={500}
    >
      <div className="flex-col w-full space-y-4 sm:w-96 sm:flex">
        <TextInput
          register={register('oldPassword')}
          label="Old password"
          placeholder="Enter password"
          required
          type="password"
          isLightMode
          errorMessage={errors.password?.message}
        />

        <hr className="border-primary-200" />

        <TextInput
          register={register('newPassword', {
            validate: (password: string) => {
              if (password.length !== 0 && password.length < 8) {
                return 'Password must be 8 characters long';
              }
              return true;
            },
          })}
          label="New password"
          placeholder="Enter password"
          required
          type="password"
          isLightMode
          errorMessage={errors.newPassword?.message}
        />
        <TextInput
          register={register('confirmPassword', {
            validate: (confirmPassword: string) => {
              if (watch('newPassword') !== confirmPassword) {
                return 'Passwords do not match';
              }
              return true;
            },
          })}
          label="Confirm new password"
          placeholder="Confirm password"
          required
          type="password"
          isLightMode
          errorMessage={errors.confirmPassword?.message}
        />
        <Button
          type={ButtonType.Secondary}
          text="Save changes"
          className="pb-0.75"
          onClick={handleSubmit(onSubmitHandler)}
          disabled={isSaving || Object.keys(errors).length > 0}
          isLoading={isSaving}
        />
      </div>
    </Modal>
  );
};
