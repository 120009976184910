/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { isAfter, isSameDay } from 'date-fns';
import { format } from 'date-fns-tz';

import {
  CalendarEvent,
  CalendarEventType,
  Pitch,
  PostCalendarEventBody,
} from '@intelligent-play-v2/domain';

import { TextInput } from '~/components/forms';
import { DatePickerField } from '~/components/datePicker';
import { Button, ButtonType } from '~/components/button';
import { Modal } from '~/components/modals';
import { Select, SelectOptionType } from '~/components/select';

import {
  PatchCalendarEventParams,
  patchCalendarEvent,
  postCalendarEvent,
} from '~/api';
import { useTimeRange } from '~/hooks';

import 'react-day-picker/lib/style.css';
import '~/styles/dayPicker.css';
import { useMetadataStore } from '~/stores';
import { compareFacilitiesByName } from '~/utils/facilitySort.utils';

import deleteIcon from 'assets/images/pages/field/modals/icon__button-delete.png';
import deleteIcon2x from 'assets/images/pages/field/modals/icon__button-delete@2x.png';
import { dateToIsoStringAtTimezone } from '~/utils/dateToIsoStringAtTimezone';

interface GenericEventModalProps {
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  defaultFacility?: SelectOptionType; // pass in to pre-fill facility select
  defaultPitch?: SelectOptionType; // pass in to pre-fill pitch select
  calendarEvent?: CalendarEvent | null; // pass in calendar event to edit
  openDeleteModal: () => void;
}

export const GenericEventModal: FC<GenericEventModalProps> = ({
  showModal,
  setShowModal,
  defaultFacility,
  defaultPitch,
  calendarEvent,
  openDeleteModal,
}) => {
  const queryClient = useQueryClient();

  const { facilities } = useMetadataStore();
  const [formError, setFormError] = useState('');

  const facilityOptions: SelectOptionType[] = [
    ...facilities
      .sort((a, b) => compareFacilitiesByName(a, b, true))
      .map(({ name, id }) => ({
        label: name || '',
        value: id.toString(),
      })),
  ];

  // TODO add facilityId to CalendarEvent to make this bit easier
  const [selectedFacility, setSelectedFacility] = useState(defaultFacility);

  let pitches: Pitch[] = [];
  const enablePitchSelect =
    selectedFacility && selectedFacility.value !== 'all';

  if (enablePitchSelect) {
    pitches =
      facilities.find(({ id }) => id === +selectedFacility.value)!.pitches ||
      [];
  }

  const initialPitchOptions = [
    ...pitches.map(({ name, id }) => ({
      label: name || '',
      value: id.toString(),
    })),
  ];

  const [pitchOptions, setPitchOptions] = useState(initialPitchOptions);
  const [selectedPitch, setSelectedPitch] = useState(defaultPitch);
  const [notes, setNotes] = useState('');
  const [title, setTitle] = useState('');
  const [startDay, setStartDay] = useState(new Date());
  const [endDay, setEndDay] = useState(new Date());

  const handleSetSelectedFacility = (
    facility: SelectOptionType | null
  ): void => {
    setSelectedFacility(facility || undefined);
  };
  const handleSetSelectedPitch = (pitch: SelectOptionType | null): void => {
    setSelectedPitch(pitch || undefined);
  };

  // When the selected facility is changed, automatically select the first pitch for that facility
  useEffect(() => {
    if (selectedFacility && !defaultPitch) {
      pitches =
        facilities.find(({ id }) => id === +selectedFacility.value)!.pitches ||
        [];

      const newPitchOptions = [
        ...pitches.map(({ name, id }) => ({
          label: name || '',
          value: id.toString(),
        })),
      ];
      setPitchOptions(newPitchOptions);
      setSelectedPitch(newPitchOptions[0]);
    }
  }, [selectedFacility]);

  useEffect(() => {
    if (calendarEvent) {
      const {
        pitchId: eventPitchId,
        startDate: eventStartDate,
        endDate: eventEndDate,
        notes: eventNotes,
        title: eventTitle,
      } = calendarEvent;

      const eventStartTime = format(new Date(eventStartDate), 'HH:mm');
      const eventEndTime = format(new Date(eventEndDate), 'HH:mm');

      const selectedPitchOption = initialPitchOptions.find(
        ({ value }) => +value === eventPitchId
      );

      const eventSelectedFacility = facilities.find(
        ({ pitches: facilityPitches }) =>
          facilityPitches?.find(({ id }) => eventPitchId === id)
      );

      const selectedFacilityOption = facilityOptions.find(
        ({ value }) => +value === eventSelectedFacility?.id
      );

      setSelectedPitch(selectedPitchOption);
      setSelectedFacility(selectedFacilityOption);
      setNotes(eventNotes ?? '');
      setTitle(eventTitle || '');
      setStartDay(new Date(eventStartDate));
      setStartTime({ label: eventStartTime, value: eventStartTime });
      setEndDay(new Date(eventEndDate));
      setEndTime({ label: eventEndTime, value: eventEndTime });
    } else {
      resetFields();
    }
  }, [calendarEvent]);

  const {
    startTime,
    startTimes,
    setStartTime,
    handleStartTimeChange,
    handleEndTimeChange,
    endTime,
    endTimes,
    setEndTime,
  } = useTimeRange(60, '00:00', '00:00');

  const {
    mutate: mutateCalendarEvent,
    isLoading: isLoadingCreateEvent,
  } = useMutation(postCalendarEvent, {
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries('getEvents');
      toast.success('Succesfully created an event');
      resetFields();
    },
    onError: () => {
      setFormError('Error creating event');
    },
  });

  // Check if start and end dates are valid on each change
  useEffect(() => {
    const startDate = `${format(startDay, 'yyyy-MM-dd')} ${startTime.value}`;
    const endDate = `${format(endDay, 'yyyy-MM-dd')} ${endTime.value}`;

    if (isAfter(new Date(startDate), new Date(endDate))) {
      setFormError('Start date must be before end date');
    } else {
      setFormError('');
    }
  }, [startDay, endDay, startTime, endTime]);

  const createEventHandler = (): void => {
    setFormError('');

    if (!selectedPitch || !selectedFacility) {
      setFormError('You must select a facility and pitch');
      return;
    }

    if (isSameDay(startDay, endDay) && startTime.value === endTime.value) {
      setFormError('You must use a time range greater than 1 hour');
      return;
    }

    const timezone =
      facilities.find(({ id }) => +selectedFacility.value === id)!
        .tzDatabaseTimezone || 'Etc/UTC';

    const startDate = dateToIsoStringAtTimezone(
      new Date(`${format(startDay, 'yyyy-MM-dd')} ${startTime.value}`),
      timezone
    );
    const endDate = dateToIsoStringAtTimezone(
      new Date(`${format(endDay, 'yyyy-MM-dd')} ${endTime.value}`),
      timezone
    );

    const body: PostCalendarEventBody = {
      notes,
      eventTypeId: CalendarEventType.Generic,
      pitchId: +selectedPitch.value,
      title,
      userIds: '',
      startDate,
      endDate,
    };
    mutateCalendarEvent(body);
  };

  const {
    mutate: mutateUpdateEvent,
    isLoading: isLoadingUpdateEvent,
  } = useMutation(patchCalendarEvent, {
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries('getEvents');
      toast.success('Succesfully updated the event');
    },
    onError: () => {
      setFormError('Error updating event');
    },
  });

  const updateEventHandler = (): void => {
    if (!calendarEvent) {
      return;
    }
    setFormError('');

    if (!selectedPitch || !selectedFacility) {
      setFormError('You must select a facility and pitch');
      return;
    }

    if (isSameDay(startDay, endDay) && startTime.value === endTime.value) {
      setFormError('You must use a time range greater than 1 hour');
      return;
    }

    const timezone =
      facilities.find(({ id }) => +selectedFacility.value === id)!
        .tzDatabaseTimezone || 'Etc/UTC';

    const startDate = dateToIsoStringAtTimezone(
      new Date(`${format(startDay, 'yyyy-MM-dd')} ${startTime.value}`),
      timezone
    );
    const endDate = dateToIsoStringAtTimezone(
      new Date(`${format(endDay, 'yyyy-MM-dd')} ${endTime.value}`),
      timezone
    );

    const updatedEvent: PatchCalendarEventParams = {
      id: calendarEvent.id,
      notes,
      title,
      startDate,
      endDate,
    };
    mutateUpdateEvent(updatedEvent);
  };

  const resetFields = (): void => {
    setSelectedPitch(selectedPitch || undefined);
    setSelectedFacility(selectedFacility || undefined);
    setNotes('');
    setTitle('');
    setStartDay(new Date());
    setEndDay(new Date());
    setStartTime(startTimes[0]);
    setEndTime(startTimes[0]);
  };

  const disableSaveButton =
    !!formError ||
    !title ||
    !selectedPitch ||
    !startDay ||
    !startTime ||
    !endDay ||
    !endTime;

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={calendarEvent ? 'Edit event' : 'Create event'}
      estimatedHeight={750}
    >
      {/* Facility / Field selects */}
      <div className="w-full sm:w-140">
        <div className="flex flex-row justify-between space-x-3 ">
          <div className="w-1/2">
            <div className="text-sm font-semibold">Facility</div>
            <div className="mt-2.5">
              <Select
                options={facilityOptions}
                selectedValue={selectedFacility}
                onChange={handleSetSelectedFacility}
                isForm
                shadow
                bordered
                disabled={!!defaultFacility || !!calendarEvent}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="text-sm font-semibold">Select field</div>
            <div className="mt-2.5">
              <Select
                options={pitchOptions}
                selectedValue={selectedPitch}
                onChange={handleSetSelectedPitch}
                isForm
                shadow
                bordered
                disabled={!!defaultPitch || !!calendarEvent}
              />
            </div>
          </div>
        </div>

        {/* Title */}
        <div className="w-full mt-5">
          <TextInput
            value={title}
            onChange={setTitle}
            label="Event title"
            placeholder="Enter event title"
            isLightMode
            required
            maxLength={255}
          />
        </div>

        {/* Notes */}
        <div className="w-full mt-5">
          <TextInput
            value={notes}
            onChange={setNotes}
            label="Notes"
            placeholder="(optional)"
            isLightMode
            required={false}
            maxLength={5000}
            isMultiline
          />
        </div>

        {/* Start date */}
        <div className="w-full mt-5">
          <div className="flex flex-col items-end gap-3 align-baseline sm:flex-row">
            <div className="w-full sm:w-1/2">
              <DatePickerField
                label="Event start"
                selectedDay={startDay}
                setSelectedDay={setStartDay}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <Select
                options={startTimes}
                selectedValue={startTime}
                onChange={handleStartTimeChange}
                bordered
                shadow
                isForm
              />
            </div>
          </div>
        </div>

        {/* End date */}
        <div className="w-full mt-5">
          <div className="flex flex-col items-end justify-between gap-3 sm:flex-row">
            <div className="w-full sm:w-1/2">
              <DatePickerField
                label="Event end"
                selectedDay={endDay}
                setSelectedDay={setEndDay}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <Select
                options={endTimes}
                selectedValue={endTime}
                onChange={handleEndTimeChange}
                bordered
                shadow
                isForm
              />
            </div>
          </div>
        </div>

        {formError && <p className="mt-4 text-red">{formError}</p>}
        {calendarEvent ? (
          <div className="flex mt-5 space-x-5">
            <div className="w-1/2">
              <Button
                onClick={openDeleteModal}
                text="Delete event"
                type={ButtonType.OutlineDanger}
                icon={deleteIcon}
                icon2x={deleteIcon2x}
                className="border border-primary-250"
              />
            </div>
            <div className="w-1/2">
              <Button
                onClick={updateEventHandler}
                text="Save event"
                isLoading={isLoadingUpdateEvent}
                disabled={disableSaveButton}
              />
            </div>
          </div>
        ) : (
          <div className="w-full mt-5">
            <Button
              onClick={createEventHandler}
              text="Create event"
              isLoading={isLoadingCreateEvent}
              disabled={disableSaveButton}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
