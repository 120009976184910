import React, { FC } from 'react';
import {
  CalendarEvent,
  MaintenanceStatus,
  PitchMaintenanceStatus,
} from '@intelligent-play-v2/domain';
import { DayStatusTooltip } from './DayStatusTooltip';
import { classnames } from 'tailwindcss-classnames';
import { TooltipParent } from '~/components/tooltip';
import { isAfter } from 'date-fns';

interface CalendarDayProps {
  day: Date;
  calendarWidth: number;
  pitchMaintenanceStatus?: PitchMaintenanceStatus;
  manualMaintenanceEvents: CalendarEvent[];
  isSelected: boolean;
  editMaintenanceEvent: (event: CalendarEvent) => void;
  deleteEvent: (event: CalendarEvent) => void;
}

export const PitchMaintenanceDay: FC<CalendarDayProps> = ({
  day,
  calendarWidth,
  pitchMaintenanceStatus,
  manualMaintenanceEvents,
  isSelected,
  editMaintenanceEvent,
  deleteEvent,
}) => {
  const date = day.getDate();
  const dayWidth = calendarWidth / 7;
  const dayHeight = Math.min(dayWidth, 64);

  const trackedClassnames = classnames(
    {
      'border-red':
        pitchMaintenanceStatus?.status === MaintenanceStatus.Overdue,
    },
    {
      'border-yellow': pitchMaintenanceStatus?.status === MaintenanceStatus.Due,
    },
    {
      'border-green':
        pitchMaintenanceStatus?.status === MaintenanceStatus.Complete,
    },
    {
      'border-white':
        pitchMaintenanceStatus?.status === MaintenanceStatus.NotDue,
    },
    {
      'border-white': !pitchMaintenanceStatus?.status,
    },
    {
      'bg-primary-300': isSelected,
    }
  );

  const dayHasIncompleteMaintenanceEvents = manualMaintenanceEvents.some(
    event => !event.completedAt
  );

  const isDayInFuture = isAfter(day, new Date());

  const manualClassnames = classnames({
    'border-white': !manualMaintenanceEvents.length,
    'border-red': dayHasIncompleteMaintenanceEvents && !isDayInFuture,
    'border-yellow': dayHasIncompleteMaintenanceEvents && isDayInFuture,
    'border-green':
      !!manualMaintenanceEvents.length && !dayHasIncompleteMaintenanceEvents,
  });

  const showTooltip =
    (pitchMaintenanceStatus &&
      pitchMaintenanceStatus.status !== MaintenanceStatus.NotDue) ||
    manualMaintenanceEvents.length;

  return (
    <div
      style={{ width: `${dayWidth}px`, height: `${dayHeight}px` }}
      className="flex items-center justify-center"
    >
      <TooltipParent
        className={`flex-grow h-full border-2 p-0.5 max-h-12 max-w-12 rounded-xs ${manualClassnames}`}
      >
        <div
          className={`flex items-center justify-center w-full h-full p-0.5 text-2xl font-semibold leading-none border-2 rounded-full ${trackedClassnames}`}
        >
          {date}
        </div>
        {!!showTooltip && (
          <DayStatusTooltip
            left={Math.min(dayWidth / 2, 22)}
            isDayInFuture={isDayInFuture}
            pitchMaintenanceStatus={pitchMaintenanceStatus}
            manualMaintenanceEvents={manualMaintenanceEvents}
            editMaintenanceEvent={editMaintenanceEvent}
            deleteEvent={deleteEvent}
          />
        )}
      </TooltipParent>
    </div>
  );
};
