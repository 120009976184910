import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';

interface PageControlsProps {
  pageNumber: number;
  totalPages: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

export const PageControls: FC<PageControlsProps> = ({
  pageNumber,
  totalPages,
  setPageNumber,
}) => {
  if (totalPages < 2) {
    return null;
  }
  const allPages = [...Array(totalPages).keys()].map(value => value + 1);
  const currentPageIndex = pageNumber - 1;
  const numberOfPageControls = 6;
  const numberOfNextControls = pageNumber === 1 ? 6 : 5;
  const nextPagesLastIndex = currentPageIndex + numberOfNextControls;
  const pages = allPages.slice(currentPageIndex, nextPagesLastIndex);
  let prevPagesStartIndex =
    currentPageIndex - numberOfPageControls + pages.length;
  prevPagesStartIndex = prevPagesStartIndex < 0 ? 0 : prevPagesStartIndex;
  pages.unshift(...allPages.slice(prevPagesStartIndex, currentPageIndex));
  const lastIndex = pages.length - 1;
  const lastPageNumber = allPages[allPages.length - 1];
  pages[lastIndex] = lastPageNumber;

  return (
    <div className="flex">
      <div className="flex mt-5 ml-auto">
        {pages.map((page, index) => {
          const showDotted =
            index === numberOfPageControls - 2 &&
            pages[index] < pages[index + 1] - 1;
          const pageNumberClass = classnames({
            'bg-green': page === pageNumber,
            'text-white': page === pageNumber,
            'cursor-pointer': !showDotted,
            'cursor-default': showDotted,
          });
          return (
            <button
              className={`${pageNumberClass} w-6 h-6 text-base font-semibold leading-none rounded-full flex items-center justify-center`}
              key={page}
              onClick={!showDotted ? () => setPageNumber(page) : undefined}
            >
              {showDotted ? '...' : page}
            </button>
          );
        })}
      </div>
    </div>
  );
};
