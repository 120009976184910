import React, { FC, useState } from 'react';
import DayPicker from 'react-day-picker';
import { endOfDay, isAfter } from 'date-fns';

import { Modal } from '~/components/modals';
import { Button } from '~/components/button';
import { DatePickerDay, DatePickerNavbar } from '~/components/datePicker';
import { TextInput } from '~/components/forms';
import { formatDateGbUs } from '~/utils';
import 'react-day-picker/lib/style.css';
import '~/styles/dayPicker.css';

interface SingleDateModalProps {
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  initialDate: Date;
  applyDates: (dateFrom: Date | null, dateTo: Date | null) => void;
}

export const SingleDateModal: FC<SingleDateModalProps> = ({
  showModal,
  setShowModal,
  initialDate,
  applyDates,
}) => {
  const [selectedDay, setSelectedDay] = useState(initialDate);

  const onDayClick = (day: Date): void => {
    if (isAfter(day, endOfDay(new Date()))) {
      return;
    }
    setSelectedDay(day);
  };

  const renderDay = (day: Date): React.ReactNode => {
    return <DatePickerDay day={day} selectedDay={selectedDay} size="small" />;
  };

  const applyDatesHandler = (): void => {
    setShowModal(false);
    applyDates(selectedDay, selectedDay);
  };
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Select date to show"
      estimatedHeight={620}
    >
      <div className="flex space-x-5">
        <TextInput
          isLightMode
          label="Date"
          value={selectedDay ? formatDateGbUs(selectedDay, 'dd/MM/yy') : ''}
          placeholder="Select date"
          disableManualInput
          isFocused
          readOnly
        />
      </div>
      <DayPicker
        className="w-full mt-7 DatePickerSmall DatePickerMobile"
        onDayClick={onDayClick}
        renderDay={renderDay}
        disabledDays={{ after: new Date() }}
        enableOutsideDaysClick={false}
        showOutsideDays={true}
        navbarElement={props => <DatePickerNavbar {...props} />}
        captionElement={<></>}
        weekdaysShort={['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']}
        firstDayOfWeek={1}
        modifiers={{ today: undefined }}
      />
      <div className="flex justify-end space-x-4">
        <div className="w-1/2">
          <Button text="Apply date" onClick={applyDatesHandler} />
        </div>
      </div>
    </Modal>
  );
};
