import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';

// eslint-disable-next-line no-shadow
export enum TooltipPosition {
  Right,
  Center,
  Left,
}
interface TooltipProps {
  pointerLeftPosition: number;
  isBordered?: boolean;
  isDanger?: boolean;
  width?: number;
  isHoverable?: boolean;
  showTooltip?: boolean;
  isHoverDisabled?: boolean;
  tooltipPosition?: TooltipPosition;
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  pointerLeftPosition,
  isBordered,
  isDanger,
  width,
  isHoverable,
  tooltipPosition = TooltipPosition.Right,
  isHoverDisabled,
  showTooltip,
}) => {
  const container = classnames(
    { 'shadow-red': isDanger },
    { 'shadow-250': !isDanger },
    { 'border-red': isDanger },
    { 'border-primary-250': !isDanger },
    { border: isBordered },
    { 'opacity-0': !showTooltip },
    { invisible: !showTooltip },
    { visible: showTooltip },
    { 'opacity-100': showTooltip },
    { 'group-hover:visible': !isHoverDisabled },
    { 'group-hover:opacity-100': !isHoverDisabled }
  );

  const tooltipPointerColour = isDanger ? '#e24259' : '#c9d1d7';

  const pointerShadowSize = isBordered ? '3px 3px 0px 0' : '2px 2px 0px 0';

  let leftValue = '';
  let rightValue = '';
  if (tooltipPosition === TooltipPosition.Center && width) {
    leftValue = `-${width / 2}px`;
    rightValue = `-${width / 2}px`;
  } else if (tooltipPosition === TooltipPosition.Left && width) {
    leftValue = `-${width}px`;
  }

  const tooltipStyle = {
    bottom: 'calc(100% + 8px)',
    left: leftValue,
    right: rightValue,
  };

  return (
    <div
      className={`bg-white rounded absolute z-20 transition-opacity ${container}`}
      style={tooltipStyle}
    >
      {/* content */}
      {children}
      {/* pointer */}
      <div
        className="absolute -bottom-0.5 w-1.5 h-1.5 rotate-45 bg-white -ml-1"
        style={{
          left: pointerLeftPosition,
          boxShadow: `${pointerShadowSize}  ${tooltipPointerColour}`,
          borderColor: 'transparent #c9d1d7 #c9d1d7 transparent',
          transform: 'rotateY(0deg) rotate(45deg)',
        }}
      />
      {isHoverable && <div className="absolute left-0 right-0 h-3 -bottom-3" />}
    </div>
  );
};
