import { queryBuilder } from '~/api';

interface HeatmapRequestParams {
  pitchId: string;
  date: Date;
}

export const getPitchStateHeatmap = async ({
  pitchId,
  date,
}: HeatmapRequestParams): Promise<string> => {
  const { data } = await queryBuilder(
    'GET',
    `pitches/${pitchId}/pitchStateHeatmap`,
    {
      date,
    }
  );
  return data;
};
