import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import returnIcon from 'assets/images/navs/icon__return.png';
import returnIcon2x from 'assets/images/navs/icon__return@2x.png';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  backTitle?: string;
  backPath?: string;
}

export const PageHeaderWithButtons: FC<PageHeaderProps> = ({
  title,
  subtitle,
  children,
  backTitle,
  backPath,
}) => {
  return (
    <div className="pt-7.5 bg-white border-b border-primary-100 md:pt-6 sm:static sticky top-15 sm:top-0 z-20 sm:z-0">
      <div className="flex px-3 pb-4 md:px-15 md:pb-7.5 pt-2 items-center">
        <div className="space-y-4">
          {backTitle && backPath && (
            <Link className="flex items-center" to={backPath}>
              <picture>
                <img
                  src={returnIcon}
                  srcSet={`${returnIcon} 1x, ${returnIcon2x} 2x`}
                />
              </picture>
              <div className="text-sm font-medium text-primary-900 ml-2.5">
                {backTitle}
              </div>
            </Link>
          )}
          <div className="mt-auto font-tw-cent text-3xl font-bold">{title}</div>
          {subtitle && (
            <div className="text-primary-300 ml-5 mb-0.5">{subtitle}</div>
          )}
        </div>
        <div className="ml-auto">
          <ul className="">{children}</ul>
        </div>
      </div>
    </div>
  );
};
