import { RefObject, useEffect } from 'react';

export const useOutsideClicker = (
  ref: RefObject<HTMLDivElement>,
  ignoreButtonRef: RefObject<HTMLButtonElement | HTMLDivElement>,
  clickOutside: () => void
): void => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: any): void => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        ignoreButtonRef.current &&
        !ignoreButtonRef.current.contains(event.target)
      ) {
        clickOutside();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [clickOutside, ignoreButtonRef, ref]);
};
