import React, { FC } from 'react';

import { SportType } from '@intelligent-play-v2/domain';
import {
  getIconForSportType,
  getLocalisedSportName,
} from '~/utils/pitchSportType';
import { Country } from 'countries-and-timezones';

interface PitchSportTypeProps {
  sportTypeId: SportType;
  isSmallText?: boolean;
  country: Country | null;
}

export const PitchSportType: FC<PitchSportTypeProps> = ({
  sportTypeId,
  isSmallText,
  country,
}) => {
  const textSizeClass = isSmallText ? 'text-sm' : '';

  const [icon, icon2x] = getIconForSportType(sportTypeId);
  const localisedSportName = getLocalisedSportName(sportTypeId, country);

  return (
    <div className="flex flex-row items-center">
      {icon && icon2x && (
        <picture className="mr-1 w-3.5">
          <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
        </picture>
      )}
      <div className={`font-semibold ${textSizeClass}`}>
        {localisedSportName}
      </div>
    </div>
  );
};
