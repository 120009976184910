import React, { FC } from 'react';
import ct from 'countries-and-timezones';
import { Facility } from '@intelligent-play-v2/domain';
import { getCountryFlag } from '~/utils';

interface FacilityNameProps {
  facility: Facility;
}

export const FacilityName: FC<FacilityNameProps> = ({ facility }) => {
  const country = ct.getCountryForTimezone(facility.tzDatabaseTimezone || '');

  const flagIcons = country ? getCountryFlag(country) : null;

  return (
    <div className="grid transform -translate-y-0.5">
      <span className="font-semibold truncate">{facility.name}</span>
      <div className="flex items-center space-x-1.5">
        {flagIcons && (
          <picture>
            <img
              src={flagIcons.flagIcon2x}
              srcSet={`${flagIcons.flagIcon} 1x, ${flagIcons.flagIcon2x} 2x`}
            />
          </picture>
        )}
        <span className="text-xs text-primary-400">
          {country ? country.name : ''}
        </span>
      </div>
    </div>
  );
};
