import {
  addDays,
  differenceInDays,
  differenceInWeeks,
  eachDayOfInterval,
  format,
  startOfDay,
  subDays,
} from 'date-fns';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import isEqual from 'lodash.isequal';
import toast from 'react-hot-toast';
import startCase from 'lodash.startcase';
import capitalize from 'lodash.capitalize';

import {
  Day,
  Pitch,
  ReportArea,
  ReportPreset,
  TimePeriod,
} from '@intelligent-play-v2/domain';

import {
  useCheckedArray,
  useContainerDimensions,
  usePageTitle,
  useTimePeriodSelect,
} from '~/hooks';
import { useMetadataStore } from '~/stores';

import { Button, ButtonType, ToggleButton } from '~/components/button';
import { FacilitySelector, ReportOption } from '~/components/createReport';
import { DateRangeModal, Modal, PromptModal } from '~/components/modals';
import { PageHeaderWithButtons } from '~/components/layout';
import { RoutesPath } from '~/types';
import { LabelledCheckbox } from '~/components/labelledCheckbox';
import { Select } from '~/components/select';
import { useTimeRange } from '~/hooks/useTimeRange.hook';
import {
  deletePreset,
  getReportPresets,
  postPreset,
  updatePreset,
} from '~/api/queries';
import { getDaysIncluded, getIncludedDays } from '~/utils';
import deleteIcon from 'assets/images/ctas_inputs_modals/icon__cta-delete-white.png';
import deleteIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-delete-white@2x.png';
import closeIcon from 'assets/images/navs/icon__mobile-close.png';
import closeIcon2x from 'assets/images/navs/icon__mobile-close@2x.png';

import { useReportQueryParams } from '~/hooks/useReportQueryParams.hook';
import { Loading } from '~/components/loading';
import { enumToObjectArray } from '~/utils/enumToObjectArray';
import { TextInput } from '~/components/forms';

export const ReportPage: FC = () => {
  usePageTitle('Report - Intelligent Play');
  const queryClient = useQueryClient();
  const history = useHistory();
  const {
    presetId: presetIdParam,
    pitchIds,
    timePeriod: timePeriodParam,
    customDates: customDatesParam,
    includedDays: initialIncludedDays,
    dailyStartTime,
    dailyEndTime,
    selectedReportAreaIds,
  } = useReportQueryParams();

  const { facilities } = useMetadataStore();
  const allPitchIds = facilities.reduce((accum: number[], facility) => {
    const facilityPitchIds =
      facility.pitches?.map(pitch => {
        return pitch.id;
      }) ?? [];
    accum.push(...facilityPitchIds);
    return accum;
  }, []);

  const sortedFacilities = [...facilities].sort((a, b) => {
    const nameA = a.name?.toUpperCase() ?? '';
    const nameB = b.name?.toUpperCase() ?? '';
    return nameA < nameB ? -1 : 1;
  });

  const [confirmDeletionPresetId, setConfirmDeletionPresetId] = useState<
    number | null
  >(null);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [showCreatePresetModal, setShowCreatePresetModal] = useState(false);
  const [createPresetTitle, setCreatePresetTitle] = useState('');
  const [createPresetTitleError, setCreatePresetTitleError] = useState('');
  const [selectedPresetId, setSelectedPresetId] = useState(0);
  const [reportTitle, setReportTitle] = useState('');
  const [postPresetErrorMessage, setPostPresetErrorMessage] = useState('');

  const onChangeCreatePresetTitle = (value: string): void => {
    if (value.length > 40) {
      setCreatePresetTitleError('Maximum title length is 40 characters');
    } else {
      setCreatePresetTitleError('');
    }
    setCreatePresetTitle(value);
  };

  const {
    startTime,
    startTimes,
    handleStartTimeChange,
    endTime,
    endTimes,
    handleEndTimeChange,
  } = useTimeRange(60, dailyStartTime, dailyEndTime);
  const {
    selectedTimePeriod,
    setSelectedTimePeriod,
    setCustomDates,
    customDates,
    customDatesText,
  } = useTimePeriodSelect(timePeriodParam, customDatesParam);

  let { data: fetchedReportPresets } = useQuery('reportPresets', async () =>
    getReportPresets()
  );

  const everyDay = [1, 2, 3, 4, 5, 6, 0];

  const getSelectedDays = (): number[] => {
    let selectedDays: number[] = [];

    switch (selectedTimePeriod) {
      case TimePeriod.Yesterday:
        selectedDays = [subDays(new Date(), 1).getDay()];
        break;
      case TimePeriod.Today:
        selectedDays = [new Date().getDay()];
        break;
      case TimePeriod.Custom:
        if (
          customDates &&
          differenceInDays(customDates.endDate, customDates.startDate) < 6
        ) {
          selectedDays = eachDayOfInterval({
            start: customDates.startDate,
            end: customDates.endDate,
          }).map(date => date.getDay());
        } else {
          selectedDays = checkedDays;
        }
        break;
      default:
        selectedDays = checkedDays;
    }
    return selectedDays;
  };

  const getDisabledDays = (selectedDays: number[]): number[] => {
    let newDisabledDays: number[] = [];
    switch (selectedTimePeriod) {
      case TimePeriod.Today:
      case TimePeriod.Yesterday:
        newDisabledDays = everyDay.filter(d => !selectedDays.includes(d));
        break;
      case TimePeriod.Custom:
        if (
          customDates &&
          differenceInDays(customDates.endDate, customDates.startDate) < 6
        ) {
          newDisabledDays = everyDay.filter(d => !selectedDays.includes(d));
        }
        break;
      default:
        newDisabledDays = [];
    }
    return newDisabledDays;
  };

  useEffect(() => {
    const selectedDays = getSelectedDays();
    const newDisabledDays = getDisabledDays(selectedDays);
    setCheckedDays(selectedDays);
    setDisabledDays(newDisabledDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimePeriod, customDates]);

  const {
    checkedItems: checkedPitchIds,
    toggleCheckedMany: toggleCheckedPitchIds,
    setCheckedItems: setCheckedPitchIds,
  } = useCheckedArray(pitchIds);

  const reportAreas = useMemo(() => enumToObjectArray(ReportArea as never), []);

  const initialReportAreas =
    selectedReportAreaIds && selectedReportAreaIds.length
      ? selectedReportAreaIds
      : reportAreas.map(({ id }) => id);

  const {
    checkedItems: checkedReportAreas,
    toggleChecked: toggleCheckedReportArea,
    setCheckedItems: setCheckedReportAreas,
  } = useCheckedArray(initialReportAreas);
  const [disabledReportAreas, setDisabledReportAreas] = useState<number[]>([]);

  const initialReportDays =
    initialIncludedDays && initialIncludedDays.length
      ? initialIncludedDays
      : [
          Day.Monday,
          Day.Tuesday,
          Day.Wednesday,
          Day.Thursday,
          Day.Friday,
          Day.Saturday,
          Day.Sunday,
        ];

  const {
    checkedItems: checkedDays,
    toggleChecked: toggleCheckedDay,
    setCheckedItems: setCheckedDays,
  } = useCheckedArray(initialReportDays);
  const [disabledDays, setDisabledDays] = useState<number[]>([]);

  useEffect(() => {
    let enableHeatmap = true;

    switch (selectedTimePeriod) {
      case TimePeriod.Custom:
        enableHeatmap =
          !customDates ||
          differenceInWeeks(customDates?.endDate, customDates?.startDate) < 4;
        break;
      case TimePeriod.Last3Months:
      case TimePeriod.Last12Months:
      case TimePeriod.QuarterToDate:
      case TimePeriod.YearToDate:
      case TimePeriod.Total:
        enableHeatmap = false;
        break;
      default:
        break;
    }

    if (!enableHeatmap) {
      setDisabledReportAreas([ReportArea.Heatmap]);
      const newSelectedReportAreas = [...checkedReportAreas].filter(
        id => id !== ReportArea.Heatmap
      );
      setCheckedReportAreas(newSelectedReportAreas);
      toast(
        t => (
          <span className="inline-flex">
            Heatmaps are not currently available for time periods longer than 4
            weeks
            <button className="w-6" onClick={() => toast.dismiss(t.id)}>
              <picture>
                <img
                  className="cursor-pointer filter invert left-5 top-5"
                  src={closeIcon2x}
                  srcSet={`${closeIcon} 1x, ${closeIcon2x} 2x`}
                />
              </picture>
            </button>
          </span>
        ),
        {
          icon: '⚠️',
          duration: Infinity,
          id: 'heatmap-toast',
        }
      );
    } else {
      toast.dismiss();
      setDisabledReportAreas([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimePeriod, customDates]);

  const applyCustomDatesHandler = (
    customFrom: Date | null,
    customTo: Date | null
  ): void => {
    if (customFrom && customTo) {
      setSelectedTimePeriod(TimePeriod.Custom);
      setCustomDates({
        startDate: customFrom,
        endDate: customTo,
      });
    }
  };

  const facilitySelectedPitches = (pitches: Pitch[]): number[] => {
    return pitches.reduce((accum: number[], pitch) => {
      if (checkedPitchIds.includes(pitch.id)) {
        accum.push(pitch.id);
      }
      return accum;
    }, []);
  };

  const [dateRangeError, setDateRangeError] = useState('');
  const [pitchSelectorError, setPitchSelectorError] = useState('');
  const [dayError, setDayError] = useState('');
  const [reportAreasError, setReportAreasError] = useState('');
  const [reportTitleError, setReportTitleError] = useState('');

  const dateRangeRef = useRef<HTMLDivElement>(null);
  const pitchSelectorRef = useRef<HTMLDivElement>(null);
  const dayRef = useRef<HTMLDivElement>(null);
  const reportAreasRef = useRef<HTMLDivElement>(null);
  const reportTitleRef = useRef<HTMLDivElement>(null);

  const [reportPresets, setReportPresets] = useState<ReportPreset[]>([]);

  useEffect(() => {
    const allPresets = fetchedReportPresets ?? [];
    const customPreset: ReportPreset = {
      id: 0,
      areas: reportAreas.map(({ id }) => id),
      endDate: null,
      startDate: null,
      startTime: '00:00',
      endTime: '00:00',
      includeMonday: true,
      includeTuesday: true,
      includeWednesday: true,
      includeThursday: true,
      includeFriday: true,
      includeSaturday: true,
      includeSunday: true,
      pitchIds: [],
      presetName: 'Custom',
      reportTitle: '',
      userId: null,
      timePeriod: TimePeriod.Last7Days,
    };
    setReportPresets([...allPresets, customPreset]);
    if (presetIdParam) {
      setSelectedPresetId(presetIdParam);
    }
  }, [fetchedReportPresets, presetIdParam, reportAreas]);

  const scrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'start',
  };

  const generateReportHandler = (): void => {
    if (/[<>]/.test(reportTitle)) {
      setReportTitleError('You cannot use the following characters < >');
      reportTitleRef.current?.scrollIntoView(scrollOptions);
      return;
    } else if (reportTitle.length > 255) {
      setReportTitleError('You must use less than 255 characters');
      reportTitleRef.current?.scrollIntoView(scrollOptions);
      return;
    } else if (!selectedTimePeriod) {
      setDateRangeError('You must select a date range');
      dateRangeRef.current?.scrollIntoView(scrollOptions);
      return;
    } else if (checkedDays.length === 0) {
      setDayError('You must select at least one day');
      dayRef.current?.scrollIntoView(scrollOptions);
      return;
    } else if (checkedReportAreas.length === 0) {
      setReportAreasError('You must select at least one Report Area');
      reportAreasRef.current?.scrollIntoView(scrollOptions);
      return;
    } else if (checkedPitchIds.length === 0) {
      setPitchSelectorError('You must select at least one Field');
      pitchSelectorRef.current?.scrollIntoView(scrollOptions);
      return;
    }

    const searchParams = new URLSearchParams({
      timePeriod: selectedTimePeriod.toString(),
      pitchIds: checkedPitchIds.join(','),
      reportTitle: reportTitleValue,
      days: checkedDays.join(','),
      startTime: startTime.value,
      endTime: endTime.value,
      reportAreas: checkedReportAreas.join(','),
    });

    if (selectedPresetId) {
      searchParams.append('presetId', selectedPresetId.toString());
    }

    if (selectedTimePeriod === TimePeriod.Custom && customDates) {
      searchParams.append(
        'startDate',
        format(customDates.startDate, 'yyyy-MM-dd')
      );
      searchParams.append('endDate', format(customDates.endDate, 'yyyy-MM-dd'));
    }

    history.push({
      pathname: RoutesPath.ReportResults,
      search: searchParams.toString(),
    });
  };

  const disableAfter = addDays(new Date(), 1);

  const isAllPitchesChecked =
    checkedPitchIds.sort().toString() === allPitchIds.sort().toString();

  const selectPresetHandler = (presetId: number): void => {
    const selectedPreset = reportPresets.find(preset => preset.id === presetId);
    if (!selectedPreset) {
      return;
    }

    setSelectedPresetId(selectedPreset.id);
    setReportTitle(selectedPreset.reportTitle);
    setCheckedReportAreas(selectedPreset.areas ?? []);
    setCheckedPitchIds(selectedPreset.pitchIds ?? []);
    setSelectedTimePeriod(selectedPreset.timePeriod);
    if (
      selectedPreset.timePeriod === TimePeriod.Custom &&
      selectedPreset.startDate &&
      selectedPreset.endDate
    ) {
      setCustomDates({
        startDate: selectedPreset.startDate,
        endDate: selectedPreset.endDate,
      });
    } else {
      setCustomDates(null);
    }
    handleStartTimeChange({
      label: selectedPreset.startTime,
      value: selectedPreset.startTime,
    });
    handleEndTimeChange({
      label: selectedPreset.endTime,
      value: selectedPreset.endTime,
    });
    const includedDays = getIncludedDays(selectedPreset);
    setCheckedDays(includedDays);
  };

  const { mutate: mutatePreset, isLoading: isPostPresetLoading } = useMutation(
    postPreset,
    {
      onSuccess: data => {
        fetchedReportPresets?.push(data);
        queryClient.invalidateQueries('reportPresets');
        setSelectedPresetId(data.id);
        toast.success('Succesfully created report preset');
        setPostPresetErrorMessage('');
        setCreatePresetTitle('');
        setShowCreatePresetModal(false);
      },
      onError: () => {
        setPostPresetErrorMessage('Error saving report preset');
      },
    }
  );

  const handleShowCreatePresetModal = (): void => {
    if (!selectedTimePeriod) {
      setDateRangeError('You must select a date range');
      dateRangeRef.current?.scrollIntoView(scrollOptions);
      return;
    }
    setShowCreatePresetModal(true);
  };

  const createPresetHandler = (): void => {
    setPostPresetErrorMessage('');
    if (!selectedTimePeriod) {
      return;
    }
    const daysIncluded = getDaysIncluded(checkedDays);
    mutatePreset({
      presetName: createPresetTitle,
      reportTitle,
      pitchIds: checkedPitchIds,
      areas: checkedReportAreas,
      timePeriod: selectedTimePeriod,
      startDate: customDates?.startDate ?? null,
      endDate: customDates?.endDate ?? null,
      startTime: startTime.value,
      endTime: endTime.value,
      ...daysIncluded,
    });
  };

  const {
    mutate: mutateDeletePreset,
    isLoading: isDeletingPreset,
  } = useMutation(deletePreset, {
    onSuccess: () => {
      fetchedReportPresets = fetchedReportPresets?.filter(
        preset => preset.id !== confirmDeletionPresetId
      );
      queryClient.invalidateQueries('reportPresets');
      setConfirmDeletionPresetId(null);
    },
  });

  const onDeletePresetHandler = (presetId: number | null): void => {
    if (!presetId) {
      return;
    }
    mutateDeletePreset(presetId);
  };

  const currentPreset = reportPresets.find(
    preset => selectedPresetId === preset.id
  );

  const hasPresetChanged = (): boolean => {
    if (!selectedTimePeriod || !currentPreset) {
      return false;
    }
    const daysIncluded = getDaysIncluded(checkedDays);
    const startDate = customDates ? startOfDay(customDates?.startDate) : null;
    const endDate = customDates ? startOfDay(customDates?.endDate) : null;

    const currentOptions: ReportPreset = {
      id: currentPreset.id,
      userId: currentPreset.userId,
      presetName: currentPreset.presetName,
      reportTitle,
      pitchIds: checkedPitchIds,
      areas: checkedReportAreas,
      timePeriod: selectedTimePeriod,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime.value,
      endTime: endTime.value,
      ...daysIncluded,
    };
    return isEqual(currentOptions, currentPreset);
  };

  const {
    mutate: mutateUpdatePreset,
    isLoading: isUpdatingPreset,
  } = useMutation(updatePreset, {
    onSuccess: () => {
      toast.success('Succesfully updated report preset');
      queryClient.invalidateQueries('reportPresets');
    },
  });

  useEffect(() => {
    if (checkedPitchIds && checkedPitchIds.length && pitchSelectorError) {
      setPitchSelectorError('');
    }
  }, [checkedPitchIds, pitchSelectorError]);

  useEffect(() => {
    if (selectedTimePeriod && dateRangeError) {
      setDateRangeError('');
    }
  }, [dateRangeError, selectedTimePeriod]);

  useEffect(() => {
    if (checkedDays && checkedDays.length && dayError) {
      setDayError('');
    }
  }, [checkedDays, dayError]);

  useEffect(() => {
    if (checkedReportAreas && checkedReportAreas.length && reportAreasError) {
      setReportAreasError('');
    }
  }, [checkedReportAreas, reportAreasError]);

  useEffect(() => {
    if (reportTitle) {
      setReportTitleError('');
    }
  }, [reportTitle]);

  const updatePresetHandler = (): void => {
    if (!selectedTimePeriod || !currentPreset) {
      return;
    }
    const daysIncluded = getDaysIncluded(checkedDays);
    const currentOptions: ReportPreset = {
      id: currentPreset.id,
      userId: currentPreset.userId,
      presetName: currentPreset.presetName,
      reportTitle,
      pitchIds: checkedPitchIds,
      areas: checkedReportAreas,
      timePeriod: selectedTimePeriod,
      startDate: customDates?.startDate ?? null,
      endDate: customDates?.endDate ?? null,
      startTime: startTime.value,
      endTime: endTime.value,
      ...daysIncluded,
    };
    mutateUpdatePreset(currentOptions);
  };

  const buttonSizingRef = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(buttonSizingRef);
  let size: undefined | 'small' | 'xsmall';
  if (width < 600) {
    size = 'xsmall';
  } else if (width < 800) {
    size = 'small';
  }

  const reportTitleValue =
    currentPreset && !reportTitle && currentPreset.id !== 0
      ? currentPreset.presetName
      : reportTitle;

  return (
    <>
      <PageHeaderWithButtons title="Create a new report">
        <div className="flex space-x-5">
          {selectedPresetId !== 0 && currentPreset && currentPreset.userId && (
            <Button
              type={ButtonType.Outline}
              text="Save preset"
              className="pb-0.75"
              onClick={updatePresetHandler}
              disabled={hasPresetChanged()}
              isLoading={isUpdatingPreset}
              size={size}
            />
          )}
          <Button
            type={ButtonType.Outline}
            text={
              selectedPresetId !== 0 && currentPreset && currentPreset.userId
                ? 'Save preset copy'
                : 'Create preset'
            }
            className="pb-0.75"
            onClick={handleShowCreatePresetModal}
            size={size}
          />
          <Button
            type={ButtonType.Secondary}
            text="Generate report"
            className="pb-0.75"
            onClick={generateReportHandler}
            size={size}
          />
        </div>
      </PageHeaderWithButtons>
      <div className="mx-5 my-1 lg:my-5 lg:mx-15">
        <div ref={buttonSizingRef}>
          <div>
            <ReportOption title="Select a preset" titleTopPadding={'4px'}>
              <div className="flex flex-wrap w-full gap-2.5">
                {reportPresets && reportPresets.length ? (
                  reportPresets.map(reportPreset => (
                    <ToggleButton
                      key={reportPreset.id}
                      size="large"
                      text={reportPreset.presetName}
                      onClick={() => selectPresetHandler(reportPreset.id)}
                      active={selectedPresetId === reportPreset.id}
                      onDelete={
                        reportPreset.id > 0 && reportPreset.userId
                          ? () => setConfirmDeletionPresetId(reportPreset.id)
                          : null
                      }
                    />
                  ))
                ) : (
                  <Loading />
                )}
              </div>
            </ReportOption>
            <ReportOption
              title="Report Title"
              titleTopPadding={'12px'}
              divRef={reportTitleRef}
              error={reportTitleError}
            >
              <TextInput
                isLightMode
                value={reportTitleValue}
                onChange={setReportTitle}
              />
            </ReportOption>
            <ReportOption
              title="Select date range"
              titleTopPadding={'4px'}
              error={dateRangeError}
              divRef={dateRangeRef}
            >
              <div className="flex flex-wrap w-full gap-2.5">
                <ToggleButton
                  size="large"
                  text={TimePeriod.Yesterday}
                  onClick={() => setSelectedTimePeriod(TimePeriod.Yesterday)}
                  active={selectedTimePeriod === TimePeriod.Yesterday}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.Today}
                  onClick={() => setSelectedTimePeriod(TimePeriod.Today)}
                  active={selectedTimePeriod === TimePeriod.Today}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.Last7Days}
                  onClick={() => setSelectedTimePeriod(TimePeriod.Last7Days)}
                  active={selectedTimePeriod === TimePeriod.Last7Days}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.Last4Weeks}
                  onClick={() => setSelectedTimePeriod(TimePeriod.Last4Weeks)}
                  active={selectedTimePeriod === TimePeriod.Last4Weeks}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.Last3Months}
                  onClick={() => setSelectedTimePeriod(TimePeriod.Last3Months)}
                  active={selectedTimePeriod === TimePeriod.Last3Months}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.Last12Months}
                  onClick={() => setSelectedTimePeriod(TimePeriod.Last12Months)}
                  active={selectedTimePeriod === TimePeriod.Last12Months}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.WeekToDate}
                  onClick={() => setSelectedTimePeriod(TimePeriod.WeekToDate)}
                  active={selectedTimePeriod === TimePeriod.WeekToDate}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.MonthToDate}
                  onClick={() => setSelectedTimePeriod(TimePeriod.MonthToDate)}
                  active={selectedTimePeriod === TimePeriod.MonthToDate}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.QuarterToDate}
                  onClick={() =>
                    setSelectedTimePeriod(TimePeriod.QuarterToDate)
                  }
                  active={selectedTimePeriod === TimePeriod.QuarterToDate}
                />
                <ToggleButton
                  size="large"
                  text={TimePeriod.YearToDate}
                  onClick={() => setSelectedTimePeriod(TimePeriod.YearToDate)}
                  active={selectedTimePeriod === TimePeriod.YearToDate}
                />
                <ToggleButton
                  size="large"
                  text={customDatesText}
                  onClick={() => {
                    if (customDates) {
                      setSelectedTimePeriod(TimePeriod.Custom);
                    }
                    setShowDateRangeModal(!showDateRangeModal);
                  }}
                  active={selectedTimePeriod === 'Custom'}
                />
              </div>
            </ReportOption>
            <ReportOption
              title="Days & times"
              titleTopPadding={'7px'}
              error={dayError}
              divRef={dayRef}
            >
              <div className="flex flex-wrap w-full gap-2.5">
                {[
                  Day.Monday,
                  Day.Tuesday,
                  Day.Wednesday,
                  Day.Thursday,
                  Day.Friday,
                  Day.Saturday,
                  Day.Sunday,
                ].map(day => (
                  <LabelledCheckbox
                    key={day}
                    title={Day[day]}
                    isChecked={checkedDays.includes(day)}
                    toggleChecked={() => toggleCheckedDay(day)}
                    disabled={disabledDays.includes(day)}
                  />
                ))}
              </div>
              <div className="flex">
                <div className="flex items-center p-2 mt-4 bg-white">
                  <div className="w-22">
                    <Select
                      options={startTimes}
                      selectedValue={startTime}
                      onChange={handleStartTimeChange}
                      bordered
                    />
                  </div>
                  <div className="px-2">-</div>
                  <div className="w-22">
                    <Select
                      options={endTimes}
                      selectedValue={endTime}
                      onChange={handleEndTimeChange}
                      bordered
                    />
                  </div>
                </div>
              </div>
            </ReportOption>
            <ReportOption
              title="Report areas"
              titleTopPadding={'7px'}
              divRef={reportAreasRef}
              error={reportAreasError}
            >
              <div className="flex flex-wrap w-full gap-2.5">
                {reportAreas.map(({ id, name }) => {
                  const title =
                    name === 'EUH' ? name : capitalize(startCase(name));
                  return (
                    <LabelledCheckbox
                      key={id}
                      title={title}
                      isChecked={checkedReportAreas.includes(id)}
                      toggleChecked={() => toggleCheckedReportArea(id)}
                      disabled={disabledReportAreas.includes(id)}
                    />
                  );
                })}
              </div>
            </ReportOption>
            <ReportOption
              title="Fields to include"
              titleTopPadding="4px"
              error={pitchSelectorError}
              divRef={pitchSelectorRef}
            >
              <LabelledCheckbox
                title="All fields"
                isChecked={isAllPitchesChecked}
                toggleChecked={() =>
                  toggleCheckedPitchIds(allPitchIds, !isAllPitchesChecked)
                }
              />
              <div className="space-y-2.5 mt-2">
                {sortedFacilities.map(facility => (
                  <FacilitySelector
                    facility={facility}
                    key={facility.id}
                    selectedPitches={facilitySelectedPitches(
                      facility.pitches ?? []
                    )}
                    onChangeCheckboxHandler={toggleCheckedPitchIds}
                  />
                ))}
              </div>
            </ReportOption>
          </div>
          <div className="flex mt-7.5">
            <div className="ml-auto">
              <Button
                text="Generate Report"
                type={ButtonType.Secondary}
                onClick={generateReportHandler}
              />
            </div>
          </div>
        </div>
        <DateRangeModal
          disableAfter={disableAfter}
          showModal={showDateRangeModal}
          setShowModal={setShowDateRangeModal}
          applyDates={applyCustomDatesHandler}
          permittedDateRange={{ months: 6 }}
        />
        <Modal
          title="Create preset"
          showModal={showCreatePresetModal}
          setShowModal={setShowCreatePresetModal}
        >
          <div className="w-80">
            <TextInput
              isLightMode
              label="Preset title (cannot be changed)"
              value={createPresetTitle}
              onChange={onChangeCreatePresetTitle}
              placeholder="Field text"
            />
            {createPresetTitleError && (
              <div className="mt-2 text-sm text-red">
                {createPresetTitleError}
              </div>
            )}
          </div>
          <Button
            className="mt-5"
            text="Create preset"
            disabled={!createPresetTitle || !!createPresetTitleError}
            onClick={() => createPresetHandler()}
            isLoading={isPostPresetLoading}
          />
          {postPresetErrorMessage && (
            <div className="mt-2 text-sm text-red">
              {postPresetErrorMessage}
            </div>
          )}
        </Modal>
        <PromptModal
          showModal={!!confirmDeletionPresetId}
          title="Are you sure you want to delete this?"
          primaryButton={
            <Button
              text="Yes, delete"
              type={ButtonType.Danger}
              size={'small'}
              onClick={() => onDeletePresetHandler(confirmDeletionPresetId)}
              isLoading={isDeletingPreset}
              icon={deleteIcon}
              icon2x={deleteIcon2x}
            />
          }
          secondaryButton={
            <Button
              text="Cancel"
              onClick={() => setConfirmDeletionPresetId(null)}
              type={ButtonType.Outline}
              size={'small'}
            />
          }
        />
      </div>
    </>
  );
};
