import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AuthLayout, AuthTitle } from '~/components/auth';
import { AuthText } from '~/components/auth/AuthText';
import { Button } from '~/components/button';
import { checkServerVersion } from '~/utils';
import { useAuthStore } from '~/stores';
import { postLogin } from '~/api/queries';
import { RoutesPath } from '~/types';
import { usePageTitle } from '~/hooks';
import { TextInput } from '~/components/forms';

type FormValues = {
  username: string;
  password: string;
};

export const AuthPage: FC = () => {
  usePageTitle('Login - Intelligent Play');
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm();

  const { setUser } = useAuthStore();
  const [error, setError] = useState<string>();

  useEffect(() => {
    checkServerVersion();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { from } = (location.state as { from: any }) || {
    from: { pathname: '/' },
  };

  const { mutate: mutateLogin, isLoading } = useMutation(postLogin, {
    onSuccess: data => {
      setUser(data);
      history.replace(from);
    },
    onError: (e: unknown) => {
      setError(String(e));
    },
  });

  const onSubmitHandler: SubmitHandler<FormValues> = (data): void => {
    mutateLogin(data);
  };

  return (
    <AuthLayout>
      <AuthTitle text="Sign in" />
      <AuthText text="Log in to your account to continue" />
      <TextInput
        register={register('username', {
          pattern: {
            value: /^\S+@\S+\.\S+$/,
            message: 'Please enter a valid email address',
          },
        })}
        label="Email address"
        placeholder="Enter email"
        required={true}
        type="email"
        errorMessage={errors.username?.message}
      />
      <TextInput
        register={register('password', { required: 'Please enter a password' })}
        label="Password"
        placeholder="Enter password"
        required={true}
        type="password"
        buttonText="Forgot password?"
        errorMessage={errors.password?.message}
        buttonAction={() => {
          history.push(RoutesPath.ForgotPassword);
        }}
      />
      <Button
        onClick={handleSubmit(onSubmitHandler)}
        text="Sign in"
        disabled={!dirtyFields.username || !dirtyFields.password}
        className="mt-auto lg:mt-12.5"
        isLoading={isLoading}
        disabledStyle="dark"
      />
      {error && (
        <p className="mt-4 text-red">
          {error.includes('401') ? 'Invalid Credentials' : 'Server Error'}
        </p>
      )}
    </AuthLayout>
  );
};
