import { DailyWeather } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api/queryBuilder';

export interface HistoricalWeatherParams {
  latLong: string;
  dates?: string;
  from?: string;
  to?: string;
}

export const getHistoricalWeather = async ({
  latLong,
  dates,
  from,
  to,
}: HistoricalWeatherParams): Promise<Record<string, DailyWeather>> => {
  const { data } = await queryBuilder('GET', 'weather/historical', {
    latLong,
    dates,
    from,
    to,
  });
  return data;
};
