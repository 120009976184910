import React, { FC } from 'react';
import { PageHeader } from '~/components/layout';
import { ContentContainer } from '~/components/layout/contentContainer';
import { FacilitiesTable } from '~/components/table/facilityTable/FacilitiesTable';
import { usePageTitle } from '~/hooks';

export const FacilitiesPage: FC = () => {
  usePageTitle('Facilities - Intelligent Play');
  return (
    <>
      <PageHeader title="My Facilities" />
      <ContentContainer className="my-5 sm:my-12.5">
        <FacilitiesTable showTitle={false} />
      </ContentContainer>
    </>
  );
};
