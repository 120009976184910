import React, { FC } from 'react';
import {
  AlertType,
  Pitch,
  UserAlertSubscription,
} from '@intelligent-play-v2/domain';
import capitalize from 'lodash.capitalize';
import startCase from 'lodash.startcase';
import { enumToObjectArray } from '~/utils/enumToObjectArray';
import { LabelledCheckbox } from '../labelledCheckbox';

interface PitchUserAlertSettingsProps {
  pitch: Pitch;
  selectedSubscriptions: UserAlertSubscription[];
  setSelectedSubscription: (alertTypeId: number, isChecked: boolean) => void;
}
export const PitchUserAlertSettings: FC<PitchUserAlertSettingsProps> = ({
  pitch,
  selectedSubscriptions,
  setSelectedSubscription,
}) => {
  const alertTypes = enumToObjectArray(AlertType as never);

  return (
    <div>
      <h6 className="pl-2 font-semibold">Your Alerts</h6>
      <div className="inline-flex flex-wrap gap-x-2">
        {Object.values(alertTypes)
          .filter(
            ({ id }) =>
              id !== AlertType.CalendarEventAssigned &&
              id !== AlertType.CalendarRecurrenceEventAssigned
          )
          .map(({ name, id }) => {
            const isChecked = !!selectedSubscriptions?.find(
              ({ pitchId, alertTypeId }) =>
                alertTypeId === id && pitchId === pitch.id
            );
            return (
              <LabelledCheckbox
                key={`${pitch.id}-${id}`}
                title={capitalize(startCase(name))}
                isChecked={isChecked}
                toggleChecked={() => {
                  setSelectedSubscription(id, !isChecked);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};
