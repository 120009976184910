import { getApiUrl } from '~/utils';

export const API_URL = getApiUrl();

export const APP_ROOT = 'root';

export const IP_ONBOARDING_TOUR_UUID = 'a9d5ba1f-4131-41ab-9a11-d783a579e864';

export const FIELDTURF_ONBOARDING_TOUR_UUID =
  'affa9fcd-8e73-4934-9302-532a09ab0750';
