import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { useMetadataStore } from '~/stores';
import { usePageTitle } from '~/hooks';
import { Calendar } from '~/components/calendar';
import { useQuery, useQueryClient } from 'react-query';
import {
  CalendarEventParams,
  getCalendarEvents,
} from '~/api/queries/calendar/getCalendarEvents';
import {
  CalendarEvent,
  DailyWeather,
  StatsDates,
} from '@intelligent-play-v2/domain';
import { getCalendarDateRange } from '~/components/calendar/calendar.utils';
import {
  EventDeleteModal,
  EventViewModal,
  MaintenanceEventModal,
} from '~/components/modals';
import { useDeleteEventModal } from '~/hooks/useDeleteEventModalHook';
import { GenericEventModal } from '~/components/calendar/GenericEventModal';
import { SelectOptionType } from '~/components/select';
import { useCalendarStore } from '~/stores/useCalendarStore';
import {
  HistoricalWeatherParams,
  getHistoricalWeather,
} from '~/api/queries/weather/getHistoricalWeather';
import { getLatLong } from '~/utils/getLatLong';
import { format, isAfter } from 'date-fns';
import { WeatherParams, getWeatherSummary } from '~/api';

export const PitchCalendarPage: FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const selectedDate = params.get('date') ?? undefined;

  const { facilities } = useMetadataStore();
  const {
    showGenericEventModal,
    setShowGenericEventModal,
  } = useCalendarStore();
  const queryClient = useQueryClient();

  const { facilityId, pitchId } = useParams<{
    facilityId: string;
    pitchId: string;
  }>();

  const selectedFacility = facilities.find(f => f.id === +facilityId);
  const selectedPitch = selectedFacility?.pitches?.find(p => p.id === +pitchId);

  const [showMaintenanceEventModal, setShowMaintenanceEventModal] = useState(
    false
  );
  const [showViewEventModal, setShowViewEventModal] = useState(false);

  // Reload events when Calendar tab is selected
  useEffect(() => {
    queryClient.invalidateQueries('getEvents');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      showMaintenanceEventModal === false &&
      showGenericEventModal === false
    ) {
      setSelectedEvent(null);
    }
  }, [showMaintenanceEventModal, showGenericEventModal]);

  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );

  const [eventTypeId, setEventTypeId] = useState<number | null>(null);

  const initialDate = selectedDate ? new Date(selectedDate) : new Date();
  const initialDateRange = getCalendarDateRange(initialDate);
  const [date, setDate] = useState<Date>(initialDate);
  const [dateRange, setDateRange] = useState<StatsDates>(initialDateRange);

  useEffect(() => {
    if (selectedDate) {
      const dateObject = new Date(selectedDate);
      setDate(dateObject);
      setDateRange(getCalendarDateRange(dateObject));
    }
  }, [selectedDate]);

  usePageTitle(
    `Calendar - ${selectedPitch?.name} - ${selectedFacility?.name} - Intelligent Play`
  );

  const calendarEventParams: CalendarEventParams = {
    pitchIds: [+pitchId],
    from: dateRange.startDate,
    to: dateRange.endDate,
  };

  const { data: events } = useQuery(
    ['getEvents', calendarEventParams],
    async () => getCalendarEvents(calendarEventParams),
    {
      refetchOnWindowFocus: false,
    }
  );

  const latLong = selectedFacility
    ? getLatLong(selectedFacility)
    : { x: 0, y: 0 };

  const historicalWeatherParams: HistoricalWeatherParams = {
    latLong: `${latLong.x},${latLong.y}`,
    from: format(dateRange.startDate, 'yyyy-MM-dd'),
    to: format(dateRange.endDate, 'yyyy-MM-dd'),
  };

  const { data: historicalWeather } = useQuery(
    ['weather', historicalWeatherParams],
    async () => getHistoricalWeather(historicalWeatherParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: isAfter(new Date(), dateRange.startDate),
    }
  );

  const weatherParams: WeatherParams = {
    latLong: `${latLong.x},${latLong.y}`,
  };

  const { data: weatherSummary } = useQuery(
    ['weather', weatherParams],
    async () => getWeatherSummary(weatherParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  let weather: Record<string, DailyWeather> = historicalWeather ?? {};
  weather = weatherSummary ? { ...weather, ...weatherSummary } : weather;

  const onChangeView = (newDate: Date): void => {
    setDate(newDate);
    setDateRange(getCalendarDateRange(newDate));
  };

  const editMaintenanceEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setShowMaintenanceEventModal(true);
  };

  const editGenericEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setShowGenericEventModal(true);
  };

  const deleteEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setShowDeleteEventModal(true);
  };

  const viewEventHandler = (event: CalendarEvent): void => {
    setSelectedEvent(event);
    setShowViewEventModal(true);
  };

  const scheduleAgainHandler = (): void => {
    setEventTypeId(selectedEvent?.eventTypeId ?? null);
    setSelectedEvent(null);
    setShowMaintenanceEventModal(true);
  };

  const onCompleteDeletion = (): void => {
    setSelectedEvent(null);
    setShowMaintenanceEventModal(false);
    setShowGenericEventModal(false);
  };

  const {
    isDeleting,
    isDeletingRecurrence,
    onDelete,
    onDeleteRecurrence,
    showDeleteEventModal,
    setShowDeleteEventModal,
  } = useDeleteEventModal(onCompleteDeletion);

  useEffect(() => {
    if (
      showMaintenanceEventModal === false &&
      showGenericEventModal === false &&
      showViewEventModal === false
    ) {
      setSelectedEvent(null);
    }
  }, [showMaintenanceEventModal, showGenericEventModal, showViewEventModal]);

  if (!selectedFacility || !selectedPitch) {
    return null;
  }

  const pitchOption: SelectOptionType = {
    label: selectedPitch.name!,
    value: selectedPitch.id.toString(),
  };
  const facilityOption: SelectOptionType = {
    label: selectedFacility.name!,
    value: selectedFacility.id.toString(),
  };

  return (
    <>
      <Calendar
        events={events || []}
        weather={weather}
        onChangeView={onChangeView}
        defaultDate={date}
        dateRange={dateRange}
        editMaintenanceEvent={editMaintenanceEventHandler}
        deleteEvent={deleteEventHandler}
        editGenericEvent={editGenericEventHandler}
        viewEvent={viewEventHandler}
      />
      <MaintenanceEventModal
        pitchId={+pitchId}
        showModal={showMaintenanceEventModal}
        setShowModal={setShowMaintenanceEventModal}
        calendarEvent={selectedEvent}
        openDeleteModal={() => setShowDeleteEventModal(true)}
        eventTypeId={eventTypeId ?? undefined}
      />
      <EventDeleteModal
        isDeleting={isDeleting}
        isDeletingRecurrence={isDeletingRecurrence}
        showRecurrenceDelete={
          !!selectedEvent && !!selectedEvent.calendarRecurrenceEventId
        }
        showModal={showDeleteEventModal}
        closeDeleteModal={() => setShowDeleteEventModal(false)}
        onDelete={() => onDelete(selectedEvent!.id)}
        onDeleteRecurrence={() =>
          selectedEvent?.calendarRecurrenceEventId &&
          onDeleteRecurrence(selectedEvent.calendarRecurrenceEventId)
        }
      />
      <GenericEventModal
        showModal={showGenericEventModal}
        setShowModal={setShowGenericEventModal}
        defaultFacility={facilityOption}
        defaultPitch={pitchOption}
        calendarEvent={selectedEvent}
        openDeleteModal={() => setShowDeleteEventModal(true)}
      />
      <EventViewModal
        showModal={showViewEventModal}
        setShowModal={setShowViewEventModal}
        openNewTaskModal={() => scheduleAgainHandler()}
        calendarEvent={selectedEvent}
      />
    </>
  );
};
