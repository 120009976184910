import React, { FC } from 'react';
import { UserRow } from '~/types';
import { PermissionInfo } from './PermissionInfo';
import { UserType } from '@intelligent-play-v2/domain';

interface PermissionsInfoProps {
  userRow: UserRow;
}

export const PermissionsInfo: FC<PermissionsInfoProps> = ({ userRow }) => {
  const pitchIds = userRow.pitchAccess;
  const facilityIds = userRow.facilityAccess;

  const facilitiesCountText =
    userRow.userTypeId === UserType.SystemAdmin ? 'All' : facilityIds.length;
  const pitchesCountText =
    userRow.userTypeId === UserType.SystemAdmin ? 'All' : pitchIds.length;

  return (
    <div className="flex flex-wrap text-sm font-semibold gap-y-1 gap-x-6">
      <PermissionInfo text={`${facilitiesCountText} Facilities`} />
      <PermissionInfo text={`${pitchesCountText} Fields`} />
    </div>
  );
};
