import React, { FC } from 'react';

import { DailyRanges, TimeRange } from '@intelligent-play-v2/domain';
import { TextInput } from '../forms';

const dayOrder: (keyof DailyRanges)[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

interface PitchOutOfHoursSettingsProps {
  pitchDailyRanges: DailyRanges;
  handleUpdateDailyRange: (
    day: keyof DailyRanges,
    timeSetting: keyof TimeRange,
    value: string
  ) => void;
}

export const PitchOutOfHoursSettings: FC<PitchOutOfHoursSettingsProps> = ({
  pitchDailyRanges,
  handleUpdateDailyRange,
}) => {
  return (
    <div className="px-2 pb-2 space-y-3">
      <div className="inline-flex justify-between w-full">
        <h6 className="font-semibold">Out of hours times</h6>
      </div>
      <div className="flex flex-col w-full lg:w-14/16 xl:w-11/16">
        {Object.entries(pitchDailyRanges)
          .sort(
            ([day1], [day2]) =>
              dayOrder.indexOf(day1 as keyof DailyRanges) -
              dayOrder.indexOf(day2 as keyof DailyRanges)
          )
          .map(([day, { start, end }], i) => {
            return (
              <div className="inline-flex items-center space-x-2" key={i}>
                <span className="self-end w-5/16 text-base font-semibold text-right capitalize transform -translate-y-2 sm:w-4/16 md:w-3/16 lg:w-2/16">
                  {day}
                </span>
                <TextInput
                  size="small"
                  label={i === 0 ? 'Start of day' : undefined}
                  type="time"
                  value={start}
                  onChange={value => {
                    if (value < end) {
                      handleUpdateDailyRange(
                        day as keyof DailyRanges,
                        'start',
                        value
                      );
                    }
                  }}
                  isLightMode
                />
                <TextInput
                  size="small"
                  label={i === 0 ? 'End of day' : undefined}
                  type="time"
                  value={end}
                  onChange={value => {
                    if (value > start) {
                      handleUpdateDailyRange(
                        day as keyof DailyRanges,
                        'end',
                        value
                      );
                    }
                  }}
                  isLightMode
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
