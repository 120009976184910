import { useCallback, useMemo, useState } from 'react';
import { SelectOptionType } from '~/components/select';

interface TimeRangeHook {
  startTimes: SelectOptionType[];
  startTime: SelectOptionType;
  handleStartTimeChange: (option: SelectOptionType | null) => void;
  endTimes: SelectOptionType[];
  endTime: SelectOptionType;
  handleEndTimeChange: (option: SelectOptionType | null) => void;
  setStartTime: React.Dispatch<React.SetStateAction<SelectOptionType>>;
  setEndTime: React.Dispatch<React.SetStateAction<SelectOptionType>>;
}

export const useTimeRange = (
  bucketSizeMinutes = 60,
  initialStartTime?: string,
  initialEndTime?: string
): TimeRangeHook => {
  const startTimes: SelectOptionType[] = useMemo(
    () =>
      [...Array(24).keys()].reduce((acc, hour) => {
        const leadingZeroHours = hour < 10 ? '0' : '';
        const bucketsInHour = [...Array(60 / bucketSizeMinutes).keys()].map(
          bucket => {
            const minutes = bucket * 5;
            const leadingZeroMinutes = minutes < 10 ? '0' : '';

            const time = `${leadingZeroHours}${hour}:${leadingZeroMinutes}${minutes}`;
            return {
              label: time,
              value: time,
            };
          }
        );

        acc.push(...bucketsInHour);
        return acc;
      }, [] as SelectOptionType[]),
    [bucketSizeMinutes]
  );

  const initialStartValue: SelectOptionType = useMemo(
    () =>
      initialStartTime
        ? { value: initialStartTime, label: initialStartTime }
        : startTimes[0],
    [initialStartTime, startTimes]
  );

  const [startTime, setStartTime] = useState(initialStartValue);

  const indexOfStartTime = useMemo(
    () =>
      startTimes
        .map(e => {
          return e.label;
        })
        .indexOf(startTime.label),
    [startTime.label, startTimes]
  );

  const endTimes: SelectOptionType[] = useMemo(
    () => [
      ...startTimes.slice(-(startTimes.length - 1 - indexOfStartTime)),
      startTimes[0],
    ],
    [indexOfStartTime, startTimes]
  );

  const initialEndValue: SelectOptionType = useMemo(
    () =>
      initialEndTime
        ? { value: initialEndTime, label: initialEndTime }
        : endTimes[0],
    [endTimes, initialEndTime]
  );

  const [endTime, setEndTime] = useState(initialEndValue);

  const handleStartTimeChange = useCallback(
    (option: SelectOptionType | null): void => {
      const newValue = option ? option : startTimes[0];
      setStartTime(newValue);
      setEndTime(prev => {
        if (newValue.label >= prev.label) {
          return endTimes[endTimes.length - 1];
        }
        return prev;
      });
    },
    [endTimes, startTimes]
  );

  const handleEndTimeChange = useCallback(
    (option: SelectOptionType | null): void => {
      const newValue = option ? option : endTimes[endTimes.length - 1];
      setEndTime(newValue);
    },
    [endTimes]
  );

  return {
    startTimes,
    startTime,
    handleStartTimeChange,
    endTimes,
    endTime,
    handleEndTimeChange,
    setStartTime,
    setEndTime,
  };
};
