import React, { FC } from 'react';
import { isBrowserLanguageAmerican } from '~/utils';

import ipLogo from 'assets/images/logos/logo__login.png';
import ipLogo2x from 'assets/images/logos/logo__login@2x.png';
import fieldTurfLogo from 'assets/images/logos/logo__field-turf-login.png';
import fieldTurfLogo2x from 'assets/images/logos/logo__field-turf-login@2x.png';

import footballFieldImg from 'assets/images/pages/login/img__izuddin-helmi-adnan-K5ChxJaheKI-unsplash.png';
import footballFieldImg2x from 'assets/images/pages/login/img__izuddin-helmi-adnan-K5ChxJaheKI-unsplash@2x.png';

export const AuthLayout: FC = ({ children }) => {
  let logo = ipLogo;
  let logo2x = ipLogo2x;
  if (isBrowserLanguageAmerican()) {
    logo = fieldTurfLogo;
    logo2x = fieldTurfLogo2x;
  }

  return (
    <main className="flex min-h-screen">
      <div className="w-full px-5 py-10 text-white bg-primary-900 lg:px-4 xl:py-24 lg:w-7/16">
        <div className="flex flex-col justify-between h-full max-w-120 m-auto">
          <div className="">
            <img
              className="w-40 lg:w-48"
              src={logo2x}
              srcSet={`${logo} 1x, ${logo2x} 2x`}
            />
            <form className="mt-12.5 xl:mt-24 flex flex-col space-y-7">
              {children}
            </form>
          </div>
          <a className="mt-8" href="https://www.intelligent-play.com/">
            <span className="w-full p-4 font-tw-cent font-semibold tracking-wide border-2 border-green-dark rounded-sm hover:bg-opacity-50 hover:bg-green bottom-10">
              Visit website
            </span>
          </a>
        </div>
      </div>
      <img
        className="hidden object-cover lg:block lg:w-9/16"
        src={footballFieldImg2x}
        srcSet={`${footballFieldImg} 1x, ${footballFieldImg2x} 2x`}
      />
    </main>
  );
};
