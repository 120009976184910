import {
  Attachment,
  CalendarEvent,
  CalendarEventType,
  UserType,
} from '@intelligent-play-v2/domain';
import React, { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { format, isSameDay } from 'date-fns';

import { AttachmentList } from '~/components/attachment';
import { Button, ButtonType } from '~/components/button';
import { Modal } from '~/components/modals';
import { useMutation, useQueryClient } from 'react-query';
import {
  PatchCalendarEventParams,
  patchCalendarEvent,
  postMarkEventAsComplete,
} from '~/api';

import { userHasCalendarWriteAccess } from '@intelligent-play-v2/lib';
import { useAuthStore } from '~/stores';
import { isAllDayEvent } from '~/utils';

interface EventViewModalProps {
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  calendarEvent: CalendarEvent | null;
  openNewTaskModal: () => void;
}

export const EventViewModal: FC<EventViewModalProps> = ({
  showModal,
  setShowModal,
  calendarEvent,
  openNewTaskModal,
}) => {
  const { user } = useAuthStore();
  const queryClient = useQueryClient();

  const [attachments, setAttachments] = useState<Attachment[]>(
    calendarEvent?.attachments || []
  );

  const scheduleAgainHandler = (): void => {
    setShowModal(false);
    openNewTaskModal();
  };

  useEffect(() => {
    if (calendarEvent) {
      setAttachments(calendarEvent.attachments);
    } else {
      setAttachments([]);
    }
  }, [calendarEvent]);

  const {
    mutate: mutateUpdateEvent,
    isLoading: isLoadingUpdateEvent,
  } = useMutation(patchCalendarEvent, {
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries('getPitchFieldTestingEvents');
      queryClient.invalidateQueries('getEvents');
      toast.success('Succesfully saved attachments');
    },
    onError: () => {
      toast.error('Error saving attachments');
    },
  });

  const {
    mutate: mutateMarkAsComplete,
    isLoading: isLoadingMarkAsComplete,
  } = useMutation(postMarkEventAsComplete, {
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries('getPitchFieldTestingEvents');
      queryClient.invalidateQueries('getEvents');
      toast.success('Succesfully marked event complete');
    },
    onError: () => {
      toast.error('Error marking as complete');
    },
  });

  if (!calendarEvent) {
    return null;
  }

  const shouldSaveAttachments =
    attachments &&
    attachments.length &&
    attachments !== calendarEvent.attachments;

  const updateEventHandler = (): void => {
    if (shouldSaveAttachments) {
      const updatedEvent: PatchCalendarEventParams = {
        id: calendarEvent.id,
        attachments,
      };
      mutateUpdateEvent(updatedEvent);
    } else {
      setShowModal(false);
    }
  };

  const isMultiDayEvent = !isSameDay(
    new Date(calendarEvent.startDate),
    new Date(calendarEvent.endDate)
  );

  const eventTitle = isMultiDayEvent
    ? calendarEvent.title
    : `${calendarEvent.title} - ${format(
        new Date(calendarEvent.startDate),
        'EEE d MMM yyyy'
      )}`;

  // attachments section should not be shown for generic events
  // otherwise should be visible if either the event has existing attachments, or the user has permission to add new ones
  const shouldShowAttachmentsSection =
    calendarEvent.eventTypeId !== CalendarEventType.Generic &&
    ((user && userHasCalendarWriteAccess(user)) ||
      (attachments && attachments.length > 0));

  const isAssignedUser = calendarEvent.userIds.some(
    userId => userId === user?.id
  );

  const isAdminUser =
    user?.userTypeId === UserType.FacilityAdmin ||
    user?.userTypeId === UserType.SystemAdmin;

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title={eventTitle}
      subtitle={getEventTimeString(calendarEvent, isMultiDayEvent)}
    >
      <div className="w-full sm:w-140">
        {calendarEvent.notes && calendarEvent.notes.length && (
          <div className="w-full pb-3">
            <div className="text-sm font-semibold">Notes</div>
            <span className="w-full whitespace-pre-line">
              {calendarEvent.notes}
            </span>
          </div>
        )}
        {shouldShowAttachmentsSection && (
          <AttachmentList
            eventId={calendarEvent.id}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        )}
        {!calendarEvent.completedAt && (isAssignedUser || isAdminUser) && (
          <Button
            className="mt-5"
            type={ButtonType.Secondary}
            text="Mark event complete"
            onClick={() => mutateMarkAsComplete(calendarEvent.id)}
            isLoading={isLoadingMarkAsComplete}
          />
        )}
        <div className="flex mt-5 space-x-5">
          <div className="w-1/2">
            <Button
              onClick={() => scheduleAgainHandler()}
              text="Schedule again"
              type={ButtonType.Outline}
              className="border border-primary-250"
            />
          </div>
          <div className="w-1/2">
            <Button
              onClick={updateEventHandler}
              text={shouldSaveAttachments ? 'Save attachments' : 'Close'}
              isLoading={isLoadingUpdateEvent}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const getEventTimeString = (
  calendarEvent: CalendarEvent,
  isMultiDayEvent: boolean
): string => {
  const startDate = new Date(calendarEvent.startDate);
  const endDate = new Date(calendarEvent.endDate);

  if (isAllDayEvent(startDate, endDate)) {
    return 'All day';
  }

  const dateFormat = isMultiDayEvent ? "EEE d MMM yyyy 'at' HH:mm" : 'HH:mm';
  return `${format(new Date(calendarEvent.startDate), dateFormat)} - ${format(
    new Date(calendarEvent.endDate),
    dateFormat
  )}`;
};
