import React, { FC, useState } from 'react';
import downloadAttachmentIcon from 'assets/images/pages/field/modals/icon__attachment-download.png';
import downloadAttachmentIcon2x from 'assets/images/pages/field/modals/icon__attachment-download@2x.png';
import deleteAttachmentIcon from 'assets/images/pages/field/modals/icon__attachment-delete.png';
import deleteAttachmentIcon2x from 'assets/images/pages/field/modals/icon__attachment-delete@2x.png';
import { Attachment } from '@intelligent-play-v2/domain';
import { API_URL } from '~/config';
import { EventDeleteModal } from '../modals';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { deleteCalendarEventAttachment } from '~/api/queries/calendar/deleteCalendarEventAttachment';
import { userHasCalendarWriteAccess } from '@intelligent-play-v2/lib';
import { useAuthStore } from '~/stores';

interface AttachmentCellProps {
  eventId?: number;
  attachment: Attachment;
  deleteLocalAttachment: (id: number) => void;
}

export const AttachmentCell: FC<AttachmentCellProps> = ({
  eventId,
  attachment,
  deleteLocalAttachment,
}) => {
  const { user } = useAuthStore();

  const { id, fileName, file } = attachment;
  const url = file
    ? URL.createObjectURL(file)
    : `${API_URL}/calendar/event/${eventId}/attachment?fileName=${fileName}`;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const queryClient = useQueryClient();

  const {
    mutate: mutateDeleteAttachment,
    isLoading: isDeletingAttachment,
  } = useMutation(deleteCalendarEventAttachment, {
    onSuccess: () => {
      setShowDeleteModal(false);
      deleteLocalAttachment(id);
      queryClient.invalidateQueries('getEvents');
      toast.success('Succesfully deleted attachment');
    },
    onError: () => {
      toast.error('Error deleting attachment');
    },
  });

  const handleDeleteAttachment = (): void => {
    if (!file) {
      mutateDeleteAttachment(id);
    } else {
      deleteLocalAttachment(id);
      setShowDeleteModal(false);
      toast.success('Succesfully deleted attachment');
    }
  };

  return (
    <div className="w-full border rounded shadow-250 border-primary-250 mt-2.5 p-4 flex justify-between space-x-1">
      <div className="text-base font-semibold">{fileName}</div>
      <div className="flex items-center flex-shrink-0 space-x-4">
        <a href={url} download={fileName}>
          <picture>
            <img
              src={downloadAttachmentIcon}
              srcSet={`${downloadAttachmentIcon} 1x, ${downloadAttachmentIcon2x} 2x`}
              className="hover:opacity-60"
            />
          </picture>
        </a>
        {user && userHasCalendarWriteAccess(user) && (
          <button onClick={() => setShowDeleteModal(true)}>
            <picture>
              <img
                src={deleteAttachmentIcon}
                srcSet={`${deleteAttachmentIcon} 1x, ${deleteAttachmentIcon2x} 2x`}
                className="hover:opacity-60"
              />
            </picture>
          </button>
        )}
      </div>
      <EventDeleteModal
        isDeleting={isDeletingAttachment}
        showModal={showDeleteModal}
        closeDeleteModal={() => setShowDeleteModal(false)}
        onDelete={handleDeleteAttachment}
        showRecurrenceDelete={false}
        isDeletingRecurrence={false}
      />
    </div>
  );
};
