import { useState } from 'react';
import { useQuery } from 'react-query';
import {
  PitchMaintenanceStatusParams,
  getPitchMaintenanceStatus,
} from '~/api/queries/';
import {
  addMonths,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { useMetadataStore } from '~/stores';
import {
  CalendarEvent,
  CalendarEventType,
  PitchMaintenanceStatus,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import {
  CalendarEventParams,
  getCalendarEvents,
} from '~/api/queries/calendar/getCalendarEvents';

interface PitchMaintenanceStatusEvents {
  setCurrentMonth: (date: Date) => void;
  isLoading: boolean;
  pitchMaintenanceStatuses: PitchMaintenanceStatus[];
  manualMaintenanceEvents: CalendarEvent[];
}

export const usePitchMaintenanceStatus = (
  facilityId: string,
  pitchId: string
): PitchMaintenanceStatusEvents => {
  const [currentMonth, setCurrentMonth] = useState(endOfMonth(new Date()));
  const { facilities } = useMetadataStore();

  const currentFacility = facilities.find(f => f.id === +facilityId);

  const timezone = currentFacility?.tzDatabaseTimezone ?? 'ETC/UTC';

  const startOfCurrentMonth = format(startOfMonth(currentMonth), 'yyyy-MM-dd');
  const endOfCurrentMonth = format(endOfMonth(currentMonth), 'yyyy-MM-dd');
  const startOfPrevMonth = format(
    startOfMonth(subMonths(currentMonth, 1)),
    'yyyy-MM-dd'
  );
  const endOfprevMonth = format(
    endOfMonth(subMonths(currentMonth, 1)),
    'yyyy-MM-dd'
  );
  const startOfNextMonth = format(
    startOfMonth(addMonths(currentMonth, 1)),
    'yyyy-MM-dd'
  );
  const endOfNextMonth = format(
    endOfMonth(addMonths(currentMonth, 1)),
    'yyyy-MM-dd'
  );

  const currentMonthParams: PitchMaintenanceStatusParams = {
    pitchId,
    timePeriod: TimePeriod.Custom,
    timezone,
    from: startOfCurrentMonth,
    to: endOfCurrentMonth,
  };

  const { data: currentMonthMaintenanceData, isLoading } = useQuery(
    ['maintenanceCalendar', currentMonthParams],
    async () => getPitchMaintenanceStatus(currentMonthParams)
  );

  const prevMonthParams: PitchMaintenanceStatusParams = {
    pitchId,
    timePeriod: TimePeriod.Custom,
    timezone,
    from: startOfPrevMonth,
    to: endOfprevMonth,
  };

  const { data: prevMonthMaintenanceData } = useQuery(
    ['maintenanceCalendar', prevMonthParams],
    async () => getPitchMaintenanceStatus(prevMonthParams)
  );

  const nextMonthParams: PitchMaintenanceStatusParams = {
    pitchId,
    timePeriod: TimePeriod.Custom,
    timezone,
    from: startOfNextMonth,
    to: endOfNextMonth,
  };

  const { data: nextMonthMaintenanceData } = useQuery(
    ['maintenanceCalendar', nextMonthParams],
    async () => getPitchMaintenanceStatus(nextMonthParams)
  );

  const pitchMaintenanceStatuses = [
    ...(currentMonthMaintenanceData ?? []),
    ...(prevMonthMaintenanceData ?? []),
    ...(nextMonthMaintenanceData ?? []),
  ];

  const calendarEventParams: CalendarEventParams = {
    pitchIds: [+pitchId],
    eventTypeIds: [CalendarEventType.MaintenanceTask],
    from: new Date(startOfCurrentMonth),
    to: new Date(endOfCurrentMonth),
  };

  const { data: events } = useQuery(
    ['getEvents', calendarEventParams],
    async () => getCalendarEvents(calendarEventParams),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    setCurrentMonth,
    isLoading,
    pitchMaintenanceStatuses,
    manualMaintenanceEvents: events || [],
  };
};
