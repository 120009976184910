import React, { FC } from 'react';
import { useParams } from 'react-router';
import { RoutesPath } from '~/types';
import { useWeatherInWeeks } from '~/hooks';
import { WeatherPreviewCard } from '.';
import { NavLink } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import { Loading } from '../loading';
import rightArrow from 'assets/images/pages/field/snapshots/icon__arrow-next.svg';

export const WeatherPreview: FC = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const { weather: weatherForecast, isLoading } = useWeatherInWeeks(
    +facilityId
  );

  const afterTomorrowDateString = format(addDays(new Date(), 2), 'EEEE');

  if (isLoading) {
    return (
      <div className="border-primary-200 rounded-b-none shadow-250 h-4/5 w-60 rounded-xs">
        <Loading />
      </div>
    );
  } else if (!weatherForecast.length) {
    return (
      <div className="grid text-base border-primary-200 rounded-b-none shadow-250 place-items-center h-4/5 w-60 rounded-xs">
        No weather data available
      </div>
    );
  }
  return (
    <NavLink to={RoutesPath.FacilityWeather.replace(':facilityId', facilityId)}>
      <div className="shadow-250 w-60">
        <div className="flex border border-primary-200 rounded-b-none rounded-xs">
          <WeatherPreviewCard
            weather={weatherForecast[1][0]}
            dateString={'Today'}
          />
          <WeatherPreviewCard
            weather={weatherForecast[1][1]}
            dateString={'Tomorrow'}
          />
          <WeatherPreviewCard
            weather={weatherForecast[1][2]}
            dateString={afterTomorrowDateString}
            isLastElement
          />
        </div>

        <div className="w-full h-6 overflow-hidden text-xs font-semibold leading-6 text-center text-white align-middle bg-green rounded-bl rounded-br shadow-green-dark">
          <div className="flex justify-center">
            <div>14-day weather forecast</div>
            <div className="flex flex-col justify-center ml-1.5">
              <img className="mt-0.75" src={rightArrow} />
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};
