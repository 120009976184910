import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import { differenceInCalendarDays, formatDistanceStrict } from 'date-fns';
import { useParams } from 'react-router';
import { MaintenanceStatus, TrackingType } from '@intelligent-play-v2/domain';

import { useMetadataStore } from '~/stores';

import urgent from 'assets/images/pages/facility/tasks/icon__task-urgent.png';
import urgent2x from 'assets/images/pages/facility/tasks/icon__task-urgent@2x.png';
import task from 'assets/images/pages/facility/tasks/icon__task.png';
import task2x from 'assets/images/pages/facility/tasks/icon__task@2x.png';
import { FacilityTask, RoutesPath } from '~/types';
import { Link } from 'react-router-dom';
// import close from 'assets/images/pages/facility/tasks/icon__task-close.png';
// import close2x from 'assets/images/pages/facility/tasks/icon__task-close@2x.png';

interface FacilityTaskItemProps {
  facilityTask: FacilityTask;
  hideView?: boolean;
}

export const FacilityTaskItem: FC<FacilityTaskItemProps> = ({
  facilityTask,
  hideView,
}) => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const { facilities } = useMetadataStore();
  const { pitchId, status, dueDate } = facilityTask;

  const pitchFacility = facilities.find(
    facility => facility.id === +facilityId
  );

  const pitch = pitchFacility?.pitches?.find(p => p.id === pitchId);

  if (!pitch) {
    return null;
  }

  let icon = task;
  let icon2x = task2x;
  let statusTitle = 'Task';
  let statusText = 'due';
  const days = Math.abs(
    dueDate ? differenceInCalendarDays(new Date(), new Date(dueDate)) : 0
  );
  const dayLabel = days === 1 ? 'day' : 'days';
  let intervalLabel = `${days} ${dayLabel}`;
  let dateDifference = days === 0 ? 'today' : `in ${intervalLabel}`;

  if (status === MaintenanceStatus.Overdue) {
    icon = urgent;
    icon2x = urgent2x;
    statusTitle = 'Urgent';
    statusText = 'overdue';
    if (days === 0) {
      intervalLabel = formatDistanceStrict(new Date(), new Date(dueDate), {
        roundingMethod: 'floor',
      });
    }
    dateDifference = `by ${intervalLabel}`;
  }

  const statusTitleClass = classnames(
    { 'text-red': status === MaintenanceStatus.Overdue },
    { 'text-primary-400': status !== MaintenanceStatus.Overdue },
    'ml-1.5',
    'flex',
    'text-sm',
    'font-semibold'
  );

  const statusClass = classnames(
    { 'text-red': status === MaintenanceStatus.Overdue },
    { 'text-yellow': status === MaintenanceStatus.Due }
  );

  const link =
    facilityTask.trackingType === TrackingType.Manual
      ? RoutesPath.PitchCalendar.replace(':facilityId', facilityId).replace(
          ':pitchId',
          pitchId.toString()
        )
      : RoutesPath.PitchMaintenance.replace(':facilityId', facilityId).replace(
          ':pitchId',
          pitchId.toString()
        );

  return (
    <div className="p-3.5 pb-4 border-b border-primary-100">
      <div className="flex items-center">
        <picture>
          <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
        </picture>
        <div className="flex justify-between w-full">
          <div className={statusTitleClass}>{statusTitle}</div>
          {/* <picture>
            <img src={close} srcSet={`${close} 1x, ${close2x} 2x`} />
          </picture> */}
        </div>
      </div>
      <div className="mt-1.5 ml-6 text-base font-semibold text-primary-900">
        <div className="mb-1.5">
          {facilityTask.title} on {pitch.name} is
          <span className={statusClass}> {statusText}</span>
          <span> {dateDifference}</span>
        </div>
        {!hideView && (
          <Link
            to={link}
            className="text-sm font-semibold text-green cursor-pointer"
          >
            View
          </Link>
        )}
      </div>
    </div>
  );
};
