import { queryBuilder } from '../queryBuilder';

export interface PutFacilityPitchStaffParams {
  id: number;
  pitchUserAccess: Record<string, number[]>;
}

export const putFacilityPitchStaff = async (
  params: PutFacilityPitchStaffParams
): Promise<Record<string, number[]>> => {
  const { data } = await queryBuilder(
    'PUT',
    `facilities/${params.id}/staff`,
    undefined,
    {
      ...params.pitchUserAccess,
    }
  );
  return data;
};
