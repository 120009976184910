import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import {
  CalendarPage,
  ContactPage,
  DashboardPage,
  FacilitiesPage,
  FacilityPage,
  ReportPage,
  ReportResultsPage,
  ResourcesPage,
} from '~/pages';
import { UserProfilePage } from '~/pages/UserProfile.page';
import { SettingsPage } from '~/pages/Settings.page';

import { RoutesPath } from '~/types';
import { AdminRouter } from './AdminRouter';
import { PrivateRoute } from './PrivateRoute';

export const PrivateRouter: FC = () => {
  return (
    <Switch>
      <PrivateRoute
        path={RoutesPath.Facilities}
        component={FacilitiesPage}
        strict
        exact
      />
      <PrivateRoute
        path={RoutesPath.FacilityOverview}
        component={FacilityPage}
      />
      <PrivateRoute path={RoutesPath.Calendar} component={CalendarPage} />
      <PrivateRoute path={RoutesPath.Report} component={ReportPage} exact />
      <PrivateRoute
        path={RoutesPath.ReportResults}
        component={ReportResultsPage}
      />
      <PrivateRoute path={RoutesPath.Resources} component={ResourcesPage} />
      <PrivateRoute path={RoutesPath.Contact} component={ContactPage} />
      <PrivateRoute path={RoutesPath.Settings} component={SettingsPage} />
      <PrivateRoute path={RoutesPath.UserProfile} component={UserProfilePage} />
      <AdminRouter />
      <PrivateRoute
        path={RoutesPath.Dashboard}
        component={DashboardPage}
        strict
      />
    </Switch>
  );
};
