/* eslint-disable react/display-name */
import {
  Facility,
  ReportArea,
  StatsDates,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import { format } from 'date-fns';
import React, { FC, ReactNode, useContext } from 'react';
import { getDateRangeFromPeriod } from '@intelligent-play-v2/lib';
import { ToggleableReportGraph } from '~/components/graphs/ToggleableReportGraph';
import { ReportGraphType } from '~/types';
import { useReportQueryParams } from '~/hooks/useReportQueryParams.hook';
import { StatisticTables } from '../statisticTables/StatisticTables';
import { ReportLoadingContext } from '~/contexts';
import {
  useEuhDataStore,
  useMaintenanceHoursDataStore,
  useMaintenanceSessionsDataStore,
  usePlayerCountDataStore,
  usePlayerHoursDataStore,
  useReportStore,
  useUsageHoursDataStore,
} from '~/stores';

interface PrintableFacilityReportProps {
  facility: Facility;
  timePeriod: TimePeriod;
  pitchesStats?: ReactNode;
  customDates: StatsDates | null;
}

export const PrintableFacilityReport: FC<PrintableFacilityReportProps> = ({
  facility,
  timePeriod,
  pitchesStats,
  customDates,
}) => {
  const { isLoading } = useContext(ReportLoadingContext);

  const { selectedReportAreaIds } = useReportStore();

  const { isContinuous } = useReportQueryParams();

  const {
    facilityDataSeries: playerHoursDataSeries,
    dailySeriesCollections: playerHoursDailySeriesCollections,
  } = usePlayerHoursDataStore();

  const {
    facilityDataSeries: euhDataSeries,
    dailySeriesCollections: euhDailySeriesCollections,
  } = useEuhDataStore();

  const {
    facilityDataSeries: playerCountDataSeries,
    dailySeriesCollections: playerCountDailySeriesCollections,
  } = usePlayerCountDataStore();

  const {
    facilityDataSeries: usageHoursDataSeries,
    dailySeriesCollections: usageHoursDailySeriesCollections,
  } = useUsageHoursDataStore();

  const {
    facilityDataSeries: maintenanceHoursDataSeries,
    dailySeriesCollections: maintenanceHoursDailySeriesCollections,
  } = useMaintenanceHoursDataStore();

  const {
    facilityDataSeries: maintenanceSessionsDataSeries,
    dailySeriesCollections: maintenanceSessionsDailySeriesCollections,
  } = useMaintenanceSessionsDataStore();

  const timezone = facility.tzDatabaseTimezone ?? 'UTC';

  const { startDate, endDate } =
    timePeriod === TimePeriod.Custom && customDates
      ? { ...customDates }
      : getDateRangeFromPeriod(timePeriod, timezone);

  if (!startDate || !endDate || !facility.pitches) {
    return null;
  }

  const includePlayerCount =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.PlayerCount);
  const includePlayerHours =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.PlayerHours);
  const includeEuh =
    selectedReportAreaIds && selectedReportAreaIds.includes(ReportArea.EUH);
  const includeUsageHours =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.UsageHours);
  const includeMaintenanceHours =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.MachineMaintenanceHours);
  const includeMaintenanceSessions =
    selectedReportAreaIds &&
    selectedReportAreaIds.includes(ReportArea.MachineMaintenanceSessions);

  return (
    <div className="w-full">
      {/* header */}
      <div className="flex justify-between text-primary-900 border-b border-primary-900 border-opacity-10 h-14">
        <div className="flex mt-3 space-x-5">
          <div className="font-tw-cent text-3xl font-bold">{facility.name}</div>
          <div>
            {format(new Date(startDate), 'EEE d MMM yyyy')}
            {' - '}
            {format(new Date(endDate), 'EEE d MMM yyyy')}
          </div>
        </div>
      </div>
      <div className="mt-4 text-xl font-semibold">
        Stats overview for {facility.pitches?.length} Field
        {facility.pitches!.length > 1 && 's'}
      </div>
      <div className="mt-5">
        <div className={'w-full'}>
          <StatisticTables facility={facility} isPrintable={true} />
        </div>
      </div>

      {/* graphs */}
      {includePlayerHours &&
        (isContinuous
          ? playerHoursDataSeries && (
              <ToggleableReportGraph
                graphType={ReportGraphType.PlayerHours}
                toggleableDataSeries={playerHoursDataSeries[facility.id] ?? []}
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )
          : (
              playerHoursDailySeriesCollections[facility.id] ?? []
            ).map(collection => (
              <ToggleableReportGraph
                graphType={ReportGraphType.PlayerHours}
                key={collection.key}
                toggleableDataSeries={collection.data}
                pitchName={collection.name}
                isDailyGraph
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )))}
      {includeEuh &&
        (isContinuous
          ? euhDataSeries && (
              <ToggleableReportGraph
                graphType={ReportGraphType.EUH}
                toggleableDataSeries={euhDataSeries[facility.id] ?? []}
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )
          : (euhDailySeriesCollections[facility.id] ?? []).map(collection => (
              <ToggleableReportGraph
                graphType={ReportGraphType.EUH}
                key={collection.key}
                toggleableDataSeries={collection.data}
                pitchName={collection.name}
                isDailyGraph
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )))}
      {includePlayerCount &&
        (isContinuous
          ? playerCountDataSeries && (
              <ToggleableReportGraph
                graphType={ReportGraphType.PlayerCount}
                toggleableDataSeries={playerCountDataSeries[facility.id] ?? []}
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )
          : (
              playerCountDailySeriesCollections[facility.id] ?? []
            ).map(collection => (
              <ToggleableReportGraph
                graphType={ReportGraphType.PlayerCount}
                key={collection.key}
                toggleableDataSeries={collection.data}
                pitchName={collection.name}
                isDailyGraph
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )))}
      {includeUsageHours &&
        (isContinuous
          ? usageHoursDataSeries && (
              <ToggleableReportGraph
                graphType={ReportGraphType.UsageHours}
                toggleableDataSeries={usageHoursDataSeries[facility.id] ?? []}
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )
          : (
              usageHoursDailySeriesCollections[facility.id] ?? []
            ).map(collection => (
              <ToggleableReportGraph
                graphType={ReportGraphType.UsageHours}
                key={collection.key}
                toggleableDataSeries={collection.data}
                pitchName={collection.name}
                isDailyGraph
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )))}
      {includeMaintenanceHours &&
        (isContinuous
          ? maintenanceHoursDataSeries && (
              <ToggleableReportGraph
                graphType={ReportGraphType.MachineMaintenanceHours}
                toggleableDataSeries={
                  maintenanceHoursDataSeries[facility.id] ?? []
                }
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )
          : (
              maintenanceHoursDailySeriesCollections[facility.id] ?? []
            ).map(collection => (
              <ToggleableReportGraph
                graphType={ReportGraphType.MachineMaintenanceHours}
                key={collection.key}
                toggleableDataSeries={collection.data}
                pitchName={collection.name}
                isDailyGraph
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )))}
      {includeMaintenanceSessions &&
        (isContinuous
          ? maintenanceSessionsDataSeries && (
              <ToggleableReportGraph
                graphType={ReportGraphType.MachineMaintenanceSessions}
                toggleableDataSeries={
                  maintenanceSessionsDataSeries[facility.id] ?? []
                }
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )
          : (
              maintenanceSessionsDailySeriesCollections[facility.id] ?? []
            ).map(collection => (
              <ToggleableReportGraph
                graphType={ReportGraphType.MachineMaintenanceSessions}
                key={collection.key}
                toggleableDataSeries={collection.data}
                pitchName={collection.name}
                isDailyGraph
                isPrintableVersion
                facilityId={facility.id}
                isLoading={isLoading}
              />
            )))}

      {/* Heatmap */}
      {pitchesStats}
    </div>
  );
};
