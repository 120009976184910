import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { HeatmapParams, getPeakTimeSnapshots, getUsageHeatmap } from '~/api';
import { ToggleButton } from '~/components/button';
import { DateRangeModal } from '~/components/modals';
import { useMetadataStore } from '~/stores';
import { TimePeriod } from '@intelligent-play-v2/domain';
import { usePageTitle, useTimePeriodSelect } from '~/hooks';
import { HeatmapSectors } from '~/components/heatmap/heatmapSectors';
import { SectionHeader } from '~/components/layout';
import { SnapshotThumbnail } from '~/components/snapshot/SnapshotThumbnail';
import { Loading } from '~/components/loading';
import { HeatmapContainer } from '~/components/heatmap/heatmapContainer';
import { getFacilityNow } from '~/utils/getFacilityNow';
import { HeatmapSectorsDivision } from '~/components/heatmap/utils';
import { Select, SelectOptionType } from '~/components/select';
import { MobileSeparator } from '~/components/layout/mobileSeparator';

export const PitchHeatmapPage: FC = () => {
  const { facilities } = useMetadataStore();
  const { facilityId, pitchId } = useParams<{
    facilityId: string;
    pitchId: string;
  }>();
  const [
    selectedDivision,
    setSelectedDivision,
  ] = useState<HeatmapSectorsDivision>(HeatmapSectorsDivision.Thirds);

  const selectedFacility = facilities.find(f => f.id === +facilityId);
  const selectedPitch = selectedFacility?.pitches?.find(p => p.id === +pitchId);

  usePageTitle(
    `Heatmap - ${selectedPitch?.name} - ${selectedFacility?.name} - Intelligent Play`
  );

  const [showModal, setShowModal] = useState(false);

  const defaultTimePeriod = TimePeriod.Last7Days;
  const {
    selectedTimePeriod,
    setSelectedTimePeriod,
    setCustomDates,
    customDates,
    customDatesText,
  } = useTimePeriodSelect(defaultTimePeriod, null);

  const applyCustomDatesHandler = (
    customFrom: Date | null,
    customTo: Date | null
  ): void => {
    if (customFrom && customTo) {
      setSelectedTimePeriod(TimePeriod.Custom);
      setCustomDates({
        startDate: customFrom,
        endDate: customTo,
      });
    }
  };

  const heatmapParams: HeatmapParams = {
    pitchId: +pitchId,
    timePeriod: selectedTimePeriod ?? defaultTimePeriod,
    timezone: selectedFacility?.tzDatabaseTimezone || 'Etc/UTC',
  };
  if (selectedTimePeriod === TimePeriod.Custom && customDates) {
    heatmapParams.from = format(customDates.startDate, 'yyyy-MM-dd');
    heatmapParams.to = format(customDates.endDate, 'yyyy-MM-dd');
  }

  const { data: heatmap, isLoading: isLoadingHeatmap } = useQuery(
    ['usageHeatmap', heatmapParams],
    async () => getUsageHeatmap(heatmapParams)
  );

  const { data: snapshots, isLoading: isLoadingSnapshots } = useQuery(
    ['snapshots', heatmapParams],
    async () => getPeakTimeSnapshots(heatmapParams)
  );

  if (!selectedFacility || !selectedPitch) {
    // error handled on parent
    return null;
  }

  const availableTimePeriods = [
    TimePeriod.Today,
    TimePeriod.Yesterday,
    TimePeriod.Last7Days,
    TimePeriod.Last4Weeks,
    TimePeriod.Custom,
  ];
  const timePeriodOptions: SelectOptionType[] = [
    ...availableTimePeriods.map(timePeriod => ({
      label: timePeriod || '',
      value: timePeriod,
    })),
  ];
  const defaultTimePeriodSelect =
    timePeriodOptions.find(option => option.label === selectedTimePeriod) ||
    timePeriodOptions.find(option => option.label === defaultTimePeriod) ||
    timePeriodOptions[0];

  const timePersiodSelectHandler = (
    selectedTimePeriodParam: SelectOptionType | null
  ): void => {
    if (selectedTimePeriodParam) {
      if (selectedTimePeriodParam.value === TimePeriod.Custom) {
        setShowModal(!showModal);
      } else {
        setSelectedTimePeriod(selectedTimePeriodParam.value as TimePeriod);
      }
    }
  };

  const facilityTimezone = selectedFacility.tzDatabaseTimezone || 'Etc/UTC';
  const nowFacility = getFacilityNow(facilityTimezone);
  const showSnapshots =
    !isLoadingSnapshots && snapshots && snapshots.length !== 0;

  return (
    <div className="bg-white shadow-250 md:rounded md:my-4">
      <div className="flex flex-row justify-between px-3.5 sm:px-6 py-1 sm:py-3.5 border-b border-primary-100 bg-white sticky top-[167px] z-20 sm:static">
        <div className="w-28 sm:hidden">
          <Select
            options={timePeriodOptions}
            selectedValue={defaultTimePeriodSelect}
            onChange={timePersiodSelectHandler}
            isMobile
          />
        </div>
        <div className="flex-row hidden w-full space-x-1 sm:flex">
          {availableTimePeriods.map(timePeriod => {
            return (
              <ToggleButton
                text={
                  timePeriod === TimePeriod.Custom
                    ? customDatesText
                    : timePeriod
                }
                onClick={() =>
                  timePeriod === TimePeriod.Custom
                    ? setShowModal(!showModal)
                    : setSelectedTimePeriod(timePeriod)
                }
                active={selectedTimePeriod === timePeriod}
                key={timePeriod}
              />
            );
          })}
        </div>
      </div>
      <div className="flex flex-col space-y-4 px-6 py-3.5">
        <div className="flex flex-col justify-between w-full space-y-4 lg:space-y-0 lg:space-x-2 lg:flex-row">
          <div className="lg:w-9/16">
            <SectionHeader title="Heatmap" />
            <HeatmapContainer
              heatmapImage={heatmap ? heatmap.image : ''}
              isLoadingHeatmap={isLoadingHeatmap}
              pitchWidth={selectedPitch.widthMeters}
              pitchLength={selectedPitch.lengthMeters}
            />
          </div>
          <MobileSeparator />
          <div className="lg:w-6/16">
            <HeatmapSectors
              heatmap={heatmap ? heatmap.data : [[]]}
              isLoading={isLoadingHeatmap}
              benchmarkPlayerCount={selectedPitch.benchamarkPlayerCount || 0}
              selectedDivision={selectedDivision}
              setSelectedDivision={setSelectedDivision}
            />
          </div>
          <MobileSeparator />
        </div>

        <div>
          <div className="flex flex-col sm:flex-row pb-2.5 sm:justify-between">
            <div className="text-xl font-semibold">Peak-time snapshots</div>
            {showSnapshots && (
              <div className="mt-2 mb-1 text-sm">
                Images from the busiest period of the date range selected
              </div>
            )}
          </div>
          {!showSnapshots ? (
            <div>{isLoadingSnapshots ? <Loading /> : 'No snapshots found'}</div>
          ) : (
            <div className="grid gap-2 grid-cols-auto-fit">
              {snapshots.map(snapshot => (
                <SnapshotThumbnail
                  key={snapshot.deviceId}
                  snapshot={snapshot}
                  timezone={selectedFacility.tzDatabaseTimezone || 'Etc/UTC'}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <DateRangeModal
        disableAfter={nowFacility}
        showModal={showModal}
        setShowModal={setShowModal}
        permittedDateRange={{ months: 1 }}
        applyDates={applyCustomDatesHandler}
      />
    </div>
  );
};
