import React, { FC } from 'react';
import { Navigate, ToolbarProps } from 'react-big-calendar';
import { Button, ButtonType } from '~/components/button';

import nextIcon from 'assets/images/pages/calendar/icon__month-next.png';
import nextIcon2x from 'assets/images/pages/calendar/icon__month-next@2x.png';
import prevIcon from 'assets/images/pages/calendar/icon__month-prev.png';
import prevIcon2x from 'assets/images/pages/calendar/icon__month-prev@2x.png';
import addIcon from 'assets/images/pages/calendar/icon__add-button.png';
import addIcon2x from 'assets/images/pages/calendar/icon__add-button@2x.png';
import { CalendarFilters } from './CalendarFilters';
import { useCalendarStore } from '~/stores/useCalendarStore';
import { useAuthStore } from '~/stores';
import { userHasCalendarWriteAccess } from '@intelligent-play-v2/lib';

interface CalendarToolbarProps extends ToolbarProps {
  showFilters: boolean;
}

export const CalendarToolbar: FC<CalendarToolbarProps> = ({
  onNavigate,
  label,
  showFilters,
}) => {
  const {
    setShowGenericEventModal: setIsCreateEventModalOpen,
  } = useCalendarStore();
  const { user } = useAuthStore();

  return (
    <div className="inline-flex items-center justify-between w-full p-3 border border-b-0 border-primary-200 rounded-t-sm">
      <div className="inline-flex space-x-4">
        <div className="inline-flex space-x-2">
          <span onClick={() => onNavigate(Navigate.PREVIOUS)}>
            <picture>
              <img
                className="border border-white rounded-full cursor-pointer hover:border-primary-400"
                src={prevIcon}
                srcSet={`${prevIcon} 1x, ${prevIcon2x} 2x`}
              />
            </picture>
          </span>
          <span onClick={() => onNavigate(Navigate.NEXT)}>
            <picture>
              <img
                className="border border-white rounded-full cursor-pointer hover:border-primary-400"
                src={nextIcon}
                srcSet={`${nextIcon} 1x, ${nextIcon2x} 2x`}
              />
            </picture>
          </span>
        </div>
        <span className="pt-px font-semibold transform translate-y-px">
          {label}
        </span>
      </div>
      <div className="inline-flex items-center justify-end w-11/16 space-x-1">
        {showFilters && <CalendarFilters allowAll />}
        {user && userHasCalendarWriteAccess(user) && (
          <div>
            <Button
              type={ButtonType.Secondary}
              size="small"
              text="Add event"
              icon={addIcon}
              icon2x={addIcon2x}
              onClick={() => setIsCreateEventModalOpen(true)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
