export const formatNumber = (number: number): string => {
  let numberString = '';
  if (number < 10) {
    numberString = number.toFixed(2);
  } else {
    numberString = number.toFixed(0);
  }
  const parsedNumber = parseFloat(numberString);
  return parsedNumber.toLocaleString();
};
