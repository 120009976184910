import React, { FC } from 'react';
import { FieldTesting } from '~/components/fieldTesting';

export const PitchFieldTestingPage: FC = () => {
  return (
    <>
      <FieldTesting />
    </>
  );
};
