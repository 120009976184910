export const getUserInitials = (name: string | null): string => {
  if (!name) {
    return '';
  }
  const [firstName, lastName] = name.split(' ');

  let initials = firstName[0];
  if (lastName) {
    initials += lastName[0];
  }

  return initials;
};
