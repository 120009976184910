import { User } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

type LoginParams = {
  username: string;
  password: string;
};

export const postLogin = async (params: LoginParams): Promise<User> => {
  const { data } = await queryBuilder('POST', 'auth/login', undefined, params);
  return data;
};
