import React, { FC } from 'react';
import './styles.css';

interface RadioButtonProps {
  label: string;
  checked: boolean;
  onChange: () => void;
}

export const RadioButton: FC<RadioButtonProps> = ({
  label,
  checked,
  onChange,
}) => {
  return (
    <div className="flex items-center pr-4 text-base">
      <input
        type="radio"
        className="hidden w-5 h-5"
        checked={checked}
        readOnly
      />
      <label
        htmlFor="radio1"
        className="flex items-center cursor-pointer"
        onClick={onChange}
      >
        <span className="inline-block w-5 h-5 mr-2 border border-primary-200 rounded-full"></span>
        {label}
      </label>
    </div>
  );
};
