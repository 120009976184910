import create from 'zustand';
import { UserAlert } from '@intelligent-play-v2/domain';

interface AlertStore {
  userAlerts: UserAlert[];
  setUserAlerts: (facilities: UserAlert[]) => void;
  removeUserAlert: (alertId: number) => void;
}

export const useAlertStore = create<AlertStore>(set => ({
  userAlerts: [],
  setUserAlerts: userAlerts => set(() => ({ userAlerts })),
  removeUserAlert: id =>
    set(state => ({
      userAlerts: state.userAlerts.filter(({ alertId }) => alertId !== id),
    })),
}));
