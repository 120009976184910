import React, { FC, useEffect, useState } from 'react';
import { classnames } from 'tailwindcss-classnames';
import mobile from 'is-mobile';
import { useQuery } from 'react-query';
import { useAuthStore } from '~/stores';
import { usePageTitle } from '~/hooks';
import { getTasksOverview } from '~/api';
import {
  ContentContainer,
  HeaderTab,
  PageHeaderWithTabs,
} from '~/components/layout';
import { KeyStatisticsRow } from '~/components/data';
import { FacilitiesTable } from '~/components/table/facilityTable';
import { TasksOverview } from '~/components/tasksOverview';
import { useMediaQuery } from 'react-responsive';
import { Client } from '@intelligent-play-v2/domain';
import {
  FIELDTURF_ONBOARDING_TOUR_UUID,
  IP_ONBOARDING_TOUR_UUID,
} from '~/config';

// eslint-disable-next-line no-shadow
enum DashboardMobileTabs {
  KeyStatistics = 'Key statistics',
  Facilities = 'Facilities',
  Tasks = 'Tasks',
}

export const DashboardPage: FC = () => {
  usePageTitle('Dashboard - Intelligent Play');
  const { user } = useAuthStore();
  const [selectedMobileTab, setSelectedMobileTab] = useState(
    DashboardMobileTabs.KeyStatistics
  );
  const subtitleContent = 'Welcome back, ' + user?.name?.split(' ', 1);

  const { data: tasksOverview } = useQuery(
    ['getEvents', 'userOverview', user?.id],
    async () => getTasksOverview()
  );

  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

  const hasTasks = tasksOverview && tasksOverview.length > 0;

  const facilitiesTableClass = classnames(
    {
      'xl:w-2/3': hasTasks,
    },
    'w-full'
  );

  const taskOverviewClass = classnames(
    'w-full',
    'xl:w-1/3',
    {
      hidden: selectedMobileTab !== DashboardMobileTabs.Tasks,
    },
    { 'sm:block': hasTasks }
  );

  const showFacilities =
    !isMobile || selectedMobileTab === DashboardMobileTabs.Facilities;

  const showKeyStatistics =
    !isMobile || selectedMobileTab === DashboardMobileTabs.KeyStatistics;

  useEffect(() => {
    if (!mobile()) {
      const tourUuid =
        user?.clientId === Client.FieldTurf
          ? FIELDTURF_ONBOARDING_TOUR_UUID
          : IP_ONBOARDING_TOUR_UUID;
      if (window.kompassifyLaunchTour) {
        window.kompassifyLaunchTour(tourUuid, 0, true);
      }
    }
  }, [user?.clientId]);

  return (
    <>
      <PageHeaderWithTabs title="My Dashboard" subtitle={subtitleContent}>
        <div className="flex sm:hidden">
          <HeaderTab
            title={DashboardMobileTabs.KeyStatistics}
            isSelected={selectedMobileTab === DashboardMobileTabs.KeyStatistics}
            selectMobileTab={() =>
              setSelectedMobileTab(DashboardMobileTabs.KeyStatistics)
            }
          />
          <HeaderTab
            title={DashboardMobileTabs.Facilities}
            isSelected={selectedMobileTab === DashboardMobileTabs.Facilities}
            selectMobileTab={() =>
              setSelectedMobileTab(DashboardMobileTabs.Facilities)
            }
          />
          {hasTasks && (
            <HeaderTab
              title={DashboardMobileTabs.Tasks}
              isSelected={selectedMobileTab === DashboardMobileTabs.Tasks}
              selectMobileTab={() =>
                setSelectedMobileTab(DashboardMobileTabs.Tasks)
              }
            />
          )}
        </div>
      </PageHeaderWithTabs>
      <ContentContainer className="my-5 sm:my-12.5">
        {showKeyStatistics && (
          <div id="key-statistics">
            <KeyStatisticsRow />
          </div>
        )}
        <div className="sm:mt-12.5 xl:flex grid xl:gap-10">
          {showFacilities && (
            <div className={facilitiesTableClass}>
              <FacilitiesTable
                showTitle={true}
                width={hasTasks ? '2/3' : 'full'}
              />
            </div>
          )}
          <div className={taskOverviewClass}>
            <TasksOverview tasksOverview={tasksOverview} />
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
