import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { postUser } from '~/api';
import { TextInput } from '~/components/forms';
import { Button } from '~/components/button';
import { Select, SelectOptionType } from '~/components/select';
import {
  AddUserBody,
  Client,
  UserType,
  getUserTypeString,
} from '@intelligent-play-v2/domain';

interface AddUserFormProps {
  onSuccess: () => void;
}

export const AddUserForm: FC<AddUserFormProps> = ({ onSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm();
  const [error, setError] = useState<string>();

  // User type
  const userTypeOptions: SelectOptionType[] = [];
  Object.values(UserType).map(userTypeValue => {
    if (!isNaN(Number(userTypeValue))) {
      userTypeOptions.push({
        label: getUserTypeString(userTypeValue as UserType),
        value: userTypeValue.toString(),
      });
    }
  });

  const [selectedUserType, setSelectedUserType] = useState<SelectOptionType>(
    userTypeOptions[0]
  );
  const handleUserTypeChange = (option: SelectOptionType | null): void => {
    const newValue = option ? option : userTypeOptions[0];
    setSelectedUserType(newValue);
  };

  // Dashboard type
  const dashboardTypeOptions: SelectOptionType[] = [
    { label: 'Intelligent Play', value: Client.IntelligentPlay.toString() },
    { label: 'Genius', value: Client.FieldTurf.toString() },
  ];
  const [
    selectedDashboardType,
    setSelectedDashboardType,
  ] = useState<SelectOptionType>(dashboardTypeOptions[0]);
  const handleDashboardTypeChange = (option: SelectOptionType | null): void => {
    const newValue = option ? option : dashboardTypeOptions[0];
    setSelectedDashboardType(newValue);
  };

  const { mutate: mutateAddUser, isLoading } = useMutation(postUser, {
    onSuccess,
    onError: (e: unknown) => {
      setError(String(e));
    },
  });

  const onSubmitHandler: SubmitHandler<AddUserBody> = (data): void => {
    data.userTypeId = +selectedUserType.value;
    data.clientId = +selectedDashboardType.value;
    mutateAddUser(data);
  };

  return (
    <div className="w-80 md:w-120 lg:w-140">
      <form className="flex flex-col space-y-5">
        <div className="flex flex-row justify-between w-full space-x-3">
          <TextInput
            register={register('firstName', {
              required: 'Please enter a first name',
            })}
            label="First name"
            placeholder="Enter first name"
            required={true}
            type="text"
            isLightMode
            errorMessage={errors.firstName?.message}
          />
          <TextInput
            register={register('lastName', {
              required: 'Please enter a last name',
            })}
            label="Last name"
            placeholder="Enter last name"
            required={true}
            type="text"
            isLightMode
            errorMessage={errors.lastName?.message}
          />
        </div>
        <div className="flex flex-row justify-between w-full space-x-3">
          <TextInput
            register={register('email', {
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: 'Please enter a valid email address',
              },
            })}
            label="Email address"
            placeholder="Enter email address"
            type="email"
            required={true}
            isLightMode
            errorMessage={errors.email?.message}
          />
          <TextInput
            register={register('phoneNumber')}
            label="Phone number"
            placeholder="Enter phone number"
            isLightMode
            errorMessage={errors.phoneNumber?.message}
            required={false}
          />
        </div>
        <div className="flex flex-row justify-between w-full space-x-3">
          <div className="w-1/2">
            <div className="flex justify-between text-sm font-semibold">
              <p>User type</p>
            </div>
            <div className="mt-2.5">
              <Select
                options={userTypeOptions}
                selectedValue={selectedUserType}
                onChange={handleUserTypeChange}
                isForm
                shadow
              />
            </div>
          </div>
          <div className="w-1/2">
            <TextInput
              register={register('jobTitle')}
              label="Job title"
              placeholder="Enter job title"
              isLightMode
              errorMessage={errors.jobTitle?.message}
              required={false}
            />
          </div>
        </div>
        <div className="flex-grow">
          <div className="flex justify-between text-sm font-semibold">
            <p>Dashboard type</p>
          </div>
          <div className="mt-2.5">
            <Select
              options={dashboardTypeOptions}
              selectedValue={selectedDashboardType}
              onChange={handleDashboardTypeChange}
              isForm
              shadow
            />
          </div>
        </div>

        <Button
          onClick={handleSubmit(onSubmitHandler)}
          text="Create user"
          disabled={
            !dirtyFields.firstName ||
            !dirtyFields.lastName ||
            !dirtyFields.email
          }
          className="mt-auto lg:mt-12.5"
          isLoading={isLoading}
          disabledStyle="light"
        />
        {error && <p className="mt-4 text-red">{error}</p>}
      </form>
    </div>
  );
};
