import { classnames } from 'tailwindcss-classnames';

const statsBaseClass = classnames('rounded', 'shadow-250');

export const statsMessageClass = classnames(
  statsBaseClass,
  'bg-white',
  'h-32',
  'flex',
  'items-center',
  'justify-center'
);

export const statsClassFull = classnames(
  statsBaseClass,
  `grid`,
  `grid-cols-1`,
  `gap-px`,
  `sm:grid-cols-2`,
  `lg:grid-cols-3`,
  `2xl:grid-cols-6`
);

export const statsClass = classnames(
  statsBaseClass,
  `grid`,
  `grid-cols-1`,
  `gap-px`,
  `sm:grid-cols-2`,
  `md:grid-cols-2`,
  `lg:grid-cols-2`,
  `2xl:grid-cols-4`
);

export const statsClassPrintable = classnames(
  statsBaseClass,
  'grid',
  'grid-cols-2',
  'gap-px'
);

export const pageBreakStyle: React.CSSProperties = {
  pageBreakInside: 'avoid',
};
