import { ToggleableDataSeries } from '~/types';

export const toggleStoreSeriesLine = (
  dataSeries: Record<string, ToggleableDataSeries[]>,
  facilityId: number,
  lineKey: string
): Record<string, ToggleableDataSeries[]> => {
  const dataSeriesCopy = {
    ...dataSeries,
  };
  const index = dataSeriesCopy[facilityId].findIndex(
    series => series.key === lineKey
  );
  if (index >= 0) {
    dataSeriesCopy[facilityId] = dataSeriesCopy[facilityId].map((item, i) => {
      if (index === i) {
        item.showLine = !item.showLine;
      }
      return item;
    });
  }
  return dataSeriesCopy;
};
