import React, { FC } from 'react';
import { HeatmapSectorData } from './utils';
import { UsageZone } from './usageZone';

export const HeatmapSectorsQuarters: FC<HeatmapSectorData> = ({
  averageCount,
  totalEuh,
}) => {
  const max = Math.max(...averageCount[0], ...averageCount[1]);

  return (
    <div className="grid h-full grid-cols-2 grid-rows-2 gap-1">
      <UsageZone value={averageCount[0][0]} totalEuh={totalEuh} max={max} />
      <UsageZone value={averageCount[0][1]} totalEuh={totalEuh} max={max} />
      <UsageZone value={averageCount[1][0]} totalEuh={totalEuh} max={max} />
      <UsageZone value={averageCount[1][1]} totalEuh={totalEuh} max={max} />
    </div>
  );
};
