import React, { FC, Ref } from 'react';
import { DataSeries, ToggleableDataSeries } from '~/types';
import { Graph } from '.';
import { PDFHeaderAndFooter } from '~/components/layout/pdf';

interface PrintablePlayerCountGraphProps {
  dataSeries: DataSeries[] | ToggleableDataSeries[];
  facilityId: number;
  componentRef: Ref<HTMLDivElement>;
}

export const PrintablePlayerCountGraph: FC<PrintablePlayerCountGraphProps> = ({
  dataSeries,
  facilityId,
  componentRef,
}) => {
  return (
    <div ref={componentRef}>
      <PDFHeaderAndFooter>
        <div className="mt-32">
          <Graph
            dataSeries={dataSeries}
            primaryAxisLabel="Player count"
            tooltipValueLabel="players"
            height={445}
            width={1100}
            facilityId={facilityId}
          />
        </div>
      </PDFHeaderAndFooter>
    </div>
  );
};
