import cloudIcon from 'assets/images/pages/facility/weather/weather-icons/Cloud.png';
import cloudFogIcon from 'assets/images/pages/facility/weather/weather-icons/CloudFog.png';
import cloudRainIcon from 'assets/images/pages/facility/weather/weather-icons/CloudRain.png';
import cloudRainSnowIcon from 'assets/images/pages/facility/weather/weather-icons/CloudRainSnow.png';
import cloudRainThunderIcon from 'assets/images/pages/facility/weather/weather-icons/CloudRainThunder.png';
// import cloudRainWindIcon from 'assets/images/pages/facility/weather/weather-icons/CloudRainWind.png';
import cloudSnowIcon from 'assets/images/pages/facility/weather/weather-icons/CloudSnow.png';
import cloudSunIcon from 'assets/images/pages/facility/weather/weather-icons/CloudSun.png';
// import cloudRainSunIcon from 'assets/images/pages/facility/weather/weather-icons/CloudRainSun.png';
// import rainWindIcon from 'assets/images/pages/facility/weather/weather-icons/RainWind.png';
import sunIcon from 'assets/images/pages/facility/weather/weather-icons/Sun.png';
// import windIcon from 'assets/images/pages/facility/weather/weather-icons/Wind.png';

const weatherMapping = new Map<number, string>();

weatherMapping.set(113, sunIcon); // Clear/Sunny
weatherMapping.set(116, cloudSunIcon); // Partly Cloudy
weatherMapping.set(119, cloudIcon); // Cloudy
weatherMapping.set(122, cloudIcon); // Overcast
weatherMapping.set(143, cloudFogIcon); // Mist
weatherMapping.set(176, cloudRainIcon); // Patchy rain nearby
weatherMapping.set(179, cloudSnowIcon); // Patchy snow nearby
weatherMapping.set(182, cloudRainSnowIcon); // Patchy sleet nearby
weatherMapping.set(185, cloudRainSnowIcon); // Patchy freezing drizzle nearby
weatherMapping.set(200, cloudRainThunderIcon); // Thundery outbreaks in nearby
weatherMapping.set(227, cloudSnowIcon); // Blowing snow
weatherMapping.set(230, cloudSnowIcon); // Blizzard
weatherMapping.set(248, cloudFogIcon); // Fog
weatherMapping.set(260, cloudFogIcon); // Freezing fog
weatherMapping.set(263, cloudRainIcon); // Patchy light drizzle
weatherMapping.set(266, cloudRainIcon); // Light drizzle
weatherMapping.set(281, cloudSnowIcon); // Freezing drizzle
weatherMapping.set(284, cloudSnowIcon); // Heavy freezing drizzle
weatherMapping.set(293, cloudRainIcon); // Patchy light rain
weatherMapping.set(296, cloudRainIcon); // Light rain
weatherMapping.set(299, cloudRainIcon); // Moderate rain at times
weatherMapping.set(302, cloudRainIcon); // Moderate rain
weatherMapping.set(305, cloudRainIcon); // Heavy rain at times
weatherMapping.set(308, cloudRainIcon); // Heavy rain
weatherMapping.set(311, cloudRainSnowIcon); // Light freezing rain
weatherMapping.set(314, cloudRainSnowIcon); // Moderate or Heavy freezing rain
weatherMapping.set(317, cloudRainSnowIcon); // Light sleet
weatherMapping.set(320, cloudRainSnowIcon); // Moderate or heavy sleet
weatherMapping.set(323, cloudSnowIcon); // Patchy light snow
weatherMapping.set(326, cloudSnowIcon); // Light snow
weatherMapping.set(329, cloudSnowIcon); // Patchy moderate snow
weatherMapping.set(332, cloudSnowIcon); // Moderate snow
weatherMapping.set(335, cloudSnowIcon); // Patchy heavy snow
weatherMapping.set(338, cloudSnowIcon); // Heavy snow
weatherMapping.set(350, cloudSnowIcon); // Ice pellets
weatherMapping.set(353, cloudRainIcon); // Light rain shower
weatherMapping.set(356, cloudRainIcon); // Moderate or heavy rain shower
weatherMapping.set(359, cloudRainIcon); // Torrential rain shower
weatherMapping.set(362, cloudRainSnowIcon); // Light sleet showers
weatherMapping.set(365, cloudRainSnowIcon); // Moderate or heavy sleet showers
weatherMapping.set(368, cloudSnowIcon); // Light snow showers
weatherMapping.set(371, cloudSnowIcon); // Moderate or heavy snow showers
weatherMapping.set(374, cloudSnowIcon); // Light showers of ice pellets
weatherMapping.set(377, cloudSnowIcon); // Moderate or heavy showers of ice pellets
weatherMapping.set(386, cloudRainThunderIcon); // Patchy light rain in area with thunder
weatherMapping.set(389, cloudRainThunderIcon); // Moderate or heavy rain in area with thunder
weatherMapping.set(392, cloudRainThunderIcon); // Patchy light snow in area with thunder
weatherMapping.set(395, cloudRainThunderIcon); // Moderate or heavy snow in area with thunder

export const getWeatherIcon = (weatherCode: number): string => {
  return weatherMapping.get(weatherCode) || '';
};
