import React, { FC, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { postLogout } from '~/api';
import { Button, ButtonType } from '~/components/button';
import { PromptModal } from '~/components/modals';
import { UserThumbnail, UserThumbnailType } from '~/components/userThumbnail';
import { useAuthStore, useMetadataStore } from '~/stores';
import { RoutesPath } from '~/types';
import { getUserInitials } from '~/utils/getUserInitials';
import { useOutsideClicker } from '~/hooks';
import usernameArrowIcon from 'assets/images/navs/icon__username-arrow.png';
import usernameArrowIcon2x from 'assets/images/navs/icon__username-arrow@2x.png';

export const UserMenuDropdown: FC = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { user, logout } = useAuthStore();
  const history = useHistory();
  const { setFacilities } = useMetadataStore();
  const queryClient = useQueryClient();
  const { mutate: mutateLogout, isLoading } = useMutation(postLogout, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setFacilities([]);
      history.push('/login');
      logout();
    },
  });

  const handleLogout = (): void => {
    toggleDropdown();
    setShowLogoutModal(true);
  };

  const toggleDropdown = (): void => {
    setShowDropdown(!showDropdown);
  };

  const dialogRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useOutsideClicker(dialogRef, buttonRef, () => setShowDropdown(false));

  if (!user) {
    return null;
  }

  return (
    <>
      <div>
        <button
          className="flex items-center cursor-pointer group"
          onClick={toggleDropdown}
          ref={buttonRef}
        >
          <div className="mr-2.5">
            <UserThumbnail
              text={getUserInitials(user.name)}
              type={UserThumbnailType.UserProfile}
              imageSrc={user.imageThumbnailUrl}
            />
          </div>
          <div className="hidden mr-2.5 text-base font-semibold text-primary-900 md:block">
            {user.name}
          </div>
          <picture className="transform group-hover:translate-y-1">
            <img
              className="hidden md:block mr-7.5"
              src={usernameArrowIcon2x}
              srcSet={`${usernameArrowIcon} 1x, ${usernameArrowIcon2x} 2x`}
            />
          </picture>
        </button>
        {showDropdown && (
          <div
            ref={dialogRef}
            className="absolute right-0 z-20 w-40 transform translate-y-2.3 bg-white border-t border-primary-100 shadow-250 rounded-xs"
          >
            <Link
              to={{ pathname: RoutesPath.UserProfile }}
              className="flex p-2 text-base font-semibold cursor-pointer hover:bg-primary-100"
              onClick={toggleDropdown}
            >
              My profile
            </Link>
            <div
              className="p-2 text-base font-semibold cursor-pointer hover:bg-primary-100"
              onClick={handleLogout}
            >
              Log out
            </div>
          </div>
        )}
      </div>
      <PromptModal
        title="Are you sure you want to log out?"
        showModal={showLogoutModal}
        primaryButton={
          <Button
            text="Yes, log out"
            onClick={mutateLogout}
            size="small"
            isLoading={isLoading}
          />
        }
        secondaryButton={
          <Button
            text="Cancel"
            onClick={() => setShowLogoutModal(false)}
            size="small"
            type={ButtonType.Outline}
          />
        }
      />
    </>
  );
};
