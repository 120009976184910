import { User } from '@intelligent-play-v2/domain';
import { SelectOptionType } from '~/components/select';

const defaultSortBy = {
  label: 'A-Z',
  value: 'a-z',
};

export const sortUsers = (
  users: User[],
  sortBy: SelectOptionType = defaultSortBy
): User[] => {
  return users.sort((a, b) => {
    if (sortBy.value === 'a-z' || sortBy.value === 'z-a') {
      const isAsc = sortBy.value === 'a-z';
      const nameA = a.name?.toUpperCase() ?? '';
      const nameB = b.name?.toUpperCase() ?? '';
      if (isAsc) {
        return nameA < nameB ? -1 : 1;
      } else {
        return nameA > nameB ? -1 : 1;
      }
    }
    if (sortBy.value === 'recentlyAdded') {
      return a.createdAt > b.createdAt ? -1 : 1;
    }
    return a.userTypeId < b.userTypeId ? -1 : 1;
  });
};
