import React, { FC } from 'react';

interface EditReportOptionProps {
  title: string;
}

export const EditReportOption: FC<EditReportOptionProps> = ({
  title,
  children,
}) => {
  return (
    <div className="flex mt-7.5">
      <div className="w-1/6">
        <div className="flex items-center text-xl font-normal text-primary-900 h-14">
          {title}
        </div>
      </div>
      <div className="flex flex-wrap w-5/6 -mb-2.5">{children}</div>
    </div>
  );
};
