import { ForgotPassword } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const postForgotPassword = async (
  param: ForgotPassword
): Promise<Response> => {
  const { data } = await queryBuilder(
    'POST',
    'auth/forgotPassword',
    undefined,
    param
  );
  return data;
};
