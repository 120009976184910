import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Pitch } from '@intelligent-play-v2/domain';
import { RoutesPath } from '~/types';
import fieldIcon from 'assets/images/pages/admin/icon__field-surface.png';
import fieldIcon2x from 'assets/images/pages/admin/icon__field-surface@2x.png';
import { AdminPitchSportType } from './adminPitchSportType';
import editIcon from 'assets/images/pages/admin/icon__field-edit.png';
import editIcon2x from 'assets/images/pages/admin/icon__field-edit@2x.png';
import { getCountryForTimezone } from 'countries-and-timezones';

interface PitchRowProps {
  facilityId: number;
  pitch: Pitch;
}

export const PitchRow: FC<PitchRowProps> = ({ pitch, facilityId }) => {
  const country = pitch.timezone ? getCountryForTimezone(pitch.timezone) : null;
  return (
    <div className="flex items-center h-15 px-5 border rounded shadow-250 border-primary-250">
      <div className="flex items-center w-10/12 sm:w-6/12 lg:w-4/12">
        <div className="flex items-center justify-center flex-shrink-0 text-xl font-semibold text-white bg-green rounded-full shadow-green-dark w-7 h-7">
          {pitch.facilityPitchNumber}
        </div>
        <div className="ml-5 text-lg font-semibold truncate">{pitch.name}</div>
      </div>
      <div className="items-center hidden w-3/12 sm:flex">
        <div className="flex items-center justify-center w-6 h-6 bg-primary-100 rounded-sm">
          <picture>
            <img
              src={fieldIcon}
              srcSet={`${fieldIcon} 1x, ${fieldIcon2x} 2x`}
            />
          </picture>
        </div>
        <div className="ml-2 text-base font-semibold leading-none">
          {pitch.turfType}
        </div>
      </div>
      <div className="items-center hidden w-6/12 lg:flex">
        <div className="flex space-x-5">
          {pitch.sportTypes?.map(sport => (
            <AdminPitchSportType
              key={sport}
              sportTypeId={sport}
              country={country}
            />
          ))}
        </div>
      </div>
      <div className="flex w-2/12 lg:w-1/12">
        <Link
          className="ml-auto"
          to={RoutesPath.AdminPitch.replace(
            ':facilityId',
            facilityId.toString()
          ).replace(':pitchId', pitch.id.toString())}
        >
          <picture>
            <img src={editIcon} srcSet={`${editIcon} 1x, ${editIcon2x} 2x`} />
          </picture>
        </Link>
      </div>
    </div>
  );
};
