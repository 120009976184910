import React, { FC, useEffect } from 'react';
import closeIcon from 'assets/images/ctas_inputs_modals/icon__modal-close.png';
import closeIcon2x from 'assets/images/ctas_inputs_modals/icon__modal-close@2x.png';

interface ModalProps {
  showModal?: boolean;
  setShowModal: (show: boolean) => void;
  title: string;
  subtitle?: string;
  estimatedHeight?: number;
}

export const Modal: FC<ModalProps> = ({
  showModal,
  setShowModal,
  children,
  title,
  subtitle,
  estimatedHeight,
}) => {
  useEffect(() => {
    const close = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        setShowModal(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [setShowModal]);

  return showModal ? (
    <>
      <div className="fixed inset-0 z-40 flex w-full overflow-x-hidden text-primary-900">
        <div
          className="fixed inset-0 bg-primary-900 opacity-50"
          onClick={() => setShowModal(false)}
        />
        <div
          className="relative flex flex-col w-full m-auto sm:w-auto"
          style={{
            height: estimatedHeight
              ? `${estimatedHeight.toString()}px`
              : undefined,
          }}
        >
          <div className="bg-white rounded shadow-250 ">
            {/* header*/}
            <div className="sticky top-0 z-30 flex items-start justify-between p-5 bg-white border-b border-primary-100 rounded sm:relative">
              <div className="flex-col space-y-1">
                <h3 className="text-xl font-semibold">{title}</h3>
                <h4 className="text-lg font-normal">{subtitle}</h4>
              </div>
              <button
                className="flex items-center justify-center w-5 h-5 my-auto ml-auto"
                onClick={() => setShowModal(false)}
              >
                <picture>
                  <img
                    src={closeIcon2x}
                    srcSet={`${closeIcon} 1x, ${closeIcon2x} 2x`}
                  />
                </picture>
              </button>
            </div>
            {/* body*/}
            <div className="relative flex-auto p-5">{children}</div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};
