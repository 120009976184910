import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import {
  AdminFacilityPage,
  AdminPage,
  AdminPitchPage,
  AdminUserProfilePage,
  DashboardPage,
} from '~/pages';

import { RoutesPath } from '~/types';
import { AdminRoute } from './AdminRoute';
import { PrivateRoute } from './PrivateRoute';

export const AdminRouter: FC = () => {
  return (
    <Switch>
      <AdminRoute path={RoutesPath.AdminPitch} component={AdminPitchPage} />
      <AdminRoute
        path={RoutesPath.AdminFacility}
        component={AdminFacilityPage}
      />
      <AdminRoute
        strict
        path={RoutesPath.AdminUserProfile}
        component={AdminUserProfilePage}
      />
      <AdminRoute path={RoutesPath.Admin} component={AdminPage} />
      <PrivateRoute
        path={RoutesPath.Dashboard}
        component={DashboardPage}
        strict
      />
    </Switch>
  );
};
