import React, { FC } from 'react';

interface ContactItemProps {
  icon: string;
  icon2x: string;
  title: string;
  information: string;
}

export const ContactItem: FC<ContactItemProps> = ({
  icon,
  icon2x,
  title,
  information,
}) => {
  return (
    <div className="flex p-5 border-b border-primary-100 last:border-b-0">
      <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-green-lightest rounded">
        <picture>
          <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
        </picture>
      </div>
      <div className="ml-5">
        <div className="text-sm">{title}</div>
        <div className="mt-1 text-base font-semibold">{information}</div>
      </div>
    </div>
  );
};
