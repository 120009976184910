/* eslint-disable @typescript-eslint/no-explicit-any */

import { pageBreakStyle } from '../data/styles';
import { Link } from 'react-router-dom';
import React, { FC, ReactNode } from 'react';

import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { PageControls } from './PageControls';
import { usePagination } from '~/hooks';
import { ListItem } from '~/types';

export interface TableRowData extends ListItem {
  link?: string;
}

interface TableProps {
  columns: TableColumn[];
  rows: TableRowData[];
  showTableHeaders?: boolean;
  linksEnabled?: boolean;
  rowsPerPage?: number;
  isPrintable?: boolean;
}

export interface TableColumn {
  heading: string;
  widthClass: string;
  value: (data: any, index?: number) => ReactNode;
}

export const Table: FC<TableProps> = ({
  rows,
  columns,
  showTableHeaders,
  linksEnabled = true,
  rowsPerPage,
  isPrintable = false,
}) => {
  const { paginatedItems, pageNumber, setPageNumber } = usePagination(
    rows,
    rowsPerPage
  );
  const paginatedTableRows = paginatedItems as TableRowData[][];

  return (
    <div className="w-full mb-8" style={pageBreakStyle}>
      {showTableHeaders ? <TableHeader columns={columns} /> : null}
      <div className="grid grid-rows-1 gap-y-2">
        {paginatedTableRows.length > 0 &&
          paginatedTableRows[pageNumber - 1].map((row, index) =>
            row.link && linksEnabled ? (
              <Link key={row.id} to={row.link}>
                <TableRow data={row} columns={columns} index={index} />
              </Link>
            ) : (
              <TableRow
                key={row.id}
                data={row}
                columns={columns}
                index={index}
                isPrintable={isPrintable}
              />
            )
          )}
        <PageControls
          totalPages={paginatedTableRows.length}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
    </div>
  );
};
