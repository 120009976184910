import { User } from '@intelligent-play-v2/domain';
import { AxiosResponse } from 'axios';
import { queryBuilder } from '~/api';

export const getWhoami = async (): Promise<User | null> => {
  let resp: AxiosResponse;

  try {
    resp = await queryBuilder('GET', 'whoami');
  } catch (err) {
    return null;
  }

  if (!resp || resp.status !== 200) {
    return null;
  }
  return resp.data as User;
};
