import {
  Snapshot,
  SnapshotReason,
  TimePeriod,
} from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface SnapshotParams {
  pitchId: number;
  timePeriod: TimePeriod;
  timezone: string;
  from?: string;
  to?: string;
  reasons: SnapshotReason[];
}

export const getSnapshots = async ({
  pitchId,
  timePeriod,
  from,
  to,
  timezone,
  reasons,
}: SnapshotParams): Promise<Snapshot[]> => {
  const { data } = await queryBuilder('GET', 'snapshots', {
    pitchId,
    timePeriod,
    from,
    to,
    timezone,
    reasons,
  });
  return data;
};
