import { Facility } from '@intelligent-play-v2/domain';
import { compareDesc } from 'date-fns';

export const compareFacilitiesByName = (
  facilityA: Facility,
  facilityB: Facility,
  isAsc: boolean
): number => {
  const nameA = facilityA.name?.toUpperCase() ?? '';
  const nameB = facilityB.name?.toUpperCase() ?? '';
  if (isAsc) {
    return nameA < nameB ? -1 : 1;
  } else {
    return nameA > nameB ? -1 : 1;
  }
};

export const compareFacilitiesByDateAdded = (
  facilityA: Facility,
  facilityB: Facility
): number => {
  let dateA = new Date(1, 1, 1970);
  let dateB = new Date(1, 1, 1970);
  if (
    facilityA.pitches &&
    facilityA.pitches.length > 0 &&
    facilityA.pitches[0].devices &&
    facilityA.pitches[0].devices.length > 0 &&
    facilityA.pitches[0].devices[0].dateOfInstall
  ) {
    dateA = facilityA.pitches[0].devices[0].dateOfInstall;
  }
  if (
    facilityB.pitches &&
    facilityB.pitches.length > 0 &&
    facilityB.pitches[0].devices &&
    facilityB.pitches[0].devices.length > 0 &&
    facilityB.pitches[0].devices[0].dateOfInstall
  ) {
    dateB = facilityB.pitches[0].devices[0].dateOfInstall;
  }
  return compareDesc(new Date(dateA), new Date(dateB));
};
