import { PostCalendarRecurrenceEventBody } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export const postCalendarRecurrence = async (
  body: PostCalendarRecurrenceEventBody
): Promise<PostCalendarRecurrenceEventBody> => {
  const { data } = await queryBuilder(
    'POST',
    'calendar/recurrence',
    undefined,
    {
      ...body,
    }
  );
  return data;
};
