/* eslint-disable react/no-unknown-property */
import React, { FC } from 'react';
import { TickRendererProps } from '@visx/axis';
import { format } from 'date-fns';
import {
  formatDateGbUs,
  getPreferredTemperature,
  getWeatherIcon,
} from '~/utils';
import { DailyWeather } from '@intelligent-play-v2/domain';

interface DateWeatherTickProps {
  tickRendererProps: TickRendererProps;
  daysInterval: number;
  weather: DailyWeather | null;
}

export const DateWeatherTick: FC<DateWeatherTickProps> = ({
  tickRendererProps,
  daysInterval,
  weather,
}) => {
  const { x, dy, formattedValue } = tickRendererProps;

  if (!formattedValue) {
    return null;
  }

  const date = new Date(formattedValue);

  return (
    <svg x="0" y="0" style={{ overflow: 'visible' }}>
      <text transform="" x={x} y={30} textAnchor="middle">
        <tspan x={x} dy={dy}>
          {daysInterval < 1
            ? format(date, 'HH:mm')
            : daysInterval < 7
            ? formatDateGbUs(date, 'E d MMM')
            : formatDateGbUs(date, 'dd/MM')}
        </tspan>
      </text>
      <>
        {weather && (
          <image
            x={x - 18}
            y={37}
            href={getWeatherIcon(weather.weatherCode)}
            width={20}
            height={20}
          />
        )}
        <text
          transform=""
          x={x}
          y={59}
          textAnchor="middle"
          className="text-2xl font-semibold"
        >
          <tspan x={x + 4} dy={dy}>
            {weather && getPreferredTemperature(weather.highTempCelsius) + '°'}
          </tspan>
        </text>
      </>
    </svg>
  );
};
