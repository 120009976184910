import React, { FC, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useMetadataStore } from '~/stores';
import {
  PutFacilityPitchStaffParams,
  putFacilityPitchStaff,
} from '~/api/queries';
import { getUsers } from '~/api';
import { getPitchesUserPermissionsObj } from '~/utils';
import { useCheckedRecordArray } from '~/hooks';
import { RoutesPath } from '~/types';

import { Button, ButtonType } from '~/components/button';
import { ContentContainer, PageHeaderWithButtons } from '~/components/layout';
import { AdminUpdateTeam } from '~/components/admin/adminFacility';

export const AdminPitchPage: FC = () => {
  const { facilities } = useMetadataStore();
  const queryClient = useQueryClient();

  const { facilityId, pitchId } = useParams<{
    facilityId: string;
    pitchId: string;
  }>();
  const facility = facilities.find(f => f.id === +facilityId);
  const pitch = facility?.pitches?.find(p => p.id === +pitchId);

  const { data: users } = useQuery('users', async () => getUsers());

  const {
    mutate: mutatePatchFacilityStaff,
    isLoading: isSavingStaff,
  } = useMutation(putFacilityPitchStaff, {
    onSuccess: () => {
      toast.success('Successfully updated staff');
      queryClient.invalidateQueries('facilities');
      queryClient.invalidateQueries('users');
    },
    onError: () => {
      toast.error('Failed to update staff');
    },
  });

  const onSaveHandler = (): void => {
    const params: PutFacilityPitchStaffParams = {
      id: +facilityId,
      pitchUserAccess: checkedUsers,
    };
    mutatePatchFacilityStaff(params);
  };

  const {
    checkedItems: checkedUsers,
    toggleChecked: toggleCheckedUser,
    setCheckedItems: setCheckedItems,
  } = useCheckedRecordArray({});

  useEffect(() => {
    const pitchesUserPermissions = getPitchesUserPermissionsObj(
      facility,
      users ?? []
    );
    setCheckedItems(pitchesUserPermissions);
  }, [facility, users, setCheckedItems]);

  const setIsChecked = (userId: number): void => {
    toggleCheckedUser(pitchId, userId);
  };

  return (
    <>
      <PageHeaderWithButtons
        title={pitch?.name ?? ''}
        backTitle={facility?.name ?? ''}
        backPath={RoutesPath.AdminFacility.replace(':facilityId', facilityId)}
      >
        <Button
          text="Save changes"
          type={ButtonType.Secondary}
          onClick={onSaveHandler}
          isLoading={isSavingStaff}
        />
      </PageHeaderWithButtons>
      <ContentContainer>
        <AdminUpdateTeam
          users={users ?? []}
          checkedUsers={checkedUsers}
          setIsChecked={(_, userId) => setIsChecked(userId)}
          isSaving={isSavingStaff}
          pitchId={pitchId}
        />
      </ContentContainer>
    </>
  );
};
