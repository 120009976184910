import { FieldTestingResponse } from '@intelligent-play-v2/domain';
import { queryBuilder } from '~/api';

export interface PitchFieldTestingEventsParams {
  pitchId: number;
}

export const getPitchFieldTestingEvents = async ({
  pitchId,
}: PitchFieldTestingEventsParams): Promise<FieldTestingResponse> => {
  const { data } = await queryBuilder('GET', 'calendar/fieldTesting', {
    pitchId,
  });
  return data;
};
