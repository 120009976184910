import { User } from '@intelligent-play-v2/domain';
import React, { FC } from 'react';
import { useAuthStore } from '~/stores';
import { getUserInitials } from '~/utils/getUserInitials';
import { UserThumbnail, UserThumbnailType } from '../userThumbnail';

interface FacilityTeamRowProps {
  staffMember: User;
}

export const FacilityTeamRow: FC<FacilityTeamRowProps> = ({ staffMember }) => {
  const { user } = useAuthStore();

  return (
    <div
      key={staffMember.id}
      className="flex flex-row items-center p-3 space-x-6 border-b border-primary-100"
    >
      <div className="flex flex-row items-center flex-grow-0 w-6/16 space-x-2">
        <UserThumbnail
          text={getUserInitials(staffMember.name)}
          type={UserThumbnailType.UserProfile}
          imageSrc={staffMember.imageThumbnailUrl}
        />
        <span className="font-semibold transform -translate-y-px">
          {staffMember.name}
        </span>
        <span className="text-primary-400">
          {staffMember.id === user?.id ? ' (me)' : null}
        </span>
      </div>
      <div className="w-4/16 overflow-x-auto text-base">
        {staffMember.jobTitle}
      </div>
      <div className="w-6/16 text-base break-words">{staffMember.email}</div>
    </div>
  );
};
