import { UserAlertSubscription } from '@intelligent-play-v2/domain';
import { queryBuilder } from '../queryBuilder';

export const putUserAlertSubscriptions = async (
  pitchId: number,
  userAlertSubscriptions: UserAlertSubscription[]
): Promise<Response> => {
  const { data } = await queryBuilder(
    'PUT',
    `alerts/userAlertSubscriptions`,
    { pitchId },
    { userAlertSubscriptions }
  );
  return data;
};
