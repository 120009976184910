import { DailyWeather } from '@intelligent-play-v2/domain';
import { useQuery } from 'react-query';
import { WeatherParams, getWeatherSummary } from '~/api/queries';
import { useMetadataStore } from '~/stores';
import { getLatLong } from '~/utils/getLatLong';

type WeeklyWeather = DailyWeather[];

export const useWeather = (
  facilityId: number
): { weather?: Record<string, DailyWeather>; isLoading: boolean } => {
  const { facilities } = useMetadataStore();
  const facility = facilities.find(({ id }) => id === facilityId);

  const latLong = facility ? getLatLong(facility) : { x: 0, y: 0 };

  const weatherParams: WeatherParams = {
    latLong: `${latLong.x},${latLong.y}`,
  };

  const { data: weatherSummary, isLoading } = useQuery(
    ['weather', weatherParams],
    async () => getWeatherSummary(weatherParams),
    {
      enabled: !!facilityId,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  return { weather: weatherSummary, isLoading };
};

export const useWeatherInWeeks = (
  facilityId: number
): { weather: WeeklyWeather[]; isLoading: boolean } => {
  const daysInWeek = 7;
  const { weather: weatherSummary, isLoading } = useWeather(facilityId);

  if (!weatherSummary) {
    return { weather: [], isLoading };
  }

  const dataByWeek = Object.values(weatherSummary).reduce(
    (acc, dailyWeather, index) => {
      const weekIndex = Math.floor(index / daysInWeek);

      if (!acc[weekIndex]) {
        acc[weekIndex] = []; // start a new chunk
      }

      acc[weekIndex].push(dailyWeather);

      return acc;
    },
    [] as WeeklyWeather[]
  );

  const finalDay = Object.values(weatherSummary)[
    Object.values(weatherSummary).length - 1
  ];

  dataByWeek[dataByWeek.length - 2].push(finalDay);

  return { weather: dataByWeek, isLoading };
};
