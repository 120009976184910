import React, { FC } from 'react';
import { HeaderTab, PageHeaderWithTabs } from '~/components/layout';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RoutesPath } from '~/types';
import { usePageTitle } from '~/hooks';
import { AdminFacilitiesPage, AdminOverviewPage, AdminUsersPage } from '.';

export const AdminPage: FC = () => {
  usePageTitle('Admin - Intelligent Play');
  return (
    <>
      <PageHeaderWithTabs title="Admin Dashboard">
        <HeaderTab title="Overview" path={RoutesPath.AdminOverview} />
        <HeaderTab title="Users" path={RoutesPath.AdminUsers} />
        <HeaderTab title="Facilities" path={RoutesPath.AdminFacilities} />
      </PageHeaderWithTabs>
      <Switch>
        <Route strict exact path={RoutesPath.Admin}>
          <Redirect to={RoutesPath.AdminOverview} />
        </Route>
        <Route strict path={RoutesPath.AdminOverview}>
          <AdminOverviewPage />
        </Route>
        <Route strict exact path={RoutesPath.AdminUsers}>
          <AdminUsersPage />
        </Route>
        <Route strict path={RoutesPath.AdminFacilities}>
          <AdminFacilitiesPage />
        </Route>
      </Switch>
    </>
  );
};
