import { useState } from 'react';
import { StatsDates, TimePeriod } from '@intelligent-play-v2/domain';
import { formatDateGbUs } from '~/utils';

interface TimePeriodSelectHook {
  selectedTimePeriod: TimePeriod | null;
  setSelectedTimePeriod: (timePeriod: TimePeriod | null) => void;
  customDates: StatsDates | null;
  setCustomDates: (customDates: StatsDates | null) => void;
  customDatesText: string;
}

export const useTimePeriodSelect = (
  defaultTimePeriod: TimePeriod | null,
  defaultCustomDates: StatsDates | null
): TimePeriodSelectHook => {
  const [
    selectedTimePeriod,
    setSelectedTimePeriod,
  ] = useState<TimePeriod | null>(defaultTimePeriod);

  const [customDates, setCustomDates] = useState<StatsDates | null>(
    defaultCustomDates
  );

  const customDatesText = customDates
    ? formatDateGbUs(customDates.startDate, 'dd/MM/yyyy') +
      ' - ' +
      formatDateGbUs(customDates.endDate, 'dd/MM/yyyy')
    : 'Custom range';

  const handleSetCustomDates = (newCustomDates: StatsDates | null): void => {
    if (newCustomDates) {
      setCustomDates({
        startDate: new Date(newCustomDates.startDate || ''),
        endDate: new Date(newCustomDates.endDate || ''),
      });
    }
  };

  return {
    selectedTimePeriod,
    setSelectedTimePeriod,
    customDates,
    setCustomDates: handleSetCustomDates,
    customDatesText,
  };
};
