import { SnapshotSchedule } from '@intelligent-play-v2/domain';
import { queryBuilder } from '../../queryBuilder';

export const postSnapshotSchedule = async (
  body: SnapshotSchedule
): Promise<SnapshotSchedule> => {
  const { data } = await queryBuilder('POST', 'snapshot_schedules', undefined, {
    ...body,
  });
  return data;
};
