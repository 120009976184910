import React, { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Pitch, SnapshotSchedule } from '@intelligent-play-v2/domain';
import { deleteSnapshotSchedule, getSnapshotSchedules } from '~/api/queries';
import { Button, ButtonType, ToggleButton } from '../button';
import { format } from 'date-fns';
import deleteIcon from 'assets/images/ctas_inputs_modals/icon__cta-delete-white.png';
import deleteIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-delete-white@2x.png';
import addIcon from 'assets/images/ctas_inputs_modals/icon__cta-add-16px.png';
import addIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-add-16px@2x.png';
import editIcon from 'assets/images/ctas_inputs_modals/icon__cta-edit.png';
import editIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-edit@2x.png';
import { PromptModal } from '../modals';
import { SnapshotScheduleModal } from '../modals/snapshotScheduleModal';

interface PitchSnapshotScheduleSettingsProps {
  pitch: Pitch;
}

export const PitchSnapshotScheduleSettings: FC<PitchSnapshotScheduleSettingsProps> = ({
  pitch,
}) => {
  const queryClient = useQueryClient();

  const { data: pitchSnapshotSchedules, isError } = useQuery(
    ['getSnapshotSchedules', { pitchId: pitch.id }],
    async () => getSnapshotSchedules(pitch.id)
  );

  const [selectedScheduleId, setSelectedScheduleId] = useState<number | null>(
    null
  );
  const [confirmDeletionScheduleId, setConfirmDeletionScheduleId] = useState<
    number | null
  >(null);
  const [isNewScheduleModalOpen, setIsNewScheduleModalOpen] = useState(false);
  const [isEditScheduleModalOpen, setIsEditScheduleModalOpen] = useState(false);

  const {
    mutate: mutateDeleteSchedule,
    isLoading: isDeletingSchedule,
  } = useMutation(deleteSnapshotSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        'getSnapshotSchedules',
        {
          pitchId: pitch.id,
        },
      ]);
      setConfirmDeletionScheduleId(null);
    },
  });

  const onDeletePresetHandler = (scheduleId: number | null): void => {
    if (!scheduleId) {
      return;
    }
    mutateDeleteSchedule(scheduleId);
  };

  const openNewScheduleModal = (): void => {
    setIsNewScheduleModalOpen(true);
  };

  const openEditScheduleModal = (): void => {
    setIsEditScheduleModalOpen(true);
  };

  if (!pitchSnapshotSchedules || isError) {
    return <div>Failed to fetch snapshot schedules for pitch.</div>;
  }

  const selectedSchedule = pitchSnapshotSchedules.find(
    ({ customScheduleId }) => customScheduleId === selectedScheduleId
  );

  return (
    <div className="pb-2 pl-2 space-y-3">
      <h6 className="font-semibold">Snapshot Schedules</h6>
      <div className="flex flex-wrap w-full gap-2.5">
        {pitchSnapshotSchedules.map(snapshotSchedule => {
          const { customScheduleId } = snapshotSchedule;
          return (
            <ToggleButton
              key={customScheduleId || 0}
              size="large"
              text={getScheduleLabel(snapshotSchedule)}
              onClick={() => setSelectedScheduleId(customScheduleId)}
              active={selectedScheduleId === customScheduleId}
              onDelete={
                customScheduleId
                  ? () => setConfirmDeletionScheduleId(customScheduleId)
                  : null
              }
            />
          );
        })}
      </div>
      <div className="w-24">
        <h6 className="pb-1 text-sm font-semibold">Snapshot times</h6>
        {selectedSchedule?.times
          .sort((timeA, timeB) => timeA.localeCompare(timeB))
          .map((time, i) => (
            <div
              className="w-auto p-2 text-center border border-primary-200"
              key={i}
            >
              {time.split(':').slice(0, 2).join(':')}
            </div>
          ))}
      </div>

      <div className="inline-flex pb-3 space-x-2">
        <Button
          className="w-auto"
          text="Edit schedule"
          size="xsmall"
          type={ButtonType.Outline}
          icon={editIcon}
          icon2x={editIcon2x}
          onClick={openEditScheduleModal}
        />
        <Button
          className="w-auto"
          text="New custom schedule"
          size="xsmall"
          type={ButtonType.Secondary}
          icon={addIcon}
          icon2x={addIcon2x}
          onClick={openNewScheduleModal}
        />
      </div>

      <PromptModal
        showModal={!!confirmDeletionScheduleId}
        title="Are you sure you want to delete this?"
        primaryButton={
          <Button
            text="Yes, delete"
            type={ButtonType.Danger}
            size={'small'}
            onClick={() => onDeletePresetHandler(confirmDeletionScheduleId)}
            isLoading={isDeletingSchedule}
            icon={deleteIcon}
            icon2x={deleteIcon2x}
          />
        }
        secondaryButton={
          <Button
            text="Cancel"
            onClick={() => setConfirmDeletionScheduleId(null)}
            type={ButtonType.Outline}
            size={'small'}
          />
        }
      />
      <SnapshotScheduleModal
        showModal={isNewScheduleModalOpen}
        setShowModal={setIsNewScheduleModalOpen}
        pitchId={pitch.id}
        pitchSnapshotSchedules={pitchSnapshotSchedules}
      />
      <SnapshotScheduleModal
        selectedSchedule={selectedSchedule}
        showModal={isEditScheduleModalOpen}
        setShowModal={setIsEditScheduleModalOpen}
        pitchId={pitch.id}
        pitchSnapshotSchedules={pitchSnapshotSchedules}
      />
    </div>
  );
};

const scheduleLabelFormat = 'MMM dd yyyy';
const getScheduleLabel = (snapshotSchedule: SnapshotSchedule): string =>
  snapshotSchedule.customScheduleId &&
  snapshotSchedule.startDate &&
  snapshotSchedule.endDate
    ? `${format(
        new Date(snapshotSchedule.startDate),
        scheduleLabelFormat
      )} - ${format(new Date(snapshotSchedule.endDate), scheduleLabelFormat)}`
    : 'Default';
