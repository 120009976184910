import { Country } from 'countries-and-timezones';
import flagAU from 'assets/images/flags/icon__flag-au.png';
import flagNZ from 'assets/images/flags/icon__flag-nz.png';
import flagUK from 'assets/images/flags/icon__flag-uk.png';
import flagUS from 'assets/images/flags/icon__flag-us.png';
import flagES from 'assets/images/flags/icon__flag-es.png';
import flagPR from 'assets/images/flags/icon__flag-pr.png';
import flagNL from 'assets/images/flags/icon__flag-nl.png';
import flagSE from 'assets/images/flags/icon__flag-se.png';

import flagAU2x from 'assets/images/flags/icon__flag-au@2x.png';
import flagNZ2x from 'assets/images/flags/icon__flag-nz@2x.png';
import flagUK2x from 'assets/images/flags/icon__flag-uk@2x.png';
import flagUS2x from 'assets/images/flags/icon__flag-us@2x.png';
import flagES2x from 'assets/images/flags/icon__flag-es@2x.png';
import flagPR2x from 'assets/images/flags/icon__flag-pr@2x.png';
import flagNL2x from 'assets/images/flags/icon__flag-nl@2x.png';
import flagSE2x from 'assets/images/flags/icon__flag-se@2x.png';

export const getCountryFlag = (
  country: Country
): {
  flagIcon: string;
  flagIcon2x: string;
} => {
  const countryId = country.id as
    | 'AU'
    | 'NZ'
    | 'GB'
    | 'US'
    | 'ES'
    | 'PR'
    | 'NL';
  const flags = flagIconMapping[countryId];
  return flags;
};

const flagIconMapping = {
  AU: {
    flagIcon: flagAU,
    flagIcon2x: flagAU2x,
  },
  NZ: {
    flagIcon: flagNZ,
    flagIcon2x: flagNZ2x,
  },
  GB: {
    flagIcon: flagUK,
    flagIcon2x: flagUK2x,
  },
  US: {
    flagIcon: flagUS,
    flagIcon2x: flagUS2x,
  },
  ES: {
    flagIcon: flagES,
    flagIcon2x: flagES2x,
  },
  PR: {
    flagIcon: flagPR,
    flagIcon2x: flagPR2x,
  },
  NL: {
    flagIcon: flagNL,
    flagIcon2x: flagNL2x,
  },
  SE: {
    flagIcon: flagSE,
    flagIcon2x: flagSE2x,
  },
};
