import React, { CSSProperties, FC } from 'react';
import { getClosestColor } from './utils';
import clockIcon from 'assets/images/pages/field/heatmap/icon__sectors-hours.png';
import clockIcon2x from 'assets/images/pages/field/heatmap/icon__sectors-hours@2x.png';
import euhIcon from 'assets/images/pages/field/heatmap/icon__sectors-EUH.png';
import euhIcon2x from 'assets/images/pages/field/heatmap/icon__sectors-EUH@2x.png';

interface UsageZoneProps {
  value: number;
  totalEuh: number;
  max: number;
}

export const UsageZone: FC<UsageZoneProps> = ({ value, totalEuh, max }) => {
  const colour = getClosestColor(value, max);

  const style = {
    backgroundColor: `rgb(${colour})`,
    flexDirection: 'column',
    display: 'grid',
    placeItems: 'center',
    fontWeight: 'bold',
  };

  return (
    <div style={style as CSSProperties}>
      <div className="w-1/2 py-2 text-center bg-white min-w-18">
        <div className="flex flex-row justify-center space-x-1">
          <picture>
            <img
              className="h-4 transform translate-y-1"
              src={clockIcon2x}
              srcSet={`${clockIcon} 1x, ${clockIcon2x} 2x`}
            />
          </picture>
          <span>
            {totalEuh !== 0 ? ((value / totalEuh) * 100).toFixed(1) : 0}%
          </span>
        </div>
        <div className="flex flex-row justify-center space-x-1">
          <picture>
            <img
              className="h-4 transform translate-y-1"
              src={euhIcon2x}
              srcSet={`${euhIcon} 1x, ${euhIcon2x} 2x`}
            />
          </picture>
          <span>{value.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};
