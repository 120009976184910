import { KeyStatisticColour, KeyStatisticType } from '~/types';

import playerHoursIcon from 'assets/images/pages/dashboard/key_stats/icon__player-hours.png';
import playerHoursIcon2x from 'assets/images/pages/dashboard/key_stats/icon__player-hours@2x.png';
import usageHoursIcon from 'assets/images/pages/dashboard/key_stats/icon__usage-hours.png';
import usageHoursIcon2x from 'assets/images/pages/dashboard/key_stats/icon__usage-hours@2x.png';
import euhIcon from 'assets/images/pages/dashboard/key_stats/icon__EUH.png';
import euhIcon2x from 'assets/images/pages/dashboard/key_stats/icon__EUH@2x.png';
import maintenanceIcon from 'assets/images/pages/dashboard/key_stats/icon__maintenance.png';
import maintenanceIcon2x from 'assets/images/pages/dashboard/key_stats/icon__maintenance@2x.png';
import overdueIcon from 'assets/images/pages/dashboard/key_stats/icon__overdue.png';
import overdueIcon2x from 'assets/images/pages/dashboard/key_stats/icon__overdue@2x.png';
import alertsIcon from 'assets/images/pages/dashboard/key_stats/icon__alerts.png';
import alertsIcon2x from 'assets/images/pages/dashboard/key_stats/icon__alerts@2x.png';

interface KeyStatisticUI {
  title: string;
  icon: string;
  icon2x: string;
  infoText: string;
  colour: KeyStatisticColour;
}

export const getKeyStatisticUI = (
  keyStatistic: KeyStatisticType
): KeyStatisticUI => {
  switch (keyStatistic) {
    case KeyStatisticType.PlayerHours:
      return {
        title: 'Player hours',
        icon: playerHoursIcon,
        icon2x: playerHoursIcon2x,
        infoText:
          'The number of cumulative hours that players have been tracked during a specified time period.',
        colour: KeyStatisticColour.Green,
      };
    case KeyStatisticType.UsageHours:
      return {
        title: 'Usage hours',
        icon: usageHoursIcon,
        icon2x: usageHoursIcon2x,
        infoText:
          'The number of cumulative hours that fields have had any use on it during a specified time period.',
        colour: KeyStatisticColour.Green,
      };
    case KeyStatisticType.EquivalentUserHours:
      return {
        title: 'Equivalent User Hours',
        icon: euhIcon,
        icon2x: euhIcon2x,
        infoText:
          'Cumulative player hours divided by benchmark player count for each field during a specified time period.',
        colour: KeyStatisticColour.Green,
      };
    case KeyStatisticType.MaintenanceHours:
      return {
        title: 'Maintenance hours',
        icon: maintenanceIcon,
        icon2x: maintenanceIcon2x,
        infoText:
          'The number of cumulative hours that maintenance vehicles have been tracked during a specified time period.',
        colour: KeyStatisticColour.Green,
      };
    case KeyStatisticType.OverdueMaintananeDays:
      return {
        title: 'Overdue Maintenance days',
        icon: overdueIcon,
        icon2x: overdueIcon2x,
        infoText:
          'The total number of maintenance days that have been marked as overdue during a specified time period.',
        colour: KeyStatisticColour.Yellow,
      };
    case KeyStatisticType.OutOfHoursAlerts:
      return {
        title: 'Out of hours alerts',
        icon: alertsIcon,
        icon2x: alertsIcon2x,
        infoText:
          'The total number of out of hours alerts during a specified time period.',
        colour: KeyStatisticColour.Yellow,
      };
    default:
      return {
        title: '',
        icon: '',
        icon2x: '',
        infoText: '',
        colour: KeyStatisticColour.Green,
      };
  }
};
