import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { User } from '@intelligent-play-v2/domain';
import {
  PatchUserAsAdminParams,
  deleteUser,
  getUsers,
  patchUserAsAdmin,
} from '~/api';
import { Button, ButtonType } from '~/components/button';
import { ContentContainer, PageHeaderWithButtons } from '~/components/layout';
import { PromptModal } from '~/components/modals';
import {
  UserActivityHistory,
  UserFacilitiesList,
  UserPitchPermissions,
} from '~/components/userDetails';
import { AdminUserDetails } from '~/components/userDetails/AdminUserDetails';
import { RoutesPath } from '~/types';
import deleteIcon from 'assets/images/ctas_inputs_modals/icon__cta-delete-white.png';
import deleteIcon2x from 'assets/images/ctas_inputs_modals/icon__cta-delete-white@2x.png';
import { useCheckedArray } from '~/hooks';
import { useMetadataStore } from '~/stores';

export const AdminUserProfilePage: FC = () => {
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const userInputImageRef = useRef<HTMLInputElement | null>(null);
  const [imageFile, setImageFile] = useState<File>();
  const [imagePreview, setImagePreview] = useState('');

  const history = useHistory();
  const queryClient = useQueryClient();
  const { userId } = useParams<{ userId: string }>();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [selectedUser, setSelectedUser] = useState<User | null | undefined>(
    undefined
  );
  const { facilities } = useMetadataStore();

  const allPitchIds = facilities.reduce((accum: number[], facility) => {
    const facilityPitchIds =
      facility.pitches?.map(pitch => {
        return pitch.id;
      }) ?? [];
    accum.push(...facilityPitchIds);
    return accum;
  }, []);

  const sortedFacilities = [...facilities].sort((a, b) => {
    const nameA = a.name?.toUpperCase() ?? '';
    const nameB = b.name?.toUpperCase() ?? '';
    return nameA < nameB ? -1 : 1;
  });

  const {
    checkedItems: checkedPitchIds,
    toggleCheckedMany: toggleCheckedPitchIds,
    setCheckedItems: setCheckedPitchIds,
  } = useCheckedArray(selectedUser?.pitchAccess || []);

  const { mutate: mutatePatchUser, isLoading: isSaving } = useMutation(
    patchUserAsAdmin,
    {
      onSuccess: () => {
        toast.success('Successfully saved user');
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries('facilities');
      },
      onError: () => {
        toast.error('An error occured when saving user');
      },
    }
  );

  const { mutate: mutateDeleteUser, isLoading: isDeleting } = useMutation(
    deleteUser,
    {
      onSuccess: () => {
        toast.success('Successfully deleted user');
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries('facilities');
        history.push(RoutesPath.AdminUsers);
      },
      onError: () => {
        toast.error('An error occured when deleting user');
      },
    }
  );

  const { data: users, isLoading } = useQuery('users', async () => getUsers());

  useEffect(() => {
    const user = users?.find(u => u.id === +userId);
    setSelectedUser(user || null);
    setCheckedPitchIds(user?.pitchAccess || []);
  }, [setCheckedPitchIds, userId, users]);

  useEffect(() => {
    if (imageFile) {
      if (imageFile.size > 5000000) {
        toast.error('Maximum image size exceeded - 5mb');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImagePreview('');
    }
  }, [imageFile]);

  // finding the user, very quick but we don't want to display the modal
  if (isLoading || selectedUser === undefined) {
    return <></>;
  }
  // userId does not exist
  if (selectedUser === null) {
    return (
      <PromptModal
        title="User not found"
        type="danger"
        text="This user does not exist, or you do not have permission to view it"
        primaryButton={
          <Button
            text="Close"
            size="small"
            onClick={() => history.push(RoutesPath.Admin)}
          />
        }
        showModal={true}
      />
    );
  }

  const onSubmit = (data: PatchUserAsAdminParams): void => {
    const patchUserParams: PatchUserAsAdminParams = {
      userId: selectedUser.id,
      pitchAccess: checkedPitchIds,
    };
    const name = data.firstName + ' ' + data.lastName;
    if (name !== selectedUser.name) {
      patchUserParams.name = name;
    }
    if (data.email !== selectedUser.email) {
      patchUserParams.email = data.email;
    }
    if (data.phoneNumber !== selectedUser.phoneNumber) {
      patchUserParams.phoneNumber = data.phoneNumber;
    }
    if (
      data.userTypeId &&
      data.userTypeId.toString() !== selectedUser.userTypeId.toString()
    ) {
      patchUserParams.userTypeId = data.userTypeId;
    }
    if (data.jobTitle !== selectedUser.jobTitle) {
      patchUserParams.jobTitle = data.jobTitle;
    }
    if (
      data.clientId &&
      data.clientId.toString() !== selectedUser.clientId.toString()
    ) {
      patchUserParams.clientId = data.clientId;
    }
    if (imagePreview) {
      patchUserParams.image = imagePreview.split(',')[1];
    }

    mutatePatchUser(patchUserParams);
  };

  return (
    <>
      <PageHeaderWithButtons title={selectedUser.name + "'s profile"}>
        <div className="flex space-x-5">
          <Button
            type={ButtonType.Outline}
            text="Return"
            className="pb-0.75"
            onClick={history.goBack}
          />
          <Button
            type={ButtonType.Danger}
            className="pb-0.75"
            icon={deleteIcon}
            icon2x={deleteIcon2x}
            onClick={() => setShowDeletionModal(true)}
          />
          <Button
            type={ButtonType.Secondary}
            text="Save changes"
            className="pb-0.75"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSaving}
          />
        </div>
      </PageHeaderWithButtons>
      <ContentContainer className="py-12.5 space-y-7.5">
        <div className="flex lg:space-x-15 space-x-7.5">
          <div className="w-2/3">
            <AdminUserDetails
              user={selectedUser}
              register={register}
              setValue={setValue}
              errors={errors}
              userInputImageRef={userInputImageRef}
              setImageFile={setImageFile}
              imagePreview={imagePreview}
            />
            <div className="flex space-x-12 pt-7.5">
              <div className="w-1/2 ">
                <UserActivityHistory
                  userActivity={selectedUser.userActivity || []}
                />
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <UserFacilitiesList user={selectedUser} />
          </div>
        </div>
        <div className="w-full">
          <UserPitchPermissions
            checkedPitchIds={checkedPitchIds}
            toggleCheckedPitchIds={toggleCheckedPitchIds}
            allPitchIds={allPitchIds}
            sortedFacilities={sortedFacilities}
          />
        </div>
      </ContentContainer>
      <PromptModal
        showModal={showDeletionModal}
        title="Are you sure you want to delete this?"
        primaryButton={
          <Button
            text="Yes, delete"
            type={ButtonType.Danger}
            size={'small'}
            onClick={() => mutateDeleteUser(+userId)}
            isLoading={isDeleting}
            icon={deleteIcon}
            icon2x={deleteIcon2x}
          />
        }
        secondaryButton={
          <Button
            text="Cancel"
            onClick={() => setShowDeletionModal(false)}
            type={ButtonType.Outline}
            size={'small'}
          />
        }
      />
    </>
  );
};
