import create from 'zustand';
import { User } from '@intelligent-play-v2/domain';
import { getWhoami } from '~/api';
import axios from 'axios';

interface AuthStore {
  user: User | null;
  isTimedOutError: boolean;
  fetchUser: () => void;
  setUser: (user: User | null) => void;
  logout: () => void;
}

const setupAxiosInterceptors = (onUnauthenticated: () => void): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onResponseFail = async (error: any): Promise<any> => {
    const status = error.status || error.response.status;
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(error);
  };
  axios.interceptors.response.use(undefined, onResponseFail);
};

export const useAuthStore = create<AuthStore>(set => {
  setupAxiosInterceptors(() => {
    set({ isTimedOutError: true });
  });
  return {
    user: null,
    isTimedOutError: false,
    fetchUser: async () => {
      const user = await getWhoami();
      set({ user, isTimedOutError: false });
    },
    setUser: user => {
      set({ user, isTimedOutError: false });
    },
    logout: () => {
      set({ user: null, isTimedOutError: false });
    },
  };
});
