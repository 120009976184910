import React, { FC, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonType } from '~/components/button';
import { getUsers } from '~/api';
import { SectionHeader } from '~/components/layout';
import { SearchBar } from '~/components/searchBar';
import { Select, SelectOptionType } from '~/components/select';
import { RoutesPath, UserRow } from '~/types';
import { Table } from '..';
import { userColumns } from './userColumns';
import CsvDownloader from 'react-csv-downloader';
import toast from 'react-hot-toast';

import addUserIcon from 'assets/images/pages/admin/icon__user-add-button.png';
import addUserIcon2x from 'assets/images/pages/admin/icon__user-add-button@2x.png';
import { Modal } from '~/components/modals';
import { AddUserForm } from '~/components/admin/AddUserForm';
import { Client, getUserTypeString } from '@intelligent-play-v2/domain';
import { sortUsers } from '~/utils';
import { Loading } from '~/components/loading';
import { DownloadButton } from '~/components/uiButton';
import { format } from 'date-fns';

interface UsersTableProps {
  showTitle: boolean;
  xlWidth?: 'full' | 'half';
  showTableHeaders?: boolean;
  showCount?: boolean;
  showAddUserButton?: boolean;
  rowsPerPage?: number;
}

export const UsersTable: FC<UsersTableProps> = ({
  showTitle,
  xlWidth = 'full',
  showTableHeaders,
  showCount,
  showAddUserButton,
  rowsPerPage,
}) => {
  const queryClient = useQueryClient();

  let tableWidth = xlWidth;
  if (useMediaQuery({ query: '(max-width: 1536px)' })) {
    tableWidth = 'full';
  }
  const isSmallerThanLg = useMediaQuery({ query: '(max-width: 1024px)' });
  const tableCellLinksEnabled = isSmallerThanLg || tableWidth === 'half';

  const [sortedUsers, setSortedUsers] = useState<UserRow[]>([]);

  const [searchText, setSearchText] = useState('');

  const [sortBy, setSortBy] = useState<SelectOptionType>(sortingOptions[0]);

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleAddSuccess = (): void => {
    toast.success('Successfully added user');
    setShowAddUserModal(false);
    queryClient.invalidateQueries('users');
  };
  const { data: users, isLoading } = useQuery('users', async () => getUsers());

  const [userRows, setUserRows] = useState<UserRow[]>([]);

  const csvFilename = `ip_dashboard_users_${format(
    new Date(),
    'yyyy-MM-dd'
  )}.csv`;

  useEffect(() => {
    setUserRows(
      users?.map(user => ({
        ...user,
        key: user.id.toString(),
        link: RoutesPath.AdminUserProfile.replace(
          ':userId',
          user.id.toString()
        ),
      })) ?? []
    );
  }, [users]);

  useEffect(() => {
    const filteredUsers =
      userRows.filter(
        user =>
          user.name?.toUpperCase().includes(searchText.toUpperCase()) ||
          getUserTypeString(user.userTypeId)
            .toUpperCase()
            .includes(searchText.toUpperCase()) ||
          user.email?.toUpperCase().includes(searchText.toUpperCase())
      ) ?? [];

    sortUsers(filteredUsers, sortBy);

    setSortedUsers(filteredUsers);
  }, [searchText, sortBy, userRows]);

  return (
    <div>
      <SectionHeader
        title={showTitle ? 'Users' : undefined}
        subtitle={
          showCount ? (
            <span>
              Total users:
              <span className="font-semibold text-primary-900">
                {` ${userRows.length}`}
              </span>
            </span>
          ) : null
        }
      >
        <div className="pt-1 pl-5">
          <CsvDownloader
            datas={sortedUsers.map(user => ({
              email: user.email,
              name: user.name,
              phoneNumber: user.phoneNumber,
              jobTitle: user.jobTitle,
              userType: getUserTypeString(user.userTypeId),
              client: Client[user.clientId],
              createdAt: user.createdAt.toString(),
              modifiedAt: user.modifiedAt.toString(),
            }))}
            filename={csvFilename}
          >
            <DownloadButton />
          </CsvDownloader>
        </div>
        <div className="flex ml-auto space-x-4">
          {showAddUserButton && (
            <div>
              <Button
                type={ButtonType.Secondary}
                text={'Add a new user'}
                size={'xsmall'}
                icon={addUserIcon}
                icon2x={addUserIcon2x}
                onClick={() => setShowAddUserModal(true)}
              />
            </div>
          )}
          <div className="w-36">
            <Select
              key="sort"
              options={sortingOptions}
              selectedValue={sortBy}
              onChange={option => setSortBy(option || sortingOptions[0])}
              shadow
            />
          </div>
        </div>
      </SectionHeader>
      <SearchBar searchText={searchText} onChange={setSearchText} />
      {isLoading ? (
        <div>
          <Loading />{' '}
        </div>
      ) : (
        <Table
          rows={sortedUsers}
          columns={userColumns(tableWidth)}
          showTableHeaders={showTableHeaders}
          linksEnabled={tableCellLinksEnabled}
          rowsPerPage={rowsPerPage}
        />
      )}
      <Modal
        showModal={showAddUserModal}
        setShowModal={setShowAddUserModal}
        title="Add new user"
      >
        <AddUserForm onSuccess={handleAddSuccess} />
      </Modal>
    </div>
  );
};

const sortingOptions: SelectOptionType[] = [
  {
    label: 'A-Z',
    value: 'a-z',
  },
  {
    label: 'Z-A',
    value: 'z-a',
  },
  {
    label: 'Recently Added',
    value: 'recentlyAdded',
  },
];
