import React, { FC, MouseEvent } from 'react';
import { TArg, classnames } from 'tailwindcss-classnames';
import { Loading } from '../loading';

export enum ButtonType {
  Primary,
  Secondary,
  Danger,
  Outline,
  OutlineDanger,
}

interface ButtonProps {
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string | TArg;
  size?: 'small' | 'xsmall';
  type?: ButtonType;
  isLoading?: boolean;
  icon?: string;
  icon2x?: string;
  disabledStyle?: 'light' | 'dark';
}

export const Button: FC<ButtonProps> = ({
  text,
  onClick,
  disabled = false,
  className = '',
  size,
  type = ButtonType.Primary,
  isLoading = false,
  icon,
  icon2x,
  disabledStyle = 'light',
}) => {
  const buttonClassnames = (className as string).split(' ') as TArg[];
  const isDisabled = disabled || isLoading;
  const btnClass = classnames(
    { 'bg-green': !isDisabled && type === ButtonType.Primary },
    { 'shadow-green-dark': !isDisabled && type === ButtonType.Primary },
    { 'hover:bg-green-dark': !isDisabled && type === ButtonType.Primary },
    { 'text-white': !isDisabled && type === ButtonType.Primary },
    { 'bg-blue': !isDisabled && type === ButtonType.Secondary },
    { 'shadow-blue-dark': !isDisabled && type === ButtonType.Secondary },
    { 'hover:bg-blue-dark': !isDisabled && type === ButtonType.Secondary },
    { 'text-white': !isDisabled && type === ButtonType.Secondary },
    { 'bg-red': !isDisabled && type === ButtonType.Danger },
    { 'shadow-red-dark': !isDisabled && type === ButtonType.Danger },
    { 'hover:bg-red-dark': !isDisabled && type === ButtonType.Danger },
    { 'text-white': !isDisabled && type === ButtonType.Danger },
    { 'bg-white': !isDisabled && type === ButtonType.Outline },
    { border: !isDisabled && type === ButtonType.Outline },
    { 'border-primary-250': !isDisabled && type === ButtonType.Outline },
    { 'shadow-250': !isDisabled && type === ButtonType.Outline },
    { 'hover:bg-primary-100': !isDisabled && type === ButtonType.Outline },
    { 'text-primary-900': !isDisabled && type === ButtonType.Outline },
    { 'bg-white': !isDisabled && type === ButtonType.OutlineDanger },
    { 'shadow-250': !isDisabled && type === ButtonType.OutlineDanger },
    {
      'hover:bg-primary-100': !isDisabled && type === ButtonType.OutlineDanger,
    },
    { 'text-red': !isDisabled && type === ButtonType.OutlineDanger },
    { 'bg-primary-600': isDisabled && disabledStyle === 'dark' },
    { 'shadow-550': isDisabled && disabledStyle === 'dark' },
    { 'opacity-50': isDisabled && disabledStyle === 'dark' },
    { 'cursor-not-allowed': isDisabled },
    { 'bg-primary-100': isDisabled && disabledStyle === 'light' },
    { 'shadow-250': isDisabled && disabledStyle === 'light' },
    { 'text-primary-400': isDisabled && disabledStyle === 'light' },
    { 'h-9': size === 'small' },
    { 'rounded-xs': size === 'small' },
    { 'text-base': size === 'small' },
    { 'px-2': size === 'small' },
    { 'h-7.5': size === 'xsmall' },
    { 'rounded-xs': size === 'xsmall' },
    { 'text-sm': size === 'xsmall' },
    { 'font-normal': size === 'xsmall' },
    { 'px-2': size === 'xsmall' },
    { 'px-8': !size },
    { 'h-12.5': !size },
    { rounded: !size },
    { 'text-lg': !size },
    { 'font-semibold': size !== 'xsmall' },
    'w-full',
    'flex',
    'items-center',
    'justify-center',
    'space-x-2',
    ...buttonClassnames
  );

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    if (onClick) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <button
      disabled={isDisabled}
      type="submit"
      onClick={onClickHandler ?? null}
      className={btnClass}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {icon && (
            <picture className="flex-shrink-0 w-4">
              <img src={icon} srcSet={`${icon} 1x, ${icon2x} 2x`} />
            </picture>
          )}
          {text && <p className="font-semibold whitespace-nowrap">{text}</p>}
        </>
      )}
    </button>
  );
};
