export const getApiUrl = (): string => {
  const { hostname } = location;
  if (hostname === 'localhost') {
    return 'http://localhost:4000';
  } else if (
    /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/.test(hostname)
  ) {
    return `http://${hostname}:4000`;
  }
  return `https://api.${hostname}`;
};
