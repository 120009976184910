import React, { FC } from 'react';

interface AuthTextProps {
  text: string;
}

export const AuthText: FC<AuthTextProps> = ({ text }) => {
  return (
    <p className="!mt-2 text-base font-medium leading-5 sm:!mt-7">{text}</p>
  );
};
