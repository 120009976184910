import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { classnames } from 'tailwindcss-classnames';

import { HeatmapParams, getUsageHeatmap } from '~/api';
import { useReportStore } from '~/stores/useReportStore';
import { HeatmapSectors } from '../heatmap/heatmapSectors';
import { Pitch, StatsDates, TimePeriod } from '@intelligent-play-v2/domain';
import { HeatmapContainer } from '../heatmap/heatmapContainer';
import { HeatmapSectorsDivision } from '../heatmap/utils';
import { pageBreakStyle } from '../data/styles';
import { useReportQueryParams } from '~/hooks/useReportQueryParams.hook';
import { LabelledCheckbox } from '../labelledCheckbox';

interface PitchUsageHeatmapProps {
  pitch: Pitch;
  customDates: StatsDates | null;
  timePeriod: TimePeriod;
  timezone: string;
  isPrintableVersion?: boolean;
}

export const PitchUsageHeatmap: FC<PitchUsageHeatmapProps> = ({
  pitch,
  customDates,
  timePeriod,
  timezone,
  isPrintableVersion = false,
}) => {
  const {
    pdfIncludedPitchHeatmaps,
    setPdfIncludedPitchHeatmap,
  } = useReportStore();

  const { dailyStartTime, dailyEndTime, includedDays } = useReportQueryParams();
  const [
    lastSelectedDivision,
    setLastSelectedDivision,
  ] = useState<HeatmapSectorsDivision>(HeatmapSectorsDivision.Thirds);

  const heatmapParams: HeatmapParams = {
    pitchId: pitch.id,
    timePeriod,
    timezone,
    includedDays,
    dailyStartTime,
    dailyEndTime,
  };
  if (timePeriod === TimePeriod.Custom && customDates) {
    heatmapParams.from = format(customDates.startDate, 'yyyy-MM-dd');
    heatmapParams.to = format(customDates.endDate, 'yyyy-MM-dd');
  }

  const { data: heatmap, isLoading: isLoadingHeatmap } = useQuery(
    ['usageHeatmap', heatmapParams],
    async () => getUsageHeatmap(heatmapParams),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      suspense: true,
    }
  );

  if (isLoadingHeatmap || !heatmap || !heatmap.data.length) {
    return (
      <div>{isLoadingHeatmap ? 'Loadng...' : 'No heatmap data found'}</div>
    );
  }

  const componentClassNames = classnames({
    border: isPrintableVersion,
    'border-primary-200': isPrintableVersion,
    'mb-4': !isPrintableVersion,
  });

  const containerClassNames = classnames('flex', {
    'flex-row': isPrintableVersion,
    'justify-around': isPrintableVersion,
    'border-t': isPrintableVersion,
    'border-primary-200': isPrintableVersion,
    'justify-between': !isPrintableVersion,
    'flex-col': !isPrintableVersion,
    'lg:flex-row': !isPrintableVersion,
    'px-15': !isPrintableVersion,
    'space-y-4': !isPrintableVersion,
    'lg:space-x-2': !isPrintableVersion,
  });

  const heatmapClassNames = classnames({
    'w-1/2': isPrintableVersion,
    'lg:w-9/16': !isPrintableVersion,
  });

  const sectorsClassNames = classnames('h-full', {
    'w-7/16': isPrintableVersion,
    'lg:w-6/16': !isPrintableVersion,
  });

  const selectedSectorDivision = pdfIncludedPitchHeatmaps?.get(pitch.id);
  const isIncluded = selectedSectorDivision !== null;

  const toggleIncluded = (): void => {
    if (isIncluded) {
      setLastSelectedDivision(
        selectedSectorDivision || HeatmapSectorsDivision.Thirds
      );
      setPdfIncludedPitchHeatmap(pitch.id, null);
    } else {
      setPdfIncludedPitchHeatmap(pitch.id, lastSelectedDivision);
    }
  };

  const setSelectedSectorDivision = (
    selectedDivision: HeatmapSectorsDivision
  ): void => {
    if (isIncluded) {
      setPdfIncludedPitchHeatmap(pitch.id, selectedDivision);
    } else {
      setLastSelectedDivision(selectedDivision);
    }
  };

  return (
    <div
      style={pageBreakStyle}
      className={`${componentClassNames} bg-white rounded shadow-250 mt-4`}
    >
      <div className="flex flex-row justify-between px-4 py-3.5 border-b border-primary-100 items-center">
        <div className="text-xl font-semibold">{pitch.name}</div>

        {!isPrintableVersion ? (
          <LabelledCheckbox
            title={'Include in PDF Export'}
            isChecked={isIncluded}
            toggleChecked={toggleIncluded}
          />
        ) : null}
      </div>
      <div
        className={`${containerClassNames} justify-between w-full p-5 lg:space-y-0 lg:space-x-2`}
      >
        <div className={heatmapClassNames}>
          <HeatmapContainer
            heatmapImage={heatmap ? heatmap.image : ''}
            isLoadingHeatmap={false}
            pitchWidth={pitch.widthMeters}
            pitchLength={pitch.lengthMeters}
          />
        </div>
        <div className={sectorsClassNames}>
          <HeatmapSectors
            heatmap={heatmap.data}
            isLoading={false}
            benchmarkPlayerCount={pitch.benchamarkPlayerCount || 0}
            isPrintableVersion={isPrintableVersion}
            selectedDivision={selectedSectorDivision || lastSelectedDivision}
            setSelectedDivision={setSelectedSectorDivision}
          />
        </div>
      </div>
    </div>
  );
};
