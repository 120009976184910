import React, { FC } from 'react';
import { ContentContainer } from '~/components/layout';
import { AdminFacilityTable } from '~/components/table/adminFacilityTable/AdminFacilityTable';

export const AdminFacilitiesPage: FC = () => {
  return (
    <ContentContainer className="py-12.5">
      <AdminFacilityTable
        showTitle
        showTableHeaders
        showCount
        rowsPerPage={10}
      />
    </ContentContainer>
  );
};
